import React, { useRef, useState } from "react";
import { ChevronLeft, ChevronRight, Trash } from "react-bootstrap-icons";
import { LocationFilterDrop } from "../../../widgets/Dropdown/accountDeactivationDrop";
import CustomisedButton from "../../../widgets/Button/Button";
import SearchInput from "../../../widgets/SearchInput/searchInput";
import { Form } from "react-bootstrap";

export function RoleFilterDrop({ display, func, setData, defaultData }) {
  const toggleDrop = () => func();

  const [state, setState] = useState({
    All: true,
  });
  const handleDoneBtn = () => {
    toggleDrop();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("role", state);
  };

  const handleChecks = (id) => {
    if (id !== "All" && state["All"]) {
      setState({ ...state, All: false, [id]: !state[id] });
    } else {
      setState({ ...state, [id]: !state[id] });
    }
  };

  return (
    <div
      className="drop_wrapper location_filter"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section flex-between p-20 mbd">
        <div className="flex-center" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 point" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton text="Done" bg="#FFDA9A" fg="#000" width="56px" />
          </span>
        </div>
      </div>
      <div className="drop_main_content">
        <div className="flex-start mbd ph-10">
          <SearchInput height="35px" width="200px" placeholder="Search..." />
        </div>
      </div>
      <div className="overflow">
        <div className="drop_main_content">
          <div className="flex-start mbd p-20">
            <span className="mr-10" onClick={() => handleChecks("All")}>
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={state["All"]}
              />
            </span>
            All
          </div>
        </div>
        <div className="drop_main_content p-20">
          {defaultData.map((item, index) => (
            <div className="flex-start drop_item mt-10" key={index}>
              <span className="mr-10" onClick={() => handleChecks(item.id)}>
                <Form.Check
                  type="checkbox"
                  className="menu_chkbox"
                  checked={state[item.id]}
                  onChange={() => handleChecks(item.id)}
                />
              </span>
              {item.name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function AdministrationFilterDropdown(props) {
  const { display, func, setData, filters, defaultData } = {
    ...props,
  };

  const filterRef = useRef(null);

  const [state, setState] = useState({});

  const toggleDrop = () => {
    setState({});
    func();
  };

  const clearFilters = () => {
    func("clear");
  };

  const checkCategory = (category) => {
    setState({});
    setState({ [category]: true });
    func();
  };

  return (
    <div ref={filterRef}>
      <div
        className="drop_wrapper filter_drop"
        style={{ display: display ? "block" : "none" }}
      >
        <div className="head_section flex-between p-20">
          <span>Select a filter</span>
          <div
            className="filter_title"
            onClick={clearFilters}
            style={{ color: filters > 0 ? "#D41811" : "" }}
          >
            Clear All&nbsp;
            <Trash size={15} className="filter_trash_icon" />
          </div>
        </div>
        <div className="drop_main_content p-20">
          <div
            className="flex-between"
            onClick={() => checkCategory("location")}
          >
            Location
            <ChevronRight size={15} className="right_icon" />
          </div>
          <div
            className="flex-between mt-10"
            onClick={() => checkCategory("role")}
          >
            Role
            <ChevronRight size={15} className="right_icon" />
          </div>
        </div>
      </div>
      <LocationFilterDrop
        display={state["location"]}
        func={toggleDrop}
        setData={setData}
      />
      <RoleFilterDrop
        display={state["role"]}
        func={toggleDrop}
        setData={setData}
        defaultData={defaultData?.roles}
      />
    </div>
  );
}
