import React, { useEffect, useState } from "react";
import Cards from "../../components/Experiences/Cards";
import Infos from "../../components/Experiences/Infos";

import { AllExperiences } from "../../services/query/experiences";
import { AllPurchases } from "../../services/query/purchases";
import { matchDateRange } from "../../utils/helpers";

import "../../scss/Admin/experiences.scss";

const thisWeekData = (data) => {
  return data.filter((elt) =>
    matchDateRange(elt.createdAt, 7)
  );
}


function StorePage() {
  const [experiencesData, setExperiencesData] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [experiencesThisWeek, setExperiencesThisWeek] = useState(0);
  const [purchasesThisWeek, setPurchasesThisWeek] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalSalesThisWeek, setTotalSalesThisWeek] = useState(0);

  useEffect(() => {
    AllExperiences().then((experiences) => {
      setExperiencesData(experiences);
      setExperiencesThisWeek(thisWeekData(experiences).length);
    });
    AllPurchases().then((purchases) => {
      setPurchases(purchases);
      const purchasesThisWeek = thisWeekData(purchases)
      setPurchasesThisWeek(purchasesThisWeek.length);
      const totalSales = purchases.reduce((acc, item) => acc + item.amount, 0);
      setTotalSalesThisWeek(purchasesThisWeek.reduce((acc, item) => acc + item.amount, 0));
      setTotalSales(totalSales);
    });
  }, []);

  return (
    <div className="experiences_main_content">
      <Cards
        experiences={experiencesData}
        purchases={purchases.length}
        purchasesThisWeek={purchasesThisWeek}
        experiencesThisWeek={experiencesThisWeek}
        totalSales={totalSales}
        totalSalesThisWeek={totalSalesThisWeek}
      />
      <Infos 
        data={experiencesData} 
        purchases={purchases} 
      />
    </div>
  );
}

export default StorePage;
