import React, { useRef, useState, useEffect } from "react";
import "../../scss/Template/dashboardTemplate.scss";
import {
  Person,
  ChevronRight,
  Dot,
  Discord,
  BoxArrowUpRight,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { sideBarActives, subs } from "../../assets/data/menus";
import { useSelector } from "react-redux";

function NavSideBar({ active }) {
  const user = useSelector((store) => store.adminUser.data);
  const navigate = useNavigate();
  const [state, setState] = useState({});

  const navsWithDrops = [
    "users",
    "account_center",
    "store_main",
    "configuration",
    "admin_settings",
  ];

  const changeRoute = (pathname) => {
    if (!state[pathname]) {
      setState({ [pathname]: !state[pathname] });
    }
    if (!navsWithDrops.includes(pathname)) {
      navigate("/" + pathname);
    }
  };

  const containerRef = useRef(null);

  const hasAccess = (module) => {
    const moduleObj = user?.modules?.find((elt) => elt.name === module);
    return (
      user?.role?.edit?.includes(moduleObj.id) ||
      user?.role?.read?.includes(moduleObj.id)
    );
  };

  useEffect(() => {
    setState({ ...state, [active]: true });
  }, [active]);

  return (
    <div className="nav_sidebar_wrapper">
      <div className="nav_sidebar_top_section">
        <div className="logo_wrapper">
          <img src={require("../../assets/images/user_logo.png")} alt="" />
        </div>
        <div className="admin_details">
          <h4 className="header">{user?.userName}</h4>
          <h4>{user?.role?.name}</h4>
        </div>
      </div>
      <div className="nav_sidebar_main" ref={containerRef}>
        <div
          className={`nav_links mbd ${!hasAccess("Dashboard") && "no-access"}`}
          style={{ color: "#fff" }}
          onClick={() => changeRoute("dashboard")}
        >
          <div
            className={`nav_link  flex-start ${
              state["dashboard"] ? "active" : ""
            }`}
          >
            <div className="align-items">
              {state["dashboard"]
                ? sideBarActives["dashboard"]["active"]
                : sideBarActives["dashboard"]["not_active"]}
            </div>
            <span className="ml-10">
              Dashboard{!hasAccess("Dashboard") && "*"}
            </span>
          </div>
        </div>
        <div className="mbd">
          <div
            className={`inner_navlinks ${
              !hasAccess("Users") && "no-access"
            } flex-between mt-10`}
            style={{ color: "#fff" }}
            onClick={() => setState({ ...state, users: !state["users"] })}
          >
            <div className="flex-start">
              <div className="align-items">
                {state["users"]
                  ? sideBarActives["users"]["active"]
                  : sideBarActives["users"]["not_active"]}
              </div>
              <span className="ml-10">Users {!hasAccess("Users") && "*"}</span>
            </div>
            <div className="right_chevron_icon align-items">
              <ChevronRight />
            </div>
          </div>
          <div
            className={`sub_links 
              ${
                state["users"] || subs["users"].includes(active)
                  ? "show"
                  : "hide"
              }`}
          >
            <div
              onClick={() => changeRoute("youth")}
              className={`${state["youth"] ? "sub_links_active" : ""}`}
            >
              Youth
            </div>
            <div
              onClick={() => changeRoute("resources")}
              className={`${state["resources"] ? "sub_links_active" : ""}`}
            >
              Resources
            </div>
            <div
              onClick={() => changeRoute("councelors")}
              className={`${state["councelors"] ? "sub_links_active" : ""}`}
            >
              Councelors
            </div>
          </div>
          <div
            className={`inner_navlinks ${
              !hasAccess("Account Management") && "no-access"
            } flex-between mt-10`}
            style={{ color: "#fff" }}
            onClick={() =>
              setState({ ...state, account_center: !state["account_center"] })
            }
          >
            <div className="flex-start">
              <div className="align-items">
                {state["account_center"]
                  ? sideBarActives["account_center"]["active"]
                  : sideBarActives["account_center"]["not_active"]}
              </div>
              <span className="ml-10">
                Account Center {!hasAccess("Account Management") && "*"}
                {hasAccess("Account Management") && (
                  <Dot fill="#FF1D15" size={25} />
                )}
              </span>
            </div>
            <div className="align-items">
              <ChevronRight />
            </div>
          </div>
          <div
            className={`sub_links 
              ${
                state["account_center"] ||
                subs["account_center"].includes(active)
                  ? "show"
                  : "hide"
              }`}
          >
            <div
              onClick={() => changeRoute("account-verification")}
              className={`${
                state["account-verification"] ? "sub_links_active" : ""
              }`}
            >
              Account Verification
            </div>
            <div
              onClick={() => changeRoute("account-deactivation")}
              className={`${
                state["account-deactivation"] ? "sub_links_active" : ""
              }`}
            >
              Account De-activation
            </div>
          </div>
          <div
            className="inner_navlinks flex-between mt-10"
            style={{ color: "#fff" }}
            onClick={() => changeRoute("feedback")}
          >
            <div className="flex-start">
              <div className="align-items">
                {state["feedback"]
                  ? sideBarActives["feedback"]["active"]
                  : sideBarActives["feedback"]["not_active"]}
              </div>
              <span className="ml-10">Feedback Center</span>
            </div>
            <div className="align-items">
              <ChevronRight />
            </div>
          </div>
        </div>
        <div className="mbd">
          <div
            className={`inner_navlinks flex-between mt-10 ${
              state["store"] ? "sub_links_active" : ""
            } ${!hasAccess("Store") && "no-access"}`}
            style={{ color: "#fff" }}
            onClick={() => changeRoute("store")}
            // onClick={() => setState({...state, "store_main": !state["store_main"]})}
          >
            <div className="flex-start">
              <div className="align-items">
                {state["store_main"]
                  ? sideBarActives["store_main"]["active"]
                  : sideBarActives["store_main"]["not_active"]}
              </div>
              <span className="ml-10">Store {!hasAccess("Store") && "*"}</span>
            </div>
            <div className="align-items">
              <ChevronRight />
            </div>
          </div>
          <div
            className={`sub_links 
              ${
                state["store_main"] || subs["store"].includes(active)
                  ? "show"
                  : "hide"
              }`}
          >
            <div
              onClick={() => changeRoute("experiences")}
              className={`${state["experiences"] ? "sub_links_active" : ""}`}
            >
              Experiences
            </div>
            <div
              onClick={() => changeRoute("purchases")}
              className={`${state["purchases"] ? "sub_links_active" : ""}`}
            >
              Purchases
            </div>
          </div>
          <div
            className={`inner_navlinks ${
              !hasAccess("Configuration") && "no-access"
            } flex-between mt-10`}
            style={{ color: "#fff" }}
            onClick={() =>
              setState({ ...state, configuration: !state["configuration"] })
            }
          >
            <div className="flex-start">
              <div className="align-items">
                {state["configuration"]
                  ? sideBarActives["configuration"]["active"]
                  : sideBarActives["configuration"]["not_active"]}
              </div>
              <span className="ml-10">
                Configuration {!hasAccess("Configuration") && "*"}
              </span>
            </div>
            <div className="align-items">
              <ChevronRight />
            </div>
          </div>
          <div
            className={`sub_links 
              ${
                state["configuration"] || subs["configuration"].includes(active)
                  ? "show"
                  : "hide"
              }`}
          >
            <div
              onClick={() => changeRoute("interests")}
              className={`${state["interests"] ? "sub_links_active" : ""}`}
            >
              Interests
            </div>
            <div
              onClick={() => changeRoute("credits")}
              className={`${state["credits"] ? "sub_links_active" : ""}`}
            >
              Credits
            </div>
          </div>
          <div
            className={`inner_navlinks ${
              !hasAccess("Administration") && "no-access"
            } flex-between mt-10`}
            style={{ color: "#fff" }}
            onClick={() =>
              setState({ ...state, admin_settings: !state["admin_settings"] })
            }
          >
            <div className="flex-start">
              <div className="align-items">
                {state["admin_settings"]
                  ? sideBarActives["admin_settings"]["active"]
                  : sideBarActives["admin_settings"]["not_active"]}
              </div>
              <span className="ml-10">
                Admin Settings {!hasAccess("Administration") && "*"}
              </span>
            </div>
            <div className="align-items">
              <ChevronRight />
            </div>
          </div>
          <div
            className={`sub_links 
              ${
                state["admin_settings"] ||
                subs["admin_settings"].includes(active)
                  ? "show"
                  : "hide"
              }`}
          >
            <div
              onClick={() => changeRoute("administrators")}
              className={`${state["administrators"] ? "sub_links_active" : ""}`}
            >
              Administrators
            </div>
            <div
              onClick={() => changeRoute("roles-and-permissions")}
              className={`${
                state["roles-and-permissions"] ? "sub_links_active" : ""
              }`}
            >
              Roles and Permissions
            </div>
          </div>
        </div>
        <div className="">
          <div
            className="inner_navlinks flex-between mt-10"
            style={{ color: "#fff" }}
            onClick={() => changeRoute("my-account")}
          >
            <div className="flex-start">
              <div className="align-items">
                <Person className="mr-10" size={20} />
              </div>
              <span className="ml-10">My Account</span>
            </div>
            <div className="align-items">
              <ChevronRight />
            </div>
          </div>
          <div
            className="inner_navlinks flex-between mt-10"
            style={{ color: "#fee75c" }}
          >
            <div className="flex-start">
              <div className="align-items">
                <Discord className="mr-10" size={20} />
              </div>
              <span className="ml-10">
                <span className="mr-10">Discord Community</span>
                <BoxArrowUpRight fill="#fff" />
              </span>
            </div>
            <div className="align-items">
              <ChevronRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavSideBar;
