import { gql } from '@apollo/client';

export const UPDATE_SCHOOL = gql`
  mutation UpdateSchool(
    $id: ID!
    $about: String
    $image: String
    $website: String
    $banner: String
    $contact_phone: AWSPhone
    $invitations: [AWSEmail]
    $invitations_names: [String]
    $invitations_dates: [AWSDateTime]
    $accepting_connection: Boolean
    $is_verified: Boolean
  ) {
    updateSchool(
      input: {
        id: $id
        about: $about
        website: $website
        contact_phone: $contact_phone
        invitations: $invitations
        image: $image
        banner: $banner
        is_verified: $is_verified
        invitations_names: $invitations_names
        invitations_dates: $invitations_dates
        accepting_connection: $accepting_connection
      }
    ) {
      contact_email
      contact_phone
      createdAt
      id
      name
      state
      accepting_connection
      full_address
      updatedAt
      zipcode
      connections
      type
      banner
      address
      website
      team_size
      counselors_dont_show_again
      about
      connection_dates
      invitations
      invitations_dates
      invitations_names
      dont_show_again
      is_verified
    }
  }
`;
