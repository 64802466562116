import {
    SET_CURRENT_TABLE_DATA,
    RESET_CURRENT_TABLE_DATA,
} from "../constants/currentTableDataConstants";

const initialLoginState = {
    data: {
        resourcesTable: {},
        counselorsTable: {},
        youthTable: {},
        accountVerificationTable: {},
        verificationTab: "verification"
    },
};

const currentTableDataReducer = (state = initialLoginState, action) => {
    switch (action.type) {
        case SET_CURRENT_TABLE_DATA:
            return { ...state, data: { ...state.data, ...action.payload } };
        case RESET_CURRENT_TABLE_DATA:
            state = initialLoginState;
            return state;
        default:
            return state;
    }
};

export default currentTableDataReducer;
