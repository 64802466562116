import React from 'react';
import { Arrow90degLeft, Check } from "react-bootstrap-icons";
import ModalWrapper from "../../widgets/Modal/modal";
import {setPopupDataAction} from "../../redux/actions/popupActions";
import {useDispatch} from "react-redux";


function DeactivationRequested(props) {
  
  const { show } = {...props}

  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setPopupDataAction({ leftModal: true }));
    dispatch(setPopupDataAction({ deactivateRequested: false }));
  }

  return ( 
    <ModalWrapper
      state={`${show ? "show" : ""}`}
    >
      <div className="wrapper p-20">
        <div 
          className="return_btn p-20"
          onClick={closeModal}
        >
          <Arrow90degLeft className="mr-10"/>
          <span>Back to Account Overview</span>
        </div>
        <div 
          className="check_icon_wrapper mt-40 flex-center"
          style={{display: show ? "flex" : "none"}}
        >
          <div className="check_icon p-20">
            <Check size={80}/>
          </div>
        </div>
        <div className="text-center mt-20">
          <h4 className="bold">De-activation Requested</h4>
          <h6>Your request for de-activation has been sent. </h6>
        </div>

      </div>
    </ModalWrapper>
  );
}

export default DeactivationRequested;