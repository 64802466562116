import React, { useRef, useState, useEffect } from "react";
import { ChevronRight, ChevronLeft, Trash } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import CustomisedButton from "../Button/Button";
import {
  resourcesLocationFilter,
  resourcesVerificationFilter,
} from "../../assets/data/menus";
import "./style.scss";

export function LocationFilterDrop({ display, func, setData, locations }) {
  const [state, setState] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const toggleDrop = () => {
    func();
  };

  const handleDoneBtn = () => {
    toggleDrop();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("locations", Object.keys(state));
  };

  const handleChecks = (id) => {
    setState({ ...state, [id]: !state[id] });
  };

  useEffect(() => {
    if (selectAll) {
      const keys = resourcesLocationFilter.map((item) => item.value);
      for (let i = 0; i < keys.length; i++) {
        setState({ ...state, [keys[i]]: true });
      }
    } else {
      setState({});
    }
  }, [selectAll]);

  return (
    <div
      className="drop_wrapper location_filter"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section mbd">
        <div className="cursor-pointer" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 cursor-pointer" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton
              text="Done"
              bg="#FFDA9A"
              fg="#000"
              width="56px"
              className="filter_btn"
            />
          </span>
        </div>
      </div>

      <div className="overflow">
        <div className="drop_main_content subheader">
          <div className="flex-start mbd">
            <span className="mr-10" onClick={() => handleChecks("All")}>
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={selectAll}
                onChange={() => {
                  setSelectAll(!selectAll);
                }}
              />
            </span>
            <span>All</span>
          </div>
        </div>
        <div className="drop_main_content sublist">
          {locations.map((item, index) => (
            <div key={index}>
              <span className="mr-10" onClick={() => handleChecks(item)}>
                <Form.Check
                  type="checkbox"
                  className="menu_chkbox"
                  checked={state[item]}
                  onChange={() => handleChecks(item)}
                />
              </span>
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function VerificationStatusFilterDrop({ display, func, setData }) {
  const handleDoneBtn = (id) => {
    setData("verification_status", id);
  };

  const toggleDrop = (id) => {
    handleDoneBtn(id);
    func();
  };

  return (
    <div
      className="drop_wrapper verification_status_filter"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="drop_main_content sublist">
        {resourcesVerificationFilter.map((item, index) => (
          <div key={index} onClick={() => toggleDrop(item.value)}>
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
}

export function MentorsTopDrop(props) {
  const { display, func, setData, locations, filters } = { ...props };

  const filterRef = useRef(null);

  const [state, setState] = useState({});

  const toggleDrop = () => {
    setState({});
    func();
  };

  const clearFilters = () => {
    func("clear");
  };

  const checkCategory = (category) => {
    setState({});
    setState({ [category]: true });
    func();
  };

  return (
    <div ref={filterRef}>
      <div
        className="drop_wrapper filter_drop"
        style={{ display: display ? "block" : "none" }}
      >
        <div className="head_section flex-between p-20">
          <span>Select a filter</span>
          <div
            className="filter_title"
            onClick={clearFilters}
            style={{ color: filters > 0 ? "#D41811" : "" }}
          >
            Clear All&nbsp;
            <Trash size={15} className="filter_trash_icon" />
          </div>
        </div>
        <div className="drop_main_content">
          <div
            className="flex-between"
            onClick={() => checkCategory("location")}
          >
            Locations
            <ChevronRight size={15} className="right_icon" />
          </div>
          <div
            className=""
            onClick={() => checkCategory("verification_status")}
          >
            Verification Status
            <ChevronRight size={15} className="right_icon" />
          </div>
        </div>
      </div>
      <LocationFilterDrop
        display={state["location"]}
        func={toggleDrop}
        setData={setData}
        locations={locations}
      />
      <VerificationStatusFilterDrop
        display={state["verification_status"]}
        func={toggleDrop}
        setData={setData}
      />
    </div>
  );
}
