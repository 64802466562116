import axios from 'axios';

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const getNotificationSettings = async (admin_id) => {
  try {
    const response = await axios.get(`${SERVER_URL}/notification-settings?admin_id=${admin_id}`);

    const result = response?.data;
    const { success, message, data } = result;

    if (success) {
      return data;
    } else {
      throw new Error(message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateNotificationSettings = async (settings) => {
  try {
    const response = await axios.put(`${SERVER_URL}/notification-settings`, {
      ...settings
    });

    const result = response?.data;
    const { success, message, data } = result;

    if (success) {
      return data;
    } else {
      throw new Error(message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const createNotificationSettings = async (settings) => {
  try {
    const response = await axios.post(`${SERVER_URL}/notification-settings`, {
      ...settings
    });

    const result = response?.data;
    const { success, message, data } = result;

    if (success) {
      return data;
    } else {
      throw new Error(message);
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};
