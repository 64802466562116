import React from "react";
import {calculateAge, filterOrgsByState} from "./helpers";
import { nameCol, verificationColTabs } from "./TableWrapper/tableWrapper";
import { InfoCircle } from "react-bootstrap-icons";
import CustomisedButton from "../widgets/Button/Button";

export function CreateInitials(props) {
  const { name, bg, width, fontSize } = { ...props };

  const createInitial = (name) => {
    let initial = "";
    const splittedName = name.split(" ");

    splittedName.forEach((name) => {
      initial += name[0].toUpperCase();
    });

    return initial;
  };

  return (
    <div className="flex-start">
      <div
        style={{
          backgroundColor: bg,
          borderRadius: "50%",
          width: width,
          height: width,
          color: "#fff",
          fontSize: fontSize,
        }}
        className="flex-center mr-10"
      >
        {createInitial(name)}
      </div>
      <div className="align-items">{name}</div>
    </div>
  );
}

export const createNameCol = (
  name,
  image = require("../assets/images/achieve_hartford.png"),
) => {
  return nameCol({
    img: image,
    name: name,
    layout: 2,
    verified: true,
  });
};

export const createCounselorsTableContent = (data) => {
  const res = [];
  data?.forEach((elt) => {
    const obj = {
      name: ["org_name_col", createNameCol(elt?.school?.name)],
      no_of_loc: ["no_of_loc", elt?.state],
      verification_status: [
        "verification_col",
        verificationColTabs(elt?.verification_status || "unverified"),
      ],
      status: ["verification_col", "Live"],
      final_col: ["final_col", "..."],
    };

    res.push(Object.values(obj));
  });
  return res;
};

export const createTableCounselorsTableContent = (data, state) => {
  const res = [];
  data?.forEach((elt) => {
    const obj = {
      name: [
        "org_name_col",
        createNameCol(elt?.school?.name, elt?.school?.image),
      ],
      no_of_loc: ["no_of_loc", filterOrgsByState(elt?.schools, state)?.length],
      verification_status: [
        "verification_col",
        verificationColTabs(elt?.verification_status || "unverified"),
      ],
      final_col: ["final_col", "..."],
    };

    res.push(Object.values(obj));
  });
  return res;
};

export const createYouthModalDetails = (details) => {
  const verificationInfo = {
    title: "VERIFICATION INFORMATION",
    innerItem: [
      {
        key: "Parent/Guardian:",
        val: "Elvis Taylor",
      },
      {
        key: "Phone:",
        val: "+1 343-647 6271",
      },
      {
        key: "Email:",
        val: "elvistaylor@mail.com",
      },
      {
        key: "Verification Date:",
        val: "Sunday, March 21, 2022",
      },
      {
        key: "Verification Status:",
        val: (
          <CustomisedButton
            text="Verified"
            bg="#DAF8EE"
            fg="#226E54"
            bd="1px solid #44DDA9"
            rd="25px"
            pd="5px 10px"
          />
        ),
      },
    ],
  };
  const personalInfo = {
    title: "PERSONAL INFORMATION",
    innerItem: [
      {
        key: "Identified As:",
        val: details?.gender,
      },
      {
        key: "Age:",
        val: calculateAge(details?.dob),
      },
      {
        key: "Location:",
        val: details?.state,
      },
    ],
  };

  const interestInfo = {
    title: "INTEREST",
    innerItem: [
      {
        key: (
          <>
            Business:
            <InfoCircle fill="#B9BDC3" />
          </>
        ),
        val: (
          <CustomisedButton
            text="Entrepreneurship"
            bg="#FFE3B3"
            fg="#554223"
            bd="1px solid #FFC768"
            rd="25px"
            pd="5px 10px"
          />
        ),
      },
      {
        key: "Technology:",
        val: (
          <>
            <CustomisedButton
              text="Web & App Design"
              bg="#FFE3B3"
              fg="#554223"
              bd="1px solid #FFC768"
              rd="25px"
              pd="5px 10px"
            />
            &nbsp;&nbsp;
            <CustomisedButton
              text="IT Consulting"
              bg="#FFE3B3"
              fg="#554223"
              bd="1px solid #FFC768"
              rd="25px"
              pd="5px 10px"
            />
          </>
        ),
      },
    ],
  };

  const educationInfo = {
    title: "EDUCATION",
    innerItem: [
      {
        key: "Status:",
        val: "Enrolled in school",
      },
      {
        key: "School Name:",
        val: "St Joseph High School",
      },
      {
        key: "Grade",
        val: "12",
      },
    ],
  };

  const volunteerWorkInfo = {
    title: "VOLUNTEER WORK",
    innerItem: [
      {
        key: "Volunteer Hours:",
        val: "300",
      },
      {
        key: "Organizations:",
        val: "Mitsubishi,Louis Vuitton,L'Oréal,Sony",
      },
    ],
  };

  return [
    verificationInfo,
    personalInfo,
    interestInfo,
    educationInfo,
    volunteerWorkInfo,
  ];
};

export const youthAccountTabList = [
  {
    title: "VERIFICATION INFORMATION",
    innerItem: [
      {
        key: "Parent/Guardian:",
        val: "Elvis Taylor",
      },
      {
        key: "Phone:",
        val: "+1 343-647 6271",
      },
      {
        key: "Email:",
        val: "elvistaylor@mail.com",
      },
      {
        key: "Verification Date:",
        val: "Sunday, March 21, 2022",
      },
      {
        key: "Verification Status:",
        val: (
          <CustomisedButton
            text="Verified"
            bg="#DAF8EE"
            fg="#226E54"
            bd="1px solid #44DDA9"
            rd="25px"
            pd="5px 10px"
          />
        ),
      },
    ],
  },

  {
    title: "PERSONAL INFORMATION",
    innerItem: [
      {
        key: "Identified As:",
        val: "Male",
      },
      {
        key: "Age:",
        val: "18",
      },
      {
        key: "Location:",
        val: "Hartford, CT",
      },
    ],
  },

  {
    title: "INTEREST",
    innerItem: [
      {
        key: (
          <>
            Business:
            <InfoCircle fill="#B9BDC3" />
          </>
        ),
        val: (
          <CustomisedButton
            text="Entrepreneurship"
            bg="#FFE3B3"
            fg="#554223"
            bd="1px solid #FFC768"
            rd="25px"
            pd="5px 10px"
          />
        ),
      },
      {
        key: "Technology:",
        val: (
          <>
            <CustomisedButton
              text="Web & App Design"
              bg="#FFE3B3"
              fg="#554223"
              bd="1px solid #FFC768"
              rd="25px"
              pd="5px 10px"
            />
            &nbsp;&nbsp;
            <CustomisedButton
              text="IT Consulting"
              bg="#FFE3B3"
              fg="#554223"
              bd="1px solid #FFC768"
              rd="25px"
              pd="5px 10px"
            />
          </>
        ),
      },
    ],
  },

  {
    title: "EDUCATION",
    innerItem: [
      {
        key: "Status:",
        val: "Enrolled in school",
      },
      {
        key: "School Name:",
        val: "St Joseph High School",
      },
      {
        key: "Grade",
        val: "12",
      },
    ],
  },

  {
    title: "VOLUNTEER WORK",
    innerItem: [
      {
        key: "Volunteer Hours:",
        val: "300",
      },
      {
        key: "Organizations:",
        val: "Mitsubishi,Louis Vuitton,L'Oréal,Sony",
      },
    ],
  },
];
