import React from 'react';
import { Row, Col } from "react-bootstrap";
import { 
  ArrowUpCircleFill,
  Box, 
  Briefcase, 
} from "react-bootstrap-icons";
import {ReactComponent as Sales} from "../../assets/icons/sales.svg";
import { useNavigate } from "react-router-dom"


function Cards(props) {
  const navigate = useNavigate();
  const cardClicked = (id) => {
    if (id === "experiences") {
      navigate("/experiences")
    } else if (id === "purchases") {
      navigate("/purchases")
    }
  }  

  const cardList = [
    {
      "icon": <Box size={24}/>,
      "category_title": "Experiences",
      "category_number": `${props.experiences?.length || 0}`,
      "bottom_text": `${props.experiencesThisWeek} new this week`,
      "id": "experiences"
    },
    {
      "icon": <Briefcase size={24}/>,
      "category_title": "Purchases",
      "category_number": `${props.purchases}`,
      "bottom_text": `${props.purchasesThisWeek} new this week`,
      "id": "purchases"
    },
    {
      "icon": <Sales size={24}/>,
      "category_title": "Total Sales",
      "category_number": `${props.totalSales.toFixed(2)} JC`,
      "bottom_text": `${props.totalSalesThisWeek.toFixed(2)} JC this week`,
      "id": "sales"
    }
  ]

  return ( 
    <Row>
      {cardList.map((card) => 
        <Col 
          key={card.id}
          onClick={() => cardClicked(card.id)}
        >
          <div className="card_icon_wrapper">
            {card.icon}
          </div>
          <div className="card_category">
            <div className="card_category_title">
              <h4>{card.category_title}</h4>
            </div>
            <h1>{card.category_number}</h1>
          </div>
          <div className="card_foot_note">
            <p>See all {'>'} </p>
            <div className="right_details">
              {card.bottom_text !== "" ? 
              <>
                <div className="right_details_icon_wrapper">
                  <ArrowUpCircleFill color="#226E54"/>
                </div>
                <p>{card.bottom_text}</p>
              </>
              : ""
              } 
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
}

export default Cards;