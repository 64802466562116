export const checkExpiryDate = (elt) => {
  const date = new Date(elt?.expiry_date);
  const current = new Date();
  if ((date.getFullYear() - current.getFullYear()) > 0){
    return true
  }

  else if ((date.getFullYear() - current.getFullYear()) == 0) {
    if ((date.getMonth() - current.getMonth()) > 0){
      return true
    }

    else if ((date.getMonth() - current.getMonth()) === 0){
      if ((date.getDate() - current.getDate()) >= 0) {
        return true
      }

      else {return false}
    }

    else { return false }
  }

  else { 
    return false 
  }
}