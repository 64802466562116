import React from 'react';
import Interests from "../../components/Interests/MainContent"
import "../../scss/Admin/interestsPage.scss"



function InterestsPage() {
  return ( 
    <div className="interests_main_content">
      <div className="interests_main_inner">
        <Interests/>
      </div>
    </div>
  );
}

export default InterestsPage;