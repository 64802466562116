import React, { useState, useEffect, useRef } from "react";
import { GeoAlt } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { ReactComponent as Sales } from "../../../assets/icons/sales.svg";
import CustomisedButton from "../../../widgets/Button/Button";
import { UPDATE_EXPERIENCE } from "../../../services/mutation/experiences";
import { useMutation } from "@apollo/client";
import { ClipLoader } from "react-spinners";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { axiosClient } from "../../../libs/axiosClient";
import {getCities} from "../../../utils/helpers";

const animatedComponents = makeAnimated();

function DetailsTab(props) {
  const { item } = { ...props };
  const [updateExperience] = useMutation(UPDATE_EXPERIENCE);
  const [state, setState] = useState({});
  const [stateChanged, setStateChanged] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const firstRunRef = useRef(true);
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);

  const modifiedStates = getCities({
    keys: ["label", "value"],
    requiresAll: false,
    returnObj: true,
    sort: true
  });

  const handleSelectChange = (e) => {
    const partnersSelected = [];
    e.forEach((elt) => {
      partnersSelected.push(elt.value);
    });
    setState({
      ...state,
      partners: partnersSelected,
    });
    setPartners(e);
  };

  const progress = () => {
    return <ClipLoader size={10} color={loading ? "#fff" : ""} />;
  };

  const handleInputs = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleTypeChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleSelect = (value) => {
    setState({
      ...state,
      location: value,
    });
  };

  const returnClassName = (type) => {
    if (state?.type !== undefined) {
      return state?.type === type ? "active" : "";
    } else {
      return item?.type === type ? "active" : "";
    }
  };

  const returnValue = (type) => {
    if (state[type] !== undefined) {
      return state[type];
    } else {
      return item[type];
    }
  };

  const saveChanges = () => {
    setLoading(true);
    const inputData = { ...state };
    inputData.id = item?.id;

    Object.keys(item)?.forEach((elt) => {
      if (!Object.keys(inputData).includes(elt)) {
        inputData[elt] = item[elt];
      }
    });
    updateExperience({
      variables: { ...inputData },
    })
      .then((data) => {
        const resp = data?.data?.updateExperience;
        setState(resp);
        setStateChanged(false);
        setLoading(false);
        toast("Updated successfully!");
      })
      .catch(() => setLoading(false));
  };

  const getAllPartners = async () => {
    const partnersResp = await axiosClient.post("/schools", {
      limit: 1000,
      fields: ["name"],
    });
    const dataOptions = partnersResp.data.data.map((elt) => {
      return { label: elt.name, value: elt.name };
    });
    setSelectOptions(dataOptions);
  };

  useEffect(() => {
    if (!firstRunRef.current) {
      setStateChanged(true);
    } else {
      firstRunRef.current = false;
    }
  }, [state]);

  useEffect(() => {
    if (item) {
      const dataOptions = item?.partners?.map((elt) => {
        return { label: elt, value: elt };
      });
      setPartners(dataOptions);
    }
  }, [item]);

  useEffect(() => {
    getAllPartners();
  }, []);

  return (
    <>
      <div className="details_tab add_experience">
        <div className="main_content mt-10">
          <div className="p-20 sect_1">
            <h6 className="mt-10">Featured Image</h6>
            <div className="mt-10">
              <img src={returnValue("image")} className="exp_detail_img" />
            </div>
          </div>
          <div className="field mt-10 p-20">
            <h6>Title</h6>
            <Form.Control
              name="title"
              value={returnValue("title")}
              onChange={handleInputs}
              placeholder="e.g. Zoom Call with Lebron James"
            />
          </div>
          <div className="field mt-10 p-20">
            <h6>Price per Person (in JC)</h6>
            <div className="input_with_icon">
              <div className="align-items">
                <Sales fontSize={100} />
              </div>
              <Form.Control
                placeholder="0.0 0"
                name="price_per_person"
                onChange={handleInputs}
                value={returnValue("price_per_person")}
              />
            </div>
          </div>
          <div className="field mt-10 p-20">
            <h6>Location</h6>
            <div className="input_with_icon">
              <div className="align-items">
                <GeoAlt size={16} />
              </div>
              <Form.Select
                value={returnValue("location")}
                onChange={handleSelect}
              >
                <option value={0}>Select location</option>
                {modifiedStates.map((elt, index) => (
                  <option value={elt.value} key={index}>
                    {elt.label}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <div className="field mt-10 p-20 mbd">
            <h6>Type of Experience</h6>
            <div className="flex-start">
              <CustomisedButton
                text="In-person Experience"
                bg="rgba(244, 245, 247, 0.64)"
                bd="1px solid #DDDFE2"
                pd="10px 10px"
                rd="24px"
                fs="13px"
                onClick={() => handleTypeChange("type", "inperson")}
                className={`mr-10 type_btn ${returnClassName("inperson")}`}
              />
              <CustomisedButton
                text="Online Experience"
                bg="rgba(244, 245, 247, 0.64)"
                bd="1px solid #DDDFE2"
                pd="10px 10px"
                rd="24px"
                fs="13px"
                onClick={() => handleTypeChange("type", "online")}
                className={`type_btn ${returnClassName("online")}`}
              />
            </div>
          </div>
          <div className="field mt-10 p-20">
            <h6>Describe this Experience</h6>
            <div className="">
              <Form.Control
                as="textarea"
                name="description"
                onChange={handleInputs}
                value={returnValue("description")}
                placeholder="Join a 45min..."
              />
            </div>
          </div>
          <div>
            <h6>Partners</h6>
            <div className="subtitle mb-2">
              Add up to a maximum of 3 partners for this experience
            </div>
            <Select
              closeMenuOnSelect={false}
              components={animatedComponents}
              isMulti
              options={selectOptions}
              placeholder="Start typing ..."
              name="partners"
              value={partners}
              onChange={handleSelectChange}
            />
          </div>
          <div className="field mt-10 p-20">
            <CustomisedButton
              text={loading ? progress() : "Save Changes"}
              width="100%"
              bg="#fff"
              bd="1px solid #DDDFE2"
              pd="10px 10px"
              rd="10px"
              className="add_exp_btn"
              onClick={saveChanges}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailsTab;
