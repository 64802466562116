import React, { useState } from 'react';
import { Tabs, Tab } from "react-bootstrap";
import AccountTab from "../../Resources/AccountTab";
import TeamTab from "../../Resources/TeamTab";
import OpportunitiesTab from "../../Resources/OpportunitesTab";
import ActivityTab from "../../Resources/ActivityTab";
import AnalyticsTab from "../../Resources/AnalyticsTab";
import PaymentTab from "../../Resources/PaymentTab";
import { 
  resourcesAccountTabList, resourcesPaymentTabList, 
  resourcesTeamTabList , resourcesActivityTabList
} from "../../../assets/data/resourcesTabList";


function ResourcesLeftModalTabs(props) {

  const { 
    display, 
    items, 
    locationId,
    details
  } = {...props}

  const [key, setKey] = useState('account');

  return (
    <div 
      className="resources_tabs tabs_wrapper"
      style={{display: display ? "block" : "none"}}
    >
      <Tabs
        id="resources_tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="account" title="Account">
          <AccountTab 
            items={items}
            locationId={locationId}
            layout="resources"
            orgs={details}
            data={resourcesAccountTabList(details, locationId)}
          />
        </Tab>
        <Tab eventKey="team" title="Team">
          <TeamTab
            className="resources"
            item={details}
            locationId={locationId}
            data={resourcesTeamTabList}
          />
        </Tab>
        <Tab eventKey="opportunities" title="Opportunities">
          <OpportunitiesTab
            locationId={locationId}
            item={details}
          />
        </Tab>
        <Tab eventKey="activity" title="Activity">
          <ActivityTab
            layout="1"
            list={resourcesActivityTabList}
          />
        </Tab>
        <Tab eventKey="analytics" title="Analytics">
          <AnalyticsTab
            locationId={locationId}
            item={details}
          />
        </Tab>
        <Tab eventKey="payment" title="Payment">
          <PaymentTab
            data={resourcesPaymentTabList}
            details={details}
          />
        </Tab>
      </Tabs>
    </div> 
  );
}

export default ResourcesLeftModalTabs;