import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Button } from "react-bootstrap";
import Validation from "../../utils/Validation/Validation";
import { cognitoidentity, paramsWithPassword, paramsWithUsername } from "../../userPools";
import { useNavigate } from "react-router-dom";
import "../../scss/AccountAuth/signupPage.scss";
import { sendEmailNotification } from "../../utils/helpers";

function SignupPage() {
  const [state, setState] = useState({});
  const user = useSelector((store) => store.adminUser.data);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  useEffect(() => {
    Validation.activateAuthButton(state.npassword, state.cpassword, "signup")
      ? setBtnDisabled(false)
      : setBtnDisabled(true);
  }, [state.npassword, state.cpassword]);

  const handleInput = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handlePasswordReset = async() => {
    setLoading(true);
    try{
      cognitoidentity.adminSetUserPassword(
        paramsWithPassword(state.npassword, user?.username)).promise();
      const userData = await cognitoidentity
      .adminGetUser(paramsWithUsername(user?.username))
      .promise();

      const name = userData?.UserAttributes?.find((elt) => elt?.Name === 'name')?.Value;
      const email = userData?.UserAttributes?.find((elt) => elt?.Name === 'email')?.Value;
      
      await sendEmailNotification({
        template: 'admin-password-changed',
        subject: 'Password Changed',
        email: email,
        context: {
          fname: name?.split(' ')[0],
          adminUrl: window.location.origin
        }
      })
      setLoading(false);
      navigate('/login');
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  return (
    <div className="signup_card_content">
      <div className="form_header">
        <h1>Welcome, {user.challengeParam.userAttributes.name || ""}</h1>
        <div className="sub__header">
          <span>Your username is</span>
          <span>
            <b>&nbsp; {user.username}</b>
          </span>
        </div>
        <p>Set a new password to start using your account</p>
      </div>
      <div className="form_wrapper">
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>New password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Choose a password"
              id="npassword"
              name="npassword"
              onChange={handleInput}
            />
            <Form.Text className="text-muted">
              Password should be at least 8 characters long and contain a
              combination of alphabets, numbers and special characters.
            </Form.Text>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Confirm new password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Type your new password again"
              id="cpassword"
              name="cpassword"
              onChange={handleInput}
            />
          </Form.Group>
          <div className="d-grid gap-2">
            <Button 
              variant="primary" 
              style={{ fontSize: 14 }} 
              size="lg" 
              disabled={btnDisabled}
              onClick={handlePasswordReset}
            >
              {loading ? "Loading..." : "Change Password and Login"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default SignupPage;
