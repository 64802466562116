/* eslint-disable no-unused-expressions */
import { gql } from "@apollo/client";
import { API } from 'aws-amplify'
import { GetSingleGuardian } from './studentGuardian';
import { SingleStudentVolunteerWork } from './studentVolunteerWork';

export const GET_ALL_STUDENTS = gql`
  query AllStudents {
    listStudents (limit: 1000) {
      items {
        email
        name
        state
        id
        educatorID
        guardianID
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        username
        interestIDs
        interests
        address 
        balance
        apartment
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        current_employer
        current_occupation
        email_verified
        dont_show_app_pop
      }

      nextToken
    }
  }
`;

export const GET_ALL_STUDENTS_WITH_TOKEN = gql`
  query StudentsQuery ($limit: Int, $nextToken: String) {
    listStudents (limit: $limit, nextToken: $nextToken) {
      items {
        email
        name
        state
        id
        educatorID
        guardianID
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        address
        balance
        apartment
        interestIDs
        interests
        graduation_year
        graduation_term
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        is_verified
        school
        schooling_mode
        profile_sharing
        employed
        current_employer
        current_occupation
        email_verified
        username
        dont_show_app_pop
      }
      nextToken
    }
}
`

export const GET_ALL_STUDENTS_BY_STATE = gql`
  query AllStudents($state: String) {
    listStudents(limit: 1000, filter: {state: {contains: $state}}) {
      items {
        email
        name
        state
        id
        educatorID
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        multiplier
        multiplier_level
        address
        balance
        apartment
        graduation_year
        graduation_term
        is_verified
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        interestIDs
        interests
        schooling_mode
        school
        profile_sharing
        current_employer
        current_occupation
        employed
        email_verified
        dont_show_app_pop
        username
        event_submissions_recommendation_scan
        event_submissions_scan
        consecutive_counselor_chat_scan
        consecutive_resource_chat_scan
        invited_by
        invites_scan
        opportunity_acceptance_scane
        opportunity_share_scan
        resource_reviews_scan
        submissions_scan
        unique_views_scan
        shared_opportunities
      }

      nextToken
    }
  }
`;

export const GET_ALL_STUDENTS_BY_STATE_WITH_TOKEN = gql`
  query StudentsQuery($limit: Int, $nextToken: String, $state: String) {
    listStudents(limit: $limit, nextToken: $nextToken, filter: {state: {eq: $state}}) {
      items {
        email
        name
        state
        id
        educatorID
        dob
        gender
        phone
        points
        schoolID
        updatedAt
        zipcode
        createdAt
        multiplier
        multiplier_level
        address
        balance
        apartment
        graduation_year
        graduation_term
        is_verified
        verification_date_started
        verification_progress
        verification_progress_dates
        verification_status
        interestIDs
        interests
        schooling_mode
        school
        profile_sharing
        current_employer
        current_occupation
        employed
        email_verified
        dont_show_app_pop
        username
        event_submissions_recommendation_scan
        event_submissions_scan
        consecutive_counselor_chat_scan
        consecutive_resource_chat_scan
        invited_by
        invites_scan
        opportunity_acceptance_scane
        opportunity_share_scan
        resource_reviews_scan
        submissions_scan
        unique_views_scan
        shared_opportunities
      }
      nextToken
    }
  }
`;

export async function AllStudents() {
  const resp = await API.graphql({ 
    query: GET_ALL_STUDENTS,
  });

  const data = resp?.data?.listStudents
  let nextToken = data?.nextToken
  let students = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_ALL_STUDENTS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listStudents

    const previousData = [...students, ...respData.items]
    students = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return await students?.reduce(async (previousPromise, student) => {
    const guardian = await GetSingleGuardian(student?.guardianID);
    const volunteer = await SingleStudentVolunteerWork(student?.id);
    let studentData = await previousPromise;
    return [...studentData, { ...student, guardian, volunteer } ];
  }, Promise.resolve([]));
}

export const GET_STUDENT = gql`
  query SingleStudent($id: ID!) {
    getStudent(id: $id) {
      email
      name
      state
      id
      educatorID
      guardianID
      dob
      gender
      phone
      points
      schoolID
      updatedAt
      zipcode
      createdAt
      address
      balance
      apartment
      graduation_year
      graduation_term
      is_verified
      verification_date_started
      verification_progress
      verification_progress_dates
      verification_status
      interestIDs
      interests
      schooling_mode
      school
      profile_sharing
      current_employer
      current_occupation
      employed
      email_verified
      dont_show_app_pop
      username
    }
  }
`;

export async function GetSingleStudent(id) {
  try {
    const respStudent = await API.graphql({
      query: GET_STUDENT,
      variables: { id },
      cache: true,
    });
    return respStudent?.data?.getStudent;
  } catch (err) {
    console.error(err);
    return {};
  }
}


export async function GetStudentByEmail(email) {
  const students = await AllStudents();
  const student = students.find((elt) => elt.email === email);
  return student || {};
}

export async function GetStudentByPhone(phone) {
  const students = await AllStudents();
  const student = students.find((elt) => elt.phone === phone);
  return student || {};
}

export const AllStudentsByState = async(state)=>{
  try {
    const resp = await API.graphql({
      query: GET_ALL_STUDENTS_BY_STATE,
      variables: {state}
    });
  
    const data = resp?.data?.listStudents;
    let nextToken = data?.nextToken;
    let students = data?.items;
  
    while (nextToken) {
      const resp = await API.graphql({
        query: GET_ALL_STUDENTS_BY_STATE_WITH_TOKEN,
        variables: { limit: 1000, nextToken, state }
      });
      const respData = resp?.data?.listStudents;
  
      const previousData = [...students, ...respData.items];
      students = [...previousData];
  
      nextToken = respData?.nextToken;
    }
  
    return students;
    
  } catch (error) {
    console.log(error)
    return []
  }
}