import React, { useState, useEffect } from "react";
import { archivePurchasesTabList } from "../../../assets/data/experiences";
import TableTemplate from "../../Admin/TableTemplate";
import { AllPurchases } from "../../../services/query/purchases"
import { createPurchasesTableContent } from "../../../utils/Experiences/utils"
import { GetSingleStudent } from "../../../services/query/students";

function PurchasesTab({ item }) {
  const [contentList, setContentList] = useState([]);
  const [formattedPurchases, setFormattedPurchases] = useState([]);

  useEffect(() => {
    AllPurchases().then(async (purchases) => {
      const experiencePurchase = purchases?.filter(elt => elt?.experienceID === item?.id);
      const formattedPurchases = await experiencePurchase?.reduce(async (acc, purchase) => {
        const prev = await acc;
        const purchaserDetails = await GetSingleStudent(purchase?.purchased_by);
        const purchaseObj = { ...purchase }
        purchaseObj.userData = purchaserDetails
        return [ ...prev, purchaseObj ];
      }, Promise.resolve([]));

      setContentList(createPurchasesTableContent(formattedPurchases));
      setFormattedPurchases(formattedPurchases);
    })

  }, [item]);
  
  return ( 
    <div className="archive_purchases_tab">
      <h6>Showing {formattedPurchases?.length} purchases</h6>
      <TableTemplate 
        layout="experiences_purchase"
        data={archivePurchasesTabList}
        tableContent={contentList}
        details={formattedPurchases}
      />
    </div>
  );
}

export default PurchasesTab;