import React, { useState, useEffect, useContext } from "react";
import { resourcesTableList } from "../../assets/data/resourcesTableList";
import TableTemplate from "../../components/Admin/TableTemplate";
import PaginationWrapper from "../../components/Youth/PaginationWrapper";
import TopSection from "../../components/Youth/TopSection";
import { AllResources } from "../../services/query/resources";
import { createResourcesTableContent } from "../../utils/Resources/utils";
import "../../scss/Admin/resourcesPage.scss";
import { AllSchools } from "../../services/query/school";
import { 
  matchDomain,
  searchData, 
  sortDataByField,
  sortDataByFieldCount,
  filterDataBySchoolsCount,
  filterDataByVerificationStatus,
  getDataByDate
 } from "../../utils/helpers";
import { PageContext } from "../../templates/DashBoardTemplate";
import DeactivationModal from "../../components/AccountVerification/DeactivationModal";
import {useDispatch, useSelector} from "react-redux";
import {setPopupDataAction} from "../../redux/actions/popupActions";
import ActivationModal from "../../components/AccountVerification/ActivationModal";
import {setCurrentTableDataAction} from "../../redux/actions/currentTableDataActions";
import DeactivationRequested from "../../components/AccountVerification/DeactivationSucessful";
import {useLocation} from "react-router-dom";

function ResourcesPage() {
  const [resources, setResources] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { filterByDate } = useContext(PageContext);
  const popups = useSelector((store) => store.popups.data);
  const currentTable = useSelector((store) => store.currentTablesData.data);
  const currentState = useSelector((store) => store.currentState.state);

  const dispatch = useDispatch();

  const search = new URLSearchParams(useLocation().search);
  const id = search.get('id');

  const sortTable = (sortBy)=>{
    if (sortBy === 'locations'){
      setContentList(createResourcesTableContent(sortDataByFieldCount(filtered, "schools"), currentState))
    }
    else {
      setContentList(createResourcesTableContent(sortDataByField(filtered, sortBy), currentState))
    }
  }

  const filterCallback = (id, data) => {
    let newContent = filtered;

    if (data.length){
      if (id === 'location')
        newContent = filterDataBySchoolsCount(newContent, data)

      else if (id === 'verification_status')
        newContent = filterDataByVerificationStatus(newContent, data)

      setFiltered(newContent)
    }
    else {
      setFiltered(resources)
    }
  }

  const closeModal = (id) => {
    dispatch(setPopupDataAction({[id]: false}));
  }

  const getResources = async () => {
    AllResources().then(async (resources) => {
      const resourceAdmins = resources.filter(
          (resource) => resource.isAdmin === true && resource.verification_status
      );
      const allSchools = await AllSchools();
      const resourceWithLocations = await resourceAdmins.reduce(
          async (acc, resource) => {
            const schools = allSchools.filter((school) =>
                matchDomain(school?.contact_email, resource.contact_email)
                && school.type === "resource"
            );
            const prev = await acc;
            return [ ...prev, { ...resource, schools }];
          }
          , Promise.resolve([]));
      setResources(resourceWithLocations);
      const detail = resourceWithLocations?.find((item) => item?.id === currentTable?.accountVerificationTable?.id)
      dispatch(setCurrentTableDataAction(detail || {}));
      setContentList(createResourcesTableContent(resourceWithLocations, currentState));
    });
  }

  useEffect(() => {
    getResources();
  }, [refresh]);

  useEffect(() => {
    setContentList(createResourcesTableContent(filtered, currentState))
  }, [filtered]);
  
  useEffect(() => {
    setContentList(createResourcesTableContent(resources, currentState))
  }, [resources, currentState]);

  useEffect(() => {
    if (filterByDate?.length){
      setFiltered(getDataByDate(resources, ...filterByDate))
    }
    else {
      setFiltered(resources)
    }
  }, [filterByDate])

  return (
    <div className="youth_main_content">
      <div className="youth_main_inner">
        <TopSection 
          menu="resources" 
          inputPlaceholder="Search for a resource..." 
          content={resources}
          filtered={filtered}
          setFiltered={setFiltered}
          callback={searchData}
          filterCallback={filterCallback}
        />
        <TableTemplate
          data={resourcesTableList}
          layout="resources"
          details={resources}
          tableContent={contentList}
          sortFunction={sortTable}
          id={id}
        />
        <PaginationWrapper
          moveToNextPage={() => {}}
          moveToPreviousPage={() => {}}
          page={1}
          setPageLimit={() => {}}
          nextToken={""}
        />
        <DeactivationModal
            previousModal={"leftModal"}
            show={popups?.deactivateModal}
            setShow={() => closeModal("deactivateModal")}
            details={currentTable?.resourcesTable}
            refetch={() => setRefresh(prev => !prev)}
        />
        <ActivationModal
            previousModal={"leftModal"}
            show={popups?.activationModal}
            subLayout={"resources"}
            setShow={() => closeModal("activationModal")}
            details={currentTable?.resourcesTable}
            detailsRefetch={() => setRefresh(prev => !prev)}
        />
        <DeactivationRequested
            show={popups?.deactivateRequested}
        />
      </div>
    </div>
  );
}

export default ResourcesPage;
