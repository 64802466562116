import React from 'react';
import { ToastContainer, Toast } from "react-bootstrap";

function ToastContainerComponent(props) {

  const {
    setToastShow, toastShow, 
    revokeVerification, data,
    bottom
  } = {...props}

  return ( 
    // <ToastContainer
    //   position="top-end"
    //   className="p-3"
    //   style={{zIndex: 99}}
    // >
    <ToastContainer
      position="top-end"
      style={{zIndex: 99}}
    >
    <Toast 
      onClose={() => setToastShow(false)} 
      show={toastShow} 
      delay={5000} 
      autohide
    >
      <Toast.Body>
        <div className="flex-start">
          <div className="notification_body_icon mr-10">
            {data.icon}
          </div>
          <div className="top_card_info flex-v">
            <div className="card_info_title">{data.title}</div>
            <div className="card_info_main_text">
              {data.message}
            </div>
            {
              bottom 
              ? 
              <div className="flex-start mt-20">
                <div 
                  className="dismiss_btn mr-10"
                  onClick={() => setToastShow(false)}
                >
                  Dismiss
                </div>
                <div 
                  className="undo_btn"
                  onClick={revokeVerification}
                >
                  Undo
                </div>
              </div>
              : ""
            }
          </div>
        </div>
      </Toast.Body>
    </Toast>
  </ToastContainer>
  );
}

export default ToastContainerComponent;