import React from 'react';
import { 
  calculateAge, 
  formatDatePurchaseStyle,
  getSchoolState, 
  titleWords
} from "../helpers"
import {accountVerificationProgress, nameCol, verificationColTabs} from "../TableWrapper/tableWrapper"
import { InfoCircle } from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";



export const createNameCol = (image, name) => {
  return (
    nameCol({
      img: image,
      name: titleWords(name),
      layout: 2,
      verified: true
    })
  )
}

export const createCounselorsTableContent = (data) => {
  const res = []
  data?.forEach((elt) => {
    if (elt?.verification_date_started) {
      const obj = {
        name: ["col-4", createNameCol(elt?.school?.image, elt?.school?.name)],
        no_of_loc: ["col-2 align-items", getSchoolState(elt?.school?.full_address)],
        date_requested: ["col-2 align-items justify-center", formatDatePurchaseStyle(elt?.verification_date_started)],
        verification_status: [
          "col-2 align-items justify-center", 
          accountVerificationProgress(Number(elt?.verification_progress))
        ],
        status: ["col-2 align-items justify-center", elt?.verifier || "Not Started"],
      }
  
      res.push(Object.values(obj));
    }
  })
  return res
}

export const createAllCounselorsTableContent = (data) => {
  const res = []
  data?.forEach((elt) => {
    const obj = {
      name: [
        "col-4", 
        createNameCol(elt?.name, elt?.image),
        elt?.name,
        require("../../assets/images/achieve_hartford.png"),
      ],
      no_of_loc: ["no_of_loc", elt?.state?.length || 0],
      org_verification_col: [
        "org_verification_col", 
        verificationColTabs(elt?.verification_status || "unverified")
      ],
      final_col: ["final_col", "..."],
    }

    res.push(Object.values(obj))
  })
  return res
}


export const createYouthModalDetails = (details) => {
  const verificationInfo = {
    "title": "VERIFICATION INFORMATION",
    "innerItem": [
      {
        "key": "Parent/Guardian:",
        "val": "Elvis Taylor",
      },
      {
        "key": "Phone:",
        "val": "+1 343-647 6271",
      },
      {
        "key": "Email:",
        "val": "elvistaylor@mail.com",
      },
      {
        "key": "Verification Date:",
        "val": "Sunday, March 21, 2022",
      },
      {
        "key": "Verification Status:",
        "val": 
          <CustomisedButton 
            text="Verified" 
            bg="#DAF8EE"
            fg="#226E54"
            bd="1px solid #44DDA9"
            rd="25px"
            pd="5px 10px"
          />
        ,
      },
    ]
  }
  const personalInfo =   {
    "title": "PERSONAL INFORMATION",
    "innerItem": [
      {
        "key": "Identified As:",
        "val": details?.gender,
      },
      {
        "key": "Age:",
        "val": calculateAge(details?.dob),
      },
      {
        "key": "Location:",
        "val": details?.state,
      }
    ]
  }

  const interestInfo = {
    "title": "INTEREST",
    "innerItem": [
      {
        "key": <>Business:<InfoCircle fill="#B9BDC3"/></>,
        "val": 
          <CustomisedButton 
            text="Entrepreneurship" 
            bg="#FFE3B3"
            fg="#554223"
            bd="1px solid #FFC768"
            rd="25px"
            pd="5px 10px"
          />
        ,
      },
      {
        "key": "Technology:",
        "val": 
        <>
          <CustomisedButton 
            text="Web & App Design" 
            bg="#FFE3B3"
            fg="#554223"
            bd="1px solid #FFC768"
            rd="25px"
            pd="5px 10px"
          />
          &nbsp;&nbsp;
          <CustomisedButton 
            text="IT Consulting" 
            bg="#FFE3B3"
            fg="#554223"
            bd="1px solid #FFC768"
            rd="25px"
            pd="5px 10px"
          />
        </>
      },
    ]
  }

  const educationInfo = {
    "title": "EDUCATION",
    "innerItem": [
      {
        "key": "Status:",
        "val": "Enrolled in school",
      },
      {
        "key": "School Name:",
        "val": "St Joseph High School",
      },
      {
        "key": "Grade",
        "val": "12",
      },
    ]
  }

  const volunteerWorkInfo =   {
    "title": "VOLUNTEER WORK",
    "innerItem": [
      {
        "key": "Volunteer Hours:",
        "val": "300",
      },
      {
        "key": "Organizations:",
        "val": "Mitsubishi,Louis Vuitton,L'Oréal,Sony",
      },
    ]  
  }

  return [
    verificationInfo,
    personalInfo,
    interestInfo,
    educationInfo,
    volunteerWorkInfo
  ]
}


export const youthAccountTabList = [
  {
    "title": "VERIFICATION INFORMATION",
    "innerItem": [
      {
        "key": "Parent/Guardian:",
        "val": "Elvis Taylor",
      },
      {
        "key": "Phone:",
        "val": "+1 343-647 6271",
      },
      {
        "key": "Email:",
        "val": "elvistaylor@mail.com",
      },
      {
        "key": "Verification Date:",
        "val": "Sunday, March 21, 2022",
      },
      {
        "key": "Verification Status:",
        "val": 
          <CustomisedButton 
            text="Verified" 
            bg="#DAF8EE"
            fg="#226E54"
            bd="1px solid #44DDA9"
            rd="25px"
            pd="5px 10px"
          />
        ,
      },
    ]
  },

  {
    "title": "PERSONAL INFORMATION",
    "innerItem": [
      {
        "key": "Identified As:",
        "val": "Male",
      },
      {
        "key": "Age:",
        "val": "18",
      },
      {
        "key": "Location:",
        "val": "Hartford, CT",
      }
    ]
  },

  {
    "title": "INTEREST",
    "innerItem": [
      {
        "key": <>Business:<InfoCircle fill="#B9BDC3"/></>,
        "val": 
          <CustomisedButton 
            text="Entrepreneurship" 
            bg="#FFE3B3"
            fg="#554223"
            bd="1px solid #FFC768"
            rd="25px"
            pd="5px 10px"
          />
        ,
      },
      {
        "key": "Technology:",
        "val": 
        <>
          <CustomisedButton 
            text="Web & App Design" 
            bg="#FFE3B3"
            fg="#554223"
            bd="1px solid #FFC768"
            rd="25px"
            pd="5px 10px"
          />
          &nbsp;&nbsp;
          <CustomisedButton 
            text="IT Consulting" 
            bg="#FFE3B3"
            fg="#554223"
            bd="1px solid #FFC768"
            rd="25px"
            pd="5px 10px"
          />
        </>
      },
    ]
  },

  {
    "title": "EDUCATION",
    "innerItem": [
      {
        "key": "Status:",
        "val": "Enrolled in school",
      },
      {
        "key": "School Name:",
        "val": "St Joseph High School",
      },
      {
        "key": "Grade",
        "val": "12",
      },
    ]
  },

  {
    "title": "VOLUNTEER WORK",
    "innerItem": [
      {
        "key": "Volunteer Hours:",
        "val": "300",
      },
      {
        "key": "Organizations:",
        "val": "Mitsubishi,Louis Vuitton,L'Oréal,Sony",
      },
    ]  
  },

]

