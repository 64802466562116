import {
    SET_POPUP_DATA,
    RESET_POPUP_DATA,
} from "../constants/popupConstants";

export const setPopupDataAction = (data) => async (dispatch) => {
    dispatch({
        type: SET_POPUP_DATA,
        payload: data,
    });
};

export const resetPopupDataAction = () => async (dispatch) => {
    dispatch({
        type: RESET_POPUP_DATA,
    });
};
