

import SearchInput from "../../widgets/SearchInput/searchInput";
import React, { useState } from 'react';
import { Funnel } from "react-bootstrap-icons";
import { CouncelorsYouthFilterSectionDrop } from "../../widgets/Dropdown/dropdown";
import { GetSingleStudent } from "../../services/query/students";
import { AllSchools } from "../../services/query/school";
import { useEffect } from "react";
import { nameCol } from "../../utils/TableWrapper/tableWrapper";
import { calculateAge, getInitial, getRandomColor } from "../../utils/helpers";

function YouthTab({ locationId }) {

  const [state, setState] = useState(false)
  const [filters, setFilters] = useState({})
  const [sameSchool, setSameSchool] = useState([]);

  const toggleDrop = (clear) => {
    if (clear){setFilters({})}
    setState(!state)
  }

  const setData = (id, data) => {
    setFilters({...filters, [id]:data})
  }

  useEffect(() => {
    AllSchools().then(async (schools) => {
      const school = schools.find((elt) => elt.id === locationId);
      const sameSchool = await school?.connections?.reduce(async (acc, student) => {
        const studentData = await GetSingleStudent(student);
        const prev = await acc;
        return [ ...prev, studentData ];
      }, Promise.resolve([]));
      setSameSchool(sameSchool);
    });
  }, [locationId])
  

  return (
    <>
      <div className="resources_team_tab">
        <div className="top_section flex-between">
          <SearchInput
            placeholder="Search for team member"
            bg="rgba(244, 245, 247, 0.64)"
            height="40px"
            width="65%"
          />
          <div className="icon_with_text flex-center">
            <div 
              className={`filter_btn p-20 ${Object.keys(filters).length > 0 ? "filtered" : ""}`}
              onClick={toggleDrop}
            >
              <Funnel size={20}/>
              <span className="ml-10">
                Filters&nbsp;
                {
                  Object.keys(filters).length > 0 
                    ? `(${Object.keys(filters).length} selected)`
                    : ""
                }
              </span>
            </div>
          </div>
        </div>
        <div className="main_content mt-10">
          {sameSchool?.map((item, index) => 
          <div 
            className="mbd pv-10" 
            key={index}
          >
            {
              nameCol({
                Ibg: getRandomColor(),
                text: getInitial(item.name),
                name: item.name,
                layout: 1,
                bottomText: `${calculateAge(item.dob)} yrs`,
                verified: item?.is_verified,
              })
            }
          </div>
          )}
        </div>
      </div>
      <CouncelorsYouthFilterSectionDrop
        display={state}
        func={toggleDrop}
        setData={setData}
        filters={filters}
      />
    </>
  );
}

export default YouthTab;