import {
  SET_EXPERIENCE_DATA,
  SET_EXPERIENCE_REFETCH_DATA,
  RESET_EXPERIENCE_DATA,
} from "../constants/experienceConstants";

export const setExperienceDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_EXPERIENCE_DATA,
    payload: data,
  });
};

export const setRetchExperienceDataAction = () => async (dispatch) => {
  dispatch({
    type: SET_EXPERIENCE_REFETCH_DATA,
  });
};

export const resetExperienceDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_EXPERIENCE_DATA,
  });
};
