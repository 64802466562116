import React from 'react';
import { Pagination, Form } from "react-bootstrap";

function PaginationWrapper({ moveToPreviousPage, moveToNextPage, page, setPageLimit, nextToken }) {

    return (
        <div className="pagination_wrapper">
            <Pagination>
                <Pagination.Prev onClick={moveToPreviousPage} className={`pagination-btn ${(2 > page) ? 'disabled' : 'active'}`} />
                <Pagination.Item active>{page}</Pagination.Item>
                {/* <Pagination.Ellipsis/>
            <Pagination.Item>{16}</Pagination.Item> */}
                <Pagination.Next onClick={moveToNextPage} className={`pagination-btn ${!nextToken ? 'disabled': 'active'}`} />
            </Pagination>
            <Form>
                <Form.Group className="selected_row">
                    <Form.Select onChange={(e) => setPageLimit(e.target.value)}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Form.Group>
            </Form>
            <div className="rows_no">
                Rows per page
            </div>
        </div>
    );
}

export default PaginationWrapper;