import React from "react";
import ChartData from "../data";
import ReactApexChart from "react-apexcharts";
import { cleanChartData, sameYearData } from "../../../utils/helpers";

const BasicAreaChart = ({ earnings, purchases }) => {
  const earningsValues = earnings.reduce(
    (acc, item) => [...acc, ...item.value],
    []
  );
  const sameYearChartDataArr = earningsValues.filter((elt) =>
    sameYearData(JSON.parse(elt))
  );
  const sameMonthChartDataArr = sameYearChartDataArr.reduce((acc, elt) => {
    const resp = { ...acc };
    const eltObj = JSON.parse(elt);
    const dateCreatedAt = new Date(eltObj.createdAt);
    if (Object.keys(resp).includes(String(dateCreatedAt.getMonth()))) {
      if (Object.keys(eltObj).includes("earned_credit"))
        resp[dateCreatedAt.getMonth()] += eltObj.earned_credit;
      else resp[dateCreatedAt.getMonth()] += eltObj.credit;
    } else {
      if (Object.keys(eltObj).includes("earned_credit"))
        resp[dateCreatedAt.getMonth()] = eltObj.earned_credit;
      else resp[dateCreatedAt.getMonth()] = eltObj.credit;
    }

    return resp;
  }, {});

  const completeEarningsData = cleanChartData(sameMonthChartDataArr);

  const sameYearPurchases = purchases.filter(
    (elt) => new Date().getFullYear() === new Date(elt.createdAt).getFullYear()
  );
  const sameMonthPurchases = sameYearPurchases.reduce((acc, item) => {
    const resp = { ...acc };
    const itemCreatedMonth = new Date(item.createdAt).getMonth();
    if (Object.keys(resp).includes(String(itemCreatedMonth)))
      resp[itemCreatedMonth] += item.amount;
    else resp[String(itemCreatedMonth)] = item.amount;
    return resp;
  }, {});

  const completePurchaseData = cleanChartData(sameMonthPurchases);

  const series = [
    { name: "Earnings (JC)", data: Object.values(completeEarningsData) },
    { name: "Purchases (JC)", data: Object.values(completePurchaseData) },
  ];
  const basicOptions = {
    chart: {
      id: "Chart",
      type: "area",
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    labels: ChartData.monthDataSeries1.dates,
    xaxis: {
      type: "category",
      categories: ChartData.monthDataSeries1.dates,
      title: {
        text: "Month",
        style: {
          fontSize: "15px",
          color: "#667085",
        },
      },
    },
    colors: ["#D4A657", "#FFDA9A"],
    yaxis: {
      opposite: false,
      title: {
        text: "Earnings & Purchases",
        style: {
          fontSize: "15px",
          color: "#667085",
        },
      },
    },
    fill: {
      opacity: [0, 0, 0, 0],
      type: "gradient",
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  return (
    <div>
      <ReactApexChart
        options={basicOptions}
        series={series}
        type="area"
        height={300}
      />
    </div>
  );
};

export default BasicAreaChart;
