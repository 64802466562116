import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import CustomisedButton from "../../widgets/Button/Button";
import { Dash } from "react-bootstrap-icons";
import { formatDate } from "../../utils/Experiences/utils";
import { CREATE_EXPERIENCE_SESSION } from "../../services/mutation/experienceSession";
import { useMutation } from "@apollo/client";

function SessionEditCard(props) {
  const { func, id, sessionIds, setData, cancelSession } = { ...props };
  const [btnStates, setBtnStates] = useState({});
  const [state, setState] = useState({});
  const [createExperienceSession] = useMutation(CREATE_EXPERIENCE_SESSION);

  const handleInputs = (e) => {
    const name = e.target.name;
    const cardId = name.match(/(\d+)/);
    const fieldName = name.match(/(\D+)/);
    if (cardId) {
      const content = { ...state[`card${cardId[0]}`] };

      if (fieldName[0] === "session_date") {
        const date = new Date(e.target.value);
        content[fieldName[0]] = formatDate(date);
        content.date = e.target.value;
      } else {
        content[fieldName[0]] = e.target.value;
      }

      setState({
        ...state,
        [`card${cardId[0]}`]: content,
      });
    }
  };

  const createSession = async () => {
    const inputData = state[`card${id}`];
    await createExperienceSession({
      variables: { ...inputData },
    })
      .then((data) => {
        const resp = data?.data?.createExperienceSession;
        setData([...sessionIds, resp?.id]);
      })
      .catch((err) => console.error(err));
  };

  const maxDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 60);
    return date;
  };

  const handleSave = (id) => {
    const data = state[`card${id}`];
    func(id, data);
    createSession();
  };

  const handleCancel = (id) => {
    cancelSession(id);
  };

  useEffect(() => {
    Object.keys(state).forEach((item) => {
      const cardId = item.match(/(\d+)/);
      if (Object.keys(state[item]).length === 5) {
        setBtnStates({
          ...btnStates,
          [cardId[0]]: true,
        });
      }
    });
  }, [state]);

  return (
    <div className="session_card mt-20 p-20">
      <div className="session_card_top flex-between pv-10">
        <div className="head_left">Session</div>
      </div>
      <div className="session_card_body mt-10">
        <div className="field p-20">
          <h6>Choose a date</h6>
          <Form.Control
            type="date"
            name={`session_date${id}`}
            onChange={handleInputs}
            min={new Date().toISOString().slice(0, 10)}
            max={maxDate().toISOString().slice(0, 10)}
            placeholder="Choose a date"
          />
        </div>
        <div className="field p-20">
          <h6>Session Time</h6>
          <span className="subtitle">
            All times are in Eastern Standard Time (EST)
          </span>
          <div className="flex-between mt-2">
            <Form.Control
              type="time"
              name={`start_time${id}`}
              onChange={handleInputs}
              placeholder="Start time"
              style={{ width: "44%" }}
            />
            <div className="align-items">
              <Dash size={20} />
            </div>
            <Form.Control
              type="time"
              name={`end_time${id}`}
              onChange={handleInputs}
              placeholder="End time"
              style={{ width: "44%" }}
            />
          </div>
        </div>
        <div className="field p-20">
          <h6>Slots Available</h6>
          <div className="">
            <Form.Control
              type="number"
              name={`slots${id}`}
              onChange={handleInputs}
              placeholder={10}
              style={{ width: "30%" }}
              min={0}
            />
          </div>
        </div>
        <div className="field mt-10 p-20 flex-between">
          <CustomisedButton
            text="Save Session"
            fg={btnStates[id] ? "#554223" : "#B9BDC3"}
            bg={btnStates[id] ? "#FFDA9A" : "#F4F5F7"}
            bd={`1px solid ${btnStates[id] ? "#FFDA9A" : "#F4F5F7"}`}
            pd="10px 10px"
            rd="10px"
            className="mr-10"
            db={!btnStates[id]}
            width="48%"
            onClick={() => handleSave(id)}
          />
          <CustomisedButton
            text="Cancel"
            bg="#fff"
            bd="1px solid #DDDFE2"
            pd="10px 10px"
            rd="10px"
            width="48%"
            onClick={() => handleCancel(id)}
          />
        </div>
      </div>
    </div>
  );
}

export default SessionEditCard;
