import React, { useState, useEffect, useMemo } from "react";
import {Tabs, Tab, Badge} from "react-bootstrap";
import TabContent from "../../components/AccountDeactivation/TabContent";
import { getRandomColor } from "../../utils/helpers";
import {axiosClient} from "../../libs/axiosClient";
import "../../scss/Admin/accountDeactivationPage.scss";

function AccountDeactivationPage() {
  const [youth, setYouth] = useState([]);
  const [resources, setResources] = useState([]);
  const [educators, setEducators] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [key, setKey] = useState("youth");

  const getBackgroundColorsLength = (key) => {
    const items = {
      youth,
      resources,
      mentors: educators,
    };

    return items[key];
  };

  const colors = useMemo(() =>
    [...Array(getBackgroundColorsLength(key)?.length).keys()].map((_) =>
      getRandomColor(),
    ),
  );

  const setTitle = (title, number) => {
    return (
      <div className="set_title_wrapper">
        {title}
        <span className="ml-10">
          <Badge pill bg="danger">
            {number}
          </Badge>
        </span>
      </div>
    );
  };

  const getDeactivationRequest = async () => {
    try {
      const response = await axiosClient.get('/deactivation-request');
      const data = response?.data?.data;
      setYouth(data?.filter((elt) => elt?.accountType === 'youth'));
      setResources(data?.filter((elt) => elt?.accountType === 'resource'));
      setEducators(data?.filter((elt) => elt?.accountType === 'mentor'));
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getDeactivationRequest();
  }, [refresh]);

  return (
    <div className="tabs_wrapper account_verification_tabs account-deactivation-page">
      <Tabs id="youth_tabs" activeKey={key} onSelect={(k) => setKey(k)}>
        <Tab eventKey="youth" title={setTitle("Youth", youth.length)}>
          <TabContent layout="youth" data={youth} colors={colors} refetch={() => setRefresh(prev => !prev)} />
        </Tab>
        <Tab
          eventKey="resources"
          title={setTitle("Resources", resources.length)}
        >
          <TabContent layout="resource" data={resources} colors={colors} refetch={() => setRefresh(prev => !prev)} />
        </Tab>
        <Tab eventKey="mentors" title={setTitle("Mentors", educators.length)}>
          <TabContent layout="educator" data={educators} colors={colors} refetch={() => setRefresh(prev => !prev)} />
        </Tab>
      </Tabs>
    </div>
  );
}

export default AccountDeactivationPage;
