import { Row, Button } from "react-bootstrap";
// import topLocationCardList from "../../assets/data/topLocationCard";
import React, { useState, useEffect } from "react";

import { AllOpportunitiesAPI } from "../../services/query/opportunities"
import { AllStudentOpportunityApplications } from "../../services/query/application"
import { checkExpiryDate } from "../../utils/Dashboard/utils";
import { AllResources } from "../../services/query/resources";

function Infos() {
  const [activeOpportunities, setActiveOpportunities] = useState([]);
  const [applications, setApplications] = useState([]);
  const [superResources, setSuperResources] = useState([]);

  const [opps, ] = useState([]);
  const [locations, setLocations] = useState({});
  // resources.forEach(item => {
  //   const campaign = campaigns.filter(elt => elt.resourceID === item?.id)[0]
  //   const obj = {...item}
  //   obj.applications = campaign?.Applications?.items
  //   resourcesWithApplications.push(obj)
  // })

  useEffect(() => {
    AllOpportunitiesAPI().then((opps) => setActiveOpportunities(opps.filter((elt) => checkExpiryDate(elt))));
    AllResources().then((resources) => setSuperResources(resources.filter((elt) => elt?.isAdmin === true)));
    AllStudentOpportunityApplications().then((studentApplications) => {
      const locations = studentApplications.reduce((acc, elt) => {
        const resp = { ...acc };
        if (Object.keys(resp)?.includes(elt.state)) resp[elt.state] += 1;
        else resp[elt.state] = 1;

        return resp;
      }, {});
      
      const locationsSorted = Object.entries(locations)
      .sort(([,a],[,b]) => b-a)
      .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
      setLocations(locationsSorted);
      setApplications(studentApplications)
    });
  }, []);
  
  return ( 
    <div className="dashboard_infos">
      <Row className="info_cards_container gap-4 justify-content-between">
        <div className="info_card col-lg-5 col-xl-3">
          <div className="info_card_title">
            Key Statistics
          </div>
          <div className="info_card_details">
            <h2>{activeOpportunities.length}</h2>
            <p>Active Opportunities</p>
          </div>
          <div className="info_card_details">
            <h2>{applications.length}</h2>
            <p>Current Applications</p>
          </div>
          <div className="info_card_details">
            <h2>{superResources.length}</h2>
            <p>Super Resources</p>
          </div>
          <div className="info_card_details">
            <h2>0</h2>
            <p>Junity Influencers</p>
          </div>
        </div>
        <div className="info_card col-lg-6 col-xl-3">
          <div className="info_card_title">
            Top Locations
          </div>
          {Object.keys(locations)?.map((card, index) => 
            <div className="info_card_details top_locations" key={index}>
              <div className="info_card_numbering">
                <p>{index+1}</p>
              </div>
              <p>{card}</p>
            </div>
          )}
        </div>
        <div className="info_card col">
          <div className="info_card_title">
            Most Popular Resources
          </div>
          {opps.map((card, index) => 
            <div className="resources_details" key={index}>
              <div className="info_card_details resources" key={index}>
                <div className="info_card_img">
                  <img src={require("../../assets/images/microsoft_inc.png")} alt=""/>
                </div>
                <div className="info_inner_card_details">
                  <h4>{card.name}</h4>
                  <p>{card?.apps?.length 
                    ? card?.apps?.length
                    : "No"
                  } {card?.apps?.length === 1 ? "application" : "applications"}
                  </p>
                </div>
              </div>
              <Button>View Profile</Button>
            </div>
          )}
        </div>
      </Row>
    </div>
  );
}

export default Infos;