import React, { useRef, useState } from "react";
import {
  Person,
  Gear,
  ChevronRight,
  ChevronLeft,
  ArrowBarRight,
  Trash,
} from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import CustomisedButton from "../Button/Button";
import {
  teamDropdown,
  youthAgeFilter,
  youthVerificationFilter,
} from "../../assets/data/menus";
import SearchInput from "../SearchInput/searchInput";
import "./style.scss";
import {
  resourcesTeamRoleFilter,
  resourcesPublishedOpportunityRoleFilter,
  resourcesAppsNoOpportunityRoleFilter,
} from "../../assets/data/resourcesTabList";
import { councelorsYouthAgeFilterList } from "../../assets/data/councelorsTabList";
import { useNavigate } from "react-router-dom";
import { ThreeDotsVertical } from "react-bootstrap-icons";

export function CustomDropdown({ show, setShow, className, children }) {
  const toggle = () => {
    setShow(!show);
  };
  return (
    <div
      className={`drop_wrapper topmost_drop ${className}`}
      style={{ display: show ? "block" : "none" }}
    >
      <div className="upper_wrapper point" onClick={toggle}>
        {children}
      </div>
    </div>
  );
}

export function TopMostSectionDrop({ display, logout }) {
  const navigate = useNavigate();

  return (
    <div
      className={`drop_wrapper topmost_drop`}
      style={{ display: display ? "block" : "none" }}
    >
      <div className="upper_wrapper point">
        <div className="mb-3" onClick={() => navigate("/my-account")}>
          <Person size={24} className="mr-10" />
          My Account
        </div>
        <div className="point" onClick={() => navigate("/administrators")}>
          <Gear size={24} className="mr-10" />
          Admin Settings
        </div>
      </div>
      <div className="bottom_wrapper point" onClick={logout}>
        <ArrowBarRight size={24} className="mr-20" />
        Log Out
      </div>
    </div>
  );
}

export function AgeFilterDrop({ display, func, setData }) {
  const [state, setState] = useState({});

  const toggleDrop = () => {
    func();
  };

  const handleDoneBtn = () => {
    toggleDrop();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("age", Object.keys(state));
  };

  const handleChecks = (id) => {
    setState({ ...state, [id]: !state[id] });
  };

  return (
    <div
      className="drop_wrapper age_filter"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section flex-between p-20 mbd">
        <div className="cursor-pointer" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 point" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton
              text="Done"
              bg="#FFDA9A"
              fg="#000"
              width="56px"
              className="filter_btn"
            />
          </span>
        </div>
      </div>
      <div className="drop_main_content subheader">
        <div className="flex-start mbd">
          <span className="mr-10">
            <Form.Check
              type="checkbox"
              className="menu_chkbox"
              checked={state["All"]}
              onChange={() => handleChecks("All")}
            />
          </span>
          All
        </div>
      </div>
      <div className="drop_main_content sublist">
        {youthAgeFilter.map((item, index) => (
          <div key={index}>
            <span className="mr-10">
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={state[item.value]}
                onChange={() => handleChecks(item.value)}
              />
            </span>
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
}

export function LocationFilterDrop({ display, func, setData, locations }) {
  const toggleDrop = () => {
    func();
  };

  const [state, setState] = useState({});

  const handleDoneBtn = () => {
    toggleDrop();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("location", Object.keys(state));
  };

  const handleChecks = (id) => {
    setState({ ...state, [id]: !state[id] });
  };

  return (
    <div
      className="drop_wrapper location_filter"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section flex-between p-20 mbd">
        <div className="cursor-pointer" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 point" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton
              text="Done"
              bg="#FFDA9A"
              fg="#000"
              width="56px"
              className="filter_btn"
            />
          </span>
        </div>
      </div>
      <div className="drop_main_content">
        <div className="flex-start mbd ph-10 border-0">
          <SearchInput height="35px" width="200px" placeholder="Search..." />
        </div>
      </div>
      <div className="overflow">
        <div className="drop_main_content subheader">
          <div className="flex-start mbd">
            <span className="mr-10" onClick={() => handleChecks("All")}>
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={state["All"]}
                defaultChecked
              />
            </span>
            All
          </div>
        </div>
        <div className="drop_main_content sublist">
          {locations?.map((item, index) => (
            <div key={index}>
              <span className="mr-10" onClick={() => handleChecks(item)}>
                <Form.Check
                  type="checkbox"
                  className="menu_chkbox"
                  checked={state[item]}
                  onChange={() => handleChecks(item)}
                />
              </span>
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function JunityInfluencerFilterDrop({ display, func }) {
  const toggleDrop = () => {
    func();
  };

  // const [state, setState] = useState({})

  // const handleDoneBtn = () => {
  //   toggleDrop()
  //   for (const [key, value] of Object.entries(state)){
  //     if (!value) {
  //       delete state[key]
  //     }
  //   }
  //   setData("junity_influencer", state)
  // }

  // const handleChecks = (id) => {
  //   setState({...state, [id]:true})
  // }

  return (
    <div
      className="drop_wrapper age_filter"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section flex-between p-20 mbd">
        <div className="cursor-pointer" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10">Reset</span>
          <CustomisedButton
            text="Done"
            bg="#FFDA9A"
            fg="#000"
            width="56px"
            className="filter_btn"
          />
        </div>
      </div>
      <div className="drop_main_content subheader">
        <div className="flex-start mbd p-20">
          <span className="mr-10">
            <Form.Check type="checkbox" className="menu_chkbox" />
          </span>
          All
        </div>
      </div>
      <div className="drop_main_content sublist">
        {youthAgeFilter.map((item, index) => (
          <div className={`${item.divide ? "mbd" : ""}`} key={index}>
            <span className="mr-10">{item.leftIcon}</span>
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
}

export function TeamDropdown({ display, func, setData }) {
  const handleDoneBtn = (id) => {
    setData("team", id);
  };

  const toggleDrop = (id) => {
    handleDoneBtn(id);
    func();
  };

  return (
      <div
          className="drop_wrapper verification_status_filter"
          style={{ display: display ? "block" : "none", right: 0 }}
      >
        <div className="drop_main_content sublist">
          {teamDropdown.map((item, index) => (
              <div
                  className={`${item.divide ? "mbd" : ""}`}
                  key={index}
                  onClick={() => toggleDrop(item.value)}
                  style={{ color: item.color }}
              >
                {item.title}
              </div>
          ))}
        </div>
      </div>
  );
}

export function VerificationStatusFilterDrop({ display, func, setData }) {
  const handleDoneBtn = (id) => {
    setData("verification_status", id);
  };

  const toggleDrop = (id) => {
    handleDoneBtn(id);
    func();
  };

  return (
    <div
      className="drop_wrapper verification_status_filter"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="drop_main_content sublist">
        {youthVerificationFilter.map((item, index) => (
          <div
            className={`${item.divide ? "mbd" : ""}`}
            key={index}
            onClick={() => toggleDrop(item.value)}
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
}

export function FilterSectionDrop(props) {
  const { display, func, setData, filters, locations } = { ...props };

  const filterRef = useRef(null);

  const [state, setState] = useState({});

  const toggleDrop = () => {
    setState({});
    func();
  };

  const clearFilters = () => {
    func("clear");
  };

  const checkCategory = (category) => {
    setState({});
    setState({ [category]: true });
    func();
  };

  return (
    <div ref={filterRef} className="position-relative">
      <div
        className="drop_wrapper filter_drop"
        style={{ display: display ? "block" : "none" }}
      >
        <div className="head_section">
          <span>Select a filter</span>
          <div
            className="filter_title"
            onClick={clearFilters}
            style={{ color: filters > 0 ? "#D41811" : "" }}
          >
            Clear All&nbsp;
            <Trash size={13} className="filter_trash_icon" />
          </div>
        </div>
        <div className="drop_main_content">
          <div
            className="flex-between border-top"
            onClick={() => checkCategory("age")}
          >
            Age
            <ChevronRight size={15} className="right_icon" />
          </div>
          <div
            className="flex-between border-top"
            onClick={() => checkCategory("location")}
          >
            Location
            <ChevronRight size={15} className="right_icon" />
          </div>
          <div className="flex-between border-top inactive">
            Junity Influencer
            <ChevronRight size={15} className="right_icon" />
          </div>
          <div
            className="flex-between border-top"
            onClick={() => checkCategory("verification_status")}
          >
            Verification Status
            <ChevronRight size={15} className="right_icon" />
          </div>
        </div>
      </div>
      <AgeFilterDrop
        display={state["age"]}
        func={toggleDrop}
        setData={setData}
      />
      <LocationFilterDrop
        display={state["location"]}
        func={toggleDrop}
        setData={setData}
        locations={locations}
      />
      <JunityInfluencerFilterDrop
        display={state["junity_influencer"]}
        func={toggleDrop}
        setData={setData}
      />
      <VerificationStatusFilterDrop
        display={state["verification_status"]}
        func={toggleDrop}
        setData={setData}
      />
    </div>
  );
}

export function TeamRoleFilterDrop({ display, func, setData }) {
  const toggleDrop = () => {
    func();
  };

  const [state, setState] = useState({});

  const handleDoneBtn = () => {
    toggleDrop();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("team", state);
  };

  const handleChecks = (id) => {
    setState({ ...state, [id]: !state[id] });
  };

  return (
    <div
      className="drop_wrapper team_role_drop mr-10"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section flex-between p-20 mbd">
        <div className="cursor-pointer" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 point" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton text="Done" bg="#FFDA9A" fg="#000" width="56px" />
          </span>
        </div>
      </div>
      <div className="drop_main_content">
        <div className="flex-start mbd ph-10">
          <SearchInput height="35px" width="200px" placeholder="Search..." />
        </div>
      </div>
      <div className="overflow">
        <div className="drop_main_content">
          <div className="flex-start mbd p-20">
            <span className="mr-10" onClick={() => handleChecks("All")}>
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={state["All"]}
                defaultChecked
              />
            </span>
            All
          </div>
        </div>
        <div className="drop_main_content">
          {resourcesTeamRoleFilter.map((item, index) => (
            <div
              className={`flex-start mt-10 ${item.divide ? "mbd" : ""}`}
              key={index}
            >
              <span className="mr-10" onClick={() => handleChecks(item.title)}>
                <Form.Check
                  type="checkbox"
                  className="menu_chkbox"
                  checked={state[item.title]}
                  onChange={() => handleChecks(item.title)}
                />
              </span>
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function TeamFilterSectionDrop(props) {
  const { display, func, setData, filters, className } = { ...props };

  const filterRef = useRef(null);

  const [state, setState] = useState({});

  const toggleDrop = () => {
    setState({});
    func();
  };

  const clearFilters = () => {
    func("clear");
  };

  const checkCategory = (category) => {
    setState({});
    setState({ [category]: true });
    func();
  };

  return (
    <div ref={filterRef}>
      <div
        className={`drop_wrapper team_role_drop ${className}`}
        style={{ display: display ? "block" : "none" }}
      >
        <div className="head_section flex-between p-20">
          <span className="head_section_title">Select a filter</span>
          <div
            className="filter_title"
            onClick={clearFilters}
            style={{ color: filters > 0 ? "#D41811" : "" }}
          >
            Clear All&nbsp;
            <Trash size={15} className="filter_trash_icon" />
          </div>
        </div>
        <div className="drop_main_content">
          <div className="flex-between" onClick={() => checkCategory("team")}>
            <span className="role_title">Role</span>
            <div className="align-items">
              <ChevronRight size={15} className="right_icon" />
            </div>
          </div>
        </div>
      </div>
      <TeamRoleFilterDrop
        display={state["team"]}
        func={toggleDrop}
        setData={setData}
      />
    </div>
  );
}

export function CouncelorsYouthAgeFilterDrop({ display, func, setData }) {
  const toggleDrop = () => {
    func();
  };

  const [state, setState] = useState({});

  const handleDoneBtn = () => {
    toggleDrop();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("age", state);
  };

  const handleChecks = (id) => {
    setState({ ...state, [id]: !state[id] });
  };

  return (
    <div
      className="drop_wrapper councelors_youth_drop mr-10"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section flex-between p-20 mbd">
        <div className="cursor-pointer" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 point" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton text="Done" bg="#FFDA9A" fg="#000" width="56px" />
          </span>
        </div>
      </div>
      <div className="drop_main_content">
        <div className="flex-start mbd ph-10">
          <SearchInput height="35px" width="200px" placeholder="Search..." />
        </div>
      </div>
      <div className="overflow">
        <div className="drop_main_content">
          <div className="flex-start mbd p-20">
            <span className="mr-10" onClick={() => handleChecks("All")}>
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={state["All"]}
                defaultChecked
              />
            </span>
            All
          </div>
        </div>
        <div className="drop_main_content">
          {councelorsYouthAgeFilterList.map((item, index) => (
            <div
              className={`flex-start mt-10 ${item.divide ? "mbd" : ""}`}
              key={index}
            >
              <span className="mr-10" onClick={() => handleChecks(item.title)}>
                <Form.Check
                  type="checkbox"
                  className="menu_chkbox"
                  checked={state[item.title]}
                  onChange={() => handleChecks(item.title)}
                />
              </span>
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function CouncelorsYouthFilterSectionDrop(props) {
  const { display, func, setData, filters } = { ...props };

  const filterRef = useRef(null);

  const [state, setState] = useState({});

  const toggleDrop = () => {
    setState({});
    func();
  };

  const clearFilters = () => {
    func("clear");
  };

  const checkCategory = (category) => {
    setState({});
    setState({ [category]: true });
    func();
  };

  return (
    <div ref={filterRef}>
      <div
        className="drop_wrapper councelors_youth_drop"
        style={{ display: display ? "block" : "none" }}
      >
        <div className="head_section flex-between p-20">
          <span className="head_section_title">Select a filter</span>
          <div
            className="filter_title"
            onClick={clearFilters}
            style={{ color: filters > 0 ? "#D41811" : "" }}
          >
            Clear All&nbsp;
            <Trash size={15} className="filter_trash_icon" />
          </div>
        </div>
        <div className="drop_main_content">
          <div className="flex-between" onClick={() => checkCategory("age")}>
            <span className="role_title">Age</span>
            <div className="align-items">
              <ChevronRight size={15} className="right_icon" />
            </div>
          </div>
        </div>
      </div>
      <CouncelorsYouthAgeFilterDrop
        display={state["age"]}
        func={toggleDrop}
        setData={setData}
      />
    </div>
  );
}

export function AppsNoOpportunityFilterDrop({ display, func, setData }) {
  const toggleDrop = () => {
    func();
  };

  const [state, setState] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const handleDoneBtn = () => {
    toggleDrop();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("applications", Object.keys(state));
  };

  const handleChecks = (id) => {
    setState({ ...state, [id]: !state[id] });
  };

  return (
    <div
      className="drop_wrapper filter_drop opportunity_role_drop mr-10"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section mbd">
        <div className="cursor-pointer" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 cursor-pointer" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton
              className="filter_btn"
              text="Done"
              bg="#FFDA9A"
              fg="#000"
              width="56px"
            />
          </span>
        </div>
      </div>
      <div className="drop_main_content subheader">
        <div className="flex-start mbd ph-10">
          <SearchInput height="35px" width="200px" placeholder="Search..." />
        </div>
      </div>
      <div className="overflow">
        <div className="drop_main_content subheader">
          <div className="flex-start mbd">
            <span className="mr-10" onClick={() => handleChecks("All")}>
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={selectAll}
                onChange={() => {
                  setSelectAll(!selectAll);
                }}
              />
            </span>
            All
          </div>
        </div>
        <div className="drop_main_content sublist">
          {resourcesAppsNoOpportunityRoleFilter.map((item, index) => (
            <div
              className={`flex-start ${item.divide ? "mbd" : ""}`}
              key={index}
            >
              <span className="mr-10" onClick={() => handleChecks(item.title)}>
                <Form.Check
                  type="checkbox"
                  className="menu_chkbox"
                  checked={state[item.title]}
                  onChange={() => handleChecks(item.title)}
                />
              </span>
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function PublishedOpportunityFilterDrop({ display, func, setData }) {
  const toggleDrop = () => {
    func();
  };

  const [state, setState] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const handleDoneBtn = () => {
    toggleDrop();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("published", Object.keys(state));
  };

  const handleChecks = (id) => {
    setState({ ...state, [id]: !state[id] });
  };

  return (
    <div
      className="drop_wrapper filter_drop opportunity_role_drop mr-10"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section mbd">
        <div className="cursor-pointer" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 cursor-pointer" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton
              className="filter_btn"
              text="Done"
              bg="#FFDA9A"
              fg="#000"
              width="56px"
            />
          </span>
        </div>
      </div>
      <div className="drop_main_content subheader">
        <div className="flex-start mbd ph-10">
          <SearchInput height="35px" width="200px" placeholder="Search..." />
        </div>
      </div>
      <div className="overflow">
        <div className="drop_main_content subheader">
          <div className="flex-start mbd">
            <span className="mr-10" onClick={() => handleChecks("All")}>
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={selectAll}
                onChange={() => {
                  setSelectAll(!selectAll);
                }}
              />
            </span>
            All
          </div>
        </div>
        <div className="drop_main_content sublist">
          {resourcesPublishedOpportunityRoleFilter.map((item, index) => (
            <div
              className={`flex-start ${item.divide ? "mbd" : ""}`}
              key={index}
            >
              <span className="mr-10" onClick={() => handleChecks(item.value)}>
                <Form.Check
                  type="checkbox"
                  className="menu_chkbox"
                  checked={state[item.value]}
                  onChange={() => handleChecks(item.value)}
                />
              </span>
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function OpportunityFilterSectionDrop(props) {
  const { display, func, setData, filters } = { ...props };

  const filterRef = useRef(null);

  const [state, setState] = useState({});

  const toggleDrop = () => {
    setState({});
    func();
  };

  const clearFilters = () => {
    func("clear");
  };

  const checkCategory = (category) => {
    setState({});
    setState({ [category]: true });
    func();
  };

  return (
    <div ref={filterRef}>
      <div
        className="drop_wrapper filter_drop opportunity_role_drop"
        style={{ display: display ? "block" : "none" }}
      >
        <div className="head_section flex-between">
          <span>Select a filter</span>
          <div
            className="filter_title"
            onClick={clearFilters}
            style={{ color: Object.keys(filters).length > 0 ? "#D41811" : "" }}
          >
            Clear All&nbsp;
            <Trash size={15} className="filter_trash_icon" />
          </div>
        </div>
        <div className="drop_main_content">
          <div className="border-top" onClick={() => checkCategory("apps_no")}>
            <span>No of Applications</span>
            <div>
              <ChevronRight size={15} className="right_icon" />
            </div>
          </div>
          <div
            className="border-top"
            onClick={() => checkCategory("published")}
          >
            <span>Published</span>
            <div>
              <ChevronRight size={15} className="right_icon" />
            </div>
          </div>
        </div>
      </div>
      <AppsNoOpportunityFilterDrop
        display={state["apps_no"]}
        func={toggleDrop}
        setData={setData}
      />
      <PublishedOpportunityFilterDrop
        display={state["published"]}
        func={toggleDrop}
        setData={setData}
      />
    </div>
  );
}

export const OpportunityDropdown = ({ id, view }) => {
  const [state, setState] = useState(false);

  const checkCategory = () => {
    setState(!state);
  };

  const openOpportunity = () => {
    view(id);
    setState(!state);
  };

  return (
    <div>
      <ThreeDotsVertical
        size={20}
        fill="#515A6A"
        onClick={() => {
          setState(!state);
        }}
      />
      <div
        className="drop_wrapper filter_drop opportunity_dropdown"
        style={{ display: state ? "block" : "none" }}
      >
        <div className="drop_main_content">
          <div onClick={openOpportunity}>View Opportunity</div>
          <div onClick={() => checkCategory()}>Suspend</div>
          <div className="text-danger fw-bold" onClick={() => checkCategory()}>
            Delete
          </div>
        </div>
      </div>
    </div>
  );
};
