import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { ReactComponent as Sales } from "../../assets/icons/sales.svg";
import BasicAreaChart from "./Chart";
import { sortDataByField } from "../../utils/helpers";

function Infos({ data, purchases }) {
  const filterPurchasesByExperienceID = (id) => {
    return purchases.filter((elt) => elt.experienceID === id).length;
  };

  const [sortedData, setSortedData] = useState([]);

  useEffect(() => {
    const dataWithOrders = data.reduce((acc, item) => {
      return [
        ...acc,
        { ...item, orders: filterPurchasesByExperienceID(item.id) },
      ];
    }, []);
    setSortedData(sortDataByField(dataWithOrders, "orders"));
  }, [data]);

  return (
    <div className="experiences_infos">
      <Row>
        <div className="info_card">
          <div className="info_card_title">Most popular experiences</div>
          {sortedData?.slice(0, 4)?.map((card, index) => (
            <div className="info_card_details" key={index}>
              <div className="flex-between pv-10">
                <div className="flex-start">
                  <div className="mr-10">
                    <img
                      src={card?.image}
                      alt=""
                      style={{ width: "56px", height: "56px" }}
                    />
                  </div>
                  <div className="flex-v">
                    <div>{card?.title}</div>
                    <div>{card?.orders} orders</div>
                  </div>
                </div>
                <div className="align-items">
                  <Sales size={24} />
                  <span className="ml-10">{card?.price_per_person}</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="info_card">
          <div className="info_card_title">Sales History</div>
          <BasicAreaChart purchases={purchases} />
        </div>
      </Row>
    </div>
  );
}

export default Infos;
