import {
    SET_CURRENT_STATE_DATA,
    RESET_CURRENT_STATE_DATA,
} from "../constants/currentStateConstants";

export const setCurrentStateDataAction = (data) => async (dispatch) => {
    dispatch({
        type: SET_CURRENT_STATE_DATA,
        payload: data,
    });
};

export const resetCurrentStateDataAction = () => async (dispatch) => {
    dispatch({
        type: RESET_CURRENT_STATE_DATA,
    });
};
