import {
  SET_ADMIN_DATA,
  SET_ADMIN_TOKEN_DATA,
  RESET_ADMIN_DATA,
} from "../constants/adminConstants";

export const setAdminDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_ADMIN_DATA,
    payload: data,
  });
};

export const setAdminTokenDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_ADMIN_TOKEN_DATA,
    payload: data,
  });
};

export const resetAdminDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_ADMIN_DATA,
  });
};
