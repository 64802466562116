import React, { useState, useEffect } from "react";
import { ChevronDown } from "react-bootstrap-icons";
import { resourcesAnalyticsTabList } from "../../assets/data/resourcesTabList";
import { DashBoardTopDrop } from "../../widgets/Dropdown/alltimeDrop";
import { GetStudentOpportunityApplicationBySchoolID } from "../../services/query/application";
import { GetOpportunityByResourceID } from "../../services/query/opportunities";

function AnalyticsTab({ locationId }) {
  const [state, setState] = useState(false);
  const [apps, setApps] = useState(0);
  const [opps, setOpps] = useState(0);
  const [likedOpps, setLikedOpps] = useState(0);
  const [applicantPerOpp, setApplicantPerOpp] = useState(0);
  const [data, setData] = useState({});

  const toggleDrop = () => {
    setState(!state);
  };

  const setSelected = (id, item) => {
    setData({ ...data, [id]: item });
  };

  useEffect(() => {
    GetStudentOpportunityApplicationBySchoolID(locationId).then((apps) => {
      const applicants = apps.reduce((acc, app) => {
        if (Array.isArray(acc) && !acc?.includes(app.studentID)) {
          return acc.push(app.studentID);
        }
      }, []);
      setApps(apps.length);
      setApplicantPerOpp(applicants?.length || 0);
    });
    GetOpportunityByResourceID(locationId).then((opps) => {
      setOpps(opps.active.length);
      setLikedOpps(opps?.active?.filter((opp) => opp.likes > 0).length);
    });
  }, [locationId]);

  return (
    <>
      <div className="resources_analytics_tab">
        <div className="activity_top_sect flex-between mt-20">
          <div className="align-items">Showing recent activities</div>
          <div
            className="flex-between pd-20 all_time_drop"
            onClick={toggleDrop}
          >
            All time
            <ChevronDown size={13} className="icon_down" />
          </div>
        </div>
        <div className="main_content">
          <div className="cards_grid">
            {resourcesAnalyticsTabList(apps, opps, likedOpps, applicantPerOpp)?.map((item, index) => (
              <div className="analytics_card" key={index}>
                <h1>{item.number}</h1>
                <h6>{item.title}</h6>
              </div>
            ))}
          </div>
        </div>
      </div>
      <DashBoardTopDrop
        display={state}
        setData={setSelected}
        toggleDrop={toggleDrop}
      />
    </>
  );
}

export default AnalyticsTab;
