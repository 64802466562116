import React from 'react';
import {filterOrgsByState, titleWords} from "../helpers"
import { 
  nameCol,
  verificationColTabs
} from "../TableWrapper/tableWrapper"
import CustomisedButton from "../../widgets/Button/Button";



export const createNameCol = (name, img) => {
  return (
    nameCol({
      img,
      name: titleWords(name),
      layout: 2,
      bottomText: "",
      verified: true
    })
  )
}

export const createResourcesTableContent = (data, state) => {
  const res = []
  data?.forEach((elt) => {
    const obj = {
      name: [
        "org_name_col", 
        createNameCol(elt?.school?.name, elt?.school?.image),
        elt?.school?.name,
        // eslint-disable-next-line no-undef
        require("../../assets/images/achieve_hartford.png"),
      ],
      no_of_loc: ["no_of_loc", filterOrgsByState(elt?.schools, state)?.length],
      org_verification_col: [
        "org_verification_col", 
        verificationColTabs(elt?.verification_status || "unverified")
      ],
      final_col: ["final_col", "..."],
    }

    res.push(Object.values(obj))
  })
  return res
}

export const createResourceModalDetails =  () => {
  const verificationInfo = {
    "title": "VERIFICATION INFORMATION",
    "innerItem": [
      {
        "key": "Verification Date:",
        "val": "Sunday, March 21, 2022",
      },
      {
        "key": "Verifier:",
        "val": "Theresea Webb",
      },
      {
        "key": "Verification Status:",
        "val": 
          <CustomisedButton 
            text="Verified" 
            bg="#DAF8EE"
            fg="#226E54"
            bd="1px solid #44DDA9"
            rd="25px"
            pd="5px 10px"
          />
        ,
      },
    ]
  }

  const publicInfo = {
    "title": "PUBLIC INFORMATION",
    "innerItem": [
      {
        "key": "About Organization:",
        "val": "Ipsum enim eu nibh duis auctor adipiscing eu, proin. Vitae nibh libero ultrices in ut tristique mattis interdum justo. \
                Massa fusce purus, felis at pulvinar arcu. Ultrices arcu convallis elementum, blandit cursus risus morbi consequat auctor. \
                Posuere lobortis risus vitae eget volutpat non posuere. Enim ipsum ipsum viverra proin porta odio viverra sed.",
      },
      {
        "key": "Website",
        "val": "https://achievehartford.org",
      },
    ]
  }

  const organizationInfo = {
    "title": "ORGANIZATION INFORMATION",
    "innerItem": [
      {
        "key": "Industry:",
        "val": "Technology",
      },
      {
        "key": "Organization Size:",
        "val": "Fewer than 100",
      },
      {
        "key": "Team Size:",
        "val": "2-10",
      },
    ]
  }

  const locationInfo = {
    "title": "LOCATION INFORMATION",
    "innerItem": [
      {
        "key": "Location:",
        "val": "Hartford, CT",
      },
      {
        "key": "Office Address:",
        "val": "2118 Thornridge Cir. Hartford",
      },
    ]
  }

  const accountInfo = {
    "title": "ACCOUNT INFO",
    "innerItem": [
      {
        "key": "Created By:",
        "val": "Jennifer Pearson",
      },
      {
        "key": "Role:",
        "val": "Administrator",
      },
      {
        "key": "Email",
        "val": "jennypearson0911@mail.com",
      },
      {
        "key": "Phone:",
        "val": "+1 343-647 6271",
      },
    ]
  }

  return [
    verificationInfo,
    publicInfo,
    organizationInfo,
    locationInfo,
    accountInfo
  ]

}
