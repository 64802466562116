import React, { useRef, useState, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import { createYouthModalDetails } from "../../../utils/Youth/utils"

function LeftModalAccountTab({ data }) {

  const contentRef = useRef(null);
  const [mainData, setMainData] = useState([]);

  const collapse = (state, index, icon) => {
    if (state){
      const getSect = contentRef.current?.children[index].children[1]
      getSect.style.display = "none";
    }

    else {
      const getSect = contentRef.current?.children[index].children[1]
      getSect.style.display = "block";
    }

    if (icon === "down") {
      const getSect = contentRef.current?.children[index].children[0]
      const getIcons = getSect.children[1].children
      getIcons[0].style.display = "none";
      getIcons[1].style.display = "block";
    }

    else if (icon === "up") {
      const getSect = contentRef.current?.children[index].children[0]
      const getIcons = getSect.children[1].children
      getIcons[1].style.display = "none";
      getIcons[0].style.display = "block";
    }
  }

  useEffect(() => {
    createYouthModalDetails(data).then((details) => setMainData(details));
  }, [data])
  

  return ( 
    <div 
      className='youth_account_tab_wrapper'
      ref={contentRef}
    >
      {mainData?.map((item, index) =>
        <div key={index} className="sect_wrapper">
          <div className='sect'>
            <span>{item.title}</span>
            <div>
              <ChevronDown 
                style={{display: "none"}}
                onClick={() => collapse(false, index, "down")}
              />
              <ChevronUp 
                onClick={() => collapse(true, index, "up")}
              />
            </div>
          </div>
          <div className='sect_main'>
            {item.innerItem.map((itemInner, index) =>
              <div className='sect_details' key={index}>
                <div className='sect_details_title'>{itemInner.key}</div>
                <div className='sect_details_sub_title'>{itemInner.val}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default LeftModalAccountTab;