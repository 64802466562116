import React, { useState, useEffect } from "react";
import { ChevronRight } from "react-bootstrap-icons";
import { purchasesTableList } from "../../assets/data/purchases";
import TableTemplate from "../../components/Admin/TableTemplate";
import PaginationWrapper from "../../components/Youth/PaginationWrapper";
import TopSection from "../../components/Youth/TopSection";
import { createPurchasesFullTableContent } from "../../utils/Experiences/utils";
import { axiosClient } from "../../libs/axiosClient";
import "../../scss/Admin/purchases.scss";

function PurchasesPage() {
  const [contentList, setContentList] = useState([]);
  const [formattedPurchases, setFormattedPurchases] = useState([]);

  const getAllPurchases = async () => {
    try {
      const resp = await axiosClient.get("/purchases");
      const data = resp.data.data;
      setFormattedPurchases(data);
      setContentList(createPurchasesFullTableContent(data));
      setFormattedPurchases(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllPurchases();
  }, []);

  return (
    <div className="purchases_main_content">
      <div className="purchases_main_inner">
        <TopSection
          inputPlaceholder="Search for a purchase..."
          purchases={formattedPurchases}
          filterText={true}
        />
        <TableTemplate
          data={purchasesTableList}
          details={formattedPurchases}
          tableContent={contentList}
          layout="purchases"
          btnText={
            <>
              <span className="mr-10">View Account</span>
              <span>
                <ChevronRight size={14} />
              </span>
            </>
          }
        />
        <PaginationWrapper
            moveToPreviousPage={() => {}}
            moveToNextPage={() => {}}
            page={1}
            setPageLimit={() => {}}
            nextToken={""}
        />
      </div>
    </div>
  );
}

export default PurchasesPage;
