import React, { useEffect, useState } from "react";
import ModalWrapper from "../../widgets/Modal/modal";
import {
  opportunitiesAppsTableList,
  opportunitiesYouthTableList,
  resourcesRightModalIcons,
} from "../../assets/data/resourcesTabList";
import CustomisedButton from "../../widgets/Button/Button";
import { Grid, Grid3x3, X } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import SearchInput from "../../widgets/SearchInput/searchInput";
import PaginationWrapper from "../Youth/PaginationWrapper";
import TableTemplate from "../../components/Admin/TableTemplate";
import {
  createOppYouthTableContent,
  createOppAppsTableContent
} from "../../utils/Youth/utils"
import { 
  FilterFieldUnique, filterDataByField, searchData, sortDataByField
 } from "../../utils/helpers";
 import { debounce } from "lodash";

function OpportunitiesModalTabs(props) {
  const { show, setShow, layout, opportunity } = { ...props };
  const [contentList, setContentList] = useState([]);
  const [contentListYouth, setContentListYouth] = useState([]);
  const [applications, setApplications] = useState([]);
  const [locations, setLocations] = useState([]);
  const [youths, setYouths] = useState([]);
  const [filteredApps, setFilteredApps] = useState([]);
  const [filteredYouth, setFilteredYouth] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const filterTable = (field, value) => {
    if (layout === "apps") {
      if (value) setFilteredApps(filterDataByField(applications, field, value));
      else setFilteredApps(applications);
    } else if (layout === "youth") {
      if (value)
        setFilteredYouth(filterDataByField(applications, field, value));
      else setFilteredYouth(applications);
    }
    else if (layout === 'youth'){
      if (value)
        setFilteredYouth(filterDataByField(youth, field, value))
      else setFilteredYouth(youth)
    }
    
  }

  const sortTable = (sortBy) => {
    if (layout === "apps")
      setContentList(
        createOppAppsTableContent(sortDataByField(filteredApps, sortBy)),
      );
    else
      setContentListYouth(
        createOppYouthTableContent(sortDataByField(filteredYouth, sortBy)),
      );
  };

  const debouncedSearch = debounce((term) => {
    if (layout === "apps") setFilteredApps(searchData(applications, term));
    else setFilteredYouth(searchData(applications, term));
  }, 300);

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const apps = opportunity?.apps
    
    const applicants = apps?.map(item => {
      let student = {...item?.student}
      student.status = item?.status
      return student
    })

    setApplications(apps)
    setYouths(applicants)
    setFilteredApps(apps)
    setFilteredYouth(applicants)

  
    if (apps){
      if (layout === 'apps'){
        setLocations(FilterFieldUnique(apps, 'state'))
        setContentList(createOppAppsTableContent(apps))
      }
      else {
        setLocations(FilterFieldUnique(applicants, 'state'))
        setContentListYouth(createOppYouthTableContent(applicants))
      }
    }
  }, [opportunity]);

  useEffect(() => {
    setContentList(createOppAppsTableContent(filteredApps));
  }, [filteredApps]);

  useEffect(() => {
    setContentListYouth(createOppYouthTableContent(filteredYouth))
  }, [filteredYouth])

  const apps = () => {
    return (
      <div className="opps_apps">
        <div className="pm-20">
          <h5 className="bold">Applications ({applications?.length})</h5>
          <div className="view_sect flex-between mt-20 mb-4">
            <div className="d-flex align-items-center gap-2">
              <div className="align-items">
                <div>View</div>
              </div>
              <div className="flex-center grid3_icon">
                <Grid3x3 size={18} />
              </div>
              <div className="flex-center grid_icon">
                <Grid size={18} />
              </div>
            </div>
            <div className="flex-evenly" style={{ height: 40 }}>
              <div className="align-items mr-10">Filters:</div>
              <div className="flex-start mr-10 cover_select">
                <span className="align-items mr-10 text-dark">Location:</span>
                <Form.Select
                  onChange={(e) => {
                    filterTable("state", e.target.value);
                  }}
                >
                  <option value="">All</option>
                  {locations?.map((location, index) => (
                    <option value={location} key={`loc-${index}`}>
                      {location}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="flex-start mr-10 cover_select">
                <span className="align-items mr-10 text-dark">Status:</span>
                <Form.Select
                  onChange={(e) => {
                    filterTable("status", e.target.value);
                  }}
                >
                  <option value="">All</option>
                  <option value={"pending"}>Pending</option>
                  <option value={"accepted"}>Accepted</option>
                </Form.Select>
              </div>
              <SearchInput
                bg="#fff"
                height="40px"
                placeholder="Search for candidate by name"
                className="border rounded-3"
                onChange={setSearchQuery}
              />
            </div>
          </div>
          <TableTemplate
            data={opportunitiesAppsTableList}
            details={applications}
            tableContent={contentList}
            sortFunction={sortTable}
            layout="opps_apps"
            btnText="Btn"
          />
          <PaginationWrapper
              moveToPreviousPage={() => {}}
              moveToNextPage={() => {}}
              page={1}
              setPageLimit={() => {}}
              nextToken={""}
          />
        </div>
      </div>
    );
  };

  const youth = () => {
    return (
      <div className="opps_apps youth">
        <div className="pm-20">
          <h5 className="bold">Youths ({youths?.length})</h5>
          <div className="view_sect flex-start mt-20 mb-4">
            <div className="flex-evenly" style={{ height: 40 }}>
              <div className="align-items mr-10">Filters:</div>
              <div className="flex-start mr-10 cover_select">
                <span className="align-items mr-10 text-dark">Location:</span>
                <Form.Select
                  onChange={(e) => {
                    filterTable("state", e.target.value);
                  }}
                >
                  <option value="">All</option>
                  {locations?.map((location, index) => (
                    <option value={location} key={`loc-${index}`}>
                      {location}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="flex-start mr-10 cover_select">
                <span className="align-items mr-10 text-dark">Connection:</span>
                <Form.Select>
                  <option value="">All</option>
                  <option value={"connected"}>Connected</option>
                  <option value={"not_connected"}>Not Connected</option>
                </Form.Select>
              </div>
              <div className="flex-start mr-10 cover_select">
                <span className="align-items mr-10 text-dark">Status:</span>
                <Form.Select
                  onChange={(e) => {
                    filterTable("status", e.target.value);
                  }}
                >
                  <option value="">All</option>
                  <option value={"pending"}>Pending</option>
                  <option value={"accepted"}>Accepted</option>
                </Form.Select>
              </div>
            </div>
          </div>
          <TableTemplate
            data={opportunitiesYouthTableList}
            details={applications}
            tableContent={contentListYouth}
            sortFunction={sortTable}
            layout="opps_apps"
            btnText="Btn"
          />
          <PaginationWrapper
              moveToPreviousPage={() => {}}
              moveToNextPage={() => {}}
              page={1}
              setPageLimit={() => {}}
              nextToken={""}
          />
        </div>
      </div>
    );
  };

  const layouts = {
    apps: apps(),
    youth: youth(),
  };

  const returnLayout = (layout) => {
    if (Object.keys(layouts).includes(layout)) {
      return layouts[layout];
    }
  };

  return (
    <ModalWrapper state={`${show ? "show" : ""}`} className="opps_tabs">
      <div className="pm-20">
        <div className="flex-between">
          <div className="title">{opportunity?.name}</div>
          <div className="flex-start">
            <div
              className="modal_top_icons d-flex align-items-center justify-content-center"
              onClick={() => setShow(false)}
            >
              <X fill="#515A6A" size={16} className="cursor-pointer" />
            </div>
          </div>
        </div>
        <div className="mt-20 flex-start">
          {opportunity?.interests.map((elt, index) => (
            <CustomisedButton
              text={elt}
              bg="#DAF8EE"
              fg="#226E54"
              bd="1px solid #44DDA9"
              rd="25px"
              pd="5px 10px"
              className="mr-10"
              key={`btn-${index}`}
            />
          ))}
        </div>
        <div className="content_header border-bottom pb-4">
          <div className="flex-start">
            {resourcesRightModalIcons(opportunity)?.map((item, index) => (
              <div className={`flex-v ${index ? "mx-5" : "me-5"}`} key={index}>
                <div className="flex-start gap-1">
                  <span
                    className="opportunity-info-number"
                    style={{ fontSize: 20 }}
                  >
                    {item.number}
                  </span>
                  <div className="flex-center opportunity-info-icon">
                    {item.icon}
                  </div>
                </div>
                <div className="opportunity-info-title">{item.title}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <>{returnLayout(layout)}</>
    </ModalWrapper>
  );
}

export default OpportunitiesModalTabs;
