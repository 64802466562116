import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

function FeedbackCounselors() {
  return (
    <div className='mt-2 p-3'>
      <ListGroup className='feedback_list_group'>
        <ListGroup.Item>Indicate Interest in Junity</ListGroup.Item>
        <ListGroup.Item>Report an Opportunity</ListGroup.Item>
        <ListGroup.Item>Report a Counselor</ListGroup.Item>
        <ListGroup.Item>Report an Issue (Chat)</ListGroup.Item>
        <ListGroup.Item>Account Closure</ListGroup.Item>
      </ListGroup>
    </div>
  );
}

export default FeedbackCounselors;