import { gql } from "@apollo/client";

export const CREATE_EXPERIENCE = gql`
  mutation CreateExperienceMutation(
    $closing_date: AWSDateTime
    $description: String
    $is_featured: Boolean
    $location: String
    $partners: [String]
    $price: Float
    $requirements: [String]
    $sessionsIDs: [ID]
    $title: String!
    $type: String
    $image: String
    $is_draft: Boolean
  ) {
    createExperience(
      input: {
        closing_date: $closing_date
        description: $description
        is_featured: $is_featured
        location: $location
        partners: $partners
        price_per_person: $price
        requirements: $requirements
        sessionsIDs: $sessionsIDs
        image: $image
        title: $title
        type: $type
        is_draft: $is_draft
      }
    ) {
      id
      title
    }
  }
`;

export const UPDATE_EXPERIENCE = gql`
  mutation UpdateExperienceMutation(
    $id: ID!
    $closing_date: AWSDateTime
    $description: String
    $is_featured: Boolean
    $location: String
    $partners: [String]
    $price: Float
    $requirements: [String]
    $sessionsIDs: [ID]
    $title: String!
    $type: String
    $image: String
  ) {
    updateExperience(
      input: {
        closing_date: $closing_date
        description: $description
        is_featured: $is_featured
        location: $location
        partners: $partners
        price_per_person: $price
        requirements: $requirements
        sessionsIDs: $sessionsIDs
        image: $image
        title: $title
        type: $type
        id: $id
      }
    ) {
      type
      title
      sessionsIDs
      requirements
      price_per_person
      partners
      closing_date
      description
      id
      is_archived
      is_featured
      location
    }
  }
`;
