import React from 'react';
import { 
  calculateAge, 
  calculateTime, 
  formatDateForSessions, 
  formatDateRelativeToToday, 
  getInitial, 
  getRandomColor, 
  titleWords
} from "../helpers"
import { 
  nameCol,
  nameWithInfoCol,
  statusColTabs,
  verificationColTabs,
} from "../TableWrapper/tableWrapper";
import { InfoCircle, ThreeDots } from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";
import { GetSingleInterest } from "../../services/query/interests";
import { Link, useNavigate } from 'react-router-dom';

const progressButtons = {
  inprogress: {
    text: "In Progress",
    bd: "#FFC768",
    bg: "#FFE3B3",
    fg: "#554223",
  },
  verified: {
    text: "Verified",
    bd: "#A1EED4",
    bg: "#DAF8EE",
    fg: "#226E54",
  },
  unverified: {
    text: "Not Verified",
    bd: "#DDDFE2",
    bg: "#FFFFFF",
    fg: "#2E394C",
  },
};

export const createNameCol = (name, color, verified=false, date=false) => {
  const info = {
    Ibg: color,
    text: getInitial(name),
    name: titleWords(name),
    layout: 1,
    verified
  }

  if (date)
    info.bottomText = `Joined ${formatDateRelativeToToday(date)}`
  
  return (
    nameCol(info)
  )
}

export const applicationTableNameCol = (name, color, date) => {
  return (
    nameWithInfoCol({
      Ibg: color,
      text: getInitial(name),
      name: titleWords(name),
      layout: 1,
      bottomText: `Applied ${formatDateRelativeToToday(date)}`
    })
  )
}

export const createYouthTableContent = (data) => {
  try {
    const res = [];
    data?.forEach((elt) => {
      const color = getRandomColor();
      const obj = {
        name: ["name_col", createNameCol(elt?.name, color, elt?.is_verified)],
        location: ["location_col", elt?.state],
        status: [
          "verification_col",
          verificationColTabs(elt?.verification_status || "unverified"),
        ],
        last_seen: ["verification_col", calculateTime(elt?.updatedAt) || "Recently"],
      };

      res.push(Object.values(obj));
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

const viewProfileLink = (id)=>{
  const navigate = useNavigate()
  return (
    <Link to={navigate(`/youth?id=${id}`)} className='white-space-nowrap'>
      <i className='far fa-user fa-fw fw-bold'></i>
      View Profile
    </Link>
  )
}

export const createOppAppsTableContent = (data) => {
  try {
    const res = []
    data?.forEach((elt, index) => {
      const color = getRandomColor();
      const obj = {
        name: ["name_col", applicationTableNameCol(elt?.name, color, elt?.createdAt)],
        connection: ["connection_col", 'Not Connected'],
        age: ["age_col", calculateAge(elt?.dob)],
        location: ["location_col", elt?.state],
        status: [
          "status_col", statusColTabs(elt?.status)
        ],
        profile: ["profile_col", viewProfileLink(elt?.id)],
        final_col: ["final_col", <ThreeDots size={16} fill='#262F3F' key={index}/>],
      }

      res.push(Object.values(obj));
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createOppYouthTableContent = (data) => {
  try {
    const res = []
    data?.forEach((elt, index) => {
      const color = getRandomColor();
      const obj = {
        name: ["name_col", createNameCol(elt?.student?.name, color, elt?.is_verified)],
        age: ["age_col", calculateAge(elt?.student?.dob)],
        location: ["location_col", elt?.student?.state],
        connection: ["connection_col", 'Not Connected'],
        status: [
          "status_col", statusColTabs(elt?.status)
        ],
        actions: ["profile_col", 'No action required'],
        final_col: ["final_col", <ThreeDots size={16} fill='#262F3F' key={index}/>],
      }

      res.push(Object.values(obj));
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createYouthModalDetails = async (details) => {
  const studentInterests = await details?.interestIDs?.reduce(
    async (acc, elt, index) => {
      const prev = await acc;
      const interest = await GetSingleInterest(elt);
      const category = details?.interests[index];
      const resp = { ...prev };
      if (Object.keys(resp).includes(category)) {
        resp[category] = [...resp[category], interest];
      } else {
        resp[category] = [interest];
      }
      return resp;
    },
    Promise.resolve({}),
  );

  const verificationInfo = {
    title: "VERIFICATION INFORMATION",
    innerItem: [
      {
        key: "Parent/Guardian:",
        val: details?.guardian?.name || "Not Specified",
      },
      {
        key: "Phone:",
        val: details?.guardian?.phone
          ? "1" + details?.guardian?.phone
          : "Not Specified",
      },
      {
        key: "Email:",
        val: details?.guardian?.email || "Not Specified",
      },
      {
        key: "Verification Date:",
        val: details?.guardian?.createdAt
        ? formatDateForSessions(details?.guardian?.createdAt)
        : "Not Specified",
      },
      {
        key: "Verification Status:",
        val: (
          <CustomisedButton
            text={
              progressButtons[details?.verification_status || "unverified"][
                "text"
              ]
            }
            fg={
              progressButtons[details?.verification_status || "unverified"][
                "fg"
              ]
            }
            bg={
              progressButtons[details?.verification_status || "unverified"][
                "bg"
              ]
            }
            bd={`1px solid ${
              progressButtons[details?.verification_status || "unverified"][
                "bd"
              ]
            }`}
            rd="25px"
            pd="5px 10px"
          />
        ),
      },
    ],
  };
  const personalInfo = {
    title: "PERSONAL INFORMATION",
    innerItem: [
      {
        key: "Identified As:",
        val: titleWords(details?.gender),
      },
      {
        key: "Age:",
        val: calculateAge(details?.dob),
      },
      {
        key: "Location:",
        val: details?.state,
      },
    ],
  };

  const interestInfo = (interests) => {
    const innerItem = [];

    Object.keys(interests).forEach((category) => {
      const interest = [];
      interests[category].forEach((elt) =>
        interest.push(
          <CustomisedButton
            text={elt.name}
            bg="#FFE3B3"
            fg="#554223"
            bd="1px solid #FFC768"
            rd="25px"
            pd="5px 10px"
          />,
        ),
      );

      innerItem.push({
        key: (
          <>
            {category}:<InfoCircle fill="#B9BDC3" />
          </>
        ),
        val: interest,
      });
    });

    return { title: "INTEREST", innerItem };
  };

  const educationInfo = {
    title: "EDUCATION",
    innerItem: [
      {
        key: "Status:",
        val: details?.schooling_mode
          ? "Enrolled in school"
          : "Not enrolled in school",
      },
    ],
  };

  const volunteerWorkInfo = (volunteers) => {
    const organizations = volunteers.reduce((acc, elt, index) => {
      return (
        acc +
        (index !== volunteers?.length - 1
          ? `${titleWords(elt.organization)},`
          : titleWords(elt.organization))
      );
    }, "");

    const hoursSpent = volunteers.reduce(
      (acc, elt) => acc + (Number(elt?.hours_spent) || 0),
      0,
    );

    return {
      title: "VOLUNTEER WORK",
      innerItem: [
        {
          key: "Volunteer Hours:",
          val: hoursSpent,
        },
        {
          key: "Organizations:",
          val: organizations,
        },
      ],
    };
  };

  return [
    verificationInfo,
    personalInfo,
    interestInfo(studentInterests),
    educationInfo,
    volunteerWorkInfo(details?.volunteer),
  ];
};

export const sortByAge = (data) => {
  return data.sort((a, b) => {
    const ageA = calculateAge(a.dob);
    const ageB = calculateAge(b.dob);
    return ageA - ageB;
  });
};

export const youthAccountTabList = [
  {
    title: "VERIFICATION INFORMATION",
    innerItem: [
      {
        key: "Parent/Guardian:",
        val: "Elvis Taylor",
      },
      {
        key: "Phone:",
        val: "+1 343-647 6271",
      },
      {
        key: "Email:",
        val: "elvistaylor@mail.com",
      },
      {
        key: "Verification Date:",
        val: "Sunday, March 21, 2022",
      },
      {
        key: "Verification Status:",
        val: (
          <CustomisedButton
            text="Verified"
            bg="#DAF8EE"
            fg="#226E54"
            bd="1px solid #44DDA9"
            rd="25px"
            pd="5px 10px"
          />
        ),
      },
    ],
  },

  {
    title: "PERSONAL INFORMATION",
    innerItem: [
      {
        key: "Identified As:",
        val: "Male",
      },
      {
        key: "Age:",
        val: "18",
      },
      {
        key: "Location:",
        val: "Hartford, CT",
      },
    ],
  },

  {
    title: "INTEREST",
    innerItem: [
      {
        key: (
          <>
            Business:
            <InfoCircle fill="#B9BDC3" />
          </>
        ),
        val: (
          <CustomisedButton
            text="Entrepreneurship"
            bg="#FFE3B3"
            fg="#554223"
            bd="1px solid #FFC768"
            rd="25px"
            pd="5px 10px"
          />
        ),
      },
      {
        key: "Technology:",
        val: (
          <>
            <CustomisedButton
              text="Web & App Design"
              bg="#FFE3B3"
              fg="#554223"
              bd="1px solid #FFC768"
              rd="25px"
              pd="5px 10px"
            />
            &nbsp;&nbsp;
            <CustomisedButton
              text="IT Consulting"
              bg="#FFE3B3"
              fg="#554223"
              bd="1px solid #FFC768"
              rd="25px"
              pd="5px 10px"
            />
          </>
        ),
      },
    ],
  },

  {
    title: "EDUCATION",
    innerItem: [
      {
        key: "Status:",
        val: "Enrolled in school",
      },
      {
        key: "School Name:",
        val: "St Joseph High School",
      },
      {
        key: "Grade",
        val: "12",
      },
    ],
  },

  {
    title: "VOLUNTEER WORK",
    innerItem: [
      {
        key: "Volunteer Hours:",
        val: "300",
      },
      {
        key: "Organizations:",
        val: "Mitsubishi,Louis Vuitton,L'Oréal,Sony",
      },
    ],
  },
];
