import React, { useState, useRef } from "react";
import LeftModalTopSection from "../../Youth/LeftModal/TopSection";
import {
  ChevronDown,
  ChevronUp,
} from "react-bootstrap-icons";
import CustomisedButton from "../../../widgets/Button/Button";
import { ReactComponent as MaskIcon } from "../../../assets/images/mask.svg";
import CompleteDeactivation from "./CompleteDeactivation";
import ToastContainerComponent from "../../AccountVerification/ToastContainer";
import { CreateInitials } from "../../../utils/AccountDeactivation";
import { calculateTime } from "../../../utils/helpers";
import { ClipLoader } from "react-spinners";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

function RightContent(props) {
  const { item, id, layout, update, func, bgColor, refetch } = { ...props };
  const user = useSelector((store) => store.adminUser.data);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const handleResponse = (resp) => {
    func(resp);
  };
  const data = {
    title: "De-activation Request Approved",
    message:
      "Devon’s Junity account will be \
              deactivated when another super admin approves too.",
  };

  const progress = () => {
    return <ClipLoader size={10} color={loading ? "#fff" : ""} />;
  };

  const [collapseRequestDetails, setCollapseRequestDetails] = useState(false);
  const [collapseActivity, setCollapseActivity] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [deactivationSuccess, setDeactivationSuccess] = useState(
    item?.deactivated,
  );
  const [showToast, setShowToast] = useState(false);
  const activityTextRef = useRef(null);

  const collapse = (state, sect) => {
    if (sect === "request_details") {
      state
        ? setCollapseRequestDetails(true)
        : setCollapseRequestDetails(false);
    } else {
      state ? setCollapseActivity(true) : setCollapseActivity(false);
    }
  };

  const activateUser = () => {
    setLoading(true);
  };

  const requestedBy = () => {
    return (
      <CreateInitials
        name={item?.requesterName}
        bg={bgColor}
        width="25px"
        fontSize="12px"
      />
    );
  };

  const getActivityText = () => {
    if (!item?.approved) {
      return "2 super admin needs to approve this request."
    } else if (!item?.secondApproverApproved) {
      return "1 more super admin needs to approve this request."
    } else {
      return ""
    }
  }

  const getActionButton = () => {
    if (item?.secondApproverApproved) {
      return (
          <div className="mt-20 pd-20">
            <div className="sect_item">
              <CustomisedButton
                  width="100%"
                  height="40px"
                  bg="#226E54"
                  bd="1px solid #226E54"
                  text={loading ? progress() : "Re-activate Account"}
                  fg="#fff"
                  fs="15px"
                  fw="700"
                  onClick={activateUser}
              />
            </div>
          </div>
      )
    } else if (item?.approverID === user?.sub) {
      return <></>
    } else {
      return (
          <div className="mt-20 pd-20">
            <div className="sect_item btns">
              <CustomisedButton
                  width="100%"
                  height="40px"
                  bg="#D41811"
                  bd="1px solid #D41811"
                  text={
                    <div className="flex-start">
                      {item?.approverID === user?.sub
                          ? "Deactivation requested"
                          : "De-activate Account" }
                      <span className="ml-10">
                          <MaskIcon />
                        </span>
                    </div>
                  }
                  fg="#fff"
                  fs="15px"
                  fw="700"
                  onClick={() => setDeactivate(true)}
              />
              <CustomisedButton
                  width="100%"
                  height="40px"
                  bg="white"
                  bd="1px solid #DDDFE2"
                  text="View Request"
                  fg="#515A6A"
                  fs="15px"
                  fw="700"
              />
            </div>
          </div>
      )
    }
  }

  const layouts = {
    "educator": "councelors",
    "resources": "resources",
    "youth": "youth",
  }

  return (
    <>
      <div className={`right_main ${deactivate ? "hide" : "show"}`}>
        <LeftModalTopSection
          item={{ initials_color: bgColor, ...item }}
          show={true}
          layout="account-deactivation"
          func={handleResponse}
          btnText="View account  >"
          btnCallback={() => navigate(`/${layouts[layout]}?id=${item?.accountID}`)}
        />

        <div className="view_account_main">
          <div className="sect flex-between pd-20 mt-20">
            REQUEST DETAILS
            <div>
              <ChevronDown
                onClick={() => collapse(false, "request_details")}
                className={`${collapseRequestDetails ? "show" : "hide"}`}
              />
              <ChevronUp
                onClick={() => collapse(true, "request_details")}
                className={`${collapseRequestDetails ? "hide" : "show"}`}
              />
            </div>
          </div>

          <div
            className={`sect_items ${collapseRequestDetails ? "hide" : "show"}`}
          >
            <div className="sect_details pd-20 mt-20">
              <div className="sect_item">
                <h5>Date requested</h5>
                <h6>{calculateTime(item?.dateRequested) || "N/A"}</h6>
              </div>
              <div className="sect_item">
                <h5>Requested by</h5>
                {/* <h6>{item.requested_by}</h6> */}
                <h6>{requestedBy() || "N/A"}</h6>
              </div>
              <div className="sect_item">
                <h5>Reason for deactivation</h5>
                <h6>{item?.reason || "N/A"}</h6>
              </div>
              <div className="sect_item">
                <h5>Deactivation period</h5>
                <h6>{item?.period || "N/A"}</h6>
              </div>
            </div>

            <div className="mt-20 pd-20">
              <div className="sect_item">
                <h5>Description</h5>
                <h6>{item?.description || "N/A"}</h6>
              </div>
            </div>

            <div className="mt-20 pd-20">
              <div className="sect_item">
                <h5>Attachment (s)</h5>
              </div>
              {/*<div className="sect_item attachments">*/}
              N/A
              {/*  <div className="align_middle">*/}
              {/*    <FileEarmark size={20} />*/}
              {/*  </div>*/}
              {/*  <div className="flex-v pd-20">*/}
              {/*    <div>Document Name.pdf</div>*/}
              {/*    <div>200kb</div>*/}
              {/*  </div>*/}
              {/*  <div className="align_middle">*/}
              {/*    <Download size={20} />*/}
              {/*  </div>*/}
              {/*  <div className="align_middle">...</div>*/}
              {/*</div>*/}
            </div>
            <>{getActionButton()}</>
          </div>

          <div className="sect flex-between pd-20 mt-20">
            ACTIVITY
            <div>
              <ChevronDown
                onClick={() => collapse(false)}
                className={`${collapseActivity ? "show" : "hide"}`}
              />
              <ChevronUp
                onClick={() => collapse(true)}
                className={`${collapseActivity ? "hide" : "show"}`}
              />
            </div>
          </div>

          <div
            className={`pd-20 mt-10 activity-text ${
              collapseActivity ? "hide" : "show"
            }`}
            ref={activityTextRef}
          >
            {getActivityText()}
          </div>
        </div>
      </div>
      <CompleteDeactivation
        deactivate={deactivate}
        setDeactivate={setDeactivate}
        status={setDeactivationSuccess}
        setToast={setShowToast}
        setStatus={update}
        item={item}
        layout={layout}
        id={id}
        refetch={refetch}
      />
      <ToastContainerComponent
        setToastShow={setShowToast}
        toastShow={showToast}
        data={data}
      />
    </>
  );
}

export default RightContent;
