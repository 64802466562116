import React from 'react';
import "../../scss/Admin/dashboardPage.scss"
import Cards from "../../components/Dashboard/cards";
import Infos from "../../components/Dashboard/infos";


function DashBoardPage() {
  return ( 
    <div className="dashboard_main_content">
      <Cards/>
      <Infos/>
    </div>
  );
}

export default DashBoardPage;