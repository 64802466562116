import "../../scss/Admin/creditsPage.scss"
import Cards from "../../components/Credits/Card";
import Infos from "../../components/Credits/Info";
import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from "react-bootstrap";
import { cardList, supplyCardList } from "../../assets/data/credits";
import { AllCreditValues } from "../../services/query/creditValues";
import { API } from "aws-amplify";
import { onUpdateCreditValue } from "../../graphql/subscriptions";
import { AllCreditEvents } from "../../services/query/creditEvents";
import { AllCreditMultipliers } from "../../services/query/creditMultiplier";
import { AllPurchases } from "../../services/query/purchases";
import { AllPayments } from "../../services/query/payments";
import SupplyInfos from "../../components/Credits/SupplyInfo";


function CreditsPage() {

  const [key, setKey] = useState('demand');
  const [value, setValue] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [events, setEvents] = useState([]);
  const [multipliers, setMultipliers] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const [supplyPurchases, setSupplyPurchases] = useState([]);
  const [usagePurchases, setUsagePurchases] = useState([]);

  const handleSetEvents = () => {
    AllCreditEvents().then((events) => {
      const modCredits = [ ...events ];
      modCredits.sort((a, b) => {
        const itemA = new Date(a?.created_at);
        const itemB = new Date(b?.created_at);
        if (itemA < itemB) return -1;
        if (itemA > itemB) return 1;
        return 0;
      });

      const eventsObjArr = modCredits.reduce((acc, event) => {
        const resp = [ ...acc ];
        const currentItem = {};
        currentItem.title = event.condition;
        currentItem.value = event.credit_history;
        currentItem.event_value = event.value;
        currentItem.earnings = event.credit_history.reduce((history, elt) => {
          history += JSON.parse(elt).credit;
          return history;
        }, 0);

        resp.push(currentItem);

        return resp;
      }, []);

      setEvents(eventsObjArr);
    });
    
  }

  const handleSetMultipliers = () => {
    AllCreditMultipliers().then((multipliers) => {
      const modCredits = [ ...multipliers ];
      modCredits.sort((a, b) => {
        const itemA = new Date(a?.createdAt);
        const itemB = new Date(b?.createdAt);
        if (itemA < itemB) return -1;
        if (itemA > itemB) return 1;
        return 0;
      });

      const multipliersObjArr = modCredits.reduce((acc, multiplier) => {
        const resp = [ ...acc ];
        const currentItem = {};
        currentItem.title = multiplier.condition;
        currentItem.value = multiplier.credit_history;
        if (multiplier.value) currentItem.multiplierValue = multiplier.value;
        if (multiplier.duration) currentItem.duration = multiplier.duration
        currentItem.earnings = multiplier.credit_history.reduce((history, elt) => {
          history += JSON.parse(elt).earned_credit;
          return history;
        }, 0);

        resp.push(currentItem);

        return resp;
      }, []); 

      setMultipliers(multipliersObjArr);
      
    });
  }

  const handleSetPurchases = () => {
    AllPurchases().then((purchases) => setPurchases(purchases));
  }

  const getTotalEarnings = () => {
    return multipliers.reduce((acc, elt) => (acc + elt.earnings), 0) + events.reduce((acc, elt) => (acc + elt.earnings), 0)
  }

  const handleTransactionPurchases = async () => {
    try {
      const transactions = await AllPayments()
      const supplyPurchases = transactions.filter((elt) => elt.type === 'credit')
      const usagePurchases = transactions.filter((elt) => elt.type === 'debit')
      setSupplyPurchases(supplyPurchases)
      setUsagePurchases(usagePurchases)

    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    AllCreditValues().then((values) => {
      setValue(values[0]);
    });
  }, [refresh]);

  useEffect(() => {
    handleSetEvents();
    handleSetMultipliers();
    handleSetPurchases();
    handleTransactionPurchases();

    const creditValueSubscription = API.graphql({
      query: onUpdateCreditValue,
    }).subscribe({
      next: async () => setRefresh(prev => !prev),
      error: (error) => console.log(error)
    });

    return () => {
      creditValueSubscription.unsubscribe();
    }
  }, []);

  return ( 
    <div className="tabs_wrapper credit_tabs">
      <Tabs
        id="credit_tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="demand" title="Demand (Y)">
          <div className="demand_main_content">
            <Cards data={cardList(value.value || 0, getTotalEarnings(), purchases.length)}/>
            <Infos events={events} multipliers={multipliers} purchases={purchases} />
          </div>
        </Tab>
        <Tab eventKey="supply" title="Supply (R & C)">
          <div className="supply_main_content">
            <Cards data={supplyCardList(value.value || 0, supplyPurchases, usagePurchases)}/>
            <SupplyInfos purchase={supplyPurchases} usage={usagePurchases} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default CreditsPage;