import React, { useState } from "react";
import FirstStep from "./MessagesTab/FirstStep";
import SecondStep from "./MessagesTab/SecondStep";
import ThirdStep from "./MessagesTab/ThirdStep";

function MessagesTab(props) {
  const { edit, item } = { ...props };
  const [receivers, setReceivers] = useState([]);

  const [currentPage, setCurrentPage] = useState("first");

  const pages = {
    first: (
      <FirstStep
        item={item}
        edit={edit}
        setCurrentPage={setCurrentPage}
        setData={setReceivers}
      />
    ),
    second: (
      <SecondStep
        item={item}
        edit={edit}
        setCurrentPage={setCurrentPage}
        receivers={receivers}
      />
    ),
    third: <ThirdStep setCurrentPage={setCurrentPage} />,
  };

  return <>{pages[currentPage]}</>;
}

export default MessagesTab;
