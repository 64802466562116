import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { Form } from "react-bootstrap";
import { Funnel, Search } from "react-bootstrap-icons";
import { FilterSectionDrop } from "../../widgets/Dropdown/dropdown";
import { ResourcesTopDrop } from "../../widgets/Dropdown/resourcesTopDrop";
import { MentorsTopDrop } from "../../widgets/Dropdown/mentorsTopDrop";
import { AccountDeactivationFilterSectionDrop } from "../../widgets/Dropdown/accountDeactivationDrop";

import { AdministrationFilterDropdown } from "../Widgets/Dropdowns/administrationDropdown";

function TopSection(props) {
  const {
    filterText,
    inputPlaceholder,
    menu,
    purchases,
    locations,
    callback,
    setFiltered,
    content,
    defaultData,
    filterCallback,
  } = { ...props };

  const [state, setState] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const toggleDrop = (clear) => {
    if (clear === "clear") {
      setFilters({});
      setFiltered(content);
    }
    setState(!state);
  };

  const setData = (id, data) => {
    setFilters({ ...filters, [id]: data });

    filterCallback(id, data);
    // toggleDrop();
  };

  const resourcesMenu = () => {
    return (
      <ResourcesTopDrop
        display={state}
        func={toggleDrop}
        setData={setData}
        filters={Object.keys(filters).length}
      />
    );
  };

  const mentorsMenu = () => {
    return (
      <MentorsTopDrop
        display={state}
        func={toggleDrop}
        setData={setData}
        filters={Object.keys(filters).length}
        locations={locations}
      />
    );
  };

  const youthMenu = () => {
    return (
      <FilterSectionDrop
        display={state}
        func={toggleDrop}
        setData={setData}
        filters={Object.keys(filters).length}
        locations={locations}
      />
    );
  };

  const accountDeactivationMenu = () => {
    return (
      <AccountDeactivationFilterSectionDrop
        display={state}
        func={toggleDrop}
        setData={setData}
        filters={Object.keys(filters).length}
      />
    );
  };

  const administrationMenu = () => {
    return (
      <AdministrationFilterDropdown
        display={state}
        func={toggleDrop}
        setData={setData}
        defaultData={defaultData}
        filters={Object.keys(filters).length}
      />
    );
  };

  const returnMenu = (menu) => {
    const menus = {
      youth: youthMenu(),
      resources: resourcesMenu(),
      mentors: mentorsMenu(),
      administration: administrationMenu(),
      "account-deactivation": accountDeactivationMenu(),
    };

    return menus[menu];
  };

  const debouncedSearch = debounce((term) => {
    setFiltered(callback(content, term));
  }, 300);

  useEffect(() => {
    if (searchQuery) {
      debouncedSearch(searchQuery);
    }
  }, [searchQuery]);

  return (
    <>
      <div className="top_section flex-between">
        <div className="left_side">
          <div className="icon_with_text">
            <div
              className={`filter_btn ${
                Object.keys(filters).length > 0 ? "filtered" : ""
              }`}
              onClick={toggleDrop}
            >
              <Funnel size={16} />
              <span>
                Filters&nbsp;
                {Object.keys(filters).length > 0
                  ? `(${Object.keys(filters).length} selected)`
                  : ""}
              </span>
            </div>
          </div>
          {filterText && (
            <div className="total_entries">
              Showing &nbsp;<b>{purchases?.length}</b>&nbsp;purchases of
              experiences
            </div>
          )}
        </div>
        <div className="right_side">
          <div className="icon_wrapper">
            <Search />
          </div>
          <Form>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder={inputPlaceholder}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </Form.Group>
          </Form>
        </div>
      </div>
      {returnMenu(menu)}
    </>
  );
}

export default TopSection;
