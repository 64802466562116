import React, { useState } from "react";
// import { UPDATE_EDUCATOR } from "../../services/mutation/educator"
// import { UPDATE_RESOURCE } from "../../services/mutation/resources"
import ModalWrapper from "../../widgets/Modal/modal";
// import { useMutation } from "@apollo/client"
// import {setPopupDataAction} from "../../redux/actions/popupActions";
// import {useDispatch} from "react-redux";
import AlertToast from "../Common/Alerts/ToastAlert";
import {Tab, Tabs} from "react-bootstrap";
import AccountVerificationTab from "./AccountVerificationTab";
import AccountOverviewTab from "./AccountOverviewTab";
import {createNameCol} from "../../utils/Resources/utils";
// import {useSelector} from "react-redux";
import {X} from "react-bootstrap-icons";

export function ActivationModal(props) {
    // const [state, setState] = useState({});
    // const [loading, setLoading] = useState(false);
    const {
        show, setShow, details, detailsRefetch,
        subLayout, items
    } = { ...props }
    const [key, setKey] = useState("verification");
    const [activeToast, setActiveToast] = useState(false);
    const [activeState, setActiveState] = useState('');
    const [toast, setToast] = useState('');
    // const [updateUser] = useMutation(details?.organizationID ? UPDATE_RESOURCE : UPDATE_EDUCATOR)
    // const deactivationReasonRef = useRef(null);

    // const dispatch = useDispatch();

    // const deactivationReasons = [
    //     "Violation of Code of Conduct",
    //     "User requested to de-activate account",
    //     "Fraud or Scam"
    // ]

    // const handleChecks = (index) => {
    //     const mainDiv = deactivationReasonRef.current.children
    //     for (let i = 1; i < mainDiv.length; i++) {
    //         const outerDiv = mainDiv[i];
    //         const checkWrapper = outerDiv.children[0]
    //         const checkDiv = checkWrapper.children[0];
    //         checkDiv.style.backgroundColor = "#fff";
    //     }
    //     const outerDiv = deactivationReasonRef.current.children[index];
    //     const checkWrapper = outerDiv.children[0]
    //     const checkDiv = checkWrapper.children[0];
    //     if (checkDiv.style.backgroundColor === "rgb(255, 255, 255)") {
    //         checkDiv.style.backgroundColor = "#10408C";
    //         setState({
    //             ...state,
    //             reason: deactivationReasons[1 - index]
    //         })
    //     }
    //     else {
    //         checkDiv.style.backgroundColor = "#fff";
    //     }
    // }

    // const handleToast = (status, message) => {
    //     setActiveState(status);
    //     setToast(message);
    //     setActiveToast(true);
    // };

    // const deactivate = () => {
    //     setLoading(true);
    //     const inputData = {
    //         id: details?.id,
    //         reason: state?.reason,
    //         detailed_reason: state?.detailed_reason,
    //         period: state?.period,
    //         verification_status: "unverified",
    //         verification_progress: 0,
    //         is_verified: false,
    //         date: new Date(),
    //     }
    //     updateUser({
    //         variables: { ...inputData },
    //     }).then(() => {
    //         setLoading(false);
    //         setShow(false);
    //         handleToast("success", "Account deactivated successfully");
    //         refetch();
    //     }).catch((err) => {
    //         setLoading(false);
    //         handleToast("error", "An error occured while deactivating user");
    //         console.error(err)
    //     })
    // }

    // const closeModal = () => {
    //     if (previousModal) {
    //         dispatch(setPopupDataAction({ [previousModal]: true }));
    //     }
    //     dispatch(setPopupDataAction({ deactivateModal: false }));
    //     setShow(false);
    // }


    return (
        <>
            <AlertToast
                variant={activeState ? 'success' : 'error'}
                icon={activeState ? 'delete' : 'check'}
                active={activeToast}
                setActive={setActiveToast}
                info={toast}
            />
            <ModalWrapper
                state={`${show ? "show" : ""}`}
            >
                <div
                    className="account_verification__resource_tab tabs_wrapper"
                    style={{display: show ? "block" : "none"}}
                >
                    <div className="my-4 d-flex justify-content-between align-items-center">
                        <>{createNameCol(
                            details?.school?.name,
                            details?.school?.image
                        )}</>
                        <div
                            className="modal_top_icons d-flex align-items-center justify-content-center"
                            onClick={setShow}
                        >
                            <X
                                fill="#515A6A"
                                size={16}
                                className="cursor-pointer"
                            />
                        </div>
                    </div>
                    <Tabs
                        id="account_verification__resource_tab"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="verification" title="Verification">
                            <AccountVerificationTab
                                layout={subLayout}
                                details={details}
                                detailsRefetch={detailsRefetch}
                                modal={show}
                                setModal={setShow}
                            />
                        </Tab>
                        <Tab eventKey="account-overview" title="Account Overview">
                            <AccountOverviewTab
                                details={details}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </ModalWrapper>

        </>
    );
}

export default ActivationModal;