import ModalWrapper from "../../../widgets/Modal/modal";
import { X } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import CustomisedButton from "../../../widgets/Button/Button";
import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import browser from "browser-detect";
import {
  generateDeviceID,
  isRequiredFieldsPassed,
  sendAppNotification,
} from "../../../utils/helpers";
import { axiosClient } from "../../../libs/axiosClient";
import AdminSavedStatus from "./AdministratorSaved";
import { toast } from "react-toastify";
import { setRefreshDataAction } from "../../../redux/actions/refreshActions";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../templates/DashBoardTemplate";

function AddAdministrators(props) {
  const dispatch = useDispatch();

  const { show, setShow } = { ...props };

  const [state, setState] = useState({});
  const [roles, setRoles] = useState([]);
  const [modules, setModules] = useState([]);
  const [showPerms, setShowPerms] = useState(false);
  // const [switches, setSwitches] = useState({})
  const [loading, setLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [proceed, setProceed] = useState(false);
  const { user } = useAuth();

  const userBrowser = browser();

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  // const handleSwitch = (checked, event, id) => {
  //   setState({
  //     ...state,
  //     [id]: checked
  //   })

  //   setSwitches({
  //     ...state,
  //     [id]: checked
  //   })
  // }

  const proceedToSave = async () => {
    const re = /^[\\S]+.*[\\S]+$/;
    if (re.test(state.password)) {
      toast.error("Password format incorrect");
      return;
    }
    setLoading(true);
    state["custom:position"] = roles.find(
      (elt) => elt.id === state["custom:role"],
    )?.name;
    try {
      const result = await axiosClient.post("/admin-users", {
        ...state,
        deviceID: generateDeviceID(),
        userBrowser,
      });
     if (result?.data?.success){
      await sendAppNotification(
        user?.sub,
        'A new admin account has been created.',
        'success',
        '/administrators',
        'Click here to view',
        'admin',
        'inapp',
        'checkmarkSuccess'
      )
     }
      setLoading(false);
      dispatch(setRefreshDataAction({ adminsRefetch: true }));
      setShow(false);
      setState({});
      setProceed(true);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const isSwitchChecked = (id, type) => {
    if (state["custom:role"]) {
      const role = roles?.find((elt) => elt.id === state["custom:role"]);
      return role[type].includes(id);
    }

    return false;
  };

  const getAllRoles = async () => {
    try {
      const resp = await axiosClient.get("/permission-role");
      const data = resp.data.data;
      setRoles(data.roles);
      setModules(data.modules);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setShowPerms(state["custom:role"]);
    setBtnDisabled(!isRequiredFieldsPassed(state, 7, "eq"));
  }, [state]);

  useEffect(() => {
    getAllRoles();
  }, []);

  return (
    <>
      <ModalWrapper state={`${show ? "show" : "hide"}`}>
        <div className="add_experience add_administrators">
          <div className="mbd">
            <div className="flex-between top_sect">
              <div className="align-items bold">Add New Administrator</div>
              <div className="right_side" onClick={() => setShow(false)}>
                <X fill="#515A6A" size={24} />
              </div>
            </div>
          </div>
          <div className="modal_content pm-20">
            <div className="flex-between">
              <div className="input">
                <h6>First Name</h6>
                <Form.Control
                  type="text"
                  name="fname"
                  placeholder="First name"
                  onChange={handleChange}
                />
              </div>
              <div className="input">
                <h6>Last Name</h6>
                <Form.Control
                  type="text"
                  name="lname"
                  placeholder="Last name"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="mt-20">
              <h6>Username</h6>
              <Form.Control
                type="text"
                name="username"
                placeholder="Username"
                onChange={handleChange}
              />
            </div>
            <div className="mt-20">
              <h6>Email</h6>
              <Form.Control
                type="email"
                name="email"
                placeholder="Email"
                onChange={handleChange}
              />
            </div>
            <div className="mt-20">
              <h6>Phone</h6>
              <Form.Control
                type="text"
                name="phone_number"
                placeholder="+1 346-352 9865"
                onChange={handleChange}
              />
            </div>
            <div className="mt-20">
              <h6>Password</h6>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
              />
            </div>
            <div className="mt-20">
              <h6>Role</h6>
              <Form.Select name="custom:role" onChange={handleChange}>
                <option value="">Select a role</option>
                {roles.map((elt) => (
                  <option value={elt.id} key={elt.id}>
                    {elt.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            {showPerms && (
              <div className="mt-20">
                <h6 className="bold">This role can access;</h6>
                <div className="perms_wrapper mt-20">
                  <div className="flex-between mbd pv-10">
                    <div style={{ width: "70%" }}>Section</div>
                    <div className="flex-between" style={{ width: "30%" }}>
                      <div>Read only</div>
                      <div>Edit</div>
                    </div>
                  </div>
                  {modules?.map((item, index) => (
                    <div className="flex-between mbd pv-10" key={index}>
                      <div style={{ width: "70%" }}>{item.name}</div>
                      <div className="flex-between" style={{ width: "30%" }}>
                        <div>
                          <Switch
                            onChange={() => {}}
                            checked={isSwitchChecked(item.id, "read")}
                            onColor="#2D9371"
                            offColor="#B9BDC3"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            className="mr-10 no-pointer-events"
                            id={item.id}
                          />
                        </div>
                        <div>
                          <Switch
                            onChange={() => {}}
                            checked={isSwitchChecked(item.id, "edit")}
                            onColor="#2D9371"
                            offColor="#B9BDC3"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            className="mr-10 no-pointer-events"
                            id={item.id}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="mt-20">
              <div className="flex-between">
                <CustomisedButton
                  text="Cancel"
                  db={true}
                  bg="#F4F5F7"
                  fg="#B9BDC3"
                  pd="10px"
                  rd="10px"
                  width="49%"
                  className="mr-10"
                  onClick={() => setShow(false)}
                />
                <CustomisedButton
                  text={loading ? "Saving...." : "Save"}
                  db={loading || btnDisabled}
                  bg={loading || btnDisabled ? "#F4F5F7" : "#10408C"}
                  fg={loading || btnDisabled ? "#B9BDC3" : "#fff"}
                  pd="10px"
                  rd="10px"
                  width="49%"
                  onClick={proceedToSave}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalWrapper>
      <AdminSavedStatus
        show={proceed}
        setShow={setProceed}
        name={state?.fname + " " + state?.lname}
      />
    </>
  );
}

export default AddAdministrators;
