import { DAYS, MONTHS_FULL } from "./data";
import { store } from "../redux/store";
import { setAdminTokenDataAction } from "../redux/actions/adminActions";
import { axiosClient } from "../libs/axiosClient";
import { API } from "aws-amplify";
import { createAppNotification } from "../graphql/mutations";
import {City, State} from "country-state-city";

export const isRequiredFieldsPassed = (obj, fields, type) => {
  if (type === "eq") {
    return (
      Object.keys(obj).length === fields &&
      Object.values(obj).every((elt) => elt !== "")
    );
  }

  return false;
};

export const isRequiredFieldValuesPassed = (obj, fields, type) => {
  if (type === "eq") {
    Object.values(obj).every((elt) => elt !== "");
    return (
      fields.every((elt) => Object.keys(obj).includes(elt)) &&
      Object.values(obj).every((elt) => elt !== "")
    );
  }

  return false;
};

export const calculateAge = (date) => {
  date = new Date(date);
  const currentDate = new Date();
  try {
    return currentDate.getFullYear() - date.getFullYear();
  } catch (err) {
    return -1;
  }
};

export const getInitial = (name) => {
  let initials = "";
  if (name) {
    const splittedName = name.split(" ");
    splittedName.forEach((elt) => {
      initials += elt[0]?.toUpperCase();
    });
  }
  return initials;
};

export const calculateTime = (date) => {
  if (date) {
    const now = new Date();
    const previous = new Date(date);
    if (now.getFullYear() === previous.getFullYear()) {
      if (now.getMonth() === previous.getMonth()) {
        const res = now.getDate() - previous.getDate();
        if (res === 0) {
          return "Today";
        }
        return `${res} ${res === 1 ? "day" : "days"} ago`;
      } else {
        const res = now.getMonth() - previous.getMonth();
        return `${res} ${res === 1 ? "month" : "months"} ago`;
      }
    } else {
      const res = now.getFullYear() - previous.getFullYear();
      return `${res} ${res === 1 ? "year" : "years"} ago`;
    }
  } else {
    return "";
  }
};

export const formatDateWithBar = (date, spaced) => {
  if (date) {
    const dateNow = new Date(date)?.toString()?.split(" ");
    const monthDay = dateNow[1] + " " + dateNow[2];
    let time = dateNow[4].split(":");
    let hour = time[0];

    const ampm = parseInt(hour) >= 12 ? "pm" : "am";
    hour %= 12;
    hour = hour || 12;

    return `${monthDay}, ${dateNow[3]} ${spaced ? " " : "|"} ${hour}:${
      time[1]
    } ${ampm}`;
  }

  return "";
};

export const calculateTimeWithPmOrAm = (time) => {
  let hour = time?.split(":")[0];

  const ampm = parseInt(hour) >= 12 ? "pm" : "am";
  hour %= 12;
  hour = hour || 12;

  return `${hour}:${time?.split(":")[1]} ${ampm}`;
};

export const formatDatePurchaseStyle = (date) => {
  if (date) {
    const newDate = new Date(date);
    const monthStr = `${newDate}`.split(" ")[1];
    return monthStr + " " + newDate.getDate() + ", " + newDate.getFullYear();
  }

  return "";
};

export function WeekData(dataToExtractFrom) {
  const isInWeek = (date) => {
    const currentDate = new Date();
    const oldDate = new Date(date);
    const difference_in_team = currentDate.getTime() - oldDate.getTime();
    const difference_in_days = difference_in_team / (1000 * 3600 * 24);
    return Math.ceil(difference_in_days);
  };
  return dataToExtractFrom?.filter((elt) => isInWeek(elt?.createdAt) <= 7);
}

export const getDataByDate = (data, startDate = false, endDate = false) => {
  if (!startDate && !endDate) return data;

  return data?.filter((item) => {
    const itemDate = new Date(item?.createdAt);

    if (startDate && !endDate) return startDate.getTime() <= itemDate.getTime();

    return (
      itemDate.getTime() >= startDate.getTime() &&
      itemDate.getTime() <= endDate.getTime()
    );
  });
};

export const capitalizeFirstLetter = (title) => {
  return title[0]?.toUpperCase() + title.slice(1)?.toLowerCase();
};

export const titleWords = (sentence) => {
  if (sentence) {
    const words = sentence.split(" ");
    const newWords = [];
    words?.forEach((word) => {
      newWords.push(capitalizeFirstLetter(word));
    });
    return newWords.join(" ");
  }

  return "";
};

export const removeCommas = (str = "") => {
  if (str) {
    if (str.startsWith(",") && str.endsWith(",")) {
      return str.slice(1, -1);
    }

    if (str.startsWith(",")) {
      return str.slice(1);
    }

    if (str.endsWith(",")) {
      return str.slice(0, -1);
    }
  }

  return str;
};

export const matchDomain = (schoolMail, email) => {
  const schoolAtSignIndex = schoolMail.indexOf("@") + 1;
  const schoolDomain = String(schoolMail).slice(schoolAtSignIndex);

  const mailAtSignIndex = email.indexOf("@") + 1;
  const emailDomain = String(email).slice(mailAtSignIndex);

  return schoolDomain === emailDomain;
};

export const filterOrgsByState = (orgs, state) => {
  return orgs?.filter((org) =>
    getOrgState(org?.full_address) === state
  );
}

export const getOrgState = (address) => {
  if (address) {
    const addressObj = JSON.parse(address || "{}");
    const state = addressObj.address_components.find(
        (elt) => elt.types[0] === "administrative_area_level_1",
    );
    if (state?.short_name) {
      return state?.short_name;
    }
  }

  return "";
}

export const getSchoolState = (address) => {
  if (address) {
    const addressObj = JSON.parse(address || "{}");
    const locality = addressObj.address_components.find(
      (elt) => elt.types[0] === "locality",
    );
    const state = addressObj.address_components.find(
      (elt) => elt.types[0] === "administrative_area_level_1",
    );
    let fullAddress = "";
    if (locality?.long_name) {
      fullAddress += locality?.long_name + ", ";
    }

    if (state?.long_name) {
      fullAddress += state?.long_name;
    }

    return fullAddress;
  }

  return "";
};

export const cleanDataForDownload = (dataArr) => {
  const dataObj = {};

  dataArr.forEach((elt) => {
    dataObj[elt.createdAt] = {
      createdAt: elt.createdAt,
      createdBy: elt.createdBy,
      data: JSON.parse(elt?.data[0]),
    };
  });

  return JSON.stringify(dataObj);
};

// Function to convert a stringified JSON object to an actual object
const parseJSON = (str) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    console.error(`Failed to parse JSON: ${str}`, error);
    return null;
  }
};

// Function to convert an object to CSV row
const convertToCSVRow = (obj) => {
  const keys = Object.keys(obj);
  return keys.map((key) => obj[key]).join(",");
};

// Function to generate CSV content
const generateCSVContent = (data, type) => {
  const multiplierHeader =
    "createdAt,createdBy,condition,multiplier,value,duration,id";
  const eventHeader = "createdAt,createdBy,condition,value,duration,id";

  const header = type === "Events" ? eventHeader : multiplierHeader;
  let csvData = "";

  data.forEach((item) => {
    const { createdAt, createdBy, data, id } = item;
    const parsedData = parseJSON(data);
    if (!parsedData) {
      return;
    }
    const csvRows = Object.keys(parsedData).map((key) => {
      const subItem = parsedData[key];
      return convertToCSVRow({
        createdAt,
        createdBy,
        ...subItem,
        id,
      });
    });
    csvData += `${csvRows.join("\n")}\n`;
  });

  return `${header}\n${csvData}`;
};

export const exportMultipliersData = (data, type) => {
  const csvData = generateCSVContent(data, type);
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  const currentDate = new Date().toISOString().slice(0, 10);
  link.download = `Credit-${type}-Settings-${currentDate}.csv`;
  link.href = url;
  link.click();
};

export const matchDateRange = (date, days) => {
  const minDate = new Date();
  // Subtract duration days of event
  minDate.setDate(minDate.getDate() - days);
  // Present date
  const maxDate = new Date();
  // compare dates
  return minDate < date < maxDate;
};

export const sameYearData = (obj) => {
  const dateNow = new Date();
  const objDate = new Date(obj.createdAt);
  return dateNow.getFullYear() === objDate.getFullYear();
};

export const formatDateForSessions = (date) => {
  let dateObj = new Date(date);
  if (dateObj) {
    return `${DAYS[dateObj.getDay()]},  
      ${dateObj.getDate()}
      ${MONTHS_FULL[dateObj.getMonth()]},
      ${dateObj.getFullYear()}`;
  }

  return "";
};

export const chartMonthLabels = (objKeys) => {
  let monthsIndex = [...Array(12).keys()];
  return monthsIndex.map((month) => {
    return objKeys.includes(String(month)) ? month : "";
  });
};

export const cleanChartData = (obj) => {
  let monthsIndex = [...Array(12).keys()];
  return monthsIndex.reduce((acc, val) => {
    const resp = { ...acc };
    if (Object.keys(obj).includes(String(val))) resp[val] = obj[val];
    else resp[val] = 0;

    return resp;
  }, {});
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const sortDataByField = (data, field) => {
  data.sort(function (a, b) {
    if (a[field] < b[field]) {
      return 1;
    }
    if (a[field] > b[field]) {
      return -1;
    }
    return 0;
  });

  return data;
};

export const sortDataByFieldCount = (data, field) => {
  data.sort(function (a, b) {
    if (a[field].length < b[field].length) {
      return -1;
    }
    if (a[field].length > b[field].length) {
      return 1;
    }
    return 0;
  });

  return data;
};

export const getUserData = () => {
  return store.getState().adminUser.data.tokens;
};

export const setUserTokenData = (token) => {
  store.dispatch(setAdminTokenDataAction(token));
};

export function filterDataByAges(data, filterAges) {
  const currentDate = new Date();
  return data.filter((item) => {
    if (item.dob) {
      const birthDate = new Date(item.dob);
      const age = currentDate.getFullYear() - birthDate.getFullYear();

      return filterAges.includes(age.toString());
    }
    return false;
  });
}

export function filterDataByLocations(data, locations) {
  return data.filter((item) => locations.includes(item.state));
}

export function filterMentorsByLocations(data, locations) {
  return data.filter((item) =>
    locations.some((item1) => item.locations.includes(item1)),
  );
}

export function filterDataBySchoolsCount(data, locationsCount) {
  return data.filter((item) => {
    const schoolsCount = item.schools.length;

    if (locationsCount.includes("1")) {
      return schoolsCount === 1;
    } else if (locationsCount.includes("2-4")) {
      return schoolsCount >= 2 && schoolsCount <= 5;
    } else if (locationsCount.includes("5-10")) {
      return schoolsCount >= 5;
    }
  });
}

export const getDifferenceInHours = (targetDateTime) => {
  const now = new Date();
  const targetDate = new Date(targetDateTime);
  const differenceInMilliseconds = now - targetDate;
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
  return Math.round(differenceInHours);
};

export function filterOpportunitiesByApplications(data, count) {
  return data.filter((item) => {
    const apps = item.apps.length;

    let comparison = false;

    if (count.includes("1-49")) {
      if (apps >= 1 && apps <= 49) comparison = true;
    }

    if (count.includes("50-99")) {
      if (apps >= 50 && apps <= 99) comparison = true;
    }

    if (count.includes("100-149")) {
      if (apps >= 100 && apps <= 149) comparison = true;
    }

    return comparison;
  });
}

export const filterOpportunitiesByPublishedHours = (data, hours) => {
  return data.filter((item) => {
    let comparison = false;
    const diff = getDifferenceInHours(item.createdAt);

    hours.forEach((hour) => {
      if (diff <= Number.parseInt(hour)) comparison = true;
    });

    return comparison;
  });
};

export function filterDataByField(data, field, value) {
  if (!value || !value?.length) return data;
  return data.filter((item) => item[field] === value);
}

export function filterDataByVerificationStatus(data, verificationStatus) {
  return data.filter((item) => item.verification_status === verificationStatus);
}

export function FilterFieldUnique(data, field) {
  const rows = data.map((item) => item[field]).filter((item) => item !== null);
  return [...new Set(rows)];
}

export function FilterMentorFieldUnique(data, field) {
  const rows = data.map((item) => item[field]).filter((item) => item && true);
  return [...new Set([].concat(...rows))];
}

export const searchData = (data, searchTerm) => {
  return data.filter((item) => {
    for (const key in item) {
      if (
        item[key] &&
        item[key].toString().toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  });
};

export const generateDeviceID = () => {
  const { userAgent, hardwareConcurrency, language } = navigator;
  const { width, height } = window.screen;
  return `${userAgent}-${hardwareConcurrency}-${language}-${width}-${height}`;
};

export function formatDateRelativeToToday(dateTime) {
  const inputDate = new Date(dateTime);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const timeDiff = today.getTime() - inputDate.getTime();
  const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (dayDiff === 0) {
    return "Today";
  } else if (dayDiff === 1) {
    return "Yesterday";
  } else {
    return `${dayDiff} days ago`;
  }
}

export function substractDaysFromDate(numberOfDays) {
  const currentDate = new Date();
  const futureDate = new Date(currentDate);
  futureDate.setDate(currentDate.getDate() - numberOfDays);
  return futureDate;
}

export const timePassed = (fromDate)=>{
  const givenDate = new Date(fromDate);
  const timeDiff = Date.now() - givenDate.getTime();

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30.44);

  if (months > 0) {
    return months + 'mon';
  } else if (weeks > 0) {
    return weeks + 'w';
  } else if (days > 0) {
    return days + 'd';
  } else if (hours > 0) {
    return hours + 'h';
  } else {
    return minutes + 'm';
  }
}

export const daysToExpiration = (dateString)=>{
  const expirationDate = new Date(dateString);
  const currentDate = new Date();

  const timeDifference = expirationDate.getTime() - currentDate.getTime();
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference;
}

export const daysPassed = (date)=>{
  const now = new Date(); 
  const givenDate = new Date(date);

  const oneDayInMs = 1000 * 60 * 60 * 24;

  const timeDifference = now.getTime() - givenDate.getTime();

  const daysPassed = Math.round(timeDifference/oneDayInMs);

  return daysPassed;
}


export const hoursPassedSince = (date)=>{
  var currentDate = new Date();
  var timeDifference = currentDate - date;
  var hoursPassed = timeDifference / (1000 * 60 * 60);

  return hoursPassed;
}

export const sendEmailNotification = async(params) => {
  try {
    await axiosClient.post('/email', params)
  } catch (error) {
    console.log(error)
  }
}

export const sendAppNotification = async(userId, text, title='success',  link='', action='', module='admin', type='inapp', icon='checkmarkSuccess', read=false)=>{
  return await API.graphql({
    query: createAppNotification,
    variables: {
      input: {
        userId,
        title,
        text,
        link,
        action,
        module,
        type,
        icon,
        read,
        createdAt: new Date(),
        updatedAt: new Date()
      }
    }
  })
}

const sortDataByType = (opps, type, order) => {
  const dataToSort = [...opps];
  dataToSort.sort((a, b) => {
    const itemA = a[type];
    const itemB = b[type];
    if (itemA < itemB) {
      return order === "reverse" ? 1 : -1;
    }
    if (itemA > itemB) {
      return order === "reverse" ? -1 : 1;
    }
    return 0;
  });
  return dataToSort;
};

export const getCities = (filters) => {
  const { keys, requiresAll, returnObj, sort } = filters;
  const cities = process.env.REACT_APP_CITIES_LIST;
  let modifiedStates = cities.split(",")?.reduce((acc, city) => {
    if (returnObj) {
      const usStates = City.getCitiesOfState('US', city)?.map((elt) => {
        return { [keys[0]]: `${elt.name}, ${city}`, [keys[1]]: `${elt.name}, ${city}` };
      });
      return [...acc, ...usStates];
    } else {
      return [...acc, ...City.getCitiesOfState('US', city)]
    }
  }, []);

  if (sort) {
    modifiedStates = sortDataByType(modifiedStates, keys[0], "");
  }

  if (requiresAll) {
    modifiedStates = [{ [keys[0]]: 'All', [keys[1]]: 'All' }, ...modifiedStates];
  }

  return modifiedStates;
}

export const getCityNames = () => {
  const states = State.getStatesOfCountry("US");
  const cityNames = process.env.REACT_APP_CITIES_LIST?.split(",");
  return states?.filter((elt) => cityNames.includes(elt?.isoCode) === true);
}

export const filterYouthByState = (data, currentState) => {
  return data?.filter((elt) => {
    const location = elt?.state?.split(",");
    if (location?.length > 1) {
      const state = location[1]?.trim();
      return state === currentState;
    }
    return false;
  });
}