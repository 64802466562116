

class Validation {
    validatePassword = (password) => {
      return password?.length >=  8;
    }

    validateUsername = (username) => {
      const result = username?.trim().length > 0 ? true : false
      return result;
    }

    activateAuthButton = (field1, field2, type) => {
      if (type === "signup"){
        return this.validatePassword(field1) & (field1 === field2)
      }
      return this.validatePassword(field2) & this.validateUsername(field1)
    }
}

export default new Validation();