import React, {useState, useRef, useEffect} from "react";
import { 
  Clock, Pencil, ChevronDown, 
  ChevronUp 
} from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";
import { accountOverviewTabList } from "../../assets/data/accountVerificationTableList";
import {getSchoolState} from "../../utils/helpers";
import {axiosClient} from "../../libs/axiosClient";
import {useDispatch} from "react-redux";
import {setPopupDataAction} from "../../redux/actions/popupActions";
import {useNavigate} from "react-router-dom";


function AccountOverviewTab(props) {
  const { details, layout } = {...props};
  const navigate = useNavigate();
  const contentRef = useRef(null);
  const requestDeactivationBtn =
      details?.is_verified
          ? "Request De-activation"
          : "Requested De-activation"

  const [team, setTeam] = useState([]);

  const dispatch = useDispatch();

  const collapse = (state, index, icon) => {
    if (state) {
      const getDiv = contentRef.current.children[index].children[1];
      getDiv.style.display = "none"
    }

    else {
      const getDiv = contentRef.current.children[index].children[1];
      getDiv.style.display = "block"
    }

    if (icon === "up"){
      const getIcon = contentRef.current.children[index].children[0].children[1].children[1]
      getIcon.style.display = "none"
      const getSIcon = contentRef.current.children[index].children[0].children[1].children[0]
      getSIcon.style.display = "block"
    }

    else if (icon === "down"){
      const getFIcon = contentRef.current.children[index].children[0].children[1].children[0]
      getFIcon.style.display = "none"
      const getSIcon = contentRef.current.children[index].children[0].children[1].children[1]
      getSIcon.style.display = "block"
    }

  }

  const getOrganizationTeam = async () => {
    try {
      const resp = await axiosClient.post('/get-team', {
        schoolID: details?.school?.id,
        type: details?.schoolID ? "mentors" : "resources"
      });
      setTeam(resp.data?.data);
    } catch (e) {
      console.error(e);
    }
  }

  const handleModalChange = () => {
    dispatch(setPopupDataAction({
      overviewDeactivation: true,
    }));

    // setShowModal(true);
    dispatch(setPopupDataAction({
      leftModal: false,
    }));
  }

  const layouts = {
    "councelors": "councelors",
    "resources": "resources",
  }

  useEffect(() => {
    if (details?.school?.id) {
      getOrganizationTeam();
    }
  }, [details]);

  return ( 
    <>
      <div className="account_overview_tab">
        <div className="top_section flex-v mt-20">
          <div>
            <img 
              src={details?.school?.banner}
              alt=""
              className="tab_banner"
            />
          </div>
          <div className="tab_account_logo_sect flex-center">
            <div className="logo_wrapper flex-center">
              <img 
                src={details?.school?.image}
                alt=""
                className="tab_account_logo"
              />
            </div>
          </div>
          <div className="flex-center mt-10">
            <div className="flex-v">
              <div className="d-flex justify-content-center align-items-center">
                <p className="mr-10" style={{ fontSize: 16, fontWeight: 700, marginBottom: 0 }}>
                  {details?.school?.name}
                </p>
                <div className="clock_icon">
                  <Clock size={16}/>
                </div>
              </div>
              <p className="text-center" style={{ fontSize: 13, fontWeight: 700 }}>
                {getSchoolState(details?.school?.full_address)}
              </p>
            </div>
          </div>
          <div className="bottom_btns mt-10 flex-center">
            <CustomisedButton
              text={
                <>
                  <span className="mr-10">Edit Account Info</span>
                  <Pencil/>
                </>
              }
              fg="#2E394C"
              bg="#F4F5F7"
              fw="600"
              bd="none"
              pd="10px 10px"
              className="mr-10"
              onClick={() => navigate(`/${layouts[layout]}?id=${details?.id}`)}
            />
            <CustomisedButton
              text={
                <>
                  <span className="mr-10">{requestDeactivationBtn}</span>
                  <img 
                    src={
                      require("../../assets/images/mask.png")
                    }
                    alt=""
                    className="mask_icon_account_overview_tab"
                  />
                </>
              }
              fg="#AA130E"
              fw="600"
              bg="rgba(251, 213, 213, 0.3)"
              bd="none"
              pd="10px 10px"
              db={!details?.is_verified}
              onClick={handleModalChange}
            />
          </div>
        </div>
        <div className="main_content overflow">
          <div 
            className='mb-5'
          >
              <div 
                className="sect_wrapper mt-20"
                ref={contentRef}
              >
                {accountOverviewTabList(details, team).map((item, index) =>
                  <div key={index}>
                    <div className='sect d-flex justify-content-between'>
                      <span className="mt-20">{item.title}</span>
                      <div className="mt-20">
                        <ChevronDown 
                          style={{display: "none"}}
                          onClick={() => collapse(false, index, "down")}
                        />
                        <ChevronUp 
                          onClick={() => collapse(true, index, "up")}
                        />
                      </div>
                    </div>
                    <div className="main_section">
                      {item?.notify 
                        ?
                        <div>
                          <div className="empty_space"></div>
                          <div style={{width:"97%"}}>{item?.notify}</div>
                          <div className="empty_space"></div>
                          <div>{item?.top_section}</div>
                        </div>
                        : ""
                      }
                      <div className='sect_main mbd pv-10'>
                        {item.innerItem.map((itemInner, index) => 
                          <div 
                            className='sect_details mt-20'
                            key={index}
                          >
                            <div className='sect_details_title'>{itemInner.key}</div>
                            <div className='sect_details_sub_title'>{itemInner.val}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
          </div>
        </div>
      </div>
    </> 
  );
}

export default AccountOverviewTab;