import React from 'react'
import "./style.scss"

function CustomisedButton(props) {
  const {
    bg, fg, fs, bd, fw, mg, text, 
    width, height, rd, pd, onClick,
    className, db, op
  } = {...props}

  return ( 
      <button 
        className={`cbtn ${className}`}
        onClick={onClick}
        disabled={db}
        style={{
          backgroundColor: bg,
          textAlign: "center",
          color: fg,
          width: width,
          opacity: op,
          height: height,
          border: bd,
          margin: mg,
          fontWeight: fw,
          borderRadius: rd,
          padding: pd,
          fontSize: fs,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
          {text}
      </button>
  );
}

export const PublicProfileBtn = ({ href })=>{
  return (
    <a 
      href={href}
      target='_blank'
      rel='noreferrer'
      className='cbtn fw-bold d-flex justify-content-center align-items-center'
      style={{
        width: '48%',
        height: 40,
        background: 'white',
        border: '1px solid #DDDFE2',
        margin: '20px 0',
        color: '#515A6A'
      }}
    >
      View public profile
    </a>
  )
}

export default CustomisedButton;