import React, { useState } from "react";
// import { ReactComponent as Empty } from "../../assets/icons/Group.svg";
import LeftModal from "../Youth/LeftModal/LeftModal";

function ArchiveTab({ experiences }) {
  const experiencesArchived = experiences?.filter(
    elt => elt?.is_archived === true
  )
  // const emptyItem = (
  //   <div className="empty_grid_item flex-center">
  //     <div className="flex-v flex-center">
  //       <Empty/>
  //       <h6 className="descriptive_text">Add New Experience</h6>
  //     </div>
  //   </div>
  // )

  const [currentItem, setCurrentItem] = useState({})
  const [showModal, setShowModal] = useState(false)

  const handleSelect = (item) => {
    setCurrentItem(item)
    setShowModal(true)
  }

  const itemSlots = (session) => {
    return session.sessions.reduce((acc, item) => acc + item.slots, 0);
  }

  return ( 
    <>
      <div className="active_tab">
        <div className="top_section">
          Showing {experiencesArchived?.length} archived experiences
        </div>
        <div className="grid_wrapper">
          {experiencesArchived?.map((item, index) => 
            <div 
              className="item_grid"
              key={index}
              onClick={() => handleSelect(item)}
            >
              <div className="">
                <div className="grid_img grayed">
                  <img src={require("../../assets/images/exp_1.png")}/>
                </div>
              </div>
              <div className="">
                <div className="flex-v">
                  <span
                    style={{color: "#2E394C"}}
                  >
                    {item?.title}
                  </span>
                  <span 
                    className="subtitle"
                  >
                    0 of {itemSlots(item)} slots purchased
                  </span>
                </div>
                <div>
                  <span 
                    style={{
                      fontSize:"15px",
                      color: "#AA8545",
                      fontWeight: "600"
                    }}
                  >
                    {`${item?.price_per_person} JC`}
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <LeftModal
        item={currentItem} 
        show={showModal}
        setShowModal={setShowModal}
        layout={"experiences-archive"}
      />
    </>
  );
}

export default ArchiveTab;