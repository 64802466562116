/* eslint-disable no-unused-expressions */
import { gql, useQuery } from "@apollo/client";
import { API } from 'aws-amplify';
import { GetResource } from "./resources";
import { GetStudentOpportunityApplicationByOpportunityID } from "./application";

export const GET_OPPORTUNITIES = gql`
  query OpportunitiesQuery {
    listOpportunities (limit: 1000) {
      items {
        id
        name
        description
        tag
        expiry_date
        questions
        createdAt
        remote
        duration
        facilitatorIDs
        facilitators
        resourceID
        state
        liked_by
        likes
        views
        category
        interests
      }
    }
  }
`;

export const GET_OPPORTUNITIES_GRAPHQL = gql`
  query OpportunitiesQuery {
    listOpportunities (limit: 1000) {
      items {
        id
        name
        description
        tag
        expiry_date
        questions
        createdAt
        remote
        duration
        resourceID
        facilitatorIDs
        facilitators
        state
        liked_by
        likes
        views
        category
        interests
      }

      nextToken
    }
}
`

export const GET_OPPORTUNITIES_WITH_TOKEN = gql`
  query OpportunitiesQuery ($limit: Int, $nextToken: String) {
    listOpportunities (limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        tag
        expiry_date
        questions
        createdAt
        duration
        resourceID
        facilitatorIDs
        facilitators
        state
        views
        remote
        liked_by
        likes
        category
        interests
      }
      nextToken
    }
}
`

export async function AllOpportunitiesAPI() {
  const resp = await API.graphql({ 
    query: GET_OPPORTUNITIES_GRAPHQL,
    cache: true
  });

  const data = resp?.data?.listOpportunities
  let nextToken = data?.nextToken
  let opportunities = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_OPPORTUNITIES_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listOpportunities

    const previousData = [...opportunities, ...respData.items]
    opportunities = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return await opportunities?.reduce(async (previousPromise, opportunity) => {
    const resource = await GetResource(opportunity.resourceID);
    const apps = await GetStudentOpportunityApplicationByOpportunityID(opportunity.id);
    let opportunitiesData = await previousPromise;
    const obj = { ...opportunity, resource, apps };
    opportunitiesData.push(obj);
    return opportunitiesData;
  }, Promise.resolve([]));
}

export async function GetOpportunitiesByLikedBy (id, userID) {
  const opps = await AllOpportunitiesAPI();
  const opp = opps.find(opp => (opp.id === id) & (opp.liked_by.includes(userID)));
  return opp || {};
}

export const GET_OPPORTUNITY = gql`
  query SingleOpportunity($id: ID!) {
    getOpportunity(id: $id) {
      id
      name
      description
      tag
      expiry_date
      facilitatorIDs
      facilitators
      createdAt
      questions
      duration
      resourceID
      automatic_message
      state
      liked_by
      remote
      likes
      views
      category
      interests
    }
  }
`;

export async function GetOpportunityByResourceID (id) {
  const opportunities = await AllOpportunitiesAPI();
  const activeFilteredOpps = opportunities?.filter(
    (elt) => elt?.resource?.organizationID === id && (new Date(elt?.expiry_date) > new Date())
  );
  const closedFilteredOpps = opportunities?.filter(
    (elt) => elt?.resource?.organizationID === id && (new Date() > new Date(elt?.expiry_date))
  );

  return {
    active: activeFilteredOpps,
    closed: closedFilteredOpps,
  }
}

export function GetOpportunity(id) {
  const resp = useQuery(GET_OPPORTUNITY, {
    variables: {
      id: id,
    },
  });

  if (resp?.data) {
    return resp?.data?.getOpportunity?.items;
  }

  return "";
}

export async function GetSingleOpportunity(id) {
  try {
    const respOpportunity = await API.graphql({
      query: GET_OPPORTUNITY,
      variables: { id },
    });
    const opportunity = respOpportunity?.data?.getOpportunity;
    const resource = await GetResource(opportunity.resourceID);
    const apps = await GetStudentOpportunityApplicationByOpportunityID(opportunity.id);
    const opportunityWithResource = { ...opportunity, resource, apps };
    return opportunityWithResource;
  } catch (err) {
    console.error(err);
    return {};
  }
}


