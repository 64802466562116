import React, { useState, useEffect, useRef } from 'react';
import { AllCreditValues } from "../../../../services/query/creditValues";
import { ArrowRight, ChevronDown, ChevronUp } from 'react-bootstrap-icons';
import  { Form } from "react-bootstrap";
import { formatDateWithBar } from '../../../../utils/helpers';
import { useSelector } from 'react-redux';

function EditValue({ active, setData, refetch }) {

  const user = useSelector((store) => store.adminUser.data);
  const userName = user?.userName;

  const [state, setState] = useState({});
  const [value, setValue] = useState({});
  const [accordionCollapsed, setAccordionCollapsed] = useState(false);

  const firstRun = useRef(true);

  const handleCollapse = () => setAccordionCollapsed(prev => !prev);
  const handleChange = (e) => setState({ ...state,  value: e.target.value });

  useEffect(() => {
    AllCreditValues().then((value) => {
      setValue(value[0]);
      setState(value[0]);
    });
  }, [refetch]);

  useEffect(() => {
    if (!firstRun.current && !active) {
      setState(value);
    } else {
      firstRun.current = false;
    }
  }, [active]);

  useEffect(() => {
    const stateObj = { ...state };

    stateObj.updatedAt = [...(state?.updatedAt || []), new Date()];
    stateObj.updatedBy = [...(state?.updatedBy || []), userName];
    stateObj.updatedValues = [...(state?.updatedValues || []), state?.value];

    setData(stateObj);
  }, [state]);
  
  return ( 
    <div className="edit_value_grid overflow-auto">
      <div className='currency-conversion-wrapper align-items-center flex-evenly'>
        <div className='default-currency flex-start'>
          <div>JC</div>
          <div>1.00</div>
        </div>
        <div className='convert-arrow'>
          <ArrowRight />
        </div>
        <div className='default-currency flex-start'>
          <div>USD</div>
          <Form.Control
            value={state.value}
            onChange={handleChange}
            disabled={!active}
            className={!active ? 'input-disabled' : 'input-enabled' }
          />
        </div>
      </div>
      <div className='accordions mt-3'>
        <div className='accordion-sect'>
          <div className='accordion-head flex-between'>
            <span className='accordion-title'>Activity</span>
            <span className='flex-start' onClick={handleCollapse}>
              {!accordionCollapsed
              ? <ChevronUp size={12} />
              : <ChevronDown size={12} />}
            </span>
          </div>
          {!accordionCollapsed &&
          <div className='accordion-content'>
            {state?.updatedBy?.reverse()?.map((name, index) => 
            <div className='accordion-content-item' key={`state-${name}`}>
              <div>{formatDateWithBar(new Date(state?.updatedAt[index]), true)}</div>
              <div>You changed the value of 1 JC to ${state?.updatedValues[index]}</div>
            </div>
            )}
          </div>
          }
        </div>
      </div>
    </div>
  );
}

export default EditValue;