import { Clock, ChevronDown, ChevronUp } from "react-bootstrap-icons";
import React, { useRef } from "react";
import { getSchoolState } from "../../utils/helpers";

function AccountTab(props) {
  const { items, locationId, layout, orgs, data } = { ...props };

  const contentRef = useRef(null);
  const currentOrg = orgs?.schools?.find((elt) => elt.id === locationId);

  const collapse = (state, index, icon) => {
    if (state) {
      const getSect = contentRef.current?.children[index].children[1];
      getSect.style.display = "none";
    } else {
      const getSect = contentRef.current?.children[index].children[1];
      getSect.style.display = "block";
    }

    if (icon === "down") {
      const getSect = contentRef.current?.children[index].children[0];
      const getIcons = getSect.children[1].children;
      getIcons[0].style.display = "none";
      getIcons[1].style.display = "block";
    } else if (icon === "up") {
      const getSect = contentRef.current?.children[index].children[0];
      const getIcons = getSect.children[1].children;
      getIcons[1].style.display = "none";
      getIcons[0].style.display = "block";
    }
  };

  return (
    <div className={`${layout}_account_tab`}>
      {layout === "resources" ? (
        <div className="top_section flex-v">
          <div
            className="w-100 position-relative border-0"
            style={{
              height: "144px",
            }}
          >
            {" "}
            {currentOrg?.banner ? (
              <img
                src={currentOrg?.banner}
                alt=""
                className="tab_banner position-absolute w-100"
                style={{
                  height: "144px",
                  objectFit: "cover",
                  objectPosition: "top",
                }}
              />
            ) : (
              ""
            )}
          </div>
          <div
            className="tab_account_logo_sect flex-center mt-0"
            style={{ zIndex: "2" }}
          >
            <img src={currentOrg?.image} alt="" className="tab_account_logo" />
          </div>
          <div className="flex-v justify-content-center mt-3">
            <div className="d-flex align-items-center justify-content-center gap-2">
              <h5 className="youth_header text-center fw-bold m-0">
                {items.length > 0
                  ? items[0].length > 0
                    ? items[0][2]
                    : ""
                  : ""}
              </h5>
              <Clock size={14} fill="#969CA5" />
            </div>
            <h6
              className="text-center fs-nm fw-bold mt-2"
              style={{ color: "var(--neutral-700, #515A6A)" }}
            >
              {getSchoolState(currentOrg?.full_address)}
            </h6>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="main_content mt-3">
        <div style={{ marginBottom: 30 }} ref={contentRef}>
          {data.map((item, index) => (
            <div key={index} className="sect_wrapper">
              <div className="sect flex-between">
                <span className="mt-20">{item.title}</span>
                <div className="mt-20">
                  <ChevronUp
                    style={{ display: "none" }}
                    onClick={() => collapse(false, index, "down")}
                  />
                  <ChevronDown onClick={() => collapse(true, index, "up")} />
                </div>
              </div>
              <div className="sect_main">
                {item.innerItem.map((itemInner, index) => (
                  <div className="sect_details mt-20" key={index}>
                    <div className="sect_details_title">{itemInner.key}</div>
                    <div className="sect_details_sub_title">
                      {itemInner.val}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AccountTab;
