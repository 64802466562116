import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { ChevronRight } from "react-bootstrap-icons";
import LeftModalAccountTab from "./LeftModalAccountTab";
import LeftModalActivityTab from "./LeftModalActivityTab";
import ResourcesLeftModalTabs from "./ResourcesTabs";
import CouncelorsLeftModalTabs from "../../Councelors/CouncelorsLeftModal";
import AccountVerificationTabs from "../../AccountVerification/AccountVerificationTabs";
import DetailsTab from "../../Experiences/ArchiveModal/DetailsTab";
import SessionsTab from "../../Experiences/ArchiveModal/SessionsTab";
import PurchasesTab from "../../Experiences/ArchiveModal/PurchasesTab";
import MessagesTab from "../../Experiences/ArchiveModal/MessagesTab";
import PurchasesModal from "../../Purchases/ModalContent";
import {filterOrgsByState, getSchoolState} from "../../../utils/helpers";
import {useSelector} from "react-redux";

function LeftModalContent(props) {
  const {
    layout,
    subLayout,
    selected,
    modal,
    setModal,
    items,
    details,
    detailsRefetch,
  } = { ...props };
  const [state, setState] = useState(false);
  const [resourceContent, setResourceContent] = useState(true);
  const [locationId, setLocationId] = useState("");
  const currentState = useSelector((store) => store.currentState.state);

  useEffect(() => {
    setResourceContent(true);
    setState(false);
  }, [modal]);

  const toggleResourceContents = (id) => {
    setResourceContent(!resourceContent);
    setState(!state);
    setLocationId(id);
  };

  const YouthLayout = () => {
    const [key, setKey] = useState("account");

    return (
      <div className="tabs_wrapper">
        <Tabs
          id="youth_tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="account" title="Account">
            <LeftModalAccountTab data={details} />
          </Tab>
          <Tab eventKey="activity" title="Activity">
            <LeftModalActivityTab cls={"youth_activity"} />
          </Tab>
        </Tabs>
      </div>
    );
  };

  const ExperiencesLayout = (type) => {
    const [key, setKey] = useState("details");

    return (
      <div className="tabs_wrapper details_tab">
        <Tabs
          id="details_tab"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="details" title="Details">
            <DetailsTab item={items} />
          </Tab>
          <Tab eventKey="sessions" title="Sessions">
            <SessionsTab item={items} />
          </Tab>
          <Tab eventKey="purchases" title="Purchases">
            <PurchasesTab item={items} />
          </Tab>
          <Tab eventKey="messages" title="Messages">
            {type === "active" ? (
              <MessagesTab edit={true} item={items} />
            ) : (
              <MessagesTab item={items} />
            )}
          </Tab>
        </Tabs>
      </div>
    );
  };

  const ResourcesLayout = () => {
    const filteredSchools = filterOrgsByState(details?.schools, currentState);
    return (
      <>
        <div
          className="resources_modal_content"
          style={{ display: resourceContent ? "block" : "none" }}
        >
          <div className="resources_modal_header mv-10 pd-20">
            This organization is a super resource. Select a location you want to
            view to proceed
          </div>
          <div className="resources_modal_sub_header pd-20">
            Locations ({filteredSchools?.length} results)
          </div>
          <div className="mt-20">
            {filteredSchools?.map((school, index) => (
              <div
                className="resources_result_cards p-20 mh-20 flex-between"
                key={index}
                onClick={() => toggleResourceContents(school?.id)}
              >
                <div className="flex-v">
                  <span style={{ color: "#969CA5" }}>{selected}</span>
                  <span>{getSchoolState(school?.full_address)}</span>
                </div>
                <div className="flex-center">
                  <ChevronRight />
                </div>
              </div>
            ))}
          </div>
        </div>
        <ResourcesLeftModalTabs
          display={state}
          items={items}
          locationId={locationId}
          details={details}
        />
      </>
    );
  };

  const councelorsLayout = () => {
    const filteredSchools = filterOrgsByState(details?.schools, currentState);

    return (
      <div className="councelors_tab_wrapper">
        <div
          className="resources_modal_content"
          style={{ display: resourceContent ? "block" : "none" }}
        >
          <div className="resources_modal_header mv-10 pd-20">
            This organization is a super counselor. Select a location you want
            to view to proceed
          </div>
          <div className="resources_modal_sub_header pd-20">
            Locations ({filteredSchools?.length} results)
          </div>
          <div className="mt-20">
            {filteredSchools?.map((school, index) => (
              <div
                className="resources_result_cards p-20 mh-20 flex-between"
                key={index}
                onClick={() => toggleResourceContents(school?.id)}
              >
                <div className="flex-v">
                  <span style={{ color: "#969CA5" }}>{school?.name}</span>
                  <span>{getSchoolState(school?.full_address)}</span>
                </div>
                <div className="flex-center">
                  <ChevronRight />
                </div>
              </div>
            ))}
          </div>
        </div>
        <CouncelorsLeftModalTabs
          display={state}
          items={items}
          details={details}
          locationId={locationId}
        />
      </div>
    );
  };

  const purchasesLayout = () => {
    return (
      <div>
        <PurchasesModal item={details} />
      </div>
    );
  };

  const accountVerificationLayout = () => {
    return (
      <div className="account_verification_tab_wrapper">
        <AccountVerificationTabs
          display={true}
          details={details}
          detailsRefetch={detailsRefetch}
          subLayout={subLayout}
          modal={modal}
          setModal={setModal}
        />
      </div>
    );
  };

  const layouts = {
    youth: YouthLayout(),
    resources: ResourcesLayout(),
    councelors: councelorsLayout(),
    "account-verification": accountVerificationLayout(),
    "experiences-archive": ExperiencesLayout("archives"),
    experiences: ExperiencesLayout("active"),
    purchases: purchasesLayout(),
  };

  const returnLayout = (layout) => {
    if (Object.keys(layouts).includes(layout)) {
      return layouts[layout];
    } else {
      return "";
    }
  };

  return <>{returnLayout(layout)}</>;
}

export default LeftModalContent;
