import React from 'react'
import { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import AdministratorsTab from "../../components/Administrators/AdministratorsTab";
import RolesAndPermissionsTab from "../../components/RolesAndPermissions/RolesAndPermissionsTab";
import "../../scss/Admin/administratorsPage.scss";
import { useNavigate } from 'react-router-dom';

function AdministratorsPage() {
  const [key, setKey] = useState("administrators");
  const navigate = useNavigate();

  const handleSelect = (k) => {
    setKey(k);
    navigate(k === 'administrators' ? '/administrators': '/roles-and-permissions')
  }

  return (
    <div className="tabs_wrapper account_verification_tabs users_page">
      <Tabs
        id="administrators_tabs"
        activeKey={key}
        onSelect={handleSelect}
      >
        <Tab eventKey="administrators" title="Admininstrators">
          <AdministratorsTab />
        </Tab>
        <Tab eventKey="roles-and-permissions" title="Roles and Permissions">
          <RolesAndPermissionsTab />
        </Tab>
      </Tabs>
    </div>
  );
}

export default AdministratorsPage;
