import { gql } from "@apollo/client";
import { API } from "aws-amplify";

export const GET_CREDIT_VALUES = gql`
  query CreditValuesQuery {
    listCreditValues {
      items {
        id
        updatedAt
        updatedBy
        updatedValues
        value
      }
    }
  }
`;


export async function AllCreditValues() {
  const resp = await API.graphql({ 
    query: GET_CREDIT_VALUES,
  });

  const data = resp?.data?.listCreditValues
  let creditValues = data?.items;

  return creditValues || [];
}