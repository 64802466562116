import React from 'react';
import { Row, Col } from "react-bootstrap";
import { ArrowUpCircleFill } from "react-bootstrap-icons";


function Cards({ data }) {

  return ( 
    <Row>
      {data.map((card) => 
        <Col 
          key={card.id}
        >
          <div className="card_icon_wrapper">
            {card.icon}
          </div>
          <div className="card_category">
            <div className="card_category_title">
              <h4>{card.category_title}</h4>
            </div>
            <h2>{card.category_number}</h2>
          </div>
          <div className="card_foot_note">
            <p>{card?.sub_text}</p>
            <div className="right_details">
              {card.bottom_text !== "" ? 
              <>
                <div className="right_details_icon_wrapper">
                  <ArrowUpCircleFill color="#226E54"/>
                </div>
                <p>{card.bottom_text}</p>
              </>
              : ""
              } 
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
}

export default Cards;