import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

function FeedbackResources() {
  return (
    <div className='mt-2 p-3'>
      <ListGroup className='feedback_list_group'>
        <ListGroup.Item>Suggest an Interest</ListGroup.Item>
        <ListGroup.Item>Request for a Promotion</ListGroup.Item>
        <ListGroup.Item>Feedback on a Closed Opportunity</ListGroup.Item>
        <ListGroup.Item>Report an Issue (Chat)</ListGroup.Item>
        <ListGroup.Item>Account Closure</ListGroup.Item>
      </ListGroup>
    </div>
  );
}

export default FeedbackResources;