import React, { useEffect, useState } from "react";
import {
  ChevronDown,
  ChevronUp,
  People,
  PlusCircle,
} from "react-bootstrap-icons";
import CustomisedButton from "../../../../widgets/Button/Button";
import { createBtnText } from "../../../../utils/Experiences/utils";
import { axiosClient } from "../../../../libs/axiosClient";
import { formatDateWithBar } from "../../../../utils/helpers";

function FirstStep({ item, edit, setCurrentPage, setData }) {
  const [experienceMessages, setExperienceMessages] = useState([]);
  const [dropItems, setDropItems] = useState({});
  const [receivers, setReceivers] = useState([]);
  const getExperienceMessages = async () => {
    try {
      const resp = await axiosClient.post("/experiences/messages/filter", {
        filter: { experienceID: { eq: item?.id } },
      });
      const respData = resp.data.data;
      setExperienceMessages(respData);
      setReceivers(
        await Promise.all(
          respData.map(async (elt) => {
            const resp = await axiosClient.post(
              "/purchases/filter/exp-session",
              {
                experienceID: item?.id,
                sessionID: elt?.session,
                fields: ["experienceID", "sessionID", "purchased_by"],
              },
            );
            return resp.data.data;
          }),
        ),
      );
    } catch (err) {
      console.error(err);
    }
  };
  const getSessionId = (id) => {
    const sessionIndex = item?.sessionsIDs?.findIndex((elt) => elt === id);
    return sessionIndex + 1;
  };

  const proceedToAddMessage = () => {
    setData(receivers);
    setCurrentPage("second");
  };

  useEffect(() => {
    if (Object.keys(item || {}).length) {
      getExperienceMessages();
    }
  }, [item]);

  return (
    <div className="messages_tab">
      {edit && (
        <div className="flex-end mb-10">
          <CustomisedButton
            text={createBtnText(
              "Send a New Message",
              <PlusCircle fill="#fff" />,
            )}
            bg="#10408C"
            fg="#fff"
            bd="none"
            pd="10px 10px"
            onClick={proceedToAddMessage}
          />
        </div>
      )}
      <h6>
        Showing {experienceMessages.length} message
        {experienceMessages.length > 1 && "s"}
      </h6>
      {experienceMessages?.map((elt, index) => (
        <div className="messages_card_wrapper mt-20" key={`ms-card-${elt?.id}`}>
          <div className="messages_card">
            <div>{formatDateWithBar(elt?.createdAt, true)}</div>
            <div className="mt-10">
              <span className="bold mr-10">{elt?.subject}</span>
              <span className="text_wrapper">{elt?.method}</span>
            </div>
            <div className={`mt-10 ${dropItems[elt.id] && "break-word"}`}>
              {elt?.message}
            </div>
            <div
              className="mt-10 flex-between bold point"
              onClick={() =>
                setDropItems({ ...dropItems, [elt.id]: !dropItems[elt.id] })
              }
              style={{
                width: "22%",
                color: "#10408C",
              }}
            >
              <span>{dropItems[elt.id] ? "Show more" : "Show less"}</span>
              <div className="align-items">
                {!dropItems[elt.id] ? (
                  <ChevronUp size={14} />
                ) : (
                  <ChevronDown size={14} />
                )}
              </div>
            </div>
            <hr style={{ color: "#DDDFE2", height: 1.5 }}></hr>
            <div className="flex-start" style={{ padding: "0 10px 10px" }}>
              <div className="align-items">
                <People className="mr-10" />
              </div>
              <span className="mr-10">
                {receivers[index]?.length} recipient
                {receivers[index]?.length > 1 && "s"}
              </span>
              <span>(Session #{getSessionId(elt.session)})</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default FirstStep;
