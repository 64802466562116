import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Download, Pencil, X } from "react-bootstrap-icons";
import { CreateInfoCard } from "../../../utils/Credits/utils";
import { creditsModalNotifyCard } from "../../../assets/data/credits";
import CustomisedButton from "../../../widgets/Button/Button";
import OtpInput from 'react-otp-input';
import { axiosClient } from '../../../libs/axiosClient';
import { API } from 'aws-amplify';
import { onUpdateCreditEvent, onUpdateCreditMultiplier, onUpdateCreditValue } from '../../../graphql/subscriptions';
import { exportMultipliersData } from '../../../utils/helpers';
import { sortMultipliersByCreatedAtWithLimit } from '../../../services/query/creditMultiplier';
import { sortEventsByCreatedAtWithLimit } from '../../../services/query/creditEvents';


function TopSection(props) {
  const { 
    // show,
    setShow, 
    type, 
    active, 
    data,
    setActive,
    setRefetch
  } = {...props}

  const [save, setSave] = useState(false)
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpToken, setOtpToken] = useState('');

  const user = useSelector((store) => store.adminUser.data);
  const name = user?.userName;
  const email = user?.email;
  
  const btnTextWithIcon = (text, icon) => {
    return (
      <div className="flex-center">
        <span className="align-items download-txt mr-10">
          {text}
        </span>
        <span className="align-items">
          {icon}
        </span>
      </div>
    )
  }

  const initialData = {
    "btn1": {
      "text": "Unlock to Edit",
      "color": "#F4F5F752",
      "bd": "#DDDFE2",
      "fg": "#515A6A"
    },
    "btn2": {
      "text": btnTextWithIcon("Download current settings", <Download/>),
      "color": "#F4F5F752",
      "bd": "#DDDFE2",
      "fg": "#515A6A"
    }
  }

  const [btnDetails, setBtnDetails] = useState(initialData);

  const handleClick = (color) => {

    const btn1 = { ...btnDetails["btn1"] };
    btn1["color"] = color;
    btn1["text"] = "Save Changes";
    btn1["fg"] = "#fff"
    btn1["bd"] = "#DDDFE2"

    const btn2 = {...btnDetails["btn2"]};
    btn2["text"] = "Discard Changes";

    setBtnDetails({
      ...btnDetails,
      "btn1": btn1,
      "btn2": btn2
    });

    if (active) { setSave(true) }
    else { setActive(true) }
  }

  const discardChanges = async () => {
    if (active) {
      setBtnDetails(initialData);
      setActive(false);
      setSave(false);
    } else {
      let data = []
      if (type === "Edit Multipliers") {
        data = await sortMultipliersByCreatedAtWithLimit(2);
      } else if (type === "Edit Events per 1 JC") {
        data = await sortEventsByCreatedAtWithLimit(2);
      }

      const downloadType = type === "Edit Multipliers" ? "Multipliers" : "Events";
      exportMultipliersData(data, downloadType);
    }
  }

  const editMultipliersLayout = () => {
    return (
      <div className="flex-between p-20">
        <CustomisedButton
          text={
            btnTextWithIcon(
              btnDetails["btn1"]["text"],
              <Pencil/>
            )
          }
          fg={btnDetails["btn1"]["fg"]}
          bd={`1px solid ${btnDetails["btn1"]["bd"]}`}
          bg={btnDetails["btn1"]["color"]}
          height="38px"
          pd="10px"
          fs="13px"
          width="49%"
          className="mr-10 text-center"
          onClick={() => handleClick("#D41811")}
        />
        <CustomisedButton
          text={btnDetails["btn2"]["text"]}
          fg={btnDetails["btn2"]["fg"]}
          bd={`1px solid ${btnDetails["btn2"]["bd"]}`}
          bg={btnDetails["btn2"]["color"]}
          fs="13px"
          pd="10px"
          width="49%"
          height="38px"
          onClick={discardChanges}
        />
      </div>
    )
  }

  const editValueLayout = () => {
    return (
      <div className="flex-between p-20">
        <CustomisedButton
          text={
            btnTextWithIcon(
              btnDetails["btn1"]["text"],
              <Pencil/>
            )
          }
          fg={btnDetails["btn1"]["fg"]}
          bd={`1px solid ${btnDetails["btn1"]["bd"]}`}
          bg={btnDetails["btn1"]["color"]}
          height="38px"
          pd="10px"
          fs="13px"
          width="100%"
          className="mr-10 text-center"
          onClick={() => handleClick("#D41811")}
        />
        {active &&
        <CustomisedButton
          text={btnDetails["btn2"]["text"]}
          fg={btnDetails["btn2"]["fg"]}
          bd={`1px solid ${btnDetails["btn2"]["bd"]}`}
          bg={btnDetails["btn2"]["color"]}
          fs="13px"
          pd="10px"
          width="49%"
          height="38px"
          onClick={discardChanges}
        />}
      </div>
    )
  }

  const requestCode = async () => {
    setLoading(true);
    try {
      const resp = await axiosClient.post('/change-verify', { name, email });
      setOtpToken(resp.data.data);
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  }

  const layouts = {
    "Edit Multipliers": editMultipliersLayout(),
    "Edit Events per 1 JC": editMultipliersLayout(),
    "Edit JC Value": editValueLayout(),
  }

  const returnLayout = (type) => {
    if (Object.keys(layouts).includes(type)) {
      return layouts[type]
    }
  }

  const rightSideOnClick = () => {
    setShow(false);
    setSave(false);
    setBtnDetails(initialData);
  }

  const scheduleCreditUpdate = async () => {
    const date = new Date();
    date.setSeconds(date.getSeconds() + 30);
    try {
      await axiosClient.post('/credit-update', { date, type, data, email });
    } catch (e) {
      console.error(e);
    }
  }

  const validateOtp = async () => {
    try {
      const resp = await axiosClient.post('/decode-token', { token: otpToken });
      if (resp.data.otp === parseInt(otp, 10)) {
        setSave(false);
        setOtp('');
        await scheduleCreditUpdate();
        setBtnDetails(initialData);
        setActive(false);
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    console.log(otp)
    if (otp.length === 6) validateOtp();
  }, [otp]);

  useEffect(() => {
    const creditMultiplierSubscription = API.graphql({
      query: onUpdateCreditMultiplier,
    }).subscribe({
      next: async (data) => setRefetch(data.value.data),
      error: (error) => console.log(error)
    });

    const creditEventSubscription = API.graphql({
      query: onUpdateCreditEvent,
    }).subscribe({
      next: async (data) => setRefetch(data.value.data),
      error: (error) => console.log(error)
    });

    const creditValueSubscription = API.graphql({
      query: onUpdateCreditValue,
    }).subscribe({
      next: async (data) => setRefetch(data.value.data),
      error: (error) => console.log(error)
    });


    return () => {
      creditMultiplierSubscription.unsubscribe();
      creditValueSubscription.unsubscribe();
      creditEventSubscription.unsubscribe()
    }

  }, []);
  
  return ( 
    <div>
      <div className="mbd">
        <div className="flex-between top_sect">
          <div className="align-items bold">
            {type}
          </div>
          <div className="right_side" onClick={rightSideOnClick}>
            <X fill="#515A6A" size={24}/>
          </div>
        </div>
      </div>
      <div className="p-20 mt-20">
        <CreateInfoCard 
          data={creditsModalNotifyCard}
        />
      </div>
      <div>
        <div className={save ? "hide" : "show"}>
          {returnLayout(type)}
        </div>
        <div className={save ? "show" : "hide"}>
          <div className="flex-between mt-2 pd-20">
            <div className="align-items verify-txt">
              Enter verification 
              code to save changes
            </div>
            <div className="flex-start">
              <CustomisedButton
                text={loading ? "Loading..." : "Request Code" }
                className="mr-10"
                height="34px"
                bg="#10408C"
                fg="#fff"
                pd="10px"
                fs="13px"
                onClick={requestCode}
              />
              <CustomisedButton
                text="Discard Changes"
                className="mr-10"
                height="34px"
                bg="#fff"
                fg="#515A6A"
                fw="700"
                bd="1px solid #DDDFE2"
                pd="10px"
                fs="13px"
                onClick={discardChanges}
              />
            </div>
          </div>
          <div className="pd-20 mt-3 otp-input-wrapper">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              placeholder="000000"
              inputStyle={"otp-input"}
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopSection;