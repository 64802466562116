import { gql } from "@apollo/client";
import { API } from "aws-amplify";
import { GetSingleSchool } from "./school";

export const GET_EDUCATORS = gql`
  query EducatorsQuery {
    listEducators (limit: 1000) {
      items {
        id
        bio
        createdAt
        dob
        address
        image
        email
        gender
        name
        personality
        role
        isAdmin
        phone
        schoolID
        schoolid
        verifier
        state
        updatedAt
        zipcode
        is_verified
        verification_progress
        verification_status
        verification_date_started
        verification_progress_dates
        deactivation_reason
        deactivation_detailed_reason
        deactivation_period
        date_requested
        deactivated
        date_deactivated
      }

      nextToken
    }
  }
`;


export const GET_EDUCATOR = gql`
  query SingleEducator($id: ID!) {
    getEducator(id: $id) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      image
      personality
      role
      isAdmin
      phone
      schoolID
      schoolid
      state
      updatedAt
      zipcode
      verifier
      is_verified
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
    }
  }
`;

export async function GetEducator(id) {
  try {
    const resp = await API.graphql({
      query: GET_EDUCATOR,
      variables: { id },
    });
  
    const educator = resp?.data?.getEducator;
    const school = await GetSingleSchool(educator.schoolID);
    const obj = { ...educator, school };
    return obj || {};
  
  } catch (err) {
    console.error(err);
    return {};
  }

}

export const GET_EDUCATORS_WITH_TOKEN = gql`
  query EducatorsQuery ($limit: Int, $nextToken: String) {
    listEducators (limit: $limit, nextToken: $nextToken) {
      items {
        id
        bio
        createdAt
        dob
        address
        verifier
        email
        gender
        name
        personality
        role
        isAdmin
        phone
        image
        schoolID
        schoolid
        state
        updatedAt
        image
        zipcode
        is_verified
        verification_progress
        verification_status
        verification_date_started
        verification_progress_dates
        deactivation_reason
        deactivation_detailed_reason
        deactivation_period
        date_requested
        deactivated
        date_deactivated
      }
      nextToken
    }
  }
`;

export async function AllEducators() {
  const resp = await API.graphql({ 
    query: GET_EDUCATORS,
  });

  const data = resp?.data?.listEducators
  let nextToken = data?.nextToken
  let educators = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_EDUCATORS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listEducators

    const previousData = [...educators, ...respData.items ]
    educators = [...previousData];

    nextToken = respData?.nextToken
  }

  return await educators?.reduce(async (previousPromise, currentEducator) => {
    const school = await GetSingleSchool(currentEducator.schoolID);
    let educatorData = await previousPromise;
    const obj = { ...currentEducator, school };
    educatorData.push(obj);
    return educatorData;
  }, Promise.resolve([]));
}

export async function GetEducatorsBySchoolID (id) {
  const educators = await AllEducators();
  const filteredEducators = educators?.filter((elt) => elt.schoolID === id);
  return filteredEducators || [];
}

export async function GetEducatorsByEmail (email) {
  const educators = await AllEducators();
  const filteredEducators = educators.find((elt) => elt.email === email);
  return filteredEducators || {};
}
