import {
  SET_REFRESH_ACTIONS_DATA,
  RESET_REFRESH_ACTIONS_DATA,
} from "../constants/refreshActionsConstants";

export const setRefreshDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_REFRESH_ACTIONS_DATA,
    payload: data,
  });
};

export const resetRefreshDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_REFRESH_ACTIONS_DATA,
  });
};
