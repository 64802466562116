import React, { useState, useRef, useEffect } from "react";
import { ChevronLeft, Trash, ChevronRight } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import CustomisedButton from "../Button/Button";
import SearchInput from "../SearchInput/searchInput";
import { DateRange } from "react-date-range";
import {
  DeactivationStatusFilter,
  RequestedByFilter,
} from "../../assets/data/menus";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {getCities} from "../../utils/helpers";

export function DateRequestedFilterDrop({ display, func, setData }) {
  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    const getRdrDayNumber = document.getElementsByClassName("rdrDayNumber");
    for (let i = 0; i < getRdrDayNumber.length; i++) {
      getRdrDayNumber[i].children[0].className = "";
    }

    const days = document.getElementsByClassName("rdrDay");
    for (let i = 0; i < days.length; i++) {
      if (days[i].children.length > 1) {
        if (
          days[i].children[0]?.className.search("rdrEndEdge") >= 0 ||
          days[i].children[0]?.className.search("rdrStartEdge") >= 0
        ) {
          if (days[i].children[1].children[0]) {
            days[i].children[1].children[0].className = "setColor-fff";
          }
        } else {
          days[i].children[1].children[0].className = "setColor-000";
        }
      }
    }
  }, [dateState]);

  const cancelDateRange = () => {
    setDateState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
    func();
  };

  const handleDateRangeSelect = () => {
    setData("cdata_range", dateState);
    func();
  };

  return (
    <div
      className="drop_wrapper account_deactivation_date_range_picker_wrapper"
      style={{ display: display ? "block" : "none" }}
    >
      <DateRange
        ranges={dateState}
        showDateDisplay={false}
        rangeColors={["#226E54", "#fff", "#red"]}
        moveRangeOnFirstSelection={false}
        onChange={(item) => setDateState([item.selection])}
        className="date-range-picker"
      />
      <div className="flex-between p-20">
        <CustomisedButton
          text="Cancel"
          bg="#fff"
          fg="#515A6A"
          bd="1px solid #DDDFE2"
          rd="6px"
          pd="5px 10px"
          width="150px"
          onClick={cancelDateRange}
        />
        <CustomisedButton
          text="Select"
          bg="#FFDA9A"
          fg="#515A6A"
          bd="1px solid #FFDA9A"
          rd="6px"
          pd="5px 10px"
          width="150px"
          onClick={handleDateRangeSelect}
        />
      </div>
    </div>
  );
}

export function LocationFilterDrop({ display, func, setData }) {
  const usCities = getCities({
    keys: [],
    requiresAll: false,
    returnObj: false,
    sort: true
  });

  const [state, setState] = useState({
    All: true,
  });

  const handleDoneBtn = () => {
    func();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("location", state);
  };

  const handleChecks = (id) => {
    if (id !== "All" && state["All"]) {
      setState({ ...state, All: false, [id]: !state[id] });
    } else {
      setState({ ...state, [id]: !state[id] });
    }
  };

  return (
    <div
      className="drop_wrapper location_filter"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section flex-between p-20 mbd">
        <div className="flex-center" onClick={func}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 point" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton text="Done" bg="#FFDA9A" fg="#000" width="56px" />
          </span>
        </div>
      </div>
      <div className="drop_main_content">
        <div className="flex-start mbd ph-10">
          <SearchInput height="35px" width="200px" placeholder="Search..." />
        </div>
      </div>
      <div className="overflow">
        <div className="drop_main_content subheader">
          <div className="flex-start mbd p-20">
            <span className="mr-10" onClick={() => handleChecks("All")}>
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={state["All"]}
              />
            </span>
            All
          </div>
        </div>
        <div className="drop_main_content sublist">
          {usCities.map((item, index) => (
            <div key={index}>
              <span className="mr-10" onClick={() => handleChecks(item.name)}>
                <Form.Check
                  type="checkbox"
                  className="menu_chkbox"
                  checked={state[item.name]}
                  onChange={() => handleChecks(item.name)}
                />
              </span>
              {item?.name}, {item?.stateCode}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function DeactivationStatusFilterDrop({ display, func, setData }) {
  const toggleDrop = () => {
    func();
  };

  const [state, setState] = useState({});

  const handleDoneBtn = () => {
    toggleDrop();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("deactivation_status", state);
  };

  const handleChecks = (id) => {
    setState({ ...state, [id]: !state[id] });
  };

  return (
    <div
      className="drop_wrapper location_filter"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section flex-between p-20 mbd">
        <div className="flex-center" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 point" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton text="Done" bg="#FFDA9A" fg="#000" width="56px" />
          </span>
        </div>
      </div>
      <div className="drop_main_content">
        <div className="flex-start mbd ph-10">
          <SearchInput height="35px" width="200px" placeholder="Search..." />
        </div>
      </div>
      <div className="overflow">
        <div className="drop_main_content">
          <div className="flex-start mbd p-20">
            <span className="mr-10" onClick={() => handleChecks("All")}>
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={state["All"]}
                defaultChecked
              />
            </span>
            All
          </div>
        </div>
        <div className="drop_main_content">
          {DeactivationStatusFilter.map((item, index) => (
            <span
              className={`flex-start mt-10 ${item.divide ? "mbd" : ""}`}
              key={index}
            >
              <span className="mr-10" onClick={() => handleChecks(item.title)}>
                <span
                  className="mr-10"
                  onClick={() => handleChecks(item.title)}
                >
                  <Form.Check
                    type="checkbox"
                    className="menu_chkbox"
                    checked={state[item.title]}
                    onChange={() => handleChecks(item.title)}
                  />
                </span>
                {item.title}
              </span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export function RequestedByFilterDrop({ display, func, setData }) {
  const toggleDrop = () => {
    func();
  };

  const [state, setState] = useState({});

  const handleDoneBtn = () => {
    toggleDrop();
    for (const [key, value] of Object.entries(state)) {
      if (!value) {
        delete state[key];
      }
    }
    setData("requested_by", state);
  };

  const handleChecks = (id) => {
    setState({ ...state, [id]: !state[id] });
  };

  return (
    <div
      className="drop_wrapper location_filter"
      style={{ display: display ? "block" : "none" }}
    >
      <div className="head_section flex-between p-20 mbd">
        <div className="flex-center" onClick={toggleDrop}>
          <ChevronLeft size={15} />
        </div>
        <div>
          <span className="mr-10 point" onClick={() => setState({})}>
            Reset
          </span>
          <span onClick={handleDoneBtn}>
            <CustomisedButton text="Done" bg="#FFDA9A" fg="#000" width="56px" />
          </span>
        </div>
      </div>
      <div className="drop_main_content">
        <div className="flex-start mbd ph-10">
          <SearchInput height="35px" width="200px" placeholder="Search..." />
        </div>
      </div>
      <div className="overflow">
        <div className="drop_main_content">
          <div className="flex-start mbd p-20">
            <span className="mr-10" onClick={() => handleChecks("All")}>
              <Form.Check
                type="checkbox"
                className="menu_chkbox"
                checked={state["All"]}
                defaultChecked
              />
            </span>
            All
          </div>
        </div>
        <div className="drop_main_content">
          {RequestedByFilter.map((item, index) => (
            <div
              className={`flex-start mt-10 ${item.divide ? "mbd" : ""}`}
              key={index}
            >
              <span className="mr-10" onClick={() => handleChecks(item.title)}>
                <Form.Check
                  type="checkbox"
                  className="menu_chkbox"
                  checked={state[item.title]}
                  onChange={() => handleChecks(item.title)}
                />
              </span>
              {item.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function AccountDeactivationFilterSectionDrop(props) {
  const { display, func, setData, filters } = { ...props };

  const filterRef = useRef(null);

  const [state, setState] = useState({});

  const toggleDrop = () => {
    setState({});
    func();
  };

  const setDate = () => {
    setState({ ...state, date_requested: false });
  };

  const clearFilters = () => {
    func("clear");
  };

  const checkCategory = (category) => {
    setState({});
    setState({ [category]: true });
    func();
  };

  return (
    <div ref={filterRef}>
      <div
        className="drop_wrapper filter_drop"
        style={{ display: display ? "block" : "none" }}
      >
        <div className="head_section flex-between p-20">
          <span>Select a filter</span>
          <div
            className="filter_title"
            onClick={clearFilters}
            style={{ color: filters > 0 ? "#D41811" : "" }}
          >
            Clear All&nbsp;
            <Trash size={15} className="filter_trash_icon" />
          </div>
        </div>
        <div className="drop_main_content">
          <div
            className="flex-between"
            onClick={() => checkCategory("location")}
          >
            Location
            <ChevronRight size={15} className="right_icon" />
          </div>
          <div onClick={() => checkCategory("date_requested")}>
            Date Requested
            <ChevronRight size={15} className="right_icon" />
          </div>
          <div onClick={() => checkCategory("deactivation_status")}>
            De-activation Status
            <ChevronRight size={15} className="right_icon" />
          </div>
          <div onClick={() => checkCategory("requested_by")}>
            Requested By
            <ChevronRight size={15} className="right_icon" />
          </div>
        </div>
      </div>
      <DateRequestedFilterDrop
        display={state["date_requested"]}
        func={setDate}
        setData={setData}
      />
      <LocationFilterDrop
        display={state["location"]}
        func={toggleDrop}
        setData={setData}
      />
      <DeactivationStatusFilterDrop
        display={state["deactivation_status"]}
        func={toggleDrop}
        setData={setData}
      />
      <RequestedByFilterDrop
        display={state["requested_by"]}
        func={toggleDrop}
        setData={setData}
      />
    </div>
  );
}
