import React from 'react';
import { 
  Heart, 
  ChevronExpand,
  Eye, 
  Check2Circle, 
  Person,
  PersonPlus,
  ThreeDots
} from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";
import { textLabel } from "../../utils/Purchases/utils"
import { nameCol } from "../../utils/TableWrapper/tableWrapper"
// import { passDate } from "../../utils/Purchases/utils"
import { formatDatePurchaseStyle, getSchoolState, titleWords } from "../../utils/helpers";


export const resourcesAccountTabList = (item, id, type) => {
  const org = item?.schools?.find((elt) => elt.id === id);
  return [
    {
      "title": "VERIFICATION INFORMATION",
      "innerItem": [
        {
          "key": "Verification Date:",
          "val": formatDatePurchaseStyle(item?.verification_date_started),
        },
        {
          "key": "Verifier:",
          "val": item?.verifier,
        },
        {
          "key": "Verification Status:",
          "val": 
            <CustomisedButton 
              text={titleWords(item?.verification_status)}
              bg="#DAF8EE"
              fg="#226E54"
              bd="1px solid #44DDA9"
              rd="25px"
              pd="5px 10px"
            />
          ,
        },
      ]
    },

    {
      "title": "PUBLIC INFORMATION",
      "innerItem": [
        {
          "key": "About Organization:",
          "val": org?.about,
        },
        {
          "key": "Website",
          "val": org?.website,
        },
      ]
    },

    {
      "title": "ORGANIZATION INFORMATION",
      "innerItem": [
        {
          "key": "Industry:",
          "val": "Technology",
        },
        {
          "key": "Organization Size:",
          "val": "Fewer than 100",
        },
        {
          "key": "Team Size:",
          "val": org?.team_size,
        },
      ]
    },

    {
      "title": "LOCATION INFORMATION",
      "innerItem": [
        {
          "key": "Location:",
          "val": getSchoolState(org?.full_address),
        },
        {
          "key": "Office Address:",
          "val": org?.address,
        },
      ]
    },

    {
      "title": "ACCOUNT INFO",
      "innerItem": [
        {
          "key": "Created By:",
          "val": item?.name,
        },
        {
          "key": "Role:",
          "val": "Administrator",
        },
        {
          "key": "Email",
          "val": type === "mentor" ? item?.email : item?.contact_email,
        },
        {
          "key": "Phone:",
          "val": type === "mentor" ? "+1"+`${item?.phone}` : item?.contact_phone,
        },
      ]
    },

  ]

}


export const resourcesTeamTabList = [
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Courtney Henry",
    "role": "Administrator",
  },
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Jimmy Hardy",
    "role": "Administrator",
  },
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Courtney Henry",
    "role": "Administrator",
  },
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Jimmy Hardy",
    "role": "Administrator",
  },
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Courtney Henry",
    "role": "Administrator",
  },
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Jimmy Hardy",
    "role": "Administrator",
  },

]


export const resourcesOpportunityTabList = [
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
  {
    "theme": "Tech Leadership Mentorship Programm For Youths",
    "appNumber": "182",
    "publishDate": "1 day ago"
  },
]

export const resourcesActivityTabList = [
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "youth_title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "youth_title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "youth_title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "youth_title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
]


export const resourcesAnalyticsTabList = (apps, opps, likedOpps, applicantPerOpp) => {
  return [
    {
      "number": apps,
      "title": "No. of Applications "
    },
    {
      "number": opps,
      "title": "No. of Opportunities "
    },
    {
      "number": "0",
      "title": "No. of Youth Connections "
    },
    {
      "number": likedOpps,
      "title": "No. of Liked opportunities "
    },
    {
      "number": applicantPerOpp,
      "title": "No. of Applicants per opportunity"
    },
  ]
}


export const resourcesPaymentTabList = [
  {
    "title": "50 Credits",
    "status": 
      <CustomisedButton 
        text="Successful" 
        bg="#DAF8EE"
        fg="#226E54"
        bd="1px solid #44DDA9"
        rd="25px"
        pd="3px 8px"
        fs="13px"
      />
    ,
    "price": "$250.00",
    "number": "****6372",
  },
  {
    "title": "50 Credits",
    "status": 
      <CustomisedButton 
        text="Successful" 
        bg="#DAF8EE"
        fg="#226E54"
        bd="1px solid #44DDA9"
        rd="25px"
        pd="3px 8px"
        fs="13px"
      />
    ,
    "price": "$250.00",
    "number": "****6372",
  },
  {
    "title": "50 Credits",
    "status": 
      <CustomisedButton 
        text="Successful" 
        bg="#DAF8EE"
        fg="#226E54"
        bd="1px solid #44DDA9"
        rd="25px"
        pd="3px 8px"
        fs="13px"
      />
    ,
    "price": "$250.00",
    "number": "****6372",
  },
  {
    "title": "50 Credits",
    "status": 
      <CustomisedButton 
        text="Successful" 
        bg="#DAF8EE"
        fg="#226E54"
        bd="1px solid #44DDA9"
        rd="25px"
        pd="3px 8px"
        fs="13px"
      />
    ,
    "price": "$250.00",
    "number": "****6372",
  },
  {
    "title": "50 Credits",
    "status": 
      <CustomisedButton 
        text="Unsuccessful" 
        bg="rgba(251, 213, 213, 0.4)"
        fg="#D41811"
        bd="1px solid #FF1D15"
        rd="25px"
        pd="3px 8px"
        fs="13px"
      />
    ,
    "price": "$250.00",
    "number": "****6372",
  },
]



export const resourcesTeamRoleFilter = [
  {
    "leftIcon": "",
    "title": "Super Admin",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Account Manager",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "JI Manager",
    "rightIcon": "",
    "divide": false,
  },
]

export const resourcesAppsNoOpportunityRoleFilter = [
  {
    "leftIcon": "",
    "title": "1-49",
    "value": 50,
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "50-99",
    "value": 100,
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "100-149",
    "value": 150,
    "rightIcon": "",
    "divide": false,
  },
]

export const resourcesPublishedOpportunityRoleFilter = [
  {
    "leftIcon": "",
    "title": "Less than an hour ago",
    "value": 1,
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "2 hours ago",
    "value": 2,
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "5 hours ago",
    "value": 5,
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "10 hours ago",
    "value": 10,
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "15 hours ago",
    "value": 15,
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "24 hours ago",
    "value": 24,
    "rightIcon": "",
    "divide": false,
  },
]


export const resourcesRightModalIcons = (item) => {
  return [
    {
      "number": item?.apps?.length,
      "icon": <Check2Circle/>,
      "title": "Applications"
    },
    {
      "number": 0,
      "icon": <PersonPlus/>,
      "title": "Connections"
    },
    {
      "number": item?.likes,
      "icon": <Heart/>,
      "title": "Likes"
    },
    {
      "number": item?.views?.length,
      "icon": <Eye/>,
      "title": "Views"
    },
  ]

}

const expandIcon = <span><ChevronExpand fontSize={18}/></span>

const textWithIcon = () => {
  return (
    <div className="flex-center">
      <span className="align-items">
        <Person fill="#10408C"/>
      </span>
      <span style={{color: "#10408C"}}>
        View Profile
      </span>
    </div>
  )
}

export const opportunitiesAppsTableList = 
{
  "th": [
    {
      "component": <>Name</>,
      "cls": "",
      "sortable": false,
      "label": "name"
    },
    {
      "component": <>Connection{expandIcon}</>,
      "cls": "",
      "sortable": true,
      "label": "connection"
    },
    {
      "component": <>Age</>,
      "cls": "",
      "sortable": false,
      "label": "age"
    },
    {
      "component": <>Location</>,
      "cls": "",
      "sortable": false,
      "label": "location"
    },
    {
      "component": <>Status{expandIcon}</>,
      "cls": "",
      "sortable": true,
      "label": "status"
    },
    {
      "component": <></>,
      "cls": "",
      "sortable": false,
      "label": "profile"
    },
    {
      "component": <></>,
      "cls": "",
      "sortable": false,
      "label": "actions"
    },
  ],

  "tc": [
    [
      ["", 
        nameCol({
          Ibg: "#D4A657",
          text: "BD",
          name: "Bryce David",
          layout: 1,
          verified: false,
          bottomText: "Joined"
        })
      ],
      ["", "connected"],
      ["","14"],
      ["","Hartford"],
      ["", textLabel("confirmed")],
      ["", textWithIcon()],
      // eslint-disable-next-line react/jsx-key
      ["", <ThreeDots/>]
    ],
    [
      ["", 
        nameCol({
          Ibg: "#D4A657",
          text: "BD",
          name: "Bryce David",
          layout: 1,
          verified: false,
          bottomText: "Joined"
        })
      ],
      ["", "connected"],
      ["","14"],
      ["","Hartford"],
      ["", textLabel("canceled")],
      ["", textWithIcon()],
      // eslint-disable-next-line react/jsx-key
      ["", <ThreeDots/>]
    ],
    [
      ["", 
        nameCol({
          Ibg: "#D4A657",
          text: "BD",
          name: "Bryce David",
          layout: 1,
          verified: false,
          bottomText: "Joined"
        })
      ],
      ["", "connected"],
      ["","14"],
      ["","Hartford"],
      ["", textLabel("confirmed")],
      ["", textWithIcon()],
      // eslint-disable-next-line react/jsx-key
      ["", <ThreeDots/>]
    ],
    [
      ["", 
        nameCol({
          Ibg: "#D4A657",
          text: "BD",
          name: "Bryce David",
          layout: 1,
          verified: false,
          bottomText: "Joined"
        })
      ],
      ["", "connected"],
      ["","14"],
      ["","Hartford"],
      ["", textLabel("confirmed")],
      ["", textWithIcon()],
      // eslint-disable-next-line react/jsx-key
      ["", <ThreeDots/>]
    ],
    [
      ["", 
        nameCol({
          Ibg: "#D4A657",
          text: "BD",
          name: "Bryce David",
          layout: 1,
          verified: false,
          bottomText: "Joined"
        })
      ],
      ["", "connected"],
      ["","14"],
      ["","Hartford"],
      ["", textLabel("confirmed")],
      ["", textWithIcon()],
      // eslint-disable-next-line react/jsx-key
      ["", <ThreeDots/>]
    ],
    [
      ["", 
        nameCol({
          Ibg: "#D4A657",
          text: "BD",
          name: "Bryce David",
          layout: 1,
          verified: false,
          bottomText: "Joined"
        })
      ],
      ["", "connected"],
      // eslint-disable-next-line react/jsx-key
      ["",<ThreeDots/>],
      ["","Hartford"],     
      ["", textLabel("confirmed")],
      ["", textWithIcon()],
      // eslint-disable-next-line react/jsx-key
      ["", <ThreeDots/>]
    ],
    [
      ["", 
        nameCol({
          Ibg: "#D4A657",
          text: "BD",
          name: "Bryce David",
          layout: 1,
          verified: false,
          bottomText: "Joined"
        })
      ],
      ["", "connected"],
      ["","14"],
      ["","Hartford"],
      ["", textLabel("confirmed")],
      ["", textWithIcon()],
      // eslint-disable-next-line react/jsx-key
      ["", <ThreeDots/>]
    ],
    [
      ["", 
        nameCol({
          Ibg: "#D4A657",
          text: "BD",
          name: "Bryce David",
          layout: 1,
          verified: false,
          bottomText: "Joined"
        })
      ],
      ["", "connected"],
      ["","14"],
      ["","Hartford"],      
      ["", textLabel("canceled")],
      ["", textWithIcon()],
      // eslint-disable-next-line react/jsx-key
      ["", <ThreeDots/>]
    ],
    [
      ["", 
        nameCol({
          Ibg: "#D4A657",
          text: "BD",
          name: "Bryce David",
          layout: 1,
          verified: false,
          bottomText: "Joined"
        })
      ],
      ["", "connected"],
      ["","14"],
      ["","Hartford"],
      ["", textLabel("confirmed")],
      ["", textWithIcon()],
      // eslint-disable-next-line react/jsx-key
      ["", <ThreeDots/>]
    ],

  ]
}
export const opportunitiesYouthTableList = 
{
  "th": [
    {
      "component": <>Candidate</>,
      "cls": "",
      "sortable": false,
      "label": "name"
    },
    {
      "component": <>Age{expandIcon}</>,
      "cls": "",
      "sortable": true,
      "label": "age"
    },
    {
      "component": <>Location</>,
      "cls": "",
      "sortable": false,
      "label": "location"
    },
    {
      "component": <>Connection{expandIcon}</>,
      "cls": "",
      "sortable": true,
      "label": "connection"
    },
    {
      "component": <>Status{expandIcon}</>,
      "cls": "",
      "sortable": true,
      "label": "status"
    },
    {
      "component": <>Actions{expandIcon}</>,
      "cls": "",
      "sortable": false,
      "label": "actions"
    },
    {
      "component": <></>,
      "cls": "",
      "sortable": false,
      "label": "actions"
    },
  ]
}

