import React from 'react';
import { Form } from "react-bootstrap"
import "../../scss/AccountAuth/loginVerificationPage.scss"


function LoginVerificationPage() {
  return ( 
    <div className="card_content">
      <div className="form_header">
        <h1>Login Verification</h1>
        <div className="sub__header">
          <span>Enter the login verification token we just sent to your email address.</span>
        </div>
      </div>
      <div className="form_wrapper">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Verification Code</Form.Label>
            <div className="form_digit_fields">
              <Form.Control type="digit" placeholder="0"/>
              <Form.Control type="digit" placeholder="0"/>
              <Form.Control type="digit" placeholder="0"/>
              <span>-</span>
              <Form.Control type="digit" placeholder="0"/>
              <Form.Control type="digit" placeholder="0"/>
              <Form.Control type="digit" placeholder="0"/>
            </div>
          </Form.Group>
          <div className="foot_note">
            Didn’t get the code?&nbsp;<span>Resend in 4:59</span>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default LoginVerificationPage;