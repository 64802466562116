import { gql } from "@apollo/client";
import { API } from "aws-amplify";

export const GET_STUDENT_VOLUNTEER_WORK = gql`
  query StudentVolunteerWorkQuery {
    listStudentVolunteerWorks (limit: 1000) {
      items {
        createdAt
        hours_spent
        id
        studentID
        organization
        updatedAt
      }
      nextToken
    }
}
`

export const GET_STUDENT_VOLUNTEER_WORK_WITH_TOKEN = gql`
  query StudentVolunteerWorkQuery ($limit: Int, $nextToken: String) {
    listStudentVolunteerWorks (limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        hours_spent
        id
        studentID
        organization
        updatedAt
      }
      nextToken
    }
}
`

export async function AllStudentVolunteerWorks() {
  const resp = await API.graphql({ 
    query: GET_STUDENT_VOLUNTEER_WORK,
  });

  const data = resp?.data?.listStudentVolunteerWorks
  let nextToken = data?.nextToken
  let volunteerWork = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_STUDENT_VOLUNTEER_WORK_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listStudentVolunteerWorks

    const previousData = [...volunteerWork, ...respData.items]
    volunteerWork = [...previousData];

    nextToken = respData?.nextToken
  }
  
  return volunteerWork || [];
}


export const GET_SINGLE_STUDENT_VOLUNTEER_WORK = gql`
  query StudentVolunteerWorkQuery($studentID: ID) {
    listStudentVolunteerWorks (filter: {studentID: {eq: $studentID}}, limit: 1000) {
      items {
        createdAt
        hours_spent
        id
        studentID
        organization
        updatedAt
      }
    }
}
`

export async function SingleStudentVolunteerWork(id) {
  try {
    const studentVolunteerWorks = await AllStudentVolunteerWorks();
    const studentVolunteerWork = studentVolunteerWorks.filter((elt) => elt.studentID === id);
    return studentVolunteerWork || [];
  } catch (e) {
    console.error(e);
  }
}

