import React, { useState, useEffect } from 'react';
import { 
  ChevronUp, 
  ChevronDown,
  InfoCircle
} from "react-bootstrap-icons";
import ModalWrapper from "../../widgets/Modal/modal";
import CustomisedButton from "../../widgets/Button/Button";
import OpportunitiesModalTopSection from "./OpportunitiesModalTopSection";
import { formatDateForSessions, getSchoolState } from "../../utils/helpers";
import { GetCategoryWithInterests } from "../../services/query/categories";


function RightModal(props) {

  const { 
    show, 
    setShow, 
    item 
  } = {...props}
  
  const toggle = (id) => {
    const obj = expands[id]
    obj.state = !obj.state
    obj.icon = icons[`${obj.state}`]

    setExpands({
      ...expands,
      [id]: obj
    })
  }

  const [categories, setCategories] = useState([])
  const [expands, setExpands] = useState({
    "oppInfo": {
      "state": true,
      "icon": <ChevronUp/>
    },
    "interests": {
      "state": true,
      "icon": <ChevronUp/>
    }
  })

  const icons = {
    "true": <ChevronDown/>,
    "false": <ChevronUp/>
  }


  useEffect(() => {
    GetCategoryWithInterests().then((category) => {
      const cats = []
      category?.forEach((cate)=>{
        cats.push({
          [cate?.name]: cate?.interests?.map((interest) => interest?.name)
        })
      })

      const itemInterests = {}
      for (let dict of cats){
        let values = Object.values(dict)[0]
        let key = Object.keys(dict)[0]
        
        if (item?.interests?.some(el => values.includes(el))){
          itemInterests[key] = item?.interests?.filter(m => values?.includes(m))
        }
      }

      setCategories(itemInterests)
    });
  }, [item])
  
   
  
  return ( 
    <ModalWrapper
      state={`${show ? "show" : ""}`}
    >
      <div 
        className="right_modal overflow-auto"
        style={{height: "100%"}}
      >
        <OpportunitiesModalTopSection
          setShow={setShow}
          item={item}
        />
        <div className="content_main mt-40">
          <div className="mbd pb-20">
            <h6 className="opportunity-info-label">Description:</h6>
            <h6 className="opportunity-info-text">
              {item?.description}
            </h6>
          </div>
          <div className="mt-20 mbd pb-20">
            <div className="flex-between">
              <h6 className="opportunity-info-header">
                OPPORTUNITY INFORMATION
              </h6>
              <div 
                className="align-items"
                onClick={() => toggle("oppInfo")}
              >
                {expands.oppInfo.icon}
              </div>
            </div>
            <div 
              className={`${expands.oppInfo.state ? "show" : "hide"} mt-10`}
            >
              <div className="mt-10">
                <h6 className="opportunity-info-label">Location:</h6>
                <h6 className="opportunity-info-text">
                  {getSchoolState(item?.resource?.school?.full_address)}
                </h6>
              </div>
              <div className="mt-3">
                <h6 className="opportunity-info-label">Published:</h6>
                <h6 className="opportunity-info-text">
                  {formatDateForSessions(item?.createdAt)}
                </h6>
              </div>
              <div className="mt-3">
                <h6 className="opportunity-info-label">
                  Closing Date:
                </h6>
                <h6 className="opportunity-info-text">
                  {formatDateForSessions(item?.expiry_date)}
                </h6>
              </div>
            </div>
          </div>
          <div className="mt-20">
            <div className="flex-between">
              <h6 className="opportunity-info-header">
                INTERESTS
              </h6>
              <div 
                className="align-items"
                onClick={() => toggle("interests")}
              >
                {expands.interests.icon}
              </div>
            </div>
            <div
              className={`${expands.interests.state ? "show" : "hide"} mt-10`}
            >
              {Object.keys(categories)?.map((key, index) =>
                <div className="mt-4" key={`interest-${index}`}>
                  <div className="d-flex align-items-center mb-2">
                    <h6 
                      className="opportunity-info-label mb-0 mr-10"
                    >
                      {key}
                    </h6>
                    <InfoCircle fill="#969CA5"/>
                  </div>
                  <div className="flex-start custom-flex-wrap gap-2">
                  {categories[key]?.map((interest, index) =>
                    <CustomisedButton 
                      text={interest} 
                      bg="#FFE3B3"
                      fg="#554223"
                      bd="1px solid #FFC768"
                      rd="25px"
                      pd="5px 10px"
                      key={`btn-${index}`}
                    />
                  )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

export default RightModal;