import { gql } from "@apollo/client";

export const CREATE_EXPERIENCE_SESSION = gql`
  mutation CreateExperienceSessionMutation(
    $date: AWSDate!
    $end_time: AWSTime!
    $slots: Int
    $start_time: AWSTime!
  ) {
    createExperienceSession(
      input: {
        date: $date
        end_time: $end_time
        slots: $slots
        start_time: $start_time
      }
    ) {
      id
      date
    }
  }
`;
