import React, { useRef, useState, useEffect } from "react";
import { ChevronDown, ChevronUp, People } from "react-bootstrap-icons";
import { ReactComponent as Sales } from "../../assets/icons/sales.svg";
import {
  experienceStatusButton,
  formatDate,
} from "../../utils/Experiences/utils";
import {
  calculateTimeWithPmOrAm,
  formatDatePurchaseStyle,
} from "../../utils/helpers";
import CustomisedButton from "../../widgets/Button/Button";
import { axiosClient } from "../../libs/axiosClient";

function PurchasesModal({ item }) {
  const contentRef = useRef(null);
  const [purchase, setPurchase] = useState({});

  const getSessionDuration = (session) => {
    if (session) {
      const dateTimeStart = new Date(
        `${session?.date}T${session?.start_time}:00`,
      );
      const dateTimeEnd = new Date(`${session?.date}T${session?.end_time}:00`);

      return dateTimeEnd.getHours() - dateTimeStart.getHours();
    }

    return 0;
  };

  const collapse = (state, index, icon) => {
    if (state) {
      const getSect = contentRef.current?.children[index].children[1];
      getSect.style.display = "none";
    } else {
      const getSect = contentRef.current?.children[index].children[1];
      getSect.style.display = "block";
    }

    if (icon === "down") {
      const getSect = contentRef.current?.children[index].children[0];
      const getIcons = getSect.children[1].children;
      getIcons[0].style.display = "none";
      getIcons[1].style.display = "block";
    } else if (icon === "up") {
      const getSect = contentRef.current?.children[index].children[0];
      const getIcons = getSect.children[1].children;
      getIcons[1].style.display = "none";
      getIcons[0].style.display = "block";
    }
  };

  const experienceTypes = {
    inperson: "In-Person Experience",
    online: "Online Experience",
  };

  const getPurchase = async (id) => {
    try {
      const resp = await axiosClient.get(`/purchases/${id}`);
      setPurchase(resp.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (item?.id) {
      getPurchase(item?.id);
    }
  }, [item?.id]);

  return (
    <div className="pd-20 overflow-auto" style={{ height: "100%" }}>
      <div className="main_content" style={{ marginBottom: 30 }}>
        <div className="" ref={contentRef}>
          <div className="sect_wrapper mt-20">
            <div className="sect flex-between mt-20">
              <span className="mt-20">PURCHASE DETAILS</span>
              <div className="mt-20">
                <ChevronDown
                  style={{ display: "none" }}
                  onClick={() => collapse(false, 0, "down")}
                />
                <ChevronUp onClick={() => collapse(true, 0, "up")} />
              </div>
            </div>
            <div className="sect_main">
              <div className="mbd pb-20">
                <div className="sect_details mt-20">
                  <div className="sect_details_title">Status</div>
                  <div className="sect_details_sub_title">
                    {experienceStatusButton(purchase?.status)}
                  </div>
                </div>
                <div className="flex-between" style={{ width: "80%" }}>
                  <div className="sect_details mt-20">
                    <div className="sect_details_title">Total Amount</div>
                    <div className="sect_details_sub_title">
                      {purchase?.amount} JC
                    </div>
                  </div>
                  <div className="sect_details mt-20">
                    <div className="sect_details_title">Date of Purchase</div>
                    <div className="sect_details_sub_title">
                      {formatDatePurchaseStyle(purchase?.createdAt)}
                    </div>
                  </div>
                </div>
                <div className="mt-20">
                  <CustomisedButton
                    text="Cancel Order and Initiate Refund"
                    fg="#D41811"
                    bg="rgba(251, 213, 213, 0.32)"
                    bd="#FBD5D5"
                    rd="10px"
                    pd="10px 10px"
                    fw="700"
                    width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="sect_wrapper mt-20">
            <div className="sect flex-between mt-20">
              <span className="mt-20">EXPERIENCES PURCHASED</span>
              <div className="mt-20">
                <ChevronDown
                  style={{ display: "none" }}
                  onClick={() => collapse(false, 1, "down")}
                />
                <ChevronUp onClick={() => collapse(true, 1, "up")} />
              </div>
            </div>
            <div className="sect_main mt-20">
              <div className="modal_card">
                <div className="card_top mbd flex-start">
                  <div className="mr-10">
                    <img
                      src={item?.experience?.image}
                      alt=""
                      style={{ width: 90, height: 90 }}
                    />
                  </div>
                  <div className="flex-v">
                    <span>{item?.experience?.title}</span>
                    <span style={{ fontSize: 14 }}>
                      {experienceTypes[item?.experience?.type]}
                    </span>
                    <span className="mt-10">
                      <Sales className="mr-10" />
                      <span className="">
                        {purchase?.experience?.price_per_person} per person/slot
                      </span>
                    </span>
                  </div>
                </div>
                {purchase?.experience?.sessions?.map((session, index) => (
                  <>
                    <div className="main_content">
                      <h6 style={{ color: "#969CA5" }}>Session #{index + 1}</h6>
                      <div className="flex-between">
                        <div className="flex-v" style={{ width: "70%" }}>
                          <h6 className="bold">{formatDate(session?.date)}</h6>
                          <h6>
                            {calculateTimeWithPmOrAm(session?.start_time)}-
                            {calculateTimeWithPmOrAm(session?.end_time)} (
                            {getSessionDuration(session)} hr)
                          </h6>
                        </div>
                        <div className="icon_text align-items">
                          <People className="mr-10" />
                          <span>
                            {session?.slots} slot
                            {session?.slots > 1 ? "s" : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="bottom_text flex-between">
                      <span>Sub-total</span>
                      <span>
                        <Sales />
                        {session?.slots *
                          parseFloat(purchase?.experience?.price_per_person)}
                      </span>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchasesModal;
