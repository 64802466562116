import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { ArrowUpCircleFill } from "react-bootstrap-icons";
import { Briefcase, Building, People } from "react-bootstrap-icons";
import { AllStudents } from "../../services/query/students";
import { AllSchools } from "../../services/query/school";
import { WeekData, getDataByDate } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { PageContext } from "../../templates/DashBoardTemplate";

function Cards() {
  const user = useSelector((store) => store.adminUser?.data);
  const [youths, setYouths] = useState([]);
  const [institutions, setInstitutions] = useState([]);
  const [resources, setResources] = useState([]);
  const [filteredYouths, setFilteredYouths] = useState([]);
  const [filteredInstitutions, setFilteredInstitutions] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const { filterByDate } = useContext(PageContext);

  const navigate = useNavigate();

  const hasAccess = (module) => {
    const moduleObj = user?.modules?.find((elt) => elt.name === module);
    return (
      user?.role?.edit?.includes(moduleObj.id) ||
      user?.role?.read?.includes(moduleObj.id)
    );
  };

  useEffect(() => {
    AllStudents().then((data) => {
      setYouths(data)
      setFilteredYouths(data)
    });
    AllSchools().then((schools) => {
      setResources(schools.filter((elt) => elt.type === "resource"));
      setFilteredResources(schools.filter((elt) => elt.type === "resource"))
      setInstitutions(schools.filter((elt) => elt.type === "mentor"))
      setFilteredInstitutions(schools.filter((elt) => elt.type === "mentor"))
    });
  }, []);

  useEffect(() => {
    if (filterByDate?.length){
      setFilteredYouths(getDataByDate(youths, ...filterByDate))
      setFilteredInstitutions(getDataByDate(institutions, ...filterByDate))
      setFilteredResources(getDataByDate(resources, ...filterByDate))
    }
    else {
      setFilteredYouths(youths)
      setFilteredInstitutions(institutions)
      setFilteredResources(resources)
    }
  }, [filterByDate])
  

  return (
    <Row className="gap-4 cards_container">
      <Col 
        className="cursor-pointer"
        onClick={() => navigate(!hasAccess("Users") ? "/dashboard" : "/youth")}
      >
        <div className="card_icon_wrapper">
          <People size={24} />
        </div>
        <div className="card_category">
          <div className="card_category_title">
            <h4>Youth</h4>
          </div>
          <h1>{filteredYouths?.length}</h1>
        </div>
        <div className="card_foot_note">
          <p>See all {">"} </p>
          <div className="right_details">
            <div className="right_details_icon_wrapper">
              <ArrowUpCircleFill color="#226E54" />
            </div>
            <p>{WeekData(youths)?.length || 0} new this week</p>
          </div>
        </div>
      </Col>
      <Col
        className="cursor-pointer"
        onClick={() =>
          navigate(!hasAccess("Users") ? "/dashboard" : "/resources")
        }
      >
        <div className="card_icon_wrapper">
          <Briefcase size={24} />
        </div>
        <div className="card_category">
          <div className="card_category_title">
            <h4>Resources</h4>
          </div>
          <h1>{filteredResources?.length}</h1>
        </div>
        <div className="card_foot_note">
          <p>See all {">"} </p>
          <div className="right_details">
            <div className="right_details_icon_wrapper">
              <ArrowUpCircleFill color="#226E54" />
            </div>
            <p>{WeekData(resources)?.length || 0} new this week</p>
          </div>
        </div>
      </Col>
      <Col
        className="cursor-pointer"
        onClick={() =>
          navigate(!hasAccess("Users") ? "/dashboard" : "/councelors")
        }
      >
        <div className="card_icon_wrapper">
          <Building size={24} />
        </div>
        <div className="card_category">
          <div className="card_category_title">
            <h4>Institutions</h4>
          </div>
          <h1>{filteredInstitutions?.length}</h1>
        </div>
        <div className="card_foot_note">
          <p>See all {">"} </p>
          <div className="right_details">
            <div className="right_details_icon_wrapper">
              <ArrowUpCircleFill color="#226E54" />
            </div>
            <p>{WeekData(institutions)?.length || 0} new this week</p>
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default Cards;
