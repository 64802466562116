import { gql } from "@apollo/client";
import { API } from "aws-amplify";
import { GetSingleSchool } from "./school";

export const GET_RESOURCES = gql`
  query AllResources {
    listResources (limit: 1000) {
      items {
        id
        verification_status
        verification_progress_dates
        verification_progress
        verification_date_started
        State
        verifier
        full_address
        address
        connections
        contact_email
        contact_phone
        createdAt
        date_deactivated
        date_requested
        deactivated
        deactivation_detailed_reason
        deactivation_period
        deactivation_reason
        description
        field
        isAdmin
        is_verified
        job_title
        naics_code
        naics_title
        name
        image
        credits
        organizationID
        team_size
        updatedAt
        website
        zipcode
      }
      nextToken
    }
  }
`;

export const GET_RESOURCES_WITH_TOKEN = gql`
  query AllResources ($limit: Int, $nextToken: String) {
    listResources (limit: $limit, nextToken: $nextToken) {
      items {
        id
        verification_status
        verification_progress_dates
        verification_progress
        verification_date_started
        State
        address
        connections
        contact_email
        contact_phone
        createdAt
        date_deactivated
        date_requested
        deactivated
        deactivation_detailed_reason
        deactivation_period
        deactivation_reason
        verifier
        description
        field
        isAdmin
        is_verified
        job_title
        naics_code
        naics_title
        name
        image
        credits
        full_address
        organizationID
        team_size
        updatedAt
        website
        zipcode
      }
      nextToken
    }
}
`

export async function AllResources() {
  const resp = await API.graphql({ 
    query: GET_RESOURCES,
  });

  const data = resp?.data?.listResources
  let nextToken = data?.nextToken
  let resources = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_RESOURCES_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listResources

    const previousData = [...resources, ...respData.items]
    resources = [...previousData];

    nextToken = respData?.nextToken
  }

  return await resources?.reduce(async (previousPromise, currentResource) => {
    const school = await GetSingleSchool(currentResource.organizationID);
    let resourceData = await previousPromise;
    const obj = { ...currentResource, school };
    resourceData.push(obj);
    return resourceData;
  }, Promise.resolve([]));
}

export const GET_RESOURCE = gql`
  query SingleResource($id: ID!) {
    getResource(id: $id) {
      id
      verification_status
      verification_progress_dates
      verification_progress
      verification_date_started
      State
      address
      connections
      contact_email
      contact_phone
      createdAt
      verifier
      date_deactivated
      date_requested
      deactivated
      deactivation_detailed_reason
      deactivation_period
      deactivation_reason
      description
      field
      full_address
      isAdmin
      is_verified
      job_title
      naics_code
      naics_title
      name
      image
      credits
      organizationID
      team_size
      updatedAt
      website
      zipcode
    }
  }
`;


export async function GetResource(id) {
  try {

    const respResource = await API.graphql({
      query: GET_RESOURCE,
      variables: { id },
    });
    const resource = respResource?.data?.getResource;
    const school = await GetSingleSchool(resource?.organizationID);
    return { ...resource, school };
  } catch (err) {
    return {};
  }
}
