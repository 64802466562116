import React, { useState } from "react";
import { ReactComponent as Empty } from "../../assets/icons/Group.svg";
import { StarFill } from "react-bootstrap-icons";
import AddExperiences from "./AddExperiences";
import LeftModal from "../Youth/LeftModal/LeftModal";

function ActiveTab({ experiences }) {
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const experiencesNotArchived = experiences?.filter(
    (elt) => new Date(elt?.closing_date) > new Date(),
  );
  const emptyItem = (
    <div
      className="empty_grid_item flex-center"
      onClick={() => setShowExperienceModal(true)}
    >
      <div className="flex-v flex-center">
        <Empty />
        <h6 className="descriptive_text">Add New Experience</h6>
      </div>
    </div>
  );

  const returnEmpty = (data) => {
    if (data) {
      const empties = [];

      const remainder = data.length % 12;
      if (remainder !== 0) {
        for (let i = 0; i < 12 - remainder; i++) {
          empties.push(emptyItem);
        }

        return empties;
      }
    }
    return [];
  };

  const [currentItem, setCurrentItem] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleSelect = (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  return (
    <>
      <div className="active_tab">
        <div className="top_section">
          Showing {experiencesNotArchived?.length} active experiences
        </div>
        <div className="grid_wrapper">
          {experiencesNotArchived?.map((item, index) => (
            <div
              className="item_grid"
              key={index}
              onClick={() => handleSelect(item)}
            >
              <div className="">
                <div className="grid_img">
                  <img src={item?.image} alt="" />
                  <div className="position-absolute top-20  img-tags">
                    {item?.is_featured && (
                      <span className="featured">
                        <StarFill fill="#554223" />
                        <span>Featured</span>
                      </span>
                    )}
                    {item?.is_draft && (
                      <span className="d-flex justify-content-center align-items-center">
                        <span className="draft">Draft</span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-between">
                <div className="flex-v">
                  <span style={{ color: "#2E394C" }}>{item?.title}</span>
                    <span className="subtitle">
                                        {!item?.is_draft ?

                                            `${item.purchases} of 20 slots purchased`
                                          : "Not published yet"}
                    </span>
                </div>
                <div>
                  <span
                    style={{
                      fontSize: "15px",
                      color: "#AA8545",
                      fontWeight: "600",
                    }}
                  >
                    {`${item?.price_per_person} JC`}
                  </span>
                </div>
              </div>
            </div>
          ))}
          <>{returnEmpty(experiencesNotArchived)}</>
        </div>
      </div>
      <LeftModal
        item={currentItem}
        show={showModal}
        setShowModal={setShowModal}
        layout={"experiences"}
      />
      <AddExperiences
        show={showExperienceModal}
        setShow={setShowExperienceModal}
      />
    </>
  );
}

export default ActiveTab;
