import React from 'react';
import { Form } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import "./style.scss"

function SearchInput(props) {

  const {
    bg, 
    placeholder, 
    height, 
    width,
    style,
    className,
    onChange
  } = {...props}

  return ( 
    <div 
      className={`search_input ${className || ''}`}
      style={{
        width: width, 
        backgroundColor: bg,
        height: height,
        ...style
      }}
    >
      <div className="search_icon">
        <Search/>
      </div>
      <Form.Control 
        type="text" 
        placeholder={placeholder}
        style={{backgroundColor: bg}}
        onChange={(e)=>{onChange(e.target.value)}}
      />
    </div>
  );
}

export default SearchInput;