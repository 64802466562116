import React from 'react';
import CustomisedButton from "../../widgets/Button/Button"
import { nameCol } from "../../utils/TableWrapper/tableWrapper"


export const councelorsTeamTabList = [
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Courtney Henry",
    "role": "Administrator",
  },
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Jimmy Hardy",
    "role": "Administrator",
  },
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Courtney Henry",
    "role": "Administrator",
  },
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Jimmy Hardy",
    "role": "Administrator",
  },
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Courtney Henry",
    "role": "Administrator",
  },
  {
    "img": require("../../assets/images/team_1.png"),
    "name": "Jimmy Hardy",
    "role": "Administrator",
  },

]

export const councelorsPaymentTabList = [
  {
    "title": "Subscription_Marian",
    "status": 
      <CustomisedButton 
        text="Successful" 
        bg="#DAF8EE"
        fg="#226E54"
        bd="1px solid #44DDA9"
        rd="25px"
        pd="3px 8px"
        fs="13px"
      />
    ,
    "price": "$250.00",
    "number": <span className="negative-credit">-70 credits</span>,
  },
  {
    "title": "50 Credits",
    "status": 
      <CustomisedButton 
        text="Successful" 
        bg="#DAF8EE"
        fg="#226E54"
        bd="1px solid #44DDA9"
        rd="25px"
        pd="3px 8px"
        fs="13px"
      />
    ,
    "price": "$250.00",
    "number": <span className="negative-credit">-70 credits</span>,
  },
  {
    "title": "50 Credits",
    "status": 
      <CustomisedButton 
        text="Successful" 
        bg="#DAF8EE"
        fg="#226E54"
        bd="1px solid #44DDA9"
        rd="25px"
        pd="3px 8px"
        fs="13px"
      />
    ,
    "price": "$250.00",
    "number": <span className="positive-credit">-70 credits</span>,
  },
  {
    "title": "50 Credits",
    "status": 
      <CustomisedButton 
        text="Successful" 
        bg="#DAF8EE"
        fg="#226E54"
        bd="1px solid #44DDA9"
        rd="25px"
        pd="3px 8px"
        fs="13px"
      />
    ,
    "price": "$250.00",
    "number": <span className="negative-credit">-70 credits</span>,
  },
  {
    "title": "50 Credits",
    "status": 
      <CustomisedButton 
        text="Unsuccessful" 
        bg="rgba(251, 213, 213, 0.4)"
        fg="#D41811"
        bd="1px solid #FF1D15"
        rd="25px"
        pd="3px 8px"
        fs="13px"
      />
    ,
    "price": "$250.00",
    "number": <span className="negative-credit">-70 credits</span>,
  },
]

export const councelorsAccountTabList = [
  {
    "title": "VERIFICATION INFORMATION",
    "innerItem": [
      {
        "key": "Verification Date:",
        "val": "Sunday, March 21, 2022",
      },
      {
        "key": "Verifier:",
        "val": "Theresea Webb",
      },
      {
        "key": "Verification Status:",
        "val": 
          <CustomisedButton 
            text="Verified" 
            bg="#DAF8EE"
            fg="#226E54"
            bd="1px solid #44DDA9"
            rd="25px"
            pd="5px 10px"
          />
        ,
      },
    ]
  },

  {
    "title": "PUBLIC INFORMATION",
    "innerItem": [
      {
        "key": "About Organization:",
        "val": "Ipsum enim eu nibh duis auctor adipiscing eu, proin. Vitae nibh libero ultrices in ut tristique mattis interdum justo. \
                Massa fusce purus, felis at pulvinar arcu. Ultrices arcu convallis elementum, blandit cursus risus morbi consequat auctor. \
                Posuere lobortis risus vitae eget volutpat non posuere. Enim ipsum ipsum viverra proin porta odio viverra sed.",
      },
      {
        "key": "Website",
        "val": "https://achievehartford.org",
      },
    ]
  },

  {
    "title": "ORGANIZATION INFORMATION",
    "innerItem": [
      {
        "key": "Industry:",
        "val": "Technology",
      },
      {
        "key": "Organization Size:",
        "val": "Fewer than 100",
      },
      {
        "key": "Team Size:",
        "val": "2-10",
      },
    ]
  },

  {
    "title": "LOCATION INFORMATION",
    "innerItem": [
      {
        "key": "Location:",
        "val": "Hartford, CT",
      },
      {
        "key": "Office Address:",
        "val": "2118 Thornridge Cir. Hartford",
      },
    ]
  },

  {
    "title": "ACCOUNT INFO",
    "innerItem": [
      {
        "key": "Created By:",
        "val": "Jennifer Pearson",
      },
      {
        "key": "Role:",
        "val": "Administrator",
      },
      {
        "key": "Email",
        "val": "jennypearson0911@mail.com",
      },
      {
        "key": "Phone:",
        "val": "+1 343-647 6271",
      },
    ]
  },

]



export const councelorsYouthTabList = [
  {
    "item": nameCol({
      img: require("../images/team_1.png"),
      name: "Courtney Henry",
      layout: 3,
      bottomText: "18yrs",
      verified: true
    }),
  },
  {
    "item": nameCol({
      img: require("../images/team_1.png"),
      name: "Annette Black",
      layout: 3,
      bottomText: "18yrs",
      verified: true
    }),
  },
  {
    "item": nameCol({
      img: require("../images/team_1.png"),
      name: "Bessie Cooper",
      layout: 3,
      bottomText: "18yrs",
      verified: false
    }),
  },
  {
    "item": nameCol({
      img: require("../images/team_1.png"),
      name: "Ariene McCoy",
      layout: 3,
      bottomText: "18yrs",
      verified: false
    }),
  },
  {
    "item": nameCol({
      img: require("../images/team_1.png"),
      name: "Brooklyn Simmons",
      layout: 3,
      bottomText: "18yrs",
      verified: false
    }),
  },
  {
    "item": nameCol({
      img: require("../images/team_1.png"),
      name: "Leslie Alexander",
      layout: 3,
      bottomText: "18yrs",
      verified: true
    }),
  },
  {
    "item": nameCol({
      img: require("../images/team_1.png"),
      name: "Jacob Jones",
      layout: 3,
      bottomText: "18yrs",
      verified: true
    }),
  },
  {
    "item": nameCol({
      img: require("../images/team_1.png"),
      name: "Ronald Richards",
      layout: 3,
      bottomText: "18yrs",
      verified: true
    }),
  },

]

export const councelorsYouthAgeFilterList = [
  {
    "leftIcon": "",
    "title": "13yrs",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "14yrs",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "15yrs",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "16yrs",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "17yrs",
    "rightIcon": "",
    "divide": false,
  },
]

export const councelorsRecommendationTabList = [
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "title": "Microsoft - Connecticut",
    "time": " 2:59PM",
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
]

export const councelorsActivityTabList = [
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "title": "Microsoft - Connecticut",
    "time": " 2:59PM",
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
]
