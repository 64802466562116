import React from "react";
import LeftModalTopSection from "../Youth/LeftModal/TopSection";
import {
  ChevronDown,
  ChevronUp,
  Trash,
  PlusCircle,
} from "react-bootstrap-icons";
import { useState, useRef, useEffect } from "react";
import CompleteDeactivation from "./CompleteDeactivation";
// import { administratorsActivityList } from "../../assets/data/administrators";
import NewItem from "./Modal/NewItem";
import { axiosClient } from "../../libs/axiosClient";
import EditAdministrators from "./Modal/EditAdministrators";
import { useAuth } from "../../templates/DashBoardTemplate";
import {getCities, sendAppNotification} from "../../utils/helpers";

function RightContent(props) {
  const { item, id, color, update, func } = { ...props };
  const { user } = useAuth();

  const handleResponse = (resp) => {
    func(resp);
  };
  const [location, setLocation] = useState("");
  const [locations, setLocations] = useState([]);

  const usCities = getCities({
    keys: [],
    requiresAll: false,
    returnObj: false,
    sort: true
  });
  // const data = {
  //   title: "New Location Assigned!",
  //   message: `${item?.Attributes?.name} has been assigned to ${location}`,
  //   icon: <Building fill="#226E54" size={40} />,
  // };

  const [collapseRequestDetails, setCollapseRequestDetails] = useState(false);
  const [collapseActivity, setCollapseActivity] = useState(false);
  const [collapseLocation, setCollapseLocation] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [deactivationSuccess, setDeactivationSuccess] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const activityTextRef = useRef(null);
  const addBtnRef = useRef(null);

  const itemsReceived = [];
  const [newItems, setNewItems] = useState([]);

  const removeItem = async (id, locationName) => {
    try {
      await axiosClient.post("/remove-location", {
        username: item.Username,
        location: locationName,
      });
      setLocations(locations.filter((elt) => elt !== locationName));
      setItems((current) =>
        current.filter((item, index) => {
          return index !== id;
        }),
      );
      await sendAppNotification(
        user?.sub,
        `${item?.Attributes?.name} has been unassigned from ${locationName}`,
        'error',
        `/administrators`,
        'Click here to view',
        'admin',
        'inapp',
        'mapDanger'
      )
    } catch (err) {
      console.error(err);
    }
  };

  const createItem = (elt, index) => {
    return (
      <div className="grid_item flex-between">
        <div style={{ fontSize: 14 }}>{elt}</div>
        <div className="point" onClick={() => removeItem(index, elt)}>
          <Trash />
        </div>
      </div>
    );
  };

  const [items, setItems] = useState([...itemsReceived]);

  const addItem = async (name) => {
    setItems([...items, createItem(name, items.length)]);
    setLocation(name);
    setLocations((prev) => [...prev, name]);
    closeNewItem();
    try {
      await axiosClient.post("/assign-location", {
        username: item.Username,
        location: name,
      });
      // setShowToast(true);
      await sendAppNotification(
        user?.sub,
        `${item?.Attributes?.name} has been assigned to ${name}`,
        'success',
        '/administrators',
        'Click here to view',
        'admin',
        'inapp',
        'mapSuccess'
      )
    } catch (err) {
      console.error(err);
    }
  };

  const collapse = (state, sect) => {
    if (sect === "request_details") {
      setCollapseRequestDetails(state);
    }

    if (sect === "locations") {
      setCollapseLocation(state);
    } else {
      setCollapseActivity(state);
    }
  };

  const closeNewItem = () => {
    setNewItems([]);
    addBtnRef.current.style.display = "flex";
  };

  const handleNewItems = () => {
    addBtnRef.current.style.display = "none";
    setNewItems([
      ...newItems,
      <NewItem
        addItem={addItem}
        closeNewItem={closeNewItem}
        key={newItems.length}
        data={usCities}
        locations={locations}
      />,
    ]);
  };

  useEffect(() => {
    if (deactivationSuccess) {
      activityTextRef.current.innerText =
        "1 more super admin needs to approve this request.";
      setShowToast(true);
    }
  }, [deactivationSuccess]);

  useEffect(() => {
    if (item && item?.Attributes["custom:locations"]) {
      const locationsArr = JSON.parse(
        item?.Attributes["custom:locations"] || "[]",
      );
      const locationItems = locationsArr.map((elt, index) =>
        createItem(elt, index),
      );
      setItems(locationItems);
      setLocations(locationsArr);
    } else if (item) {
      setItems([]);
    }
  }, [item]);

  return (
    <>
      <div className={`right_main ${deactivate ? "hide" : "show"}`}>
        <LeftModalTopSection
          item={{ color, ...item }}
          show={true}
          layout="administrators"
          func={handleResponse}
          btnCallback={() => setShowEditModal(true)}
        />

        <div className="view_account_main">
          <div className="sect flex-between pd-20 mt-20">
            ACCOUNT INFORMATION
            <div>
              <ChevronDown
                onClick={() => collapse(false, "request_details")}
                className={`${collapseRequestDetails ? "show" : "hide"}`}
              />
              <ChevronUp
                onClick={() => collapse(true, "request_details")}
                className={`${collapseRequestDetails ? "hide" : "show"}`}
              />
            </div>
          </div>

          <div
            className={`sect_items ${collapseRequestDetails ? "hide" : "show"}`}
          >
            <div className="sect_details pd-20 mt-20">
              <div className="sect_item">
                <h5>Email</h5>
                <h6>{item?.Attributes?.email}</h6>
              </div>
              <div className="sect_item">
                <h5>Work Phone no</h5>
                <h6>{item?.Attributes?.phone_number}</h6>
              </div>
              <div className="sect_item">
                <h5>Admin Status</h5>
                <h6>
                  {item?.UserStatus === "CONFIRMED"
                    ? "Verified"
                    : "Not Verified"}
                </h6>
              </div>
            </div>
          </div>

          <div className="sect flex-between pd-20 mt-20">
            ASSIGNED LOCATIONS
            <div>
              <ChevronDown
                onClick={() => collapse(false, "locations")}
                className={`${collapseLocation ? "show" : "hide"}`}
              />
              <ChevronUp
                onClick={() => collapse(true, "locations")}
                className={`${collapseLocation ? "hide" : "show"}`}
              />
            </div>
          </div>

          <div
            className={`sect_items pd-20 mt-10 ${
              collapseLocation ? "hide" : "show"
            }`}
          >
            <div className="locations_grid">
              <div className="d-flex locations">{items}</div>
              <div
                className="add-location mt-3 flex-start point"
                ref={addBtnRef}
                onClick={handleNewItems}
              >
                <div style={{ fontSize: 14 }} className="align-items mr-10">
                  Add new location
                </div>
                <div>
                  <PlusCircle />
                </div>
              </div>
            </div>
            <div className="my-3">{newItems}</div>
          </div>

          <div className="sect flex-between pd-20 mt-20">
            ACTIVITY
            <div>
              <ChevronDown
                onClick={() => collapse(false)}
                className={`${collapseActivity ? "show" : "hide"}`}
              />
              <ChevronUp
                onClick={() => collapse(true)}
                className={`${collapseActivity ? "hide" : "show"}`}
              />
            </div>
          </div>

          <div className={`pd-20 mt-10 ${collapseActivity ? "hide" : "show"}`}>
            <div className="resources_activity_tab ">
              <div className="flex-between">
                <div className="align-items">Showing recent activities</div>
                <div className="flex-between pd-20 all_time_drop">
                  All time
                  <ChevronDown size={13} className="icon_down" />
                </div>
              </div>

              <div className="activity_main mt-20">
                <div className="activity_main_header">Today</div>
                {/*{administratorsActivityList.map((item, index) =>*/}
                {/*<div*/}
                {/*  className='activity_content mt-20'*/}
                {/*  key={index}*/}
                {/*>*/}
                {/*  <div className="flex-between">*/}
                {/*    <div className='bold' style={{fontSize: 16}}>*/}
                {/*      {item.header}*/}
                {/*    </div>*/}
                {/*    <div*/}
                {/*      className='activity_content_right'*/}
                {/*      style={{fontSize: 16}}*/}
                {/*    >*/}
                {/*      {item.time}*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*)}*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CompleteDeactivation
        deactivate={deactivate}
        setDeactivate={setDeactivate}
        status={setDeactivationSuccess}
        setToast={setShowToast}
        setStatus={update}
        id={id}
      />
      {/* <ToastContainerComponent
        setToastShow={setShowToast}
        toastShow={showToast}
        data={data}
      /> */}
      <EditAdministrators
        show={showEditModal}
        setShow={setShowEditModal}
        item={item}
      />
    </>
  );
}

export default RightContent;
