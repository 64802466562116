
import { Tabs, Tab } from "react-bootstrap";
import React, { useState } from 'react';
import AccountTab from "../Resources/AccountTab";
import TeamTab from "../Resources/TeamTab";
import ActivityTab from "../Resources/ActivityTab";
import PaymentTab from "../Resources/PaymentTab";
import { councelorsActivityTabList, councelorsPaymentTabList, councelorsRecommendationTabList, councelorsTeamTabList, councelorsYouthTabList } from "../../assets/data/councelorsTabList";
import YouthTab from "./YouthTab";
import { resourcesAccountTabList } from "../../assets/data/resourcesTabList";


function CouncelorsLeftModalTabs(props) {

  const { display, items, locationId, details } = {...props}

  const [key, setKey] = useState('account');

  return (
    <div 
      className="councelors_tabs tabs_wrapper"
      style={{display: display ? "block" : "none"}}
    >
      <Tabs
        id="councelors_tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="account" title="Account">
          <AccountTab 
            items={items}
            locationId={locationId}
            layout="councelors"
            orgs={details}
            data={resourcesAccountTabList(details, locationId, "mentor")}
          />
        </Tab>
        <Tab eventKey="youth" title="Youth">
          <YouthTab
            item={details}
            locationId={locationId}
            data={councelorsYouthTabList}
          />
        </Tab>
        <Tab eventKey="team" title="Team">
          <TeamTab
            className={"councelors"}
            item={details}
            locationId={locationId}
            data={councelorsTeamTabList}
          />
        </Tab>
        <Tab eventKey="recommendation" title="Recommendation">
          <ActivityTab
            layout="2"
            list={councelorsRecommendationTabList}
          />
        </Tab>
        <Tab eventKey="activity" title="Activity">
          <ActivityTab
            layout="1"
            cls="councelors_payment"
            list={councelorsActivityTabList}
          />
        </Tab>
        <Tab eventKey="payment" title="Payment">
          <PaymentTab
            cls="councelors_payment"
            details={details}
            data={councelorsPaymentTabList}
          />
        </Tab>
      </Tabs>
    </div> 
  );
}

export default CouncelorsLeftModalTabs;
