import SearchInput from "../../widgets/SearchInput/searchInput";
import React, {useEffect, useState} from 'react';
import { Funnel } from "react-bootstrap-icons";
import {TeamDropdown, TeamFilterSectionDrop} from "../../widgets/Dropdown/dropdown";
import {axiosClient} from "../../libs/axiosClient";
import {CreateTeamDetailCard} from "../../utils/AccountVerfication/utils";


function TeamTab({className, locationId}) {

  const [state, setState] = useState(false);
  const [teamDrop, setTeamDrop] = useState(false);
  const [filters, setFilters] = useState({});

  const [users, setUsers] = useState([]);

  const toggleDrop = (clear) => {
    if (clear){setFilters({})}
    setState(!state)
  }

  const setData = (id, data) => {
    setFilters({...filters, [id]:data})
  }

  const getOrganizationTeam = async () => {
    try {
      const resp = await axiosClient.post('/get-team', {
        schoolID: locationId,
        type: className !== "resources" ? "mentors" : "resources"
      });
      setUsers(resp.data?.data);
    } catch (e) {
      console.error(e);
    }
  }

  // useEffect(() => {
  //   console.log(filters);
  // }, [filters]);

  useEffect(() => {
    getOrganizationTeam();
  }, [locationId])
  

  return (
    <>
      <div className="resources_team_tab">
        <div className="top_section flex-between">
          <SearchInput
            placeholder="Search for team member"
            bg="rgba(244, 245, 247, 0.64)"
            height="40px"
            width="65%"
          />
          <div className="icon_with_text flex-center">
            <div 
              className={`filter_btn p-20 ${Object.keys(filters).length > 0 ? "filtered" : ""}`}
              onClick={toggleDrop}
            >
              <Funnel size={20}/>
              <span className="ml-10">
                Filters&nbsp;
                {
                  Object.keys(filters).length > 0 
                    ? `(${Object.keys(filters).length} selected)`
                    : ""
                }
              </span>
            </div>
          </div>
        </div>
        <div className="main_content mt-10">
          {users.map((item, index) =>
              <CreateTeamDetailCard
                  item={item}
                  index={index}
                  key={`card-team-${index}`}
                  callBack={() => setTeamDrop(prev => !prev)}
              />
          )}
          <TeamFilterSectionDrop
              display={state}
              func={toggleDrop}
              setData={setData}
              filters={filters}
              className={className}
          />
          <TeamDropdown
              display={teamDrop}
              func={() => console.log("man")}
              setData={() => console.log("man")}
          />
        </div>
      </div>
    </>
  );
}

export default TeamTab;