import React from 'react';
import { Form } from "react-bootstrap";
import { Arrow90degLeft } from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";


function CompleteDeactivation(props) {

  const {
    deactivate, setStatus,
    status, setDeactivate, 
    id
  } = {...props}

  const handleDeactivate = () => {
    status(true)
    setDeactivate(false)
    setStatus(id, "In progress")
  }

  return ( 
    <div className={`p-20 ${deactivate ? "show" : "hide"}`}>
    <div 
      className="return_btn p-20"
      onClick={() => setDeactivate(false)}
    >
      <Arrow90degLeft className="mr-10"/>
      <span>Back to Request Details</span>
    </div>
    <div className="top_text request_deactivation_top_text p-20">
      <h4 className="bold">De-activate Devon’s Account?</h4>
      <h6>
        De-activation will suspend all of Devon’s 
        opportunities and connections for the specified period.
      </h6>
    </div>
    <div className="p-20">
      To confirm, please type 
      DEACTIVATE in all caps below:
    </div>
    <div className="type_deactivate mt-10 p-20">
      <Form.Control 
        placeholder="Type DEACTIVATE"
      />
    </div>
    <div className="flex-between p-20 mt-10">
      <div className="align-items">
        Enter Verification Code
      </div>
      <div>
        <CustomisedButton
          text="Request Code"
          fg="#515A6A"
          bg="#fff"
          bd="1px solid #DDDFE2"
          pd="10px 10px"
        />
      </div>
    </div>
    <div className="form_wrapper ph-10">
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <div className="form_digit_fields">
            <Form.Control type="digit" placeholder="0"/>
            <Form.Control type="digit" placeholder="0"/>
            <Form.Control type="digit" placeholder="0"/>
            <span className="align-items">-</span>
            <Form.Control type="digit" placeholder="0"/>
            <Form.Control type="digit" placeholder="0"/>
            <Form.Control type="digit" placeholder="0"/>
          </div>
        </Form.Group>
        <div className="mt-20 foot_text">
          Since you are the first super admin to approve 
          this request, another super admin still has 
          to approve before this user account can be de-activated.
        </div>
      </Form>
    </div>
    <div className="mt-20 ph-10">
      <div className="sect_item btns">
        <CustomisedButton 
          width="100%" 
          height="40px" 
          bg="#D41811"
          bd="1px solid #D41811"
          text="De-activate Account"
          fg="#fff"
          fs="15px"
          fw="700"
          onClick={handleDeactivate}
        />
        <CustomisedButton 
          width="100%" 
          height="40px" 
          bg="white"
          bd="1px solid #DDDFE2"
          text="Cancel"
          fg="#515A6A"
          fs="15px"
          fw="700"
          onClick={() => {setDeactivate(false), setStatus(id, "Not started")}}
        />
      </div>
    </div>
  </div>
  );
}

export default CompleteDeactivation;