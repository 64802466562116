import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Modal } from 'react-bootstrap';
import Notifications from './Notifications';
import './styles.css';

const NotificationPopup = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState('notification');
  useImperativeHandle(ref, () => ({
    showPopup(params) {
      setOpen(true);
      setOption(params);
    }
  }));
  const hidePopup = () => {
    setOpen(false);
  };
  const openSettings = (params) => {
    setOption(params);
  };
  return (
    <>
      <Modal size="md" show={open} onHide={hidePopup} centered className="rounded--lg right">
        <Modal.Body className="rounded--lg overflow-hidden">
          <Notifications default="all" hidePopup={hidePopup} openSettings={openSettings} />
        </Modal.Body>
      </Modal>
    </>
  );
});

NotificationPopup.displayName = 'NotificationPopup';

export default NotificationPopup;
