import React, { useState, useEffect, useRef } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { toast } from "react-toastify";
import {
  ChevronDown,
  ChevronUp,
  Check,
} from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";
import {
  setColor,
  formatDate,
  TYPES,
} from "../../utils/AccountVerfication/utils";
import AccountVerificationTopSection from "./AccountVerificationTopSection";
import DeactivationModal from "./DeactivationModal";
import { UPDATE_EDUCATOR } from "../../services/mutation/educator";
import { UPDATE_RESOURCE } from "../../services/mutation/resources";
import { useMutation } from "@apollo/client";
import {
  isPreviousStepVerified,
  toggleCheck,
} from "../../utils/AccountVerfication/utils";
import { useSelector } from "react-redux";
import {UPDATE_SCHOOL} from "../../services/mutation/school";
import { sendAppNotification, sendEmailNotification } from "../../utils/helpers";

function AccountVerificationTab(props) {
  const { layout, details, modal, detailsRefetch } = { ...props };
  
  const [updateCounselors] = useMutation(UPDATE_EDUCATOR);
  const [updateResources] = useMutation(UPDATE_RESOURCE);
  const [updateSchool] = useMutation(UPDATE_SCHOOL);
  const [dateStarted, setDateStarted] = useState(null);
  const [toastShow, setToastShow] = useState(false);
  const [state, setState] = useState(false);
  const [progressCircleColor, setProgressCircleColor] = useState("");
  const [hideStartBtnDisplay, setHideStartBtnDisplay] = useState(false);
  const [sectMainDisplay, setSectMainDisplay] = useState(true);
  const [arrowState, setArrowState] = useState(false);
  const [percentageProgress, setPercentageProgress] = useState(
    details?.verification_progress || 0,
  );
  const [taskNumber, setTaskNumber] = useState([]);
  const [completion, setCompletion] = useState("initial");
  const [completed, setCompleted] = useState(false);
  const [showDocumentSect, setShowDocumentSect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const documentTitleRef = useRef(null);
  const firstRunRef = useRef(true);
  const user = useSelector((store) => store.adminUser.data);
  const userName = user.userName;

  const toggleDrop = () => {
    setState(!state);
  };

  // const data = {
  //   title: "Verification Complete",
  //   message: `${details?.school?.name} has now been verified on Junity.`,
  //   icon: <Check2Circle fill="#226E54" size={40} />,
  // };

  const UpdateCloud = async(inputData) => {
    if (details?.id) {
      inputData.id = details?.id;
      inputData.verifier = userName;
      
      let isVerified = true;
      if (layout === "councelors") {
        updateCounselors({
          variables: { ...inputData },
        })
        .then(() => {
          detailsRefetch();
        })
        .catch((err) => {
          console.error(err);
          isVerified = false;
        });
      } else {
        updateResources({
          variables: { ...inputData },
        })
          .then(() => {
            detailsRefetch();
          })
          .catch((err) => {
            console.error(err);
            isVerified = false;
          });
      }

      if (inputData?.is_verified) {
        const schoolID = layout === "councelors" ? details?.schoolID : details?.organizationID;
        updateSchool({
          variables: {
            id: schoolID,
            is_verified: inputData?.is_verified
          },
        });
      }
      if (isVerified){
        if (inputData?.is_verified && 
          inputData?.verification_progress === 100 && 
          inputData?.verification_status === 'verified'){
            await sendAppNotification(
              '',
              `${details?.school?.name} has now been verified on Junity.`,
              'success',
              '/account-verification',
              'Click here to view',
              'admin',
              'inapp',
              'checkmarkSuccess'
            )

            let module;
            if (details?.organizationID) {
              module = "resource";
            } else if (details?.dob) {
              module = "youth"
            } else {
              module = "mentor"
            }
            await sendAppNotification(
              details?.id,
              'Congrats!🎉  Your account has successfully been verified.',
              'success',
              '/dashboard',
              'Click here to view',
              module,
              'inapp',
              'checkmarkSuccess'
            )

            await sendEmailNotification({
              template: 'main-verification-complete',
              subject: 'Account Verification',
              email: details?.contact_email || details?.email,
              context: {
                fname: details?.name?.split(' ')[0],
                account: details?.name,
                organization: details?.school?.name,
                moduleUrl: module === 'resource' ? process.env.REACT_APP_RESOURCE_URL : module === 'mentor' ? process.env.REACT_APP_MENTOR_URL : process.env.REACT_APP_YOUTH_URL,
              }
            })

          }
  
      }
      else {
        await sendAppNotification(
          user?.sub,
          `${details?.school?.name} couldn't be verified.`,
          'error',
          `/account-verification`,
          'Click here to view',
          'admin',
          'inapp',
          'slashCircle'
        )
      }
    }
  };

  function HandleChecks(id) {
    // if (!completed) {
      const toggleProps = {
        id: id,
        percentageProgress: percentageProgress,
        taskNumber: taskNumber,
        details: details,
        updateCloud: UpdateCloud,
        setTaskNumber: setTaskNumber,
        setPercentageProgress: setPercentageProgress,
      };
      if (isPreviousStepVerified(taskNumber, id) || id === "1") {
        toggleCheck(toggleProps);
      } else {
        toast("Please verify the previous step");
      }
    // }
  }

  const setProgressStatus = (percentageProgress) => {
    setColor(percentageProgress, setProgressCircleColor);
    if (percentageProgress !== 100) {
      setCompletion("initial");
    } else {
      setCompletion("done");
    }
  };

  const collapse = () => {
    setArrowState(!arrowState);
    setSectMainDisplay(!sectMainDisplay);
  };

  const checkProgress = () => {
    setToastShow(true);
    setCompletion("complete");
    setCompleted(true);
  };

  const HandleBtnClick = () => {
    if (completion === "done") {
      checkProgress();
      const inputData = {
        is_verified: true,
        verification_progress: 100,
        verification_status: "verified",
      };
      UpdateCloud(inputData);
    } else if (completion === "complete") {
      setShowModal(true);
    }
  };

  const handleDocumentSectDisplay = () => {
    setShowDocumentSect(!showDocumentSect);
    let text = "Hide Documents";
    if (!showDocumentSect) {
      documentTitleRef.current.innerText = text;
    } else {
      text = "Show Documents";
      documentTitleRef.current.innerText = text;
    }
  };

  const returnProgressStatus = (currentValue) => {
    if (currentValue) {
      const progress = {
        100: "verified",
        0: "unverified",
      };

      if (Object.keys(progress)?.includes(`${currentValue}`)) {
        return progress[`${currentValue}`];
      } else {
        return "inprogress";
      }
    } else {
      return "unverified";
    }
  };

  const handleRefetch = () => {
    setTaskNumber([]);
    setPercentageProgress(0);
    detailsRefetch();
  };

  useEffect(() => {
    setProgressStatus(percentageProgress);
  }, [percentageProgress]);

  useEffect(() => {
    if (modal) {
      const checked = details?.verification_progress / 20;
      const taskNumberArr = [];
      for (let i = 1; i < checked + 1; i++) {
        taskNumberArr.push(`${i}`);
      }
      setTaskNumber(taskNumberArr);
      setProgressStatus(details?.verification_progress || 0);
      setPercentageProgress(details?.verification_progress || 0);
      setHideStartBtnDisplay(details?.verification_progress !== 0);
    }
  }, [modal]);

  useEffect(() => {
    if (firstRunRef.current) {
      setCompleted(false);
      const initiateUpdate = () => {
        const lastIndex =
          taskNumber?.length === 0 ? -1 : taskNumber?.length - 1;
        const checked = parseInt(lastIndex === -1 ? 0 : taskNumber[lastIndex]);
        const percentChecked = checked * 20;
        if (percentChecked !== 100) {
          const inputData = {
            verification_progress: percentChecked,
            verification_status: returnProgressStatus(percentChecked),
            is_verified: returnProgressStatus(percentChecked) === "verified",
          };
          UpdateCloud(inputData);
        }
      };

      if (taskNumber?.length) {
        initiateUpdate();
      }
      if (details?.is_verified) {
        setCompletion("complete");
        setCompleted(true);
      }
    } else {
      firstRunRef.current = false;
    }
  }, [taskNumber]);

  return (
    <div className="account_verify_content_wrapper">
      <AccountVerificationTopSection
        setDateStarted={setDateStarted}
        layout={layout}
        updateCloud={UpdateCloud}
        hideStartDisplay={hideStartBtnDisplay}
        setHideStartDisplay={setHideStartBtnDisplay}
      />
      {hideStartBtnDisplay ? (
        <div className="verification_process_sect">
          <div className="top_section flex-between">
            <div className="flex-v">
              <h5>
                {percentageProgress === 100
                  ? "READY TO GO"
                  : "VERIFICATION IN PROGRESS"}
              </h5>
              <h6>
                {percentageProgress === 100
                  ? "You finished all verification tasks on the checklist."
                  : `You started verifying this ${layout?.slice(
                      0,
                      layout?.length - 1,
                    )}.`}
              </h6>

              <p className="mt-10">
                {dateStarted ? formatDate(dateStarted) : ""}
              </p>
            </div>
            <div className="circular_progress_bar">
              <CircularProgressbarWithChildren
                value={percentageProgress}
                styles={buildStyles({
                  pathColor: progressCircleColor,
                  trailColor: "#F4F5F7",
                  textSize: "20px",
                })}
              >
                <span className="percentage_text">{percentageProgress}%</span>
                <span className="task_completed">
                  {taskNumber.length}/5 tasks
                </span>
              </CircularProgressbarWithChildren>
            </div>
          </div>

          <div className="mt-20">
            <CustomisedButton
              text={
                <>
                  <span className="mr-10">{TYPES[completion]["text"]}</span>
                  {TYPES[completion]["icon"]}
                </>
              }
              bg={TYPES[completion]["bg"]}
              fg={TYPES[completion]["fg"]}
              bd="none"
              rd="10px"
              pd="8px 10px"
              width="100%"
              onClick={HandleBtnClick}
            />
          </div>

          <div className="sect_wrapper mt-20">
            <div className="sect flex-between mt-20">
              <span className="mt-20">CHECKLIST</span>
              <div className="mt-20">
                <ChevronDown
                  style={{
                    display: arrowState ? "block" : "none",
                  }}
                  onClick={collapse}
                />
                <ChevronUp
                  style={{
                    display: arrowState ? "none" : "block",
                  }}
                  onClick={collapse}
                />
              </div>
            </div>
            {sectMainDisplay && (
              <>
                <div className="sect_main mbd">
                  <div className="sect_details flex-start mt-20">
                    <div>
                      <div
                        className={`check_div ${
                          taskNumber?.includes("1") ? "active" : ""
                        } flex-center mr-10`}
                        onClick={() => HandleChecks("1")}
                      >
                        <Check fill="#fff" />
                      </div>
                    </div>
                    <div className="flex-v sect_text">
                      <div className="sect_details_title">
                        First contact call or e-mail
                      </div>
                      <div>
                        Call the resource to establish a relationship and
                        request other information.
                      </div>
                      <p className="mt-10"></p>
                    </div>
                  </div>
                </div>
                <div className="sect_main mbd">
                  <div className="sect_details flex-start mt-20">
                    <div>
                      <div
                        className={`check_div ${
                          taskNumber?.includes("2") ? "active" : ""
                        } flex-center mr-10`}
                        onClick={() => HandleChecks("2")}
                      >
                        <Check fill="#fff" />
                      </div>
                    </div>
                    <div className="flex-v sect_text">
                      <div className="sect_details_title">
                        Confirm NAICS code
                      </div>
                      <div>Check the NAICS code for authenticity</div>
                      <p className="mt-10"></p>
                    </div>
                  </div>
                </div>
                <div className="sect_main mbd">
                  <div className="sect_details flex-start mt-20">
                    <div>
                      <div
                        className={`check_div ${
                          taskNumber?.includes("3") ? "active" : ""
                        } flex-center mr-10`}
                        onClick={() => HandleChecks("3")}
                      >
                        <Check fill="#fff" />
                      </div>
                    </div>
                    <div className="flex-v sect_text">
                      <div className="sect_details_title">
                        Verify team members’ identity
                      </div>
                      <div>
                        Encourage the resource admin to invite any other team
                        members and then request and upload ID verification
                        documents
                      </div>
                      <p className="mt-10"></p>
                    </div>
                  </div>
                </div>
                <div className="sect_main mbd">
                  <div className="sect_details flex-start mt-20">
                    <div>
                      <div
                        className={`check_div ${
                          taskNumber?.includes("4") ? "active" : ""
                        } flex-center mr-10`}
                        onClick={() => HandleChecks("4")}
                      >
                        <Check fill="#fff" />
                      </div>
                    </div>
                    <div className="flex-v sect_text">
                      <div className="sect_details_title">
                        Upload compliance documents
                      </div>
                      <div>
                        Encourage the resource admin to invite any other team
                        members and then request ID verification documents
                      </div>
                      <p className="mt-10"></p>
                      <div
                        className="account_verify_process_btn flex-between pd-20 all_time_drop mt-10"
                        onClick={handleDocumentSectDisplay}
                      >
                        <span ref={documentTitleRef}>Show Documents</span>
                        <div>
                          {showDocumentSect ? (
                            <ChevronUp size={13} className="icon_down" />
                          ) : (
                            <ChevronDown size={13} className="icon_down" />
                          )}
                        </div>
                      </div>
                      <div className={`${showDocumentSect ? "show" : "hide"}`}>
                        <div
                          className="document_drop flex-between p-20 mt-10"
                          onClick={toggleDrop}
                        >
                          <div className="flex-v">
                            <span className="section_title">
                              <span className="mr-10">Documents</span>
                              {/* <CheckCircleFill fill="#2D9371" /> */}
                            </span>
                            <span className="file_title">0 file uploaded</span>
                          </div>
                          <ChevronDown size={13} className="icon_down" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sect_main">
                  <div className="sect_details flex-start mt-20">
                    <div>
                      <div
                        className={`check_div ${
                          taskNumber?.includes("5") ? "active" : ""
                        } flex-center mr-10`}
                        onClick={() => HandleChecks("5")}
                      >
                        <Check fill="#fff" />
                      </div>
                    </div>
                    <div className="flex-v sect_text">
                      <div className="sect_details_title">
                        Review verification
                      </div>
                      <div>
                        Check that all documents and details are intact.
                      </div>
                      <p className="mt-10"></p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <DeactivationModal
        show={showModal}
        setShow={setShowModal}
        details={details}
        refetch={handleRefetch}
      />
    </div>
  );
}

export default AccountVerificationTab;
