import React from "react";
import ChartData from "./data";
import ReactApexChart from "react-apexcharts";
import { cleanChartData, sameYearData } from "../../utils/helpers";

const sameYearChartDataArray = (data) => {
  return data.filter((elt) => sameYearData(elt) === true);
}

const sameMonthChartDataArray = (data) => {
  return cleanChartData(data.reduce((acc, elt) => {
    const resp = { ...acc };
    const dateCreatedAt = new Date(elt.createdAt);
    if (Object.keys(resp).includes(String(dateCreatedAt.getMonth()))) {
      if (Object.keys(elt).includes("amount"))
        resp[dateCreatedAt.getMonth()] += elt.amount;
      else resp[dateCreatedAt.getMonth()] += elt.amount;
    } else {
      if (Object.keys(elt).includes("amount"))
        resp[dateCreatedAt.getMonth()] = elt.amount;
      else resp[dateCreatedAt.getMonth()] = elt.amount;
    }

    return resp;
  }, {}));
}

const BasicAreaChart = ({ purchases }) => {
  const sameYearPurchaseData = sameYearChartDataArray(purchases);
  const sameMonthPurchasesByData = sameMonthChartDataArray(sameYearPurchaseData);

  const series = [
    { name: "Sales (JC}", data: Object.values(sameMonthPurchasesByData) }
  ];
  const basicoptions = {
    chart: {
      id: "Chart",
      type: "area",
      height: 350,
      zoom: {
        enabled: true
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "straight"
    },

    labels: ChartData.monthDataSeries1.dates,
    xaxis: {
      type: "category",
      categories: ChartData.monthDataSeries1.dates,
      title: {
        text: "Month",
        style: {
          fontSize: "15px",
          color: "#667085"
        }
      }
    },
    colors: [
      '#2D9371', 'rgba(45, 147, 113, 0)', 
      'rgba(127, 86, 217, 0)'
    ],
    yaxis: {
      opposite: false,
      title: {
        text: "Sales ($)",
        style: {
          fontSize: "15px",
          color: "#667085"
        }
      }
    },
    legend: {
      horizontalAlign: "left"
    }
  };

  return (
    <div>
      <ReactApexChart
        options={basicoptions}
        series={series}
        type="area"
        height={350}
      />
    </div>
  );
};

export default BasicAreaChart;
