/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTodo = /* GraphQL */ `
  subscription OnCreateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onCreateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTodo = /* GraphQL */ `
  subscription OnUpdateTodo($filter: ModelSubscriptionTodoFilterInput) {
    onUpdateTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTodo = /* GraphQL */ `
  subscription OnDeleteTodo($filter: ModelSubscriptionTodoFilterInput) {
    onDeleteTodo(filter: $filter) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateInterest = /* GraphQL */ `
  subscription OnCreateInterest(
    $id: ID
    $categoryID: ID
    $name: String
    $description: String
    $createdAt: AWSDateTime
  ) {
    onCreateInterest(
      id: $id
      categoryID: $categoryID
      name: $name
      description: $description
      createdAt: $createdAt
    ) {
      id
      categoryID
      name
      description
      icon_name
      students {
        nextToken
        __typename
      }
      category {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateInterest = /* GraphQL */ `
  subscription OnUpdateInterest(
    $id: ID
    $categoryID: ID
    $name: String
    $description: String
    $createdAt: AWSDateTime
  ) {
    onUpdateInterest(
      id: $id
      categoryID: $categoryID
      name: $name
      description: $description
      createdAt: $createdAt
    ) {
      id
      categoryID
      name
      description
      icon_name
      students {
        nextToken
        __typename
      }
      category {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteInterest = /* GraphQL */ `
  subscription OnDeleteInterest(
    $id: ID
    $categoryID: ID
    $name: String
    $description: String
    $createdAt: AWSDateTime
  ) {
    onDeleteInterest(
      id: $id
      categoryID: $categoryID
      name: $name
      description: $description
      createdAt: $createdAt
    ) {
      id
      categoryID
      name
      description
      icon_name
      students {
        nextToken
        __typename
      }
      category {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateFacilitator = /* GraphQL */ `
  subscription OnCreateFacilitator($id: ID, $name: String) {
    onCreateFacilitator(id: $id, name: $name) {
      id
      name
      bio
      createdAt
      dob
      email
      gender
      jam_status
      personality
      resourceID
      phone
      resourse_status
      state
      updatedAt
      zipcode
      __typename
    }
  }
`;
export const onUpdateFacilitator = /* GraphQL */ `
  subscription OnUpdateFacilitator($id: ID, $name: String) {
    onUpdateFacilitator(id: $id, name: $name) {
      id
      name
      bio
      createdAt
      dob
      email
      gender
      jam_status
      personality
      resourceID
      phone
      resourse_status
      state
      updatedAt
      zipcode
      __typename
    }
  }
`;
export const onDeleteFacilitator = /* GraphQL */ `
  subscription OnDeleteFacilitator($id: ID, $name: String) {
    onDeleteFacilitator(id: $id, name: $name) {
      id
      name
      bio
      createdAt
      dob
      email
      gender
      jam_status
      personality
      resourceID
      phone
      resourse_status
      state
      updatedAt
      zipcode
      __typename
    }
  }
`;
export const onCreateCampaign = /* GraphQL */ `
  subscription OnCreateCampaign($id: ID, $name: String) {
    onCreateCampaign(id: $id, name: $name) {
      id
      resourceID
      budget
      createdAt
      duration
      end_date
      start_date
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCampaign = /* GraphQL */ `
  subscription OnUpdateCampaign($id: ID, $name: String) {
    onUpdateCampaign(id: $id, name: $name) {
      id
      resourceID
      budget
      createdAt
      duration
      end_date
      start_date
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCampaign = /* GraphQL */ `
  subscription OnDeleteCampaign($id: ID, $name: String) {
    onDeleteCampaign(id: $id, name: $name) {
      id
      resourceID
      budget
      createdAt
      duration
      end_date
      start_date
      updatedAt
      __typename
    }
  }
`;
export const onCreateSchool = /* GraphQL */ `
  subscription OnCreateSchool(
    $id: ID
    $contact_email: AWSEmail
    $contact_phone: AWSPhone
    $createdAt: AWSDateTime
    $name: String
  ) {
    onCreateSchool(
      id: $id
      contact_email: $contact_email
      contact_phone: $contact_phone
      createdAt: $createdAt
      name: $name
    ) {
      id
      contact_email
      contact_phone
      createdAt
      name
      state
      updatedAt
      zipcode
      address
      about
      image
      banner
      type
      accepting_connection
      website
      is_verified
      dont_show_again
      counselors_dont_show_again
      connections
      connection_dates
      team_size
      industry
      organization_size
      full_address
      invitations
      invitations_dates
      invitations_names
      Educators {
        nextToken
        __typename
      }
      Students {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateSchool = /* GraphQL */ `
  subscription OnUpdateSchool {
    onUpdateSchool {
      id
      contact_email
      contact_phone
      createdAt
      name
      state
      updatedAt
      zipcode
      address
      about
      image
      banner
      type
      accepting_connection
      website
      is_verified
      dont_show_again
      counselors_dont_show_again
      connections
      connection_dates
      team_size
      industry
      organization_size
      full_address
      invitations
      invitations_dates
      invitations_names
      Educators {
        nextToken
        __typename
      }
      Students {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteSchool = /* GraphQL */ `
  subscription OnDeleteSchool {
    onDeleteSchool {
      id
      contact_email
      contact_phone
      createdAt
      name
      state
      updatedAt
      zipcode
      address
      about
      image
      banner
      type
      accepting_connection
      website
      is_verified
      dont_show_again
      counselors_dont_show_again
      connections
      connection_dates
      team_size
      industry
      organization_size
      full_address
      invitations
      invitations_dates
      invitations_names
      Educators {
        nextToken
        __typename
      }
      Students {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateOpportunity = /* GraphQL */ `
  subscription OnCreateOpportunity {
    onCreateOpportunity {
      automatic_message
      questions
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      id
      name
      remote
      requirements
      likes
      liked_by
      resourceID
      schoolID
      state
      status
      tag
      views
      interests
      interestIDs
      updatedAt
      saved_by
      Applications {
        nextToken
        __typename
      }
      Reviews {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateOpportunity = /* GraphQL */ `
  subscription OnUpdateOpportunity {
    onUpdateOpportunity {
      automatic_message
      questions
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      id
      name
      remote
      requirements
      likes
      liked_by
      resourceID
      schoolID
      state
      status
      tag
      views
      interests
      interestIDs
      updatedAt
      saved_by
      Applications {
        nextToken
        __typename
      }
      Reviews {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteOpportunity = /* GraphQL */ `
  subscription OnDeleteOpportunity {
    onDeleteOpportunity {
      automatic_message
      questions
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      id
      name
      remote
      requirements
      likes
      liked_by
      resourceID
      schoolID
      state
      status
      tag
      views
      interests
      interestIDs
      updatedAt
      saved_by
      Applications {
        nextToken
        __typename
      }
      Reviews {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateResource = /* GraphQL */ `
  subscription OnCreateResource(
    $id: ID
    $State: [String]
    $contact_email: AWSEmail
    $contact_phone: AWSPhone
    $createdAt: AWSDateTime
  ) {
    onCreateResource(
      id: $id
      State: $State
      contact_email: $contact_email
      contact_phone: $contact_phone
      createdAt: $createdAt
    ) {
      id
      State
      contact_email
      contact_phone
      createdAt
      description
      field
      naics_code
      naics_title
      job_title
      name
      deleted
      image
      chat_accessKey
      isAdmin
      address
      full_address
      organizationID
      updatedAt
      connections
      website
      team_size
      twoFA
      zipcode
      is_verified
      stripeID
      hubspotID
      credits
      verification_status
      deactivated
      verification_progress
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      date_deactivated
      __typename
    }
  }
`;
export const onUpdateResource = /* GraphQL */ `
  subscription OnUpdateResource(
    $id: ID
    $State: [String]
    $contact_email: AWSEmail
    $contact_phone: AWSPhone
    $createdAt: AWSDateTime
  ) {
    onUpdateResource(
      id: $id
      State: $State
      contact_email: $contact_email
      contact_phone: $contact_phone
      createdAt: $createdAt
    ) {
      id
      State
      contact_email
      contact_phone
      createdAt
      description
      field
      naics_code
      naics_title
      job_title
      name
      deleted
      image
      chat_accessKey
      isAdmin
      address
      full_address
      organizationID
      updatedAt
      connections
      website
      team_size
      twoFA
      zipcode
      is_verified
      stripeID
      hubspotID
      credits
      verification_status
      deactivated
      verification_progress
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      date_deactivated
      __typename
    }
  }
`;
export const onDeleteResource = /* GraphQL */ `
  subscription OnDeleteResource(
    $id: ID
    $State: [String]
    $contact_email: AWSEmail
    $contact_phone: AWSPhone
    $createdAt: AWSDateTime
  ) {
    onDeleteResource(
      id: $id
      State: $State
      contact_email: $contact_email
      contact_phone: $contact_phone
      createdAt: $createdAt
    ) {
      id
      State
      contact_email
      contact_phone
      createdAt
      description
      field
      naics_code
      naics_title
      job_title
      name
      deleted
      image
      chat_accessKey
      isAdmin
      address
      full_address
      organizationID
      updatedAt
      connections
      website
      team_size
      twoFA
      zipcode
      is_verified
      stripeID
      hubspotID
      credits
      verification_status
      deactivated
      verification_progress
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      date_deactivated
      __typename
    }
  }
`;
export const onCreateApplication = /* GraphQL */ `
  subscription OnCreateApplication(
    $id: ID
    $opportunityID: ID
    $studentID: ID
    $status: String
    $shareprofile: String
  ) {
    onCreateApplication(
      id: $id
      opportunityID: $opportunityID
      studentID: $studentID
      status: $status
      shareprofile: $shareprofile
    ) {
      id
      opportunityID
      studentID
      status
      shareprofile
      createdAt
      category
      campaignID
      application
      updatedAt
      __typename
    }
  }
`;
export const onUpdateApplication = /* GraphQL */ `
  subscription OnUpdateApplication(
    $id: ID
    $opportunityID: ID
    $studentID: ID
    $status: String
    $shareprofile: String
  ) {
    onUpdateApplication(
      id: $id
      opportunityID: $opportunityID
      studentID: $studentID
      status: $status
      shareprofile: $shareprofile
    ) {
      id
      opportunityID
      studentID
      status
      shareprofile
      createdAt
      category
      campaignID
      application
      updatedAt
      __typename
    }
  }
`;
export const onDeleteApplication = /* GraphQL */ `
  subscription OnDeleteApplication(
    $id: ID
    $opportunityID: ID
    $studentID: ID
    $status: String
    $shareprofile: String
  ) {
    onDeleteApplication(
      id: $id
      opportunityID: $opportunityID
      studentID: $studentID
      status: $status
      shareprofile: $shareprofile
    ) {
      id
      opportunityID
      studentID
      status
      shareprofile
      createdAt
      category
      campaignID
      application
      updatedAt
      __typename
    }
  }
`;
export const onCreateEducator = /* GraphQL */ `
  subscription OnCreateEducator(
    $id: ID
    $bio: String
    $createdAt: AWSDateTime
    $dob: AWSDate
    $email: AWSEmail
  ) {
    onCreateEducator(
      id: $id
      bio: $bio
      createdAt: $createdAt
      dob: $dob
      email: $email
    ) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      personality
      role
      isAdmin
      phone
      schoolID
      schoolid
      state
      image
      updatedAt
      zipcode
      chat_accessKey
      is_verified
      stripeID
      hubspotID
      twoFA
      credits
      deleted
      subscription
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
      __typename
    }
  }
`;
export const onUpdateEducator = /* GraphQL */ `
  subscription OnUpdateEducator(
    $id: ID
    $bio: String
    $createdAt: AWSDateTime
    $dob: AWSDate
    $email: AWSEmail
  ) {
    onUpdateEducator(
      id: $id
      bio: $bio
      createdAt: $createdAt
      dob: $dob
      email: $email
    ) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      personality
      role
      isAdmin
      phone
      schoolID
      schoolid
      state
      image
      updatedAt
      zipcode
      chat_accessKey
      is_verified
      stripeID
      hubspotID
      twoFA
      credits
      deleted
      subscription
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
      __typename
    }
  }
`;
export const onDeleteEducator = /* GraphQL */ `
  subscription OnDeleteEducator(
    $id: ID
    $bio: String
    $createdAt: AWSDateTime
    $dob: AWSDate
    $email: AWSEmail
  ) {
    onDeleteEducator(
      id: $id
      bio: $bio
      createdAt: $createdAt
      dob: $dob
      email: $email
    ) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      personality
      role
      isAdmin
      phone
      schoolID
      schoolid
      state
      image
      updatedAt
      zipcode
      chat_accessKey
      is_verified
      stripeID
      hubspotID
      twoFA
      credits
      deleted
      subscription
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
      __typename
    }
  }
`;
export const onCreateMyGroup = /* GraphQL */ `
  subscription OnCreateMyGroup(
    $id: ID
    $title: String
    $content: String
    $price: Int
    $rating: Float
  ) {
    onCreateMyGroup(
      id: $id
      title: $title
      content: $content
      price: $price
      rating: $rating
    ) {
      id
      createdAt
      updatedAt
      name
      roleID
      studentIDs
      description
      __typename
    }
  }
`;
export const onUpdateMyGroup = /* GraphQL */ `
  subscription OnUpdateMyGroup(
    $id: ID
    $title: String
    $content: String
    $price: Int
    $rating: Float
  ) {
    onUpdateMyGroup(
      id: $id
      title: $title
      content: $content
      price: $price
      rating: $rating
    ) {
      id
      createdAt
      updatedAt
      name
      roleID
      studentIDs
      description
      __typename
    }
  }
`;
export const onDeleteMyGroup = /* GraphQL */ `
  subscription OnDeleteMyGroup(
    $id: ID
    $title: String
    $content: String
    $price: Int
    $rating: Float
  ) {
    onDeleteMyGroup(
      id: $id
      title: $title
      content: $content
      price: $price
      rating: $rating
    ) {
      id
      createdAt
      updatedAt
      name
      roleID
      studentIDs
      description
      __typename
    }
  }
`;
export const onCreateNote = /* GraphQL */ `
  subscription OnCreateNote(
    $id: ID
    $color: String
    $description: String
    $title: String
    $studentID: ID
  ) {
    onCreateNote(
      id: $id
      color: $color
      description: $description
      title: $title
      studentID: $studentID
    ) {
      id
      color
      description
      title
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNote = /* GraphQL */ `
  subscription OnUpdateNote(
    $id: ID
    $color: String
    $description: String
    $title: String
    $studentID: ID
  ) {
    onUpdateNote(
      id: $id
      color: $color
      description: $description
      title: $title
      studentID: $studentID
    ) {
      id
      color
      description
      title
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNote = /* GraphQL */ `
  subscription OnDeleteNote(
    $id: ID
    $color: String
    $description: String
    $title: String
    $studentID: ID
  ) {
    onDeleteNote(
      id: $id
      color: $color
      description: $description
      title: $title
      studentID: $studentID
    ) {
      id
      color
      description
      title
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateExperienceMessage = /* GraphQL */ `
  subscription OnCreateExperienceMessage(
    $id: ID
    $message: String
    $session: String
    $method: String
    $subject: String
  ) {
    onCreateExperienceMessage(
      id: $id
      message: $message
      session: $session
      method: $method
      subject: $subject
    ) {
      id
      experienceID
      message
      session
      sessionID
      method
      subject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateExperienceMessage = /* GraphQL */ `
  subscription OnUpdateExperienceMessage {
    onUpdateExperienceMessage {
      id
      experienceID
      message
      session
      sessionID
      method
      subject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteExperienceMessage = /* GraphQL */ `
  subscription OnDeleteExperienceMessage(
    $id: ID
    $message: String
    $session: String
    $method: String
    $subject: String
  ) {
    onDeleteExperienceMessage(
      id: $id
      message: $message
      session: $session
      method: $method
      subject: $subject
    ) {
      id
      experienceID
      message
      session
      sessionID
      method
      subject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateExperienceSession = /* GraphQL */ `
  subscription OnCreateExperienceSession(
    $id: ID
    $date: AWSDate
    $end_time: AWSTime
    $slots: Int
    $start_time: AWSTime
  ) {
    onCreateExperienceSession(
      id: $id
      date: $date
      end_time: $end_time
      slots: $slots
      start_time: $start_time
    ) {
      id
      date
      end_time
      slots
      start_time
      __typename
    }
  }
`;
export const onUpdateExperienceSession = /* GraphQL */ `
  subscription OnUpdateExperienceSession(
    $id: ID
    $date: AWSDate
    $end_time: AWSTime
    $slots: Int
    $start_time: AWSTime
  ) {
    onUpdateExperienceSession(
      id: $id
      date: $date
      end_time: $end_time
      slots: $slots
      start_time: $start_time
    ) {
      id
      date
      end_time
      slots
      start_time
      __typename
    }
  }
`;
export const onDeleteExperienceSession = /* GraphQL */ `
  subscription OnDeleteExperienceSession(
    $id: ID
    $date: AWSDate
    $end_time: AWSTime
    $slots: Int
    $start_time: AWSTime
  ) {
    onDeleteExperienceSession(
      id: $id
      date: $date
      end_time: $end_time
      slots: $slots
      start_time: $start_time
    ) {
      id
      date
      end_time
      slots
      start_time
      __typename
    }
  }
`;
export const onCreateExperience = /* GraphQL */ `
  subscription OnCreateExperience(
    $id: ID
    $closing_date: AWSDateTime
    $description: String
    $is_archived: Boolean
    $is_featured: Boolean
  ) {
    onCreateExperience(
      id: $id
      closing_date: $closing_date
      description: $description
      is_archived: $is_archived
      is_featured: $is_featured
    ) {
      id
      closing_date
      viewing
      description
      is_archived
      is_featured
      location
      partners
      price_per_person
      sessionsIDs
      requirements
      title
      type
      messageIDs
      likes
      liked_by
      image
      total_slots
      is_draft
      __typename
    }
  }
`;
export const onUpdateExperience = /* GraphQL */ `
  subscription OnUpdateExperience(
    $id: ID
    $closing_date: AWSDateTime
    $description: String
    $is_archived: Boolean
    $is_featured: Boolean
  ) {
    onUpdateExperience(
      id: $id
      closing_date: $closing_date
      description: $description
      is_archived: $is_archived
      is_featured: $is_featured
    ) {
      id
      closing_date
      viewing
      description
      is_archived
      is_featured
      location
      partners
      price_per_person
      sessionsIDs
      requirements
      title
      type
      messageIDs
      likes
      liked_by
      image
      total_slots
      is_draft
      __typename
    }
  }
`;
export const onDeleteExperience = /* GraphQL */ `
  subscription OnDeleteExperience(
    $id: ID
    $closing_date: AWSDateTime
    $description: String
    $is_archived: Boolean
    $is_featured: Boolean
  ) {
    onDeleteExperience(
      id: $id
      closing_date: $closing_date
      description: $description
      is_archived: $is_archived
      is_featured: $is_featured
    ) {
      id
      closing_date
      viewing
      description
      is_archived
      is_featured
      location
      partners
      price_per_person
      sessionsIDs
      requirements
      title
      type
      messageIDs
      likes
      liked_by
      image
      total_slots
      is_draft
      __typename
    }
  }
`;
export const onCreatePurchases = /* GraphQL */ `
  subscription OnCreatePurchases(
    $id: ID
    $amount: Float
    $createdAt: AWSDateTime
    $experienceID: ID
    $purchased_by: ID
  ) {
    onCreatePurchases(
      id: $id
      amount: $amount
      createdAt: $createdAt
      experienceID: $experienceID
      purchased_by: $purchased_by
    ) {
      id
      amount
      createdAt
      experienceID
      purchased_by
      slots
      updatedAt
      purchaser_role
      sessionID
      status
      __typename
    }
  }
`;
export const onUpdatePurchases = /* GraphQL */ `
  subscription OnUpdatePurchases(
    $id: ID
    $amount: Float
    $createdAt: AWSDateTime
    $experienceID: ID
    $purchased_by: ID
  ) {
    onUpdatePurchases(
      id: $id
      amount: $amount
      createdAt: $createdAt
      experienceID: $experienceID
      purchased_by: $purchased_by
    ) {
      id
      amount
      createdAt
      experienceID
      purchased_by
      slots
      updatedAt
      purchaser_role
      sessionID
      status
      __typename
    }
  }
`;
export const onDeletePurchases = /* GraphQL */ `
  subscription OnDeletePurchases(
    $id: ID
    $amount: Float
    $createdAt: AWSDateTime
    $experienceID: ID
    $purchased_by: ID
  ) {
    onDeletePurchases(
      id: $id
      amount: $amount
      createdAt: $createdAt
      experienceID: $experienceID
      purchased_by: $purchased_by
    ) {
      id
      amount
      createdAt
      experienceID
      purchased_by
      slots
      updatedAt
      purchaser_role
      sessionID
      status
      __typename
    }
  }
`;
export const onCreateRecommendation = /* GraphQL */ `
  subscription OnCreateRecommendation(
    $id: ID
    $createdAt: AWSDateTime
    $educatorID: ID
    $opportunityID: ID
    $groupID: ID
  ) {
    onCreateRecommendation(
      id: $id
      createdAt: $createdAt
      educatorID: $educatorID
      opportunityID: $opportunityID
      groupID: $groupID
    ) {
      id
      createdAt
      educatorID
      opportunityID
      groupID
      categoryID
      studentID
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateRecommendation = /* GraphQL */ `
  subscription OnUpdateRecommendation(
    $id: ID
    $createdAt: AWSDateTime
    $educatorID: ID
    $opportunityID: ID
    $groupID: ID
  ) {
    onUpdateRecommendation(
      id: $id
      createdAt: $createdAt
      educatorID: $educatorID
      opportunityID: $opportunityID
      groupID: $groupID
    ) {
      id
      createdAt
      educatorID
      opportunityID
      groupID
      categoryID
      studentID
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteRecommendation = /* GraphQL */ `
  subscription OnDeleteRecommendation(
    $id: ID
    $createdAt: AWSDateTime
    $educatorID: ID
    $opportunityID: ID
    $groupID: ID
  ) {
    onDeleteRecommendation(
      id: $id
      createdAt: $createdAt
      educatorID: $educatorID
      opportunityID: $opportunityID
      groupID: $groupID
    ) {
      id
      createdAt
      educatorID
      opportunityID
      groupID
      categoryID
      studentID
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateLikeOpportunity = /* GraphQL */ `
  subscription OnCreateLikeOpportunity(
    $id: ID
    $opportunityID: ID
    $studentID: ID
    $roleID: ID
    $createdAt: AWSDateTime
  ) {
    onCreateLikeOpportunity(
      id: $id
      opportunityID: $opportunityID
      studentID: $studentID
      roleID: $roleID
      createdAt: $createdAt
    ) {
      id
      opportunityID
      studentID
      roleID
      createdAt
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateLikeOpportunity = /* GraphQL */ `
  subscription OnUpdateLikeOpportunity(
    $id: ID
    $opportunityID: ID
    $studentID: ID
    $roleID: ID
    $createdAt: AWSDateTime
  ) {
    onUpdateLikeOpportunity(
      id: $id
      opportunityID: $opportunityID
      studentID: $studentID
      roleID: $roleID
      createdAt: $createdAt
    ) {
      id
      opportunityID
      studentID
      roleID
      createdAt
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteLikeOpportunity = /* GraphQL */ `
  subscription OnDeleteLikeOpportunity(
    $id: ID
    $opportunityID: ID
    $studentID: ID
    $roleID: ID
    $createdAt: AWSDateTime
  ) {
    onDeleteLikeOpportunity(
      id: $id
      opportunityID: $opportunityID
      studentID: $studentID
      roleID: $roleID
      createdAt: $createdAt
    ) {
      id
      opportunityID
      studentID
      roleID
      createdAt
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStudent = /* GraphQL */ `
  subscription OnCreateStudent(
    $id: ID
    $email: AWSEmail
    $name: String
    $state: String
    $educatorID: ID
  ) {
    onCreateStudent(
      id: $id
      email: $email
      name: $name
      state: $state
      educatorID: $educatorID
    ) {
      id
      email
      username
      email_verified
      name
      state
      educatorID
      profile_sharing
      dob
      dont_show_app_pop
      gender
      phone
      points
      schoolID
      school
      hubspotID
      address
      updatedAt
      zipcode
      createdAt
      balance
      is_verified
      multiplier
      multiplier_level
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivated
      date_deactivated
      apartment
      schooling_mode
      chat_accessKey
      graduation_year
      graduation_term
      guardianID
      interestIDs
      interests
      current_employer
      employed
      current_occupation
      last_seen
      invited_by
      shared_opportunities
      submissions_scan
      submissions_scan_1
      submissions_scan_2
      event_submissions_scan
      event_submissions_recommendation_scan
      unique_views_scan
      resource_reviews_scan
      opportunity_share_scan
      consecutive_counselor_chat_scan
      consecutive_resource_chat_scan
      invites_scan
      deleted
      chat_username
      chat_secret
      referral_code
      opportunity_acceptance_scane
      Applications {
        nextToken
        __typename
      }
      Recommendations {
        nextToken
        __typename
      }
      likedOpportunity {
        nextToken
        __typename
      }
      Educators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateStudent = /* GraphQL */ `
  subscription OnUpdateStudent(
    $id: ID
    $email: AWSEmail
    $name: String
    $state: String
    $educatorID: ID
  ) {
    onUpdateStudent(
      id: $id
      email: $email
      name: $name
      state: $state
      educatorID: $educatorID
    ) {
      id
      email
      username
      email_verified
      name
      state
      educatorID
      profile_sharing
      dob
      dont_show_app_pop
      gender
      phone
      points
      schoolID
      school
      hubspotID
      address
      updatedAt
      zipcode
      createdAt
      balance
      is_verified
      multiplier
      multiplier_level
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivated
      date_deactivated
      apartment
      schooling_mode
      chat_accessKey
      graduation_year
      graduation_term
      guardianID
      interestIDs
      interests
      current_employer
      employed
      current_occupation
      last_seen
      invited_by
      shared_opportunities
      submissions_scan
      submissions_scan_1
      submissions_scan_2
      event_submissions_scan
      event_submissions_recommendation_scan
      unique_views_scan
      resource_reviews_scan
      opportunity_share_scan
      consecutive_counselor_chat_scan
      consecutive_resource_chat_scan
      invites_scan
      deleted
      chat_username
      chat_secret
      referral_code
      opportunity_acceptance_scane
      Applications {
        nextToken
        __typename
      }
      Recommendations {
        nextToken
        __typename
      }
      likedOpportunity {
        nextToken
        __typename
      }
      Educators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteStudent = /* GraphQL */ `
  subscription OnDeleteStudent(
    $id: ID
    $email: AWSEmail
    $name: String
    $state: String
    $educatorID: ID
  ) {
    onDeleteStudent(
      id: $id
      email: $email
      name: $name
      state: $state
      educatorID: $educatorID
    ) {
      id
      email
      username
      email_verified
      name
      state
      educatorID
      profile_sharing
      dob
      dont_show_app_pop
      gender
      phone
      points
      schoolID
      school
      hubspotID
      address
      updatedAt
      zipcode
      createdAt
      balance
      is_verified
      multiplier
      multiplier_level
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivated
      date_deactivated
      apartment
      schooling_mode
      chat_accessKey
      graduation_year
      graduation_term
      guardianID
      interestIDs
      interests
      current_employer
      employed
      current_occupation
      last_seen
      invited_by
      shared_opportunities
      submissions_scan
      submissions_scan_1
      submissions_scan_2
      event_submissions_scan
      event_submissions_recommendation_scan
      unique_views_scan
      resource_reviews_scan
      opportunity_share_scan
      consecutive_counselor_chat_scan
      consecutive_resource_chat_scan
      invites_scan
      deleted
      chat_username
      chat_secret
      referral_code
      opportunity_acceptance_scane
      Applications {
        nextToken
        __typename
      }
      Recommendations {
        nextToken
        __typename
      }
      likedOpportunity {
        nextToken
        __typename
      }
      Educators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateStudentInterest = /* GraphQL */ `
  subscription OnCreateStudentInterest(
    $id: ID
    $studentID: ID
    $interestID: ID
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onCreateStudentInterest(
      id: $id
      studentID: $studentID
      interestID: $interestID
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      studentID
      interestIDs
      createdAt
      updatedAt
      interest {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateStudentInterest = /* GraphQL */ `
  subscription OnUpdateStudentInterest(
    $id: ID
    $studentID: ID
    $interestID: ID
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onUpdateStudentInterest(
      id: $id
      studentID: $studentID
      interestID: $interestID
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      studentID
      interestIDs
      createdAt
      updatedAt
      interest {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteStudentInterest = /* GraphQL */ `
  subscription OnDeleteStudentInterest(
    $id: ID
    $studentID: ID
    $interestID: ID
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onDeleteStudentInterest(
      id: $id
      studentID: $studentID
      interestID: $interestID
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      studentID
      interestIDs
      createdAt
      updatedAt
      interest {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory(
    $id: ID
    $name: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onCreateCategory(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      icon_name
      name
      createdAt
      description
      updatedAt
      Interests {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory(
    $id: ID
    $name: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onUpdateCategory(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      icon_name
      name
      createdAt
      description
      updatedAt
      Interests {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory(
    $id: ID
    $name: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onDeleteCategory(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      icon_name
      name
      createdAt
      description
      updatedAt
      Interests {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const onCreateNotificationSetting = /* GraphQL */ `
  subscription OnCreateNotificationSetting(
    $id: ID
    $new_chat: Boolean
    $new_reply: Boolean
    $new_student_joining: Boolean
    $roleID: ID
  ) {
    onCreateNotificationSetting(
      id: $id
      new_chat: $new_chat
      new_reply: $new_reply
      new_student_joining: $new_student_joining
      roleID: $roleID
    ) {
      id
      new_chat
      new_reply
      new_student_joining
      roleID
      saved_closing_date
      updatedAt
      new_opportunity_application
      new_reply_review
      new_review
      opportunities_closing_date
      opportunities_closure
      group_chat_replies
      createdAt
      __typename
    }
  }
`;
export const onUpdateNotificationSetting = /* GraphQL */ `
  subscription OnUpdateNotificationSetting(
    $id: ID
    $new_chat: Boolean
    $new_reply: Boolean
    $new_student_joining: Boolean
    $roleID: ID
  ) {
    onUpdateNotificationSetting(
      id: $id
      new_chat: $new_chat
      new_reply: $new_reply
      new_student_joining: $new_student_joining
      roleID: $roleID
    ) {
      id
      new_chat
      new_reply
      new_student_joining
      roleID
      saved_closing_date
      updatedAt
      new_opportunity_application
      new_reply_review
      new_review
      opportunities_closing_date
      opportunities_closure
      group_chat_replies
      createdAt
      __typename
    }
  }
`;
export const onDeleteNotificationSetting = /* GraphQL */ `
  subscription OnDeleteNotificationSetting(
    $id: ID
    $new_chat: Boolean
    $new_reply: Boolean
    $new_student_joining: Boolean
    $roleID: ID
  ) {
    onDeleteNotificationSetting(
      id: $id
      new_chat: $new_chat
      new_reply: $new_reply
      new_student_joining: $new_student_joining
      roleID: $roleID
    ) {
      id
      new_chat
      new_reply
      new_student_joining
      roleID
      saved_closing_date
      updatedAt
      new_opportunity_application
      new_reply_review
      new_review
      opportunities_closing_date
      opportunities_closure
      group_chat_replies
      createdAt
      __typename
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview(
    $id: ID
    $review: String
    $description: String
    $stars: Int
    $createdAt: AWSDateTime
  ) {
    onCreateReview(
      id: $id
      review: $review
      description: $description
      stars: $stars
      createdAt: $createdAt
    ) {
      id
      review
      description
      opportunityID
      resourceID
      studentID
      stars
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview(
    $id: ID
    $review: String
    $description: String
    $stars: Int
    $createdAt: AWSDateTime
  ) {
    onUpdateReview(
      id: $id
      review: $review
      description: $description
      stars: $stars
      createdAt: $createdAt
    ) {
      id
      review
      description
      opportunityID
      resourceID
      studentID
      stars
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview(
    $id: ID
    $review: String
    $description: String
    $stars: Int
    $createdAt: AWSDateTime
  ) {
    onDeleteReview(
      id: $id
      review: $review
      description: $description
      stars: $stars
      createdAt: $createdAt
    ) {
      id
      review
      description
      opportunityID
      resourceID
      studentID
      stars
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateStudentOpportunityApplication = /* GraphQL */ `
  subscription OnCreateStudentOpportunityApplication {
    onCreateStudentOpportunityApplication {
      id
      dont_show_again
      employment_status
      employer
      occupation
      household_income
      opportunityID
      studentID
      schoolID
      state
      question_reply
      resourceID
      status
      opportunity {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateStudentOpportunityApplication = /* GraphQL */ `
  subscription OnUpdateStudentOpportunityApplication {
    onUpdateStudentOpportunityApplication {
      id
      dont_show_again
      employment_status
      employer
      occupation
      household_income
      opportunityID
      studentID
      schoolID
      state
      question_reply
      resourceID
      status
      opportunity {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteStudentOpportunityApplication = /* GraphQL */ `
  subscription OnDeleteStudentOpportunityApplication {
    onDeleteStudentOpportunityApplication {
      id
      dont_show_again
      employment_status
      employer
      occupation
      household_income
      opportunityID
      studentID
      schoolID
      state
      question_reply
      resourceID
      status
      opportunity {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateStudentGuardian = /* GraphQL */ `
  subscription OnCreateStudentGuardian(
    $id: ID
    $name: String
    $phone: AWSPhone
    $email: AWSEmail
    $createdAt: AWSDateTime
  ) {
    onCreateStudentGuardian(
      id: $id
      name: $name
      phone: $phone
      email: $email
      createdAt: $createdAt
    ) {
      id
      name
      phone
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateStudentGuardian = /* GraphQL */ `
  subscription OnUpdateStudentGuardian(
    $id: ID
    $name: String
    $phone: AWSPhone
    $email: AWSEmail
    $createdAt: AWSDateTime
  ) {
    onUpdateStudentGuardian(
      id: $id
      name: $name
      phone: $phone
      email: $email
      createdAt: $createdAt
    ) {
      id
      name
      phone
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteStudentGuardian = /* GraphQL */ `
  subscription OnDeleteStudentGuardian(
    $id: ID
    $name: String
    $phone: AWSPhone
    $email: AWSEmail
    $createdAt: AWSDateTime
  ) {
    onDeleteStudentGuardian(
      id: $id
      name: $name
      phone: $phone
      email: $email
      createdAt: $createdAt
    ) {
      id
      name
      phone
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateJunityInfluencer = /* GraphQL */ `
  subscription OnCreateJunityInfluencer($id: ID, $role: String, $role_ID: ID) {
    onCreateJunityInfluencer(id: $id, role: $role, role_ID: $role_ID) {
      id
      role
      role_ID
      createdAt
      is_active
      __typename
    }
  }
`;
export const onUpdateJunityInfluencer = /* GraphQL */ `
  subscription OnUpdateJunityInfluencer($id: ID, $role: String, $role_ID: ID) {
    onUpdateJunityInfluencer(id: $id, role: $role, role_ID: $role_ID) {
      id
      role
      role_ID
      createdAt
      is_active
      __typename
    }
  }
`;
export const onDeleteJunityInfluencer = /* GraphQL */ `
  subscription OnDeleteJunityInfluencer($id: ID, $role: String, $role_ID: ID) {
    onDeleteJunityInfluencer(id: $id, role: $role, role_ID: $role_ID) {
      id
      role
      role_ID
      createdAt
      is_active
      __typename
    }
  }
`;
export const onCreateStudentVolunteerWork = /* GraphQL */ `
  subscription OnCreateStudentVolunteerWork(
    $id: ID
    $organization: String
    $hours_spent: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onCreateStudentVolunteerWork(
      id: $id
      organization: $organization
      hours_spent: $hours_spent
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      organization
      hours_spent
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateStudentVolunteerWork = /* GraphQL */ `
  subscription OnUpdateStudentVolunteerWork(
    $id: ID
    $organization: String
    $hours_spent: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onUpdateStudentVolunteerWork(
      id: $id
      organization: $organization
      hours_spent: $hours_spent
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      organization
      hours_spent
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteStudentVolunteerWork = /* GraphQL */ `
  subscription OnDeleteStudentVolunteerWork(
    $id: ID
    $organization: String
    $hours_spent: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onDeleteStudentVolunteerWork(
      id: $id
      organization: $organization
      hours_spent: $hours_spent
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      organization
      hours_spent
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateWithdrawnOpportunityApplications = /* GraphQL */ `
  subscription OnCreateWithdrawnOpportunityApplications(
    $id: ID
    $studentID: ID
    $opportunityName: String
    $reason: String
    $createdAt: AWSDateTime
  ) {
    onCreateWithdrawnOpportunityApplications(
      id: $id
      studentID: $studentID
      opportunityName: $opportunityName
      reason: $reason
      createdAt: $createdAt
    ) {
      id
      studentID
      opportunityName
      reason
      createdAt
      __typename
    }
  }
`;
export const onUpdateWithdrawnOpportunityApplications = /* GraphQL */ `
  subscription OnUpdateWithdrawnOpportunityApplications(
    $id: ID
    $studentID: ID
    $opportunityName: String
    $reason: String
    $createdAt: AWSDateTime
  ) {
    onUpdateWithdrawnOpportunityApplications(
      id: $id
      studentID: $studentID
      opportunityName: $opportunityName
      reason: $reason
      createdAt: $createdAt
    ) {
      id
      studentID
      opportunityName
      reason
      createdAt
      __typename
    }
  }
`;
export const onDeleteWithdrawnOpportunityApplications = /* GraphQL */ `
  subscription OnDeleteWithdrawnOpportunityApplications(
    $id: ID
    $studentID: ID
    $opportunityName: String
    $reason: String
    $createdAt: AWSDateTime
  ) {
    onDeleteWithdrawnOpportunityApplications(
      id: $id
      studentID: $studentID
      opportunityName: $opportunityName
      reason: $reason
      createdAt: $createdAt
    ) {
      id
      studentID
      opportunityName
      reason
      createdAt
      __typename
    }
  }
`;
export const onCreateRevokeProfileSharing = /* GraphQL */ `
  subscription OnCreateRevokeProfileSharing(
    $id: ID
    $studentID: String
    $school: String
    $createdAt: AWSDateTime
  ) {
    onCreateRevokeProfileSharing(
      id: $id
      studentID: $studentID
      school: $school
      createdAt: $createdAt
    ) {
      id
      studentID
      school
      createdAt
      reason
      __typename
    }
  }
`;
export const onUpdateRevokeProfileSharing = /* GraphQL */ `
  subscription OnUpdateRevokeProfileSharing(
    $id: ID
    $studentID: String
    $school: String
    $createdAt: AWSDateTime
  ) {
    onUpdateRevokeProfileSharing(
      id: $id
      studentID: $studentID
      school: $school
      createdAt: $createdAt
    ) {
      id
      studentID
      school
      createdAt
      reason
      __typename
    }
  }
`;
export const onDeleteRevokeProfileSharing = /* GraphQL */ `
  subscription OnDeleteRevokeProfileSharing(
    $id: ID
    $studentID: String
    $school: String
    $createdAt: AWSDateTime
  ) {
    onDeleteRevokeProfileSharing(
      id: $id
      studentID: $studentID
      school: $school
      createdAt: $createdAt
    ) {
      id
      studentID
      school
      createdAt
      reason
      __typename
    }
  }
`;
export const onCreateDraftOpportunity = /* GraphQL */ `
  subscription OnCreateDraftOpportunity(
    $id: ID
    $automatic_message: String
    $campaignID: ID
    $category: String
    $createdAt: AWSDateTime
  ) {
    onCreateDraftOpportunity(
      id: $id
      automatic_message: $automatic_message
      campaignID: $campaignID
      category: $category
      createdAt: $createdAt
    ) {
      id
      automatic_message
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      name
      remote
      requirements
      questions
      likes
      liked_by
      resourceID
      state
      status
      tag
      interests
      interestIDs
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDraftOpportunity = /* GraphQL */ `
  subscription OnUpdateDraftOpportunity(
    $id: ID
    $automatic_message: String
    $campaignID: ID
    $category: String
    $createdAt: AWSDateTime
  ) {
    onUpdateDraftOpportunity(
      id: $id
      automatic_message: $automatic_message
      campaignID: $campaignID
      category: $category
      createdAt: $createdAt
    ) {
      id
      automatic_message
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      name
      remote
      requirements
      questions
      likes
      liked_by
      resourceID
      state
      status
      tag
      interests
      interestIDs
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDraftOpportunity = /* GraphQL */ `
  subscription OnDeleteDraftOpportunity(
    $id: ID
    $automatic_message: String
    $campaignID: ID
    $category: String
    $createdAt: AWSDateTime
  ) {
    onDeleteDraftOpportunity(
      id: $id
      automatic_message: $automatic_message
      campaignID: $campaignID
      category: $category
      createdAt: $createdAt
    ) {
      id
      automatic_message
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      name
      remote
      requirements
      questions
      likes
      liked_by
      resourceID
      state
      status
      tag
      interests
      interestIDs
      updatedAt
      __typename
    }
  }
`;
export const onCreateFeedBack = /* GraphQL */ `
  subscription OnCreateFeedBack(
    $id: ID
    $type: String
    $creator: String
    $creatorType: Int
    $rating: Int
  ) {
    onCreateFeedBack(
      id: $id
      type: $type
      creator: $creator
      creatorType: $creatorType
      rating: $rating
    ) {
      id
      type
      creator
      creatorType
      rating
      message
      __typename
    }
  }
`;
export const onUpdateFeedBack = /* GraphQL */ `
  subscription OnUpdateFeedBack(
    $id: ID
    $type: String
    $creator: String
    $creatorType: Int
    $rating: Int
  ) {
    onUpdateFeedBack(
      id: $id
      type: $type
      creator: $creator
      creatorType: $creatorType
      rating: $rating
    ) {
      id
      type
      creator
      creatorType
      rating
      message
      __typename
    }
  }
`;
export const onDeleteFeedBack = /* GraphQL */ `
  subscription OnDeleteFeedBack(
    $id: ID
    $type: String
    $creator: String
    $creatorType: Int
    $rating: Int
  ) {
    onDeleteFeedBack(
      id: $id
      type: $type
      creator: $creator
      creatorType: $creatorType
      rating: $rating
    ) {
      id
      type
      creator
      creatorType
      rating
      message
      __typename
    }
  }
`;
export const onCreateCreditMultiplier = /* GraphQL */ `
  subscription OnCreateCreditMultiplier(
    $id: ID
    $condition: String
    $duration: Int
    $multiplier: Float
    $created_by: String
  ) {
    onCreateCreditMultiplier(
      id: $id
      condition: $condition
      duration: $duration
      multiplier: $multiplier
      created_by: $created_by
    ) {
      id
      condition
      duration
      value
      multiplier
      created_by
      updated_by
      updatedValues
      credit_history
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCreditMultiplier = /* GraphQL */ `
  subscription OnUpdateCreditMultiplier(
    $id: ID
    $condition: String
    $duration: Int
    $multiplier: Float
    $created_by: String
  ) {
    onUpdateCreditMultiplier(
      id: $id
      condition: $condition
      duration: $duration
      multiplier: $multiplier
      created_by: $created_by
    ) {
      id
      condition
      duration
      value
      multiplier
      created_by
      updated_by
      updatedValues
      credit_history
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCreditMultiplier = /* GraphQL */ `
  subscription OnDeleteCreditMultiplier(
    $id: ID
    $condition: String
    $duration: Int
    $multiplier: Float
    $created_by: String
  ) {
    onDeleteCreditMultiplier(
      id: $id
      condition: $condition
      duration: $duration
      multiplier: $multiplier
      created_by: $created_by
    ) {
      id
      condition
      duration
      value
      multiplier
      created_by
      updated_by
      updatedValues
      credit_history
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCreditEvent = /* GraphQL */ `
  subscription OnCreateCreditEvent(
    $id: ID
    $condition: String
    $value: Int
    $updated_by: [String]
    $created_by: String
  ) {
    onCreateCreditEvent(
      id: $id
      condition: $condition
      value: $value
      updated_by: $updated_by
      created_by: $created_by
    ) {
      id
      condition
      value
      updated_by
      created_by
      duration
      credit_history
      updated_at
      created_at
      __typename
    }
  }
`;
export const onUpdateCreditEvent = /* GraphQL */ `
  subscription OnUpdateCreditEvent(
    $id: ID
    $condition: String
    $value: Int
    $updated_by: [String]
    $created_by: String
  ) {
    onUpdateCreditEvent(
      id: $id
      condition: $condition
      value: $value
      updated_by: $updated_by
      created_by: $created_by
    ) {
      id
      condition
      value
      updated_by
      created_by
      duration
      credit_history
      updated_at
      created_at
      __typename
    }
  }
`;
export const onDeleteCreditEvent = /* GraphQL */ `
  subscription OnDeleteCreditEvent(
    $id: ID
    $condition: String
    $value: Int
    $updated_by: [String]
    $created_by: String
  ) {
    onDeleteCreditEvent(
      id: $id
      condition: $condition
      value: $value
      updated_by: $updated_by
      created_by: $created_by
    ) {
      id
      condition
      value
      updated_by
      created_by
      duration
      credit_history
      updated_at
      created_at
      __typename
    }
  }
`;
export const onCreateCreditValue = /* GraphQL */ `
  subscription OnCreateCreditValue(
    $id: ID
    $updatedBy: [String]
    $updatedAt: [AWSDateTime]
    $updatedValues: [Float]
    $value: Float
  ) {
    onCreateCreditValue(
      id: $id
      updatedBy: $updatedBy
      updatedAt: $updatedAt
      updatedValues: $updatedValues
      value: $value
    ) {
      id
      updatedBy
      updatedAt
      updatedValues
      value
      __typename
    }
  }
`;
export const onUpdateCreditValue = /* GraphQL */ `
  subscription OnUpdateCreditValue(
    $id: ID
    $updatedBy: [String]
    $updatedAt: [AWSDateTime]
    $updatedValues: [Float]
    $value: Float
  ) {
    onUpdateCreditValue(
      id: $id
      updatedBy: $updatedBy
      updatedAt: $updatedAt
      updatedValues: $updatedValues
      value: $value
    ) {
      id
      updatedBy
      updatedAt
      updatedValues
      value
      __typename
    }
  }
`;
export const onDeleteCreditValue = /* GraphQL */ `
  subscription OnDeleteCreditValue(
    $id: ID
    $updatedBy: [String]
    $updatedAt: [AWSDateTime]
    $updatedValues: [Float]
    $value: Float
  ) {
    onDeleteCreditValue(
      id: $id
      updatedBy: $updatedBy
      updatedAt: $updatedAt
      updatedValues: $updatedValues
      value: $value
    ) {
      id
      updatedBy
      updatedAt
      updatedValues
      value
      __typename
    }
  }
`;
export const onCreateCreditMultiplierHistory = /* GraphQL */ `
  subscription OnCreateCreditMultiplierHistory(
    $id: ID
    $data: [String]
    $createdAt: AWSDateTime
    $createdBy: String
  ) {
    onCreateCreditMultiplierHistory(
      id: $id
      data: $data
      createdAt: $createdAt
      createdBy: $createdBy
    ) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const onUpdateCreditMultiplierHistory = /* GraphQL */ `
  subscription OnUpdateCreditMultiplierHistory(
    $id: ID
    $data: [String]
    $createdAt: AWSDateTime
    $createdBy: String
  ) {
    onUpdateCreditMultiplierHistory(
      id: $id
      data: $data
      createdAt: $createdAt
      createdBy: $createdBy
    ) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const onDeleteCreditMultiplierHistory = /* GraphQL */ `
  subscription OnDeleteCreditMultiplierHistory(
    $id: ID
    $data: [String]
    $createdAt: AWSDateTime
    $createdBy: String
  ) {
    onDeleteCreditMultiplierHistory(
      id: $id
      data: $data
      createdAt: $createdAt
      createdBy: $createdBy
    ) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const onCreateCreditEventHistory = /* GraphQL */ `
  subscription OnCreateCreditEventHistory(
    $id: ID
    $data: [String]
    $createdAt: AWSDateTime
    $createdBy: String
  ) {
    onCreateCreditEventHistory(
      id: $id
      data: $data
      createdAt: $createdAt
      createdBy: $createdBy
    ) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const onUpdateCreditEventHistory = /* GraphQL */ `
  subscription OnUpdateCreditEventHistory(
    $id: ID
    $data: [String]
    $createdAt: AWSDateTime
    $createdBy: String
  ) {
    onUpdateCreditEventHistory(
      id: $id
      data: $data
      createdAt: $createdAt
      createdBy: $createdBy
    ) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const onDeleteCreditEventHistory = /* GraphQL */ `
  subscription OnDeleteCreditEventHistory(
    $id: ID
    $data: [String]
    $createdAt: AWSDateTime
    $createdBy: String
  ) {
    onDeleteCreditEventHistory(
      id: $id
      data: $data
      createdAt: $createdAt
      createdBy: $createdBy
    ) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const onCreateSubmissionScan = /* GraphQL */ `
  subscription OnCreateSubmissionScan($id: ID, $scanned: Int) {
    onCreateSubmissionScan(id: $id, scanned: $scanned) {
      id
      name
      scanned
      __typename
    }
  }
`;
export const onUpdateSubmissionScan = /* GraphQL */ `
  subscription OnUpdateSubmissionScan($id: ID, $scanned: Int) {
    onUpdateSubmissionScan(id: $id, scanned: $scanned) {
      id
      name
      scanned
      __typename
    }
  }
`;
export const onDeleteSubmissionScan = /* GraphQL */ `
  subscription OnDeleteSubmissionScan($id: ID, $scanned: Int) {
    onDeleteSubmissionScan(id: $id, scanned: $scanned) {
      id
      name
      scanned
      __typename
    }
  }
`;
export const onCreateSubscriptionUpgradeRequest = /* GraphQL */ `
  subscription OnCreateSubscriptionUpgradeRequest($id: ID) {
    onCreateSubscriptionUpgradeRequest(id: $id) {
      id
      organizationID
      name
      plan
      cost
      roleID
      roleType
      status
      approval
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSubscriptionUpgradeRequest = /* GraphQL */ `
  subscription OnUpdateSubscriptionUpgradeRequest($id: ID) {
    onUpdateSubscriptionUpgradeRequest(id: $id) {
      id
      organizationID
      name
      plan
      cost
      roleID
      roleType
      status
      approval
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSubscriptionUpgradeRequest = /* GraphQL */ `
  subscription OnDeleteSubscriptionUpgradeRequest($id: ID) {
    onDeleteSubscriptionUpgradeRequest(id: $id) {
      id
      organizationID
      name
      plan
      cost
      roleID
      roleType
      status
      approval
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment(
    $id: ID
    $roleID: ID
    $roleType: String
    $amount: Int
    $description: String
  ) {
    onCreatePayment(
      id: $id
      roleID: $roleID
      roleType: $roleType
      amount: $amount
      description: $description
    ) {
      id
      roleID
      roleType
      type
      amount
      description
      credits
      status
      metaData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePayment = /* GraphQL */ `
  subscription OnUpdatePayment(
    $id: ID
    $roleID: ID
    $roleType: String
    $amount: Int
    $description: String
  ) {
    onUpdatePayment(
      id: $id
      roleID: $roleID
      roleType: $roleType
      amount: $amount
      description: $description
    ) {
      id
      roleID
      roleType
      type
      amount
      description
      credits
      status
      metaData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePayment = /* GraphQL */ `
  subscription OnDeletePayment(
    $id: ID
    $roleID: ID
    $roleType: String
    $amount: Int
    $description: String
  ) {
    onDeletePayment(
      id: $id
      roleID: $roleID
      roleType: $roleType
      amount: $amount
      description: $description
    ) {
      id
      roleID
      roleType
      type
      amount
      description
      credits
      status
      metaData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateChatHandler = /* GraphQL */ `
  subscription OnCreateChatHandler(
    $chat_id: ID
    $updatedAt: AWSDateTime
    $createdAt: AWSDateTime
  ) {
    onCreateChatHandler(
      chat_id: $chat_id
      updatedAt: $updatedAt
      createdAt: $createdAt
    ) {
      chat_id
      access_key
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const onUpdateChatHandler = /* GraphQL */ `
  subscription OnUpdateChatHandler(
    $chat_id: ID
    $updatedAt: AWSDateTime
    $createdAt: AWSDateTime
  ) {
    onUpdateChatHandler(
      chat_id: $chat_id
      updatedAt: $updatedAt
      createdAt: $createdAt
    ) {
      chat_id
      access_key
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const onDeleteChatHandler = /* GraphQL */ `
  subscription OnDeleteChatHandler(
    $chat_id: ID
    $updatedAt: AWSDateTime
    $createdAt: AWSDateTime
  ) {
    onDeleteChatHandler(
      chat_id: $chat_id
      updatedAt: $updatedAt
      createdAt: $createdAt
    ) {
      chat_id
      access_key
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const onCreateAdminPermissionModule = /* GraphQL */ `
  subscription OnCreateAdminPermissionModule(
    $id: ID
    $name: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onCreateAdminPermissionModule(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAdminPermissionModule = /* GraphQL */ `
  subscription OnUpdateAdminPermissionModule(
    $id: ID
    $name: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onUpdateAdminPermissionModule(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAdminPermissionModule = /* GraphQL */ `
  subscription OnDeleteAdminPermissionModule(
    $id: ID
    $name: String
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onDeleteAdminPermissionModule(
      id: $id
      name: $name
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePermissionRoles = /* GraphQL */ `
  subscription OnCreatePermissionRoles(
    $id: ID
    $name: String
    $read: [ID]
    $edit: [ID]
    $createdAt: AWSDateTime
  ) {
    onCreatePermissionRoles(
      id: $id
      name: $name
      read: $read
      edit: $edit
      createdAt: $createdAt
    ) {
      id
      name
      read
      edit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePermissionRoles = /* GraphQL */ `
  subscription OnUpdatePermissionRoles(
    $id: ID
    $name: String
    $read: [ID]
    $edit: [ID]
    $createdAt: AWSDateTime
  ) {
    onUpdatePermissionRoles(
      id: $id
      name: $name
      read: $read
      edit: $edit
      createdAt: $createdAt
    ) {
      id
      name
      read
      edit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePermissionRoles = /* GraphQL */ `
  subscription OnDeletePermissionRoles(
    $id: ID
    $name: String
    $read: [ID]
    $edit: [ID]
    $createdAt: AWSDateTime
  ) {
    onDeletePermissionRoles(
      id: $id
      name: $name
      read: $read
      edit: $edit
      createdAt: $createdAt
    ) {
      id
      name
      read
      edit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAdminNotificationSettings = /* GraphQL */ `
  subscription OnCreateAdminNotificationSettings(
    $id: ID
    $admin_id: ID
    $verification_request: Boolean
    $opportunities_feedback: Boolean
    $issue_reports: Boolean
  ) {
    onCreateAdminNotificationSettings(
      id: $id
      admin_id: $admin_id
      verification_request: $verification_request
      opportunities_feedback: $opportunities_feedback
      issue_reports: $issue_reports
    ) {
      id
      admin_id
      verification_request
      opportunities_feedback
      issue_reports
      interest_suggestions
      __typename
    }
  }
`;
export const onUpdateAdminNotificationSettings = /* GraphQL */ `
  subscription OnUpdateAdminNotificationSettings(
    $id: ID
    $admin_id: ID
    $verification_request: Boolean
    $opportunities_feedback: Boolean
    $issue_reports: Boolean
  ) {
    onUpdateAdminNotificationSettings(
      id: $id
      admin_id: $admin_id
      verification_request: $verification_request
      opportunities_feedback: $opportunities_feedback
      issue_reports: $issue_reports
    ) {
      id
      admin_id
      verification_request
      opportunities_feedback
      issue_reports
      interest_suggestions
      __typename
    }
  }
`;
export const onDeleteAdminNotificationSettings = /* GraphQL */ `
  subscription OnDeleteAdminNotificationSettings(
    $id: ID
    $admin_id: ID
    $verification_request: Boolean
    $opportunities_feedback: Boolean
    $issue_reports: Boolean
  ) {
    onDeleteAdminNotificationSettings(
      id: $id
      admin_id: $admin_id
      verification_request: $verification_request
      opportunities_feedback: $opportunities_feedback
      issue_reports: $issue_reports
    ) {
      id
      admin_id
      verification_request
      opportunities_feedback
      issue_reports
      interest_suggestions
      __typename
    }
  }
`;
export const onCreateUserDevice = /* GraphQL */ `
  subscription OnCreateUserDevice(
    $userID: ID
    $devices: [String!]
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onCreateUserDevice(
      userID: $userID
      devices: $devices
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      userID
      devices
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUserDevice = /* GraphQL */ `
  subscription OnUpdateUserDevice(
    $userID: ID
    $devices: [String!]
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onUpdateUserDevice(
      userID: $userID
      devices: $devices
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      userID
      devices
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteUserDevice = /* GraphQL */ `
  subscription OnDeleteUserDevice(
    $userID: ID
    $devices: [String!]
    $createdAt: AWSDateTime
    $updatedAt: AWSDateTime
  ) {
    onDeleteUserDevice(
      userID: $userID
      devices: $devices
      createdAt: $createdAt
      updatedAt: $updatedAt
    ) {
      userID
      devices
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDeactivationRequest = /* GraphQL */ `
  subscription OnCreateDeactivationRequest(
    $id: ID
    $accountID: String
    $accountType: String
    $dateRequested: AWSDateTime
    $period: String
  ) {
    onCreateDeactivationRequest(
      id: $id
      accountID: $accountID
      accountType: $accountType
      dateRequested: $dateRequested
      period: $period
    ) {
      id
      accountID
      organizationID
      accountType
      dateRequested
      period
      reason
      description
      requesterID
      requesterName
      attachments
      priority
      approved
      approverID
      approverName
      secondApproverApproved
      secondApproverID
      secondApproverName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDeactivationRequest = /* GraphQL */ `
  subscription OnUpdateDeactivationRequest(
    $id: ID
    $accountID: String
    $accountType: String
    $dateRequested: AWSDateTime
    $period: String
  ) {
    onUpdateDeactivationRequest(
      id: $id
      accountID: $accountID
      accountType: $accountType
      dateRequested: $dateRequested
      period: $period
    ) {
      id
      accountID
      organizationID
      accountType
      dateRequested
      period
      reason
      description
      requesterID
      requesterName
      attachments
      priority
      approved
      approverID
      approverName
      secondApproverApproved
      secondApproverID
      secondApproverName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDeactivationRequest = /* GraphQL */ `
  subscription OnDeleteDeactivationRequest(
    $id: ID
    $accountID: String
    $accountType: String
    $dateRequested: AWSDateTime
    $period: String
  ) {
    onDeleteDeactivationRequest(
      id: $id
      accountID: $accountID
      accountType: $accountType
      dateRequested: $dateRequested
      period: $period
    ) {
      id
      accountID
      organizationID
      accountType
      dateRequested
      period
      reason
      description
      requesterID
      requesterName
      attachments
      priority
      approved
      approverID
      approverName
      secondApproverApproved
      secondApproverID
      secondApproverName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateYouthNotificationSetting = /* GraphQL */ `
  subscription OnCreateYouthNotificationSetting(
    $id: String
    $youthId: String
    $push_expiring_opportunities: Boolean
    $push_update_application: Boolean
    $push_new_chat: Boolean
  ) {
    onCreateYouthNotificationSetting(
      id: $id
      youthId: $youthId
      push_expiring_opportunities: $push_expiring_opportunities
      push_update_application: $push_update_application
      push_new_chat: $push_new_chat
    ) {
      id
      youthId
      push_expiring_opportunities
      push_update_application
      push_new_chat
      email_expiring_opportunities
      email_update_application
      email_new_chat
      sms_expiring_opportunities
      sms_update_application
      sms_new_chat
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateYouthNotificationSetting = /* GraphQL */ `
  subscription OnUpdateYouthNotificationSetting(
    $id: String
    $youthId: String
    $push_expiring_opportunities: Boolean
    $push_update_application: Boolean
    $push_new_chat: Boolean
  ) {
    onUpdateYouthNotificationSetting(
      id: $id
      youthId: $youthId
      push_expiring_opportunities: $push_expiring_opportunities
      push_update_application: $push_update_application
      push_new_chat: $push_new_chat
    ) {
      id
      youthId
      push_expiring_opportunities
      push_update_application
      push_new_chat
      email_expiring_opportunities
      email_update_application
      email_new_chat
      sms_expiring_opportunities
      sms_update_application
      sms_new_chat
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteYouthNotificationSetting = /* GraphQL */ `
  subscription OnDeleteYouthNotificationSetting(
    $id: String
    $youthId: String
    $push_expiring_opportunities: Boolean
    $push_update_application: Boolean
    $push_new_chat: Boolean
  ) {
    onDeleteYouthNotificationSetting(
      id: $id
      youthId: $youthId
      push_expiring_opportunities: $push_expiring_opportunities
      push_update_application: $push_update_application
      push_new_chat: $push_new_chat
    ) {
      id
      youthId
      push_expiring_opportunities
      push_update_application
      push_new_chat
      email_expiring_opportunities
      email_update_application
      email_new_chat
      sms_expiring_opportunities
      sms_update_application
      sms_new_chat
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateAppNotification = /* GraphQL */ `
  subscription OnCreateAppNotification(
    $id: ID
    $userId: ID
    $text: String
    $module: String
    $read: Boolean
  ) {
    onCreateAppNotification(
      id: $id
      userId: $userId
      text: $text
      module: $module
      read: $read
    ) {
      id
      userId
      title
      text
      link
      action
      module
      type
      icon
      read
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateAppNotification = /* GraphQL */ `
  subscription OnUpdateAppNotification(
    $id: ID
    $userId: ID
    $text: String
    $module: String
    $read: Boolean
  ) {
    onUpdateAppNotification(
      id: $id
      userId: $userId
      text: $text
      module: $module
      read: $read
    ) {
      id
      userId
      title
      text
      link
      action
      module
      type
      icon
      read
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteAppNotification = /* GraphQL */ `
  subscription OnDeleteAppNotification(
    $id: ID
    $userId: ID
    $text: String
    $module: String
    $read: Boolean
  ) {
    onDeleteAppNotification(
      id: $id
      userId: $userId
      text: $text
      module: $module
      read: $read
    ) {
      id
      userId
      title
      text
      link
      action
      module
      type
      icon
      read
      createdAt
      updatedAt
      __typename
    }
  }
`;
