import React from 'react';
import { ExclamationCircle, Wallet } from "react-bootstrap-icons";
import { ReactComponent as Sales } from "../icons/sales.svg" 

export const cardList = (creditValue, earnings, purchases) => {
  const data = [
    {
      "icon": <Sales size={24}/>,
      "category_title": "1 JC =",
      "category_number": `$${creditValue}`,
      "sub_text": "Edit JC value >",
      "bottom_text": "",
      "id": "experiences"
    },
    {
      "icon": <Wallet size={24}/>,
      "category_title": "Earnings",
      "category_number": `${earnings} JC`,
      "bottom_text": "0 credits earned today",
      "id": "purchases"
    },
    {
      "icon": <Wallet size={24}/>,
      "category_title": "Purchases",
      "category_number": `${purchases} JC`,
      "bottom_text": "0 credits spent today",
      "id": "sales"
    }
  ]

  return data;
}

export const supplyCardList = (creditValue, supplyPurchases, usagePurchases) => {
  const totalSupply = supplyPurchases.reduce((acc, item) => acc + item.credits, 0);
  const totalUsage = usagePurchases.reduce((acc, item) => acc + item.credits, 0);

  const data = [
    {
      "icon": <Sales size={24}/>,
      "category_title": "1 JC =",
      "category_number": `$${creditValue}`,
      "sub_text": "Edit JC value >",
      "bottom_text": "",
      "id": "experiences"
    },
    {
      "icon": <Wallet size={24}/>,
      "category_title": "Purchases",
      "category_number": `${totalSupply.toFixed(2)} JC`,
      "bottom_text": "0 credits purchased this week",
      "id": "purchases"
    },
    {
      "icon": <Wallet size={24}/>,
      "category_title": "Usage",
      "category_number": `${totalUsage.toFixed(2)} JC`,
      "bottom_text": "0 credits used this week",
      "id": "sales"
    }
  ]

  return data;

}

export const earningByEventsCards = [
  {
    "value": "630.44 JC",
    "title": "50 Unique Opportunity Views"
  },
  {
    "value": "630.44 JC",
    "title": "50 Unique Opportunity Views"
  },
  {
    "value": "630.44 JC",
    "title": "50 Unique Opportunity Views"
  },
  {
    "value": "630.44 JC",
    "title": "50 Unique Opportunity Views"
  },
  {
    "value": "630.44 JC",
    "title": "50 Unique Opportunity Views"
  },
  {
    "value": "630.44 JC",
    "title": "50 Unique Opportunity Views"
  },
  {
    "value": "630.44 JC",
    "title": "50 Unique Opportunity Views"
  },
  {
    "value": "630.44 JC",
    "title": "50 Unique Opportunity Views"
  },
  {
    "value": "630.44 JC",
    "title": "50 Unique Opportunity Views"
  },
]

export const earningByMultiplier = [
  {
    "value": "856.40 JC",
    "title": "8 Submissions in 10 days"
  },
  {
    "value": "856.40 JC",
    "title": "8 Submissions in 10 days"
  },
  {
    "value": "856.40 JC",
    "title": "8 Submissions in 10 days"
  },
  {
    "value": "856.40 JC",
    "title": "8 Submissions in 10 days"
  },
  {
    "value": "856.40 JC",
    "title": "8 Submissions in 10 days"
  },
  {
    "value": "856.40 JC",
    "title": "8 Submissions in 10 days"
  },
]

export const creditsModalNotifyCard = {
  "title": "Protected Zone",
  "text": "These are core Junity settings. If you edit these settings, everyone using Junity will be affected and your action will be recorded. Make sure you know what you’re doing.",
  "icon": 
    <ExclamationCircle 
      fill="#231F20" 
      size={40}
      style={{
        backgroundColor: "#FFE3B3"
      }}
    />,
  "body" : "",
  "bg": "#FFF4E1",
  "title_color": "#806334"
}


export const editMultipliersList = [
  {
    "condition": "Condition",
    "multiplier": "Multiplier",
    "duration" : "Duration",
    "layout": "header",
  },
  {
    "condition": {
      "layout": "no_btn",
      "text": "Junity Influencer"
    },
    "multiplier": {
      "digit": "1.0",
    },
    "duration": {
      "layout": "none",
    },
    "layout": "item",
  },
  {
    "condition": {
      "layout": "with_btn",
      "text": "Submissions",
      "digit": "8"
    },
    "multiplier": {
      "digit": "0.2",
    },
    "duration": {
      "layout": "left_align",
      "text": "days",
      "digit": "8"
    },
    "layout": "item",
  },
  {
    "condition": {
      "layout": "with_btn",
      "text": "Submissions",
      "digit": "16"
    },
    "multiplier": {
      "digit": "0.5",
    },
    "duration": {
      "layout": "right_align",
      "text": "days",
      "digit": "20"
    },
    "layout": "item",
  },
]



export const editEvents = [
  {
    "title": "Unique opportunity views",
    "value": 50,
    "layout": 1,
  },
  {
    "title": "Unique opportunity views",
    "value": 50,
    "layout": 1,
  },
  {
    "title": "Unique opportunity views",
    "value": 50,
    "layout": 1,
  },
  {
    "title": "Unique opportunity views",
    "value": 50,
    "layout": 1,
  },
  {
    "title": "Unique opportunity views",
    "value": 50,
    "layout": 1,
  },
  {
    "title": "Unique opportunity views",
    "value": 50,
    "layout": 1,
  },
  {
    "title": "Unique opportunity views",
    "value": 50,
    "layout": 1,
  },
  {
    "title": "Unique opportunity views",
    "value": 50,
    "layout": 1,
  },
  {

    "layout": 2,
    "inner": [
      {
        "title": "Invite friends",
        "value": 50,
      },
      {
        "title": "per month",
        "value": 50,
      },
    ]
  },
  {
    "title": "Unique opportunity views",
    "value": 50,
    "layout": 1,
  },
]

