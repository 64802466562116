import React, {useRef, useState} from "react";
import { Form } from "react-bootstrap";
import { CloudUpload, Arrow90degLeft } from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";
import ModalWrapper from "../../widgets/Modal/modal";
import {setPopupDataAction} from "../../redux/actions/popupActions";
import {useMutation} from "@apollo/client";
import {UPDATE_RESOURCE} from "../../services/mutation/resources";
import {UPDATE_EDUCATOR} from "../../services/mutation/educator";
import {useDispatch} from "react-redux";
import {UPDATE_SCHOOL} from "../../services/mutation/school";
import {axiosClient} from "../../libs/axiosClient";
import AlertToast from "../Common/Alerts/ToastAlert";
import { useAuth } from "../../templates/DashBoardTemplate";
import { sendAppNotification } from "../../utils/helpers";


export function RequestDeactivationModal (props) {

  const { show, details, previousModal, refetch } = {...props}

  const [state, setState] = useState({});
  const [updateUser] = useMutation(details?.organizationID ? UPDATE_RESOURCE : UPDATE_EDUCATOR)
  const [updateSchool] = useMutation(UPDATE_SCHOOL);
  const [loading, setLoading] = useState(false);
  const [activeToast, setActiveToast] = useState(false);
  const [activeState, setActiveState] = useState('');
  const [toast, setToast] = useState('');
  const { user } = useAuth();


  const deactivationReasons = [
    "Violation of Code of Conduct",
    "User requested to de-activate account",
    "Fraud or Scam"
  ]

  const dispatch = useDispatch();

  const deactivationReasonRef = useRef(null)

  const handleChecks = (index) => {
    const mainDiv = deactivationReasonRef.current.children
    for (let i = 1; i < mainDiv.length; i++){
      const outerDiv = mainDiv[i];
      const checkWrapper = outerDiv.children[0]
      const checkDiv = checkWrapper.children[0];
      checkDiv.style.backgroundColor = "#fff";
    }
    const outerDiv = deactivationReasonRef.current.children[index];
    const checkWrapper = outerDiv.children[0]
    const checkDiv = checkWrapper.children[0];
    if (checkDiv.style.backgroundColor === "rgb(255, 255, 255)"){
      checkDiv.style.backgroundColor = "#10408C";
     
      setState({
        ...state,
        reason: deactivationReasons[index-1]
      })
    }
    else {
      checkDiv.style.backgroundColor = "#fff";
    }
  }

  const setPeriod = (period, name) => {
    setState({
      ...state,
      [name]: period
    })
  }

  const setDetailReason = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const updateSchoolInfo = async () => {
    await updateSchool({
      variables: {
        id: details?.school?.id,
        is_verified: false
      }
    });
  }

  const handleToast = (status, message) => {
    setActiveState(status);
    setToast(message);
    setActiveToast(true);
  };

  const getAccountType = () => {
    if (details?.organizationID) {
      return "resource";
    } else if (details?.dob) {
      return "youth"
    } else {
      return "mentor"
    }
  }

  const deactivate = async () => {
    setLoading(true);
    try {
      await axiosClient.post('/deactivation-request', {
        accountID: details?.id,
        accountType: getAccountType(),
        accountName: details?.name,
        reason: state?.reason,
        description: state?.description,
        period: state?.period,
        requesterName: user?.userName,
        requesterID: user?.sub,
        attachments: [],
        priority: state?.priority,
        approved: false,
        approverID: "",
        approverName: "",
        secondApproverApproved: false,
        secondApproverID: "",
        secondApproverName: "",
        dateRequested: new Date(),
        organizationID: details?.schoolID || details?.organizationID
      });
      setLoading(false);
      dispatch(setPopupDataAction({ deactivateRequested: true }));
      dispatch(setPopupDataAction({ deactivateModal: false }));
      await sendAppNotification(
        user?.sub,
        'Deactivation request created successfully',
        'success',
        '/account-deactivation',
        'Click here to view',
        'admin',
        'inapp',
        'checkmarkSuccess'
      )
    } catch (err) {
      console.error(err);
      setLoading(false);
      // handleToast("error", err.response?.data?.message);
      await sendAppNotification(
        user?.sub,
        err.response?.data?.message,
        'error',
        `/account-deactivation`,
        'Click here to view',
        'admin',
        'inapp',
        'slashCircle'
      )
    }
  }

  const closeModal = () => {
    if (previousModal) {
      dispatch(setPopupDataAction({ [previousModal]: true }));
    }
    dispatch(setPopupDataAction({ overviewDeactivation: false }));
  }


  return ( 
    <>
    <AlertToast
        variant={activeState ? 'success' : 'error'}
        icon={activeState ? 'delete' : 'check'}
        active={activeToast}
        setActive={setActiveToast}
        info={toast}
    />
    <ModalWrapper
      state={`${show ? "show" : ""}`}
    >
      <div className="wrapper p-20">
        <div 
          className="return_btn p-20"
          onClick={closeModal}
        >
          <Arrow90degLeft className="mr-10"/>
          <span>Back to Account Overview</span>
        </div>
        <div className="top_text request_deactivation_top_text p-20">
          <h3>Request De-activation of {details?.school?.name}’s Account?</h3>
          <h6>
            De-activation will suspend all of {details?.school?.name}’s
            opportunities and connections for the specified period.
          </h6>
        </div>
        <div 
          className="reason_deactivation mt-20 p-20"
          ref={deactivationReasonRef}
        >
          <h6>Reason for De-activation</h6>
          <div className="check_items">
            <div className="flex-start">
              <div 
                className="check_item mr-10 flex-center"
                onClick={() => handleChecks(1)}
              >
                <div className="inner_div"></div>
              </div>
              <span>Violation of Code of Conduct</span>
            </div>
          </div>
          <div className="check_items mt-10">
            <div className="flex-start">
              <div 
                className="check_item mr-10 flex-center"
                onClick={() => handleChecks(2)}
              >
                <div className="inner_div"></div>
              </div>
              <span>User requested to de-activate account</span>
            </div>
          </div>
          <div className="check_items mt-10">
            <div className="flex-start">
              <div 
                className="check_item mr-10 flex-center"
                onClick={() => handleChecks(3)}
              >
                <div className="inner_div"></div>
              </div>              
              <span>Fraud or Scam</span>
            </div>
          </div>
        </div>
        <div className="description_sect mt-10 p-20">
          <div className="flex-between">
            <h6>Describe reason for re-activation</h6>
            <div>
              <CloudUpload 
                className="mr-10"
                fill="#10408C"
              />
              <span>Attach File(s)</span>
            </div>
          </div>
          <div className="input_field mt-10">
            <Form.Control 
              as="textarea"
              className="description_textarea"
              placeholder="Describe reason for de-activation"
              name="description"
              onChange={setDetailReason}
            />
          </div>
        </div>
        <div className="deactivation_period mt-10 p-20">
          <h6 className="header">De-activation Period</h6>
          <h6 style={{ fontSize: 14}}>For how long should this account be deactivated?</h6>
          <div className="bottom_btns mt-20">
            <CustomisedButton
              width="120px"
              height="40px"
              text="7 days"
              fw="600"
              onClick={() => setPeriod("7 days", "period")}
              className={`mr-10 deact-btn ${state?.period === "7 days"
                  ? "active"
                  : ""
              }`}
            />
            <CustomisedButton
              width="120px"
              height="40px"
              text="30 days"
              fw="600"
              onClick={() => setPeriod("30 days", "period")}
              className={`mr-10 deact-btn ${state?.period === "30 days"
                  ? "active"
                  : ""
              }`}
            />
            <CustomisedButton
              width="140px"
              height="40px"
              text="Indefinitely"
              fw="600"
              onClick={() => setPeriod("Indefinitely", "period")}
              className={`mr-10 deact-btn ${state?.period === "Indefinitely"
                  ? "active"
                  : ""
              }`}
            />
          </div>
        </div>
        <div className="deactivation_period mt-10 p-20">
          <h6 className="header">Priority</h6>
          <h6 style={{ fontSize: 14}}>How urgent is this?</h6>
          <div className="bottom_btns mt-20">
            <CustomisedButton
              width="120px"
              height="40px"
              text="Low"
              fw="600"
              onClick={() => setPeriod("Low", "priority")}
              className={`mr-10 deact-btn ${state?.priority === "Low"
                  ? "active"
                  : ""
              }`}
            />
            <CustomisedButton
              width="140px"
              height="40px"
              text="Medium"
              fw="600"
              onClick={() => setPeriod("Medium", "priority")}
              className={`mr-10 deact-btn ${state?.priority === "Medium"
                  ? "active"
                  : ""
              }`}
            />
            <CustomisedButton
              width="120px"
              height="40px"
              text="Urgent"
              fw="600"
              onClick={() => setPeriod("Urgent", "priority")}
              className={`mr-10 deact-btn ${state?.priority === "Urgent"
                  ? "active"
                  : ""
              }`}
            />
          </div>
        </div>
        <div className="p-20">
          <h6 className="mt-10 bottom_text"  style={{ fontSize: 14}}>
            Other admins will be informed of your request and 
            could de-activate this account after reviewing it.
          </h6>
          <div className="mt-10 flex flex-start">
            <CustomisedButton
              text={loading ? "Loading..." : "Request De-activation"}
              bg="#D41811"
              fg="#fff"
              bd="none"
              pd="10px 10px"
              width="48%"
              className="mr-10"
              onClick={deactivate}
            />
            <CustomisedButton
              text="Cancel"
              bg="#fff"
              fg="#515A6A"
              bd="1px solid #DDDFE2"
              pd="10px 10px"
              width="49%"
              onClick={closeModal}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
    </>
  );
}

export default RequestDeactivationModal;