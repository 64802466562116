import React from 'react';
// import { Row } from "react-bootstrap";
import { ChevronDown, Download } from "react-bootstrap-icons";
// import { earningByEventsCards, earningByMultiplier } from "../../assets/data/credits";
// import { ReactComponent as Chart } from "../../assets/images/small_chart.svg";
// import { ReactComponent as Chart2 } from "../../assets/images/small_chart_blue.svg";
import CustomisedButton from "../../widgets/Button/Button";
import BasicAreaChart from "./Charts/PurchaseUsageChart";

import { ReactComponent as NoImageDistr } from "../../assets/images/no-age-distr.svg";
import BarChart from "./Charts/BarChart";
// import { AllCreditEvents } from "../../services/query/creditEvents";

const createLabel = (text, icon) => {
  return (
    <div className="flex-between">
      <span className="align-items mr-10">{text}</span>
      <span className="align-items">{icon}</span>
    </div>
  )
}

function SupplyInfos({ purchase, usage }) {
  // const totalSupplies = [ ...purchase, ...usage ]
  const purchaseByResources = purchase.filter((elt) => elt.roleType === 'resource');
  const usageByResources = usage.filter((elt) => elt.roleType === 'resource');

  const purchaseByCounselors = purchase.filter((elt) => elt.roleType === 'mentor');
  const usageByCounselors = usage.filter((elt) => elt.roleType === 'mentor');

  // const [events, setEvents] = useState([]);

  // useEffect(() => {
  //   AllCreditEvents().then((events) => {
  //     setEvents(events);
  //   });
  // }, []); 

  return ( 
    <div className="demand_infos">
      <div className="flex-v">
        <div className="info_card">
          <div className="flex-between">
            <div className="info_card_title">
              Purchases vs Usage - Resources
            </div>
            <div className="mt-10 flex-start chart-action-btns">
              <CustomisedButton
                text={createLabel("Past 1 year", <ChevronDown/>)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
                className="mr-10"
              />
              <CustomisedButton
                text={createLabel("Download", <Download/>)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
              />
            </div>
          </div>
          {[...purchaseByResources, ...usageByResources].length > 0
          ? <BasicAreaChart 
              purchase={purchaseByResources} 
              usage={usageByResources} 
              colors={["#38609F", "#879FC5"]}
            />
          : (
            <div className="flex-center no-img">
              <NoImageDistr />
            </div>
          )}
        </div>

        <div className="info_card">
          <div className="flex-between">
            <div className="info_card_title">
              Purchases vs Usage - Counsellors
            </div>
            <div className="mt-10 flex-start chart-action-btns">
              <CustomisedButton
                text={createLabel("Past 1 year", <ChevronDown/>)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
                className="mr-10"
              />
              <CustomisedButton
                text={createLabel("Download", <Download/>)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
              />
            </div>
          </div>
          {[...purchaseByCounselors, ...usageByCounselors].length > 0
          ? <BasicAreaChart 
              purchase={purchaseByCounselors} 
              usage={usageByCounselors} 
              colors={["#D4A657", "#FFDA9A"]}
            />
          : (
            <div className="flex-center no-img">
              <NoImageDistr />
            </div>
          )}
        </div>

        <div className="info_card">
          <div className="flex-between">
            <div className="info_card_title">
              Total Purchases - Resources & Counsellors
            </div>
            <div className="mt-10 flex-start chart-action-btns">
              <CustomisedButton
                text={createLabel("Past 1 year", <ChevronDown/>)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
                className="mr-10"
              />
              <CustomisedButton
                text={createLabel("Download", <Download/>)}
                bd="1px solid #DDDFE2"
                pd="8px"
                fs="12px"
                bg="#fff"
              />
            </div>
          </div>
          {/* <div className="flex-center no-img">
            <NoImageDistr />
          </div> */}
          <BarChart 
            purchasesByResource={purchaseByResources}
            purchasesByCounselors={purchaseByCounselors}
          />
        </div>

      </div>
    </div>
  );
}

export default SupplyInfos;