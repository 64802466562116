import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import "../scss/Template/accountAuthTemplate.scss";
import LoginPage from '../pages/AccountAuth/LoginPage';
import SignupPage from '../pages/AccountAuth/SignupPage';
import LoginVerificationPage from "../pages/AccountAuth/LoginVerificationPage";


export default function AccountAuthTemplate(props) {
	const { path } = props;

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route path={path} element={<PageWrapper path={path}/>}/>
    </Routes>
	);
}

function PageWrapper(props) {
  const { path } = props;

  return ( 
    <div className="account_auth_wrapper">
      <div className="account_auth__card_wrapper">
        <div className="top_section">
          <div className="top_section__img">
            <img src={
              require("../assets/images/user_logo.png")
            }
            alt=""
            />
          </div>
          <div className="top_section__heading">
            <span>Junity</span>
            <sup>TM</sup>
          </div>
        </div>
        {path === "/login" || path === "/"
        ? <LoginPage/> 
        : (path === "/verify" ? <LoginVerificationPage/> : <SignupPage/>)
      }
      </div>
    </div>
  );
}

