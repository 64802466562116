import {
  SET_USER_DATA,
  RESET_USER_DATA,
} from "../constants/userConstants";

export const setUserDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_USER_DATA,
    payload: data,
  });
};

export const resetUserDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_USER_DATA,
  });
};
