import React, { useEffect, useState } from "react";
import { Arrow90degLeft } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import CustomisedButton from "../../../../widgets/Button/Button";
// import CustomEditor from "../../../../widgets/CustomEditor";
import { axiosClient } from "../../../../libs/axiosClient";
import { isRequiredFieldsPassed } from "../../../../utils/helpers";

export default function SecondStep({ item, setCurrentPage, receivers }) {
  const [state, setState] = useState({
    method: "SMS",
  });
  const [loading, setLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const createMessage = async () => {
    const filteredData = receivers.filter((innerArray) =>
      innerArray.every((item) => item.sessionID === state?.session),
    );
    const recipients = filteredData.length
      ? filteredData[0].reduce((acc, curr) => {
          const userData =
            state.method === "Email" ? curr["email"] : curr["phone"];
          if (!acc.includes(userData)) {
            return [...acc, userData];
          }
          return acc;
        }, [])
      : [];
    setLoading(true);
    try {
      await axiosClient.post("experiences/message", {
        experienceID: item?.id,
        ...state,
        recipients,
        createdAt: new Date(),
      });
      setLoading(false);
      setCurrentPage("third");
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    setIsBtnDisabled(!isRequiredFieldsPassed(state, 4, "eq"));
  }, [state]);

  return (
    <div className="create_message_sect">
      <div
        className="mt-10 pd-20"
        style={{ cursor: "pointer" }}
        onClick={() => setCurrentPage("first")}
      >
        <Arrow90degLeft className="mr-10" />
        <span>Back to Experience Details</span>
      </div>
      <div className="mt-40 pd-20">
        <h6 className="bold">Method</h6>
        <div className="flex-between mt-20" style={{ width: "50%" }}>
          <div className="flex-start" style={{ width: "50%" }}>
            <div className="flex-center mr-10">
              <Form.Check
                className="big_check"
                checked={state.method === "SMS"}
                value="SMS"
                onClick={handleChange}
                name="method"
              />
            </div>
            <span className="align-items">SMS</span>
          </div>
          <div className="flex-start" style={{ width: "50%" }}>
            <div className="flex-center mr-10">
              <Form.Check
                className="big_check"
                checked={state.method === "Email"}
                value="Email"
                onChange={handleChange}
                name="method"
              />
            </div>
            <span className="align-items">Email</span>
          </div>
        </div>
        <div className="mt-40">
          <h6 className="bold">Recipients</h6>
          <Form.Select className="mt-10" name="session" onChange={handleChange}>
            <option>Choose a session</option>
            {item?.sessionsIDs?.map((elt, index) => (
              <option key={elt} value={elt}>
                Session #{index + 1}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="mt-20">
          <h6 className="bold">Subject</h6>
          <Form.Control
            className="mt-10"
            name="subject"
            onChange={handleChange}
            placeholder="Subject or title"
          />
        </div>
        <div
          className={`mt-20 position-relative ${
            state.method === "Email" ? "quill-main-wrapper" : "sms-wrapper"
          }`}
        >
          <h6 className="bold">Message</h6>
          {/*{state?.method === "Email" ? (*/}
          {/*  <CustomEditor*/}
          {/*    className="mt-3"*/}
          {/*    placeholder="Type message here..."*/}
          {/*    setData={(value) => setState({ ...state, message: value })}*/}
          {/*    data={state.message}*/}
          {/*  />*/}
          {/*) : (*/}
          <textarea
            rows={3}
            className="w-100 p-2"
            name="message"
            onChange={handleChange}
          ></textarea>
          {/*)}*/}
        </div>

        <div className="flex-between send-msg-btn">
          <CustomisedButton
            text={loading ? "Sending..." : "Send Message"}
            bg="#10408C"
            fg="#fff"
            bd="1px solid #DDDFE2"
            pd="10px 10px"
            rd="10px"
            width="48%"
            className="mr-10"
            db={isBtnDisabled}
            op={isBtnDisabled ? 0.6 : 1}
            onClick={createMessage}
          />
          <CustomisedButton
            text="Discard"
            bg="#fff"
            fw="700"
            fg="#515A6A"
            bd="1px solid #DDDFE2"
            pd="10px 10px"
            rd="10px"
            width="48%"
          />
        </div>
      </div>
    </div>
  );
}
