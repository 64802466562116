import React, { useState } from 'react';
import { ChevronDown } from 'react-bootstrap-icons';
import { DashBoardTopDrop } from '../../../widgets/Dropdown/alltimeDrop';


function LeftModalActivityTab({cls}) {

  const [state, setState] = useState(false)
  const [data, setData] = useState({})

  const toggleDrop = () => {
    setState(!state)
  }

  const setSelected = (id, item) => {
    setData({...data, [id]:item})
  }

  return ( 
    <>
      <div className='youth_activity_tab_wrapper drop_wrap'>
        <div className='activity_top_sect'>
          Showing recent activities
          <div 
            className="flex-between pd-20 all_time_drop"
            onClick={toggleDrop}
          >
            All time
            <ChevronDown size={13} className="icon_down"/>
          </div>
        </div>
        <DashBoardTopDrop
          display={state}
          setData={setSelected}
          toggleDrop={toggleDrop}
          cls={cls}
        />
        <div className='activity_main mt-20'>
          <div className="activity_main_header">Today</div>
          {/* {leftModalActivityList.map((item, index) => 
          <div 
            className='activity_content'
            key={index}
          >
            <div className='activity_content_left'>
              <div>{item.header}</div>
              <div>
                <img 
                  src={item.img} 
                  alt=""
                  className='mr-10'
                />
                <span className='activity_youth_title'>Microsoft - Connecticut</span>
              </div>
            </div>
            <div className='activity_content_right'>
              {item.time}
            </div>
          </div>
          )} */}
        </div>
      </div>
    </>
  );
}

export default LeftModalActivityTab;