import ModalWrapper from "../../../widgets/Modal/modal";
import { X } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import Switch from "react-switch";
import React, { useState, useEffect } from "react";
import CustomisedButton from "../../../widgets/Button/Button";
import SaveStatus from "../../Administrators/Modal/SaveStatus";
import { axiosClient } from "../../../libs/axiosClient";
import { setRefreshDataAction } from "../../../redux/actions/refreshActions";
import { useDispatch } from "react-redux";
import { useAuth } from "../../../templates/DashBoardTemplate";
import { sendAppNotification } from "../../../utils/helpers";

function AddRoleModal(props) {
  const { show, setShow } = { ...props };
  const [proceed, setProceed] = useState(false);
  const [permModules, setPermModules] = useState([]);
  const [readChecks, setReadChecks] = useState({});
  const [editChecks, setEditChecks] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const { user } = useAuth();

  const dispatch = useDispatch();

  const handleSwitch = (checked, event, id, checkType) => {
    if (!checked) {
      const checksObj =
        checkType === "read" ? { ...readChecks } : { ...editChecks };
      delete checksObj[id];
      checkType === "read"
        ? setReadChecks(checksObj)
        : setEditChecks(checksObj);
    } else {
      checkType === "read"
        ? setReadChecks({
            ...readChecks,
            [id]: checked,
          })
        : setEditChecks({
            ...editChecks,
            [id]: checked,
          });
    }
  };

  const proceedToSave = async () => {
    setLoading(true);
    try { 
      const result = await axiosClient.post('/permission-role', {
        name,
        read: Object.keys(readChecks),
        edit: Object.keys(editChecks),
      });
      console.log(result)
      if (result?.data?.success){
        await sendAppNotification(
          '',
          'A new admin role has been created.',
          'success',
          '/roles-and-permissions',
          'Click here to view',
          'admin',
          'inapp',
          'checkmarkSuccess'
        )
      }
      setLoading(false);
      dispatch(setRefreshDataAction({ rolesRefetch: true }));
      setShow(false);
      setProceed(true);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  useEffect(() => {
    setBtnDisabled(name.trim() === "");
  }, [name]);

  useEffect(() => {
    const getAllPermModules = async () => {
      try {
        const resp = await axiosClient.get("/permission-module");
        setPermModules(resp.data.data);
      } catch (err) {
        console.error(err);
      }
    };
    getAllPermModules();
  }, []);

  return (
    <>
      <ModalWrapper state={`${show ? "show" : "hide"}`}>
        <div className="add_experience add_administrators">
          <div className="mbd">
            <div className="flex-between top_sect">
              <div className="align-items bold">Add New Role</div>
              <div className="right_side" onClick={() => setShow(false)}>
                <X fill="#515A6A" size={24} />
              </div>
            </div>
          </div>
          <div className="mbd pm-20">
            <h6>Title</h6>
            <Form.Control
              type="text"
              name="name"
              onChange={(e) => setName(e.target.value)}
              placeholder="e.g. customer support"
            />
          </div>
          <div className="mt-20 pm-20">
            <h6 className="bold">Permissions</h6>
            <div className="perms_wrapper mt-20">
              <div className="flex-between mbd pv-10">
                <div style={{ width: "70%" }}>Section</div>
                <div className="flex-between" style={{ width: "30%" }}>
                  <div>Read only</div>
                  <div>Edit</div>
                </div>
              </div>
              {permModules.map((item, index) => (
                <div className="flex-between mbd pv-10" key={index}>
                  <div style={{ width: "70%" }}>{item.name}</div>
                  <div className="flex-between" style={{ width: "30%" }}>
                    <div>
                      <Switch
                        onChange={(checked, event, id) =>
                          handleSwitch(checked, event, id, "read")
                        }
                        checked={readChecks[item.id]}
                        onColor="#2D9371"
                        offColor="#B9BDC3"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        className="mr-10"
                        id={item.id}
                      />
                    </div>
                    <div>
                      <Switch
                        onChange={(checked, event, id) =>
                          handleSwitch(checked, event, id, "edit")
                        }
                        checked={editChecks[item.id]}
                        onColor="#2D9371"
                        offColor="#B9BDC3"
                        uncheckedIcon={false}
                        checkedIcon={false}
                        className="mr-10"
                        id={item.id}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mt-20 pm-20">
            <div className="flex-between">
              <CustomisedButton
                text="Cancel"
                db={true}
                bg="#F4F5F7"
                fg="#B9BDC3"
                pd="10px"
                rd="10px"
                width="49%"
                className="mr-10"
                onClick={() => setShow(false)}
              />
              <CustomisedButton
                text={loading ? "Saving...." : "Save"}
                db={loading || btnDisabled}
                bg={(loading || btnDisabled) ? "#F4F5F7" : "#10408C"}
                fg={(loading || btnDisabled) ? "#B9BDC3" : "#fff"}
                pd="10px"
                rd="10px"
                width="49%"
                onClick={proceedToSave}
              />
            </div>
          </div>
        </div>
      </ModalWrapper>
      <SaveStatus
        show={proceed}
        setShow={setProceed}
        name={name}
        reads={readChecks}
        edits={editChecks}
      />
    </>
  );
}

export default AddRoleModal;
