import React, { useState, useEffect } from 'react';
import SearchInput from "../../widgets/SearchInput/searchInput";
import { ReactComponent as FilterBtn } from "../../assets/icons/filter.svg";
import { OpportunityFilterSectionDrop, OpportunityDropdown } from "../../widgets/Dropdown/dropdown";
import { GetOpportunityByResourceID } from "../../services/query/opportunities";
import RightModal from "./OpportunitiesRightModal";
import { debounce } from "lodash";
import { 
  calculateTime,
  filterOpportunitiesByApplications,
  filterOpportunitiesByPublishedHours,
  searchData
 } from "../../utils/helpers";
import { ReactComponent as EmptyState } from '../../assets/images/no-submissions.svg';


function OpportunitiesTab({ locationId }) {

  const [state, setState] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const [rightModalState, setRightModalState] = useState(false)
  const [filters, setFilters] = useState({});
  const [activeOpps, setActiveOpps] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const toggleDrop = (clear) => {
    if (clear === "clear"){
      setFilters({})
      setFiltered(activeOpps)
    }
    setState(!state)
  }

  const filterCallback = (id, data) => {
    let newContent = filtered;

    if (data.length){
      if (id === 'applications')
        newContent = filterOpportunitiesByApplications(newContent, data)

      else if (id === 'published')
        newContent = filterOpportunitiesByPublishedHours(newContent, data)

      setFiltered(newContent)
    }
    else {
      setFiltered(activeOpps)
    }
  }

  const setData = (id, data) => {
    setFilters({...filters, [id]:data})

    filterCallback(id, data);
  }

  const debouncedSearch = debounce((term) => {
    setFiltered(searchData(activeOpps, term));
  }, 300);

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const handleSelect = (id) => {
    setSelectedItem(activeOpps?.find((elt) => elt.id === id))
    setRightModalState(!rightModalState)
  }

  useEffect(() => {
    GetOpportunityByResourceID(locationId).then((data) => {
      setActiveOpps(data.active);
      setFiltered(data.active)
    });
  }, [locationId])
  


  return (
    <>
      <div className="resources_opportunities_tab">
        {filtered?.length
            ? (
                <>
                  <div className="top_section flex-between">
                    <SearchInput
                        placeholder="Search for an opportunity"
                        bg="#F4F5F7"
                        height="40px"
                        className="mr-10"
                        style={{flex: '1'}}
                        onChange={setSearchQuery}
                    />
                    <div className="icon_with_text">
                      <div
                          className={`filter_btn ${Object.keys(filters).length > 0 ? "filtered" : ""}`}
                          onClick={toggleDrop}
                      >
                        <FilterBtn/>
                        <span>
                Filters&nbsp;
                          {
                            Object.keys(filters).length > 0
                                ? `(${Object.keys(filters).length} selected)`
                                : ""
                          }
              </span>
                      </div>
                    </div>
                    <OpportunityFilterSectionDrop
                        display={state}
                        func={toggleDrop}
                        setData={setData}
                        filters={filters}
                    />
                  </div>
                  <div className="main_content">
                    <div className="cards_grid">
                      {filtered.map((item, index) =>
                          <div
                              className="opportunity_card cursor-pointer position-relative"
                              key={index}
                          >
                            <div className="flex-between">
                              <div
                                  className="theme"
                                  onClick={() => handleSelect(item?.id)}
                              >{item.name}</div>
                              <OpportunityDropdown
                                  id={item?.id}
                                  view={handleSelect}
                                  suspend={()=>{}}
                                  remove={()=>{}}
                              />
                            </div>
                            <h3
                                className='mb-0 mt-2'
                                onClick={() => handleSelect(item?.id)}>
                              {item.apps?.length}
                            </h3>
                            <h6 onClick={() => handleSelect(item?.id)}>
                              All Applications
                            </h6>
                            <div
                                className="published_date mt-10"
                                onClick={() => handleSelect(item?.id)}>
                              <span className='me-1'>Published:</span>
                              <span>{calculateTime(item?.createdAt)}</span>
                            </div>
                          </div>
                      )}
                    </div>
                  </div>
                </>
            ): (
                <div className='h-100 py-5 d-flex justify-content-center align-items-center'>
                  <div className="d-flex flex-column">
                    <EmptyState />
                    <p className="text-center">No opportunities found</p>
                  </div>
                </div>
            )
        }
      </div>
      <RightModal
        show={rightModalState}
        setShow={setRightModalState}
        item={selectedItem}
      />
    </>
  );
}

export default OpportunitiesTab;