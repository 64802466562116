import { gql, useQuery } from '@apollo/client';
import { API } from "aws-amplify";

export const GET_NOTIFICATION_SETTING = gql`
  query AllNotificationSettings {
    listNotificationSettings {
      items {
        id
        new_chat
        new_reply
        new_student_joining
        roleID
        saved_closing_date
      }
    }
  }
`;

export function AllNotificationSettings() {
  const resp = useQuery(GET_NOTIFICATION_SETTING);
  if (resp?.data) {
    return resp?.data?.listNotificationSettings?.items;
  }
}

export const getAppNotification = /* GraphQL */ `
  query GetAppNotification($id: ID!) {
    getAppNotification(id: $id) {
      id
      userId
      title
      text
      link
      action
      module
      type
      icon
      read
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listAppNotifications = /* GraphQL */ `
  query ListAppNotifications(
    $filter: TableAppNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        title
        text
        link
        action
        module
        type
        icon
        read
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getUserNotifications = async(userId, module='admin', limit=60)=>{
  try {
        const result = await API.graphql({
            query: listAppNotifications,
            variables: {
                limit,
                filter: {userId: {eq: userId}, module: {eq: module}}
            }
        })

        return result?.data?.listAppNotifications?.items
        
    } catch (error) {
        console.log(error)
        return []
    }
}

export const getNotification = async(id)=>{
    try {
        const result = await API.graphql({
            query: getAppNotification,
            variables: {id}
        })

        return result?.data?.getAppNotification
        
    } catch (error) {
        console.log(error)
        return {}
    }
}