import React, { useContext, useEffect, useState } from "react";
import TableTemplate from "../../components/Admin/TableTemplate";
import PaginationWrapper from "../../components/Youth/PaginationWrapper";
import TopSection from "../../components/Youth/TopSection";
import { AllEducators } from "../../services/query/educators";
import { AllSchools } from "../../services/query/school";
import { createTableCounselorsTableContent } from "../../utils/AccountDeactivation";
import {
  matchDomain,
  searchData,
  sortDataByField,
  FilterMentorFieldUnique,
  filterMentorsByLocations,
  filterDataByVerificationStatus,
  getSchoolState,
  getDataByDate,
} from "../../utils/helpers";
import { councelorsSectTableList } from "../../assets/data/councelorsTableList";

import "../../scss/Admin/councelorsPage.scss";
import { PageContext } from "../../templates/DashBoardTemplate";
import DeactivationModal from "../../components/AccountVerification/DeactivationModal";
import ActivationModal from "../../components/AccountVerification/ActivationModal";
import DeactivationRequested from "../../components/AccountVerification/DeactivationSucessful";
import {useDispatch, useSelector} from "react-redux";
import {setPopupDataAction} from "../../redux/actions/popupActions";
import {useLocation} from "react-router-dom";

function CouncelorsPage() {
  const [counselors, setCounselors] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { filterByDate } = useContext(PageContext);
  const popups = useSelector((store) => store.popups.data);
  const currentTable = useSelector((store) => store.currentTablesData.data);
  const currentState = useSelector((store) => store.currentState.state);

  const dispatch = useDispatch();

  const search = new URLSearchParams(useLocation().search);
  const id = search.get('id');

  const sortTable = (sortBy) => {
    setContentList(
      createTableCounselorsTableContent(sortDataByField(filtered, sortBy), currentState),
    );
  };

  const filterCallback = (id, data) => {
    let newContent = filtered;

    if (data.length) {
      if (id === "locations")
        newContent = filterMentorsByLocations(newContent, data);
      else if (id === "verification_status")
        newContent = filterDataByVerificationStatus(newContent, data);

      setFiltered(newContent);
    } else {
      setFiltered(counselors);
    }
  };

  const closeModal = (id) => {
    dispatch(setPopupDataAction({[id]: false}));
  }

  useEffect(() => {
    AllEducators().then(async (educators) => {
      const educatorAdmins = educators.filter(
        (educator) => educator.isAdmin === true && educator.verification_status
      );
      const allSchools = await AllSchools();
      const educatorWithLocations = await educatorAdmins.reduce(
        async (acc, educator) => {
          const schools = allSchools.filter(
            (school) =>
              matchDomain(school?.contact_email, educator.email) &&
              school.type !== "resource",
          );
          const prev = await acc;
          const locations = getSchoolState(
            educator?.school?.full_address,
          ).split(", ");
          return [...prev, { ...educator, schools, locations }];
        },
        Promise.resolve([]),
      );
      setCounselors(educatorWithLocations);
      setLocations(FilterMentorFieldUnique(educatorWithLocations, "locations"));
      setContentList(createTableCounselorsTableContent(educatorWithLocations, currentState));
    });
  }, [refresh]);

  useEffect(() => {
    if (filterByDate?.length) {
      setFiltered(getDataByDate(counselors, ...filterByDate));
    } else {
      setFiltered(counselors);
    }
  }, [filterByDate]);

  useEffect(() => {
    setContentList(createTableCounselorsTableContent(filtered, currentState));
  }, [filtered]);

  useEffect(() => {
    setContentList(createTableCounselorsTableContent(counselors, currentState));
  }, [counselors, currentState]);

  return (
    <div className="youth_main_content">
      <div className="youth_main_inner">
        <TopSection
          menu="mentors"
          inputPlaceholder="Search for a mentor"
          locations={locations}
          content={counselors}
          filtered={filtered}
          setFiltered={setFiltered}
          callback={searchData}
          filterCallback={filterCallback}
        />
        <TableTemplate
          data={councelorsSectTableList}
          details={counselors}
          tableContent={contentList}
          sortFunction={sortTable}
          layout="councelors"
          id={id}
        />
        <PaginationWrapper
          moveToPreviousPage={() => {}}
          moveToNextPage={() => {}}
          page={1}
          setPageLimit={() => {}}
          nextToken={""}
        />
        <DeactivationModal
            previousModal={"leftModal"}
            show={popups?.deactivateModal}
            setShow={() => closeModal("deactivateModal")}
            details={currentTable?.counselorsTable}
            refetch={() => setRefresh(prev => !prev)}
        />
        <ActivationModal
            previousModal={"leftModal"}
            show={popups?.activationModal}
            subLayout={"councelors"}
            setShow={() => closeModal("activationModal")}
            details={currentTable?.counselorsTable}
            detailsRefetch={() => setRefresh(prev => !prev)}
        />
        <DeactivationRequested
            show={popups?.deactivateRequested}
        />
      </div>
    </div>
  );
}

export default CouncelorsPage;
