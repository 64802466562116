import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import awsExports from "./aws-exports";
import { API, Amplify } from "aws-amplify";
import { ApolloProvider } from "@apollo/client";
import { client } from "./services/client";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import './App.scss';

Amplify.configure(awsExports);

API.configure({
  cache: {
    dataStore: 'memory', 
    cachePolicy: {
      maxAge: 10 * 60 * 1000,
      caching: 'cache'
    }
  },
});

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ApolloProvider>
);



