import React, { useState, useRef, useEffect } from 'react';
import { Check, ChevronRight } from 'react-bootstrap-icons';
import { DateRange } from 'react-date-range';
import CustomisedButton from '../Button/Button';
import "./style.scss"
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';
import CreditsModal from '../../components/Credits/Modal/Content';


export function DashBoardTopDrop(props) {
  
  const {
    display, 
    setData, 
    toggleDrop,
    cls
  } = {...props}

  const [state, setState] = useState(false)
  const dropMainContentRef = useRef(null)

  const clearSelected = () => {
    const divElt = dropMainContentRef.current.children;
    for (let i = 0; i < divElt.length; i++){
      const getTick = dropMainContentRef.current.children[i].children[1]
      if (getTick !== undefined){
        dropMainContentRef.current.children[i].children[1].style.display = "none";
      }
      dropMainContentRef.current.children[i].style.backgroundColor = "#fff";
    }

  }

  const handleClick = (index, id, label='') => {
    clearSelected()
    if (id !== "cdata_range"){
      dropMainContentRef.current.children[index].style.backgroundColor = "rgba(207, 217, 232, 0.32)";
      dropMainContentRef.current.children[index].children[1].style.display = "block";
      setData(id, id, label)
      toggleDrop()
    }

    else {
      setState(!state)
      toggleDrop()
    }

  }

  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ])

  useEffect(() => {
    const getRdrDayNumber = document.getElementsByClassName("rdrDayNumber");
    for (let i = 0; i < getRdrDayNumber.length; i++){
      getRdrDayNumber[i].children[0].className = "";
    }

    const days = document.getElementsByClassName("rdrDay");
    for (let i = 0; i < days.length; i++){
      if (days[i].children.length > 1) {
        if (days[i].children[0]?.className.search("rdrEndEdge") >= 0 || days[i].children[0]?.className.search("rdrStartEdge") >= 0){
          if (days[i].children[1].children[0]){
            days[i].children[1].children[0].className = "setColor-fff";
          }
        }
        else {
          days[i].children[1].children[0].className = "setColor-000";
        }
      }
    }
  }, [dateState])

  const cancelDateRange = () => {
    setDateState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection"
      }
    ])
    setState(false)
  }

  const handleDateRangeSelect = () => {
    setData("cdata_range", dateState)
    setState(false)
  }

  return ( 
    <div>
      <div 
        className={`drop_wrapper dashboard_topsection_drop ${cls}`}
        style={{display: display ? "block" : "none"}}
      >
        <div 
          className='drop_main_content'
          ref={dropMainContentRef}
        >
          <div 
            onClick={() => handleClick(0, "all_time", "All Time")}
          >
            <div>All time</div>
            <Check size={15} className="tick-hide"/>
          </div>
          <div 
            className=''
            onClick={() => handleClick(1, "7days", "Past 7 days")}
          >
            <div>Past 7 days</div>
            <Check size={15} className="tick-hide"/>
          </div>
          <div 
            className=''
            onClick={() => handleClick(2, "30days", "Past 30 days")}
          >
            <div>Past 30 days</div>
            <Check size={15} className="tick-hide"/>
          </div>
          <div 
            className='cdata_range '
            onClick={() => handleClick(3, "cdata_range")}
          >
            Custom Data range
            <ChevronRight size={15} className="right_icon"/>
          </div>
        </div>
      </div>
      <div 
        className='drop_wrapper date_range_picker_wrapper'
        style={{display: state ? "block" : "none"}}
      >
        <DateRange
          ranges={dateState}
          showDateDisplay={false}
          rangeColors={["#226E54", "#fff", "#red"]}
          moveRangeOnFirstSelection={false}
          onChange={item => setDateState([item.selection])}
          className="date-range-picker"
        />
        <div className='flex-between p-20'>
          <CustomisedButton 
            text="Cancel" 
            bg="#fff"
            fg="#515A6A"
            bd="1px solid #DDDFE2"
            rd="6px"
            pd="5px 10px"
            width="150px"
            className="fw-bold"
            onClick={cancelDateRange}
          />
          <CustomisedButton 
            text="Select" 
            bg="#FFDA9A"
            fg="#554223"
            bd="1px solid #FFDA9A"
            rd="6px"
            pd="5px 10px"
            width="150px"
            className="fw-bold"
            onClick={handleDateRangeSelect}
          />
        </div>
      </div>
    </div>
  )
}

export function CreditsTopDrop(props) {
  
  const {
    display, 
    toggleDrop,
    cls
  } = {...props}
  const [state, setState] = useState(false)
  const dropMainContentRef = useRef(null)
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState("")
  
  const handleClick = (id) => {
    setState(!state)
    toggleDrop()
    setShowModal(true)
    setModalType(id)
  }


  return ( 
    <div>
      <div 
        className={`drop_wrapper dashboard_topsection_drop ${cls}`}
        style={{display: display ? "block" : "none"}}
      >
        <div 
          className='drop_main_content'
          ref={dropMainContentRef}
        >
          
          <div 
            className='flex-between'
            onClick={() => handleClick("Edit Multipliers")}
          >
            <div>Edit Multipliers</div>
            <Check size={15} className="tick-hide"/>
          </div>
          <div 
            className=''
            onClick={() => handleClick("Edit Events per 1 JC")}
          >
            <div>Edit Events per 1 JC</div>
            <Check size={15} className="tick-hide"/>
          </div>
          <div 
            className=''
            onClick={() => handleClick("Edit JC Value")}
          >
            <div>Edit JC Value</div>
            <Check size={15} className="tick-hide"/>
          </div>
        </div>
      </div>
      <CreditsModal
        show={showModal}
        setShow={setShowModal}
        type={modalType}
      />
    </div>
  )
}
