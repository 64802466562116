import React from "react";
import { ProgressBar } from "react-bootstrap";
import { CheckCircleFill } from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";

export const createIconWrapper = (bg, text) => {
  return (
    <div
      className="initials_wrapper"
      style={{
        backgroundColor: bg,
        padding: "5px 5px",
      }}
    >
      {text}
    </div>
  );
};

export const nameCol = (props) => {
  const f_icon = <CheckCircleFill color="#0D3575" size={15} />;
  const s_icon = <CheckCircleFill color="#2D9371" size={15} />;
  const { Ibg, text, img, name, layout, verified, bottomText } = { ...props };

  const youthLayout = (
    <div className="flex-start align-items-center">
      <div>
        {createIconWrapper(Ibg,text)}
      </div>
      <div className="flex-v">
        <div className="d-flex align-items-center gap-1 white-space-nowrap">
          <span>{name}</span>

          {verified && (
            <>
              <div className="first_check">{f_icon}</div>
              <div className="second_check">{s_icon}</div>
            </>
          )}
        </div>
        <div className="initials_bottomText">{bottomText}</div>
      </div>
    </div>
  );

  const resourcesLayout = (
    <>
      <div className="align-items">
        <img src={img} alt="" className="name-col-img" />
      </div>
      <div className="flex-v">
        <div className="flex-start">
          <span className="ml-10">{name}</span>
          {verified ? (
            <div className="ml-10">
              <div>{s_icon}</div>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="initials_bottomText ml-10">{bottomText}</div>
      </div>
    </>
  );

  const councelorsLayout = (
    <div className="flex-start">
      <img src={img} alt="" className="box-img" />
      <div className="flex-v">
        <div className="flex-start">
          <span className="ml-10 mr-10">{name}</span>
          {verified ? (
            <>
              <div className="first_check mr-10">{f_icon}</div>
              <div className="second_check">{s_icon}</div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="initials_bottomText ml-10">{bottomText}</div>
      </div>
    </div>
  );

  const getLayout = (id) => {
    if (id === 1) {
      return youthLayout;
    } else if (id === 2) {
      return resourcesLayout;
    } else if (id === 3) {
      return councelorsLayout;
    }
  };

  return <div className="align-items">{getLayout(layout)}</div>;
};

export const nameWithInfoCol = (props) => {
  const { Ibg, text, name, layout, bottomText } = { ...props };

  const youthLayout = (
    <div className="d-flex align-items-center gap-1">
      <div>{createIconWrapper(Ibg, text)}</div>
      <div className="flex-v">
        <div className="flex-start white-space-nowrap">
          <span>{name}</span>
        </div>
        <div className="initials_bottomText">{bottomText}</div>
      </div>
    </div>
  );

  const getLayout = (id) => {
    if (id === 1) {
      return youthLayout;
    }
  };

  return <div className="align-items">{getLayout(layout)}</div>;
};

export const verificationCol = (type) => {
  const btnColors = {
    Verified: ["#DAF8EE", "#A1EED4"],
    "Not Verified": ["#FFFFFF", "#DDDFE2"],
    "In Progress": ["#FFE3B3", "#FFC768"],
  };

  return (
    <>
      <CustomisedButton
        bg={btnColors[type][0]}
        bd={`1px solid ${btnColors[type][1]}`}
        text={type}
        rd="15px"
        pd="5px 8px"
        fs="13px"
      />
    </>
  );
};

export const verificationColTabs = (type) => {
  if (type !== undefined) {
    const btnColors = {
      verified: ["#DAF8EE", "#A1EED4"],
      unverified: ["#FFFFFF", "#DDDFE2"],
      inprogress: ["#FFE3B3", "#FFC768"],
    };

    const returnType = {
      verified: "Verified",
      unverified: "Not Verified",
      inprogress: "In Progress",
    };

    return (
      <>
        <CustomisedButton
          bg={Object.keys(btnColors).includes(type) ? btnColors[type][0] : ""}
          bd={`1px solid ${
            Object.keys(btnColors).includes(type) ? btnColors[type][1] : ""
          }`}
          text={returnType[type]}
          rd="15px"
          pd="5px 8px"
          fs="13px"
        />
      </>
    );
  }

  return "";
};

export const statusColTabs = (type) => {
  if (type !== undefined) {
    const btnColors = {
      accepted: ["#DAF8EE", "#A1EED4"],
      pending: ["#FFE3B3", "#FFC768"],
    };

    const returnType = {
      accepted: "Accepted",
      pending: "Pending",
    };

    return (
      <>
        <CustomisedButton
          bg={Object.keys(btnColors).includes(type) ? btnColors[type][0] : ""}
          bd={`1px solid ${
            Object.keys(btnColors).includes(type) ? btnColors[type][1] : ""
          }`}
          text={returnType[type]}
          rd="15px"
          pd="5px 8px"
          fs="11px"
          fw="700"
        />
      </>
    );
  }

  return "";
};

export const accountVerificationProgress = (progress) => {
  let color = "";

  if (Number.isInteger(progress)) {
    if (progress === 0) {
      color = "p_red";
    } else if (progress > 0 && progress <= 20) {
      color = "p_l_yellow";
    } else if (progress > 20 && progress <= 40) {
      color = "p_d_yellow";
    } else if (progress > 40 && progress <= 60) {
      color = "p_l_green";
    } else {
      color = "p_d_green";
    }

    return (
      <>
        <ProgressBar now={progress} className={color} />
        &nbsp;{progress}%
      </>
    );
  } else {
    return progress;
  }
};
