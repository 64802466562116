import React, { useRef, useState, useEffect } from "react";
import { Gear, GeoAlt, Pencil } from "react-bootstrap-icons";
import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalBody,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
// import { myAccountDevices } from "../../assets/data/myAccount";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAdminDataAction } from "../../redux/actions/adminActions";
import { resetExperienceDataAction } from "../../redux/actions/experience";
import { resetInstitutionDataAction } from "../../redux/actions/institutionAction";
import { resetUserDataAction } from "../../redux/actions/userAction";
import {
  calculateTime,
  generateDeviceID,
  getInitial,
  isRequiredFieldsPassed,
} from "../../utils/helpers";
import Validation from "../../utils/Validation/Validation";
import { cognitoidentity, paramsWithUsername } from "../../userPools";
import { setAdminDataAction } from "../../redux/actions/adminActions";
import { ClipLoader } from "react-spinners";
import { CustomDropdown } from "../../widgets/Dropdown/dropdown";
import { Auth, Storage } from "aws-amplify";
import { axiosClient } from "../../libs/axiosClient";
import { ReactComponent as Safari } from "../../assets/images/safari.svg";
import { ReactComponent as Chrome } from "../../assets/images/chrome.svg";
import FireFoxBrowserLogo from "../../assets/icons/firefox.png";
import { resetRefreshDataAction } from "../../redux/actions/refreshActions";


function LeftContent() {
  const [user, setUser] = useState(
    useSelector((store) => store.adminUser.data),
  );
  const [firstName, lastName] = user.userName.split(" ");
  const locations = user?.locations || [];
  const [state, setState] = useState({
    firstName,
    lastName,
    phone_number: user?.phone_number,
  });
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    old_password: "",
    new_password: "",
    new_password_confirm: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [imageProps, setImageProps] = useState({
    image: user.image,
    imageFile: "",
  });
  const [devices, setDevices] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileRef = useRef();

  const progress = () => {
    return <ClipLoader size={10} color={loading ? "#fff" : ""} />;
  };

  const deviceInfos = {
    chrome: {
      name: `Chromium`,
      logo: <Chrome className="box-img-small mr-10" alt="" />,
    },
    firefox: {
      name: `Firefox`,
      logo: (
        <img src={FireFoxBrowserLogo} className="box-img-small mr-10" alt="" />
      ),
    },
    safari: {
      name: `Safari`,
      logo: <Safari className="box-img-small mr-10" alt="" />,
    },
  };
  const logout = () => {
    dispatch(resetAdminDataAction());
    dispatch(resetExperienceDataAction());
    dispatch(resetInstitutionDataAction());
    dispatch(resetUserDataAction());
    dispatch(resetRefreshDataAction());
    navigate("/login");
  };

  useEffect(() => {
    isRequiredFieldsPassed(state, 3, "eq")
      ? setBtnDisabled(false)
      : setBtnDisabled(true);
  }, [state]);

  useEffect(() => {
    Validation.validatePassword(passwordForm.old_password) &&
    Validation.validatePassword(passwordForm.new_password) &&
    passwordForm.new_password === passwordForm.new_password_confirm
      ? setIsValid(true)
      : setIsValid(false);
  }, [passwordForm]);

  const handleInput = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    setPasswordForm({
      ...passwordForm,
      [e.target.name]: e.target.value,
    });
  };

  const removeItem = (index) => {
    console.log(index);
  };

  const onChangePassword = async () => {
    try {
      setLoading(true);
      await Auth.signIn(user?.username, passwordForm.old_password);
      const data = {
        ...paramsWithUsername(user.username),
        Password: passwordForm.new_password,
        Permanent: true,
      };
      await cognitoidentity.adminSetUserPassword(data).promise();

      setLoading(false);
      setShowModal(false);
      setPasswordChanged(true);
      setTimeout(logout, 5000);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error("Error changing password");
    }
  };

  const removeImage = async () => {
    try {
      toast.loading("Deleting profile picture...");
      const imageSplit = user?.image?.split("/");
      const imageName = imageSplit[imageSplit.length - 1];
      await Storage.remove(imageName);
      const uploadData = [
        {
          Name: "custom:image",
          Value: "",
        },
      ];

      await cognitoidentity
        .adminUpdateUserAttributes({
          UserAttributes: uploadData,
          ...paramsWithUsername(user.username),
        })
        .promise();
      const userInfo = { ...user };
      userInfo.image = "";

      dispatch(setAdminDataAction(userInfo));
      setUser(userInfo);
      setImageProps({
        ...imageProps,
        image: "",
      });
      toast.dismiss();
      toast.success("Successfully removed profile picture");
    } catch (error) {
      console.log(error);
      toast.dismiss();
      toast.error(error.message);
    }
  };

  const handleImageChange = async (e) => {
    const image = e.target.files[0];
    const imageSize = image.size / 1024 / 1024;
    if (imageSize > 1) {
      toast.error("Image size is more than 1mb");
    } else {
      try {
        toast.loading("Uploading Image...");
        const imgUrl = window.URL.createObjectURL(image);
        const data = await Storage.put(`${user?.email}-${image.name}`, image);
        const imageUrl =
          "https://joinjunityapp-admin-storage-d8b007d1212713-staging.s3.amazonaws.com/public/" +
          data.key;

        const uploadData = [
          {
            Name: "custom:image",
            Value: imageUrl,
          },
        ];

        await cognitoidentity
          .adminUpdateUserAttributes({
            UserAttributes: uploadData,
            ...paramsWithUsername(user.username),
          })
          .promise();
        const userData = await cognitoidentity
          .adminGetUser(paramsWithUsername(user.username))
          .promise();
        const userInfo = { ...user };

        userInfo.image = userData.UserAttributes.find(
          (elt) => elt.Name === "custom:image",
        ).Value;

        dispatch(setAdminDataAction(userInfo));
        setUser(userInfo);
        toast.dismiss();
        toast.success("Successfully updated profile picture");

        setImageProps({
          image: imgUrl,
          imageFile: image,
        });
      } catch (error) {
        console.log(error);
        toast.dismiss();
        toast.error("Error uploading image");
      }
    }
  };

  async function updateAdmin() {
    setLoading(true);
    try {
      const data = [
        {
          Name: "name",
          Value: `${state?.firstName} ${state?.lastName}`,
        },
        {
          Name: "phone_number",
          Value: state?.phone_number,
        },
      ];
      if (state?.phone_number !== user.phone_number) {
        data.push({
          Name: "phone_number_verified",
          Value: "true",
        });
      }

      await cognitoidentity
        .adminUpdateUserAttributes({
          UserAttributes: data,
          ...paramsWithUsername(user.username),
        })
        .promise();
      const userData = await cognitoidentity
        .adminGetUser(paramsWithUsername(user.username))
        .promise();
      const userInfo = { ...user };
      userInfo.isAuthenticated = true;
      userInfo.userName = userData.UserAttributes.find(
        (elt) => elt.Name === "name",
      ).Value;
      userInfo.email = userData.UserAttributes.find(
        (elt) => elt.Name === "email",
      ).Value;
      userInfo.phone_number = userData.UserAttributes.find(
        (elt) => elt.Name === "phone_number",
      ).Value;

      dispatch(setAdminDataAction(userInfo));
      setUser(userInfo);
      toast.success("Successfully updated account info");
      setLoading(false);
      setShowUpdateForm(false);
    } catch (error) {
      toast.error("An error occurred try again shortly");
    }
  }

  const getUserDevices = async () => {
    try {
      const resp = await axiosClient.post("/user-devices", {
        userSub: user?.sub,
      });
      setDevices(resp.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  const isCurrentDevice = (device) => {
    return generateDeviceID() === JSON.parse(device || "{}")?.deviceID;
  };

  const signOutDevice = async (deviceID) => {
    try {
      await axiosClient.post("/update-device-status", {
        userID: user?.sub,
        deviceID,
        status: "Remove",
      });
      getUserDevices();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (user) {
      getUserDevices();
    }
  }, [user]);

  return (
    <div className="left_content">
      <div className="flex-between pm-20">
        <div>
          <div className="profile_img">
            {imageProps?.image ? (
              <div className="initial_wrap flex-center">
                <img src={imageProps.image} className="img-fluid" alt="" />
              </div>
            ) : (
              <div
                className="initial_wrap flex-center"
                style={{ backgroundColor: "#515A6A" }}
              >
                {getInitial(user?.userName)}
              </div>
            )}
            <div className="pencil_icon_wrapper">
              <div className="position-relative">
                <div
                  className="pencil_icon cursor-pointer"
                  onClick={() => {
                    setShowDropdown(!showDropdown);
                  }}
                >
                  <Pencil />
                </div>
                <CustomDropdown
                  show={showDropdown}
                  setShow={setShowDropdown}
                  className={"left-0"}
                >
                  <div
                    className="my-2 fs-sm fw-semibold cursor-pointer"
                    onClick={() => {
                      fileRef.current.click();
                    }}
                  >
                    Change Profile Picture
                  </div>
                  <div
                    className={`mt-3 mb-2 fs-sm fw-semibold ${
                      imageProps?.image ? "cursor-pointer" : "inactive"
                    }`}
                    onClick={() => {
                      removeImage();
                    }}
                  >
                    Remove Profile Picture
                  </div>
                </CustomDropdown>
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div
            className="gear_icon cursor-pointer"
            onClick={() => {
              setShowDropdown1(!showDropdown1);
            }}
          >
            <Gear fill="#2E394C" size={18} />
          </div>
          <CustomDropdown show={showDropdown1} setShow={setShowDropdown1}>
            <div
              className="my-2 fs-sm fw-semibold cursor-pointer"
              onClick={() => {
                setShowUpdateForm(!showUpdateForm);
              }}
            >
              Edit My Profile
            </div>
            <div
              className="mt-3 mb-2 fs-sm fw-semibold cursor-pointer"
              onClick={() => {
                setShowModal(!showModal);
              }}
            >
              Change Password
            </div>
          </CustomDropdown>
        </div>
      </div>
      <input
        type="file"
        ref={fileRef}
        className="d-none"
        accept="image/*"
        onChange={handleImageChange}
      />
      {showUpdateForm ? (
        <div className="mbd pm-20">
          <div className="flex-between mt-20">
            <div className="mr-10">
              <label className="form-label">First Name</label>
              <Form.Control
                type="text"
                name="firstName"
                defaultValue={state?.firstName}
                placeholder="First name"
                onChange={handleInput}
              />
            </div>
            <div>
              <label className="form-label">Last Name</label>
              <Form.Control
                type="text"
                name="lastName"
                defaultValue={state?.lastName}
                placeholder="Last name"
                onChange={handleInput}
              />
            </div>
          </div>
          <div className="mt-20">
            <label className="form-label">Work Phone</label>
            <Form.Control
              type="text"
              name="phone_number"
              defaultValue={state?.phone_number}
              placeholder="(406) 555-0120"
              onChange={handleInput}
            />
          </div>
          <div className="mt-20">
            <Button
              variant={btnDisabled ? "light" : "primary"}
              className="w-100"
              onClick={updateAdmin}
              disabled={btnDisabled}
            >
              {loading ? progress() : "Save Profile"}
            </Button>
          </div>
        </div>
      ) : (
        <div className="mbd pm-20">
          <h1 className="account-info--name">{user?.userName}</h1>
          <h3 className="account-info">{user?.role?.name}</h3>
          <h3 className="account-info">{user?.phone_number}</h3>
          <h3 className="account-info">{user?.email}</h3>
        </div>
      )}
      <div className="mt-20 mbd pm-20">
        <h6>MY LOCATIONS</h6>
        <div className="d-flex gap-3 custom-flex-wrap">
          {locations?.map((item, index) => (
            <div
              className={`custom-pill ${index ? "" : "default"}`}
              key={`location${index}`}
            >
              <div className="mr-10" onClick={() => removeItem(index)}>
                <GeoAlt />
              </div>
              <div>
                {item} {index ? "" : "(default)"}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-20 pm-20">
        <h6>MY DEVICES</h6>
        <h6 style={{ color: "#969CA5" }}>Connected Devices</h6>
        <div className="activity_main mt-20">
          {devices.map((item, index) => (
            <div className="activity_content flex-between mt-20" key={index}>
              <div className="flex-v">
                <div className="flex-start">
                  <>{deviceInfos[JSON.parse(item || "{}")?.name]?.logo}</>
                  <div className="flex-v">
                    <div className="align-items">
                      {deviceInfos[JSON.parse(item || "{}")?.name]?.name} (
                      {JSON.parse(item || "{}")?.os}){" "}
                      {isCurrentDevice(item) && (
                        <span
                          className="activity_youth_title"
                          style={{ marginLeft: "5px" }}
                        >
                          - This Device
                        </span>
                      )}
                    </div>
                    <div className="flex flex-v">
                      <span className="activity_youth_title">
                        Version {JSON.parse(item || "{}")?.version}
                      </span>
                      <span className="activity_youth_title">
                        {JSON.parse(item || "{}")?.lastSeen
                          ? `Last seen ${calculateTime(
                              JSON.parse(item || "{}")?.lastSeen,
                            )?.toLowerCase()}`
                          : "Active now"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`activity_content_right ${
                  JSON.parse(item || "{}")?.status === "Logout" && "point"
                }`}
                onClick={
                  JSON.parse(item || "{}")?.status === "Logout"
                    ? () => signOutDevice(JSON.parse(item || "{}")?.deviceID)
                    : () => {}
                }
              >
                {JSON.parse(item || "{}")?.status}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader closeButton={true} className="p-4">
          <ModalTitle className="fs-nm fw-semibold">Change Password</ModalTitle>
        </ModalHeader>
        <ModalBody className="p-4">
          <h5 className="form-control-info mb-4">
            Change your password to keep your account secure, especially if you
            think it has been compromised.
          </h5>
          <div className="form-group mb-3">
            <label className="form-label">Old password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Enter old password"
              name="old_password"
              onChange={handleChange}
            />
          </div>
          <div className="form-group mb-3">
            <label className="form-label">New password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Choose a new password"
              name="new_password"
              onChange={handleChange}
            />
            <div className="fs-sm fw-semibold mt-1">
              Password should be at least 8 characters long and contain a
              combination of alphabets, numbers and special characters.
            </div>
          </div>
          <div className="form-group mb-3">
            <label className="form-label">Confirm new password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Type your new password again"
              name="new_password_confirm"
              onChange={handleChange}
            />
          </div>
          <Button
            className="btn btn-primary w-100"
            onClick={onChangePassword}
            disabled={!isValid}
          >
            {loading ? progress() : "Change Password"}
          </Button>
        </ModalBody>
      </Modal>
      <Modal
        show={passwordChanged}
        onHide={() => {
          setPasswordChanged(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody className="p-5 text-center">
          <img
            src="../images/lock.png"
            className="image-info mx-auto mt-3"
            alt=""
          />
          <h3 className="info-heading mt-4">Password Changed Successfully!</h3>
          <h6 className="info-description mb-4">
            You will now be logged out everywhere and redirected to the log in
            page ...
          </h6>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default LeftContent;
