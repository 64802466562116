import React from 'react';
import { ChevronExpand } from "react-bootstrap-icons"
import { nameCol, verificationCol } from "../../utils/TableWrapper/tableWrapper"


const expandIcon = <span><ChevronExpand fontSize={18}/></span>


export const leftModalActivityList = [
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "youth_title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "youth_title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "youth_title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
  {
    "header": "Applied to Mentorship for Grade 12 Students",
    "img": require("../images/ms_connecticut.png"),
    "youth_title": "Microsoft - Connecticut",
    "time": " 2:59PM"
  },
]


export const youthTableList = 
  {
    "th": [
      {
        "component": "NAME",
        "cls": "name_col",
        "sortable": false,
        "label": "name"
      },
      {
        "component": "LOCATION",
        "cls": "  location_col",
        "sortable": false,
        "label": "location"
      },
      {
        "component": <>VERIFICATION{expandIcon}</>,
        "cls": "  verification_col",
        "sortable": true,
        "label": "verification_status"
      },
      {
        "component": <>LAST SEEN{expandIcon}</>,
        "cls": "  verification_col",
        "sortable": false,
        "label": "lastseen"
      },
    ],

    "tc": [
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "BD",
            name: "Bryce David",
            layout: 1,
            verified: true,
            bottomText: "Joined"
          })
        ],
        ["age_col", "18"],
        ["location_col","Hartford, CT"],
        ["verification_col",verificationCol("Verified")],
        ["last_seen_col", "2 days ago"],
        ["final_col", "..."]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#C19581",
            text: "KW",
            name: "Kristin Watson",
            layout: 1,
            verified: true,
            bottomText: "Joined"
          })
        ],
        ["age_col", "18"],
        ["location_col","Hartford, CT"],
        ["verification_col",verificationCol("Verified")],
        ["last_seen_col", "2 days ago"],
        ["final_col", "..."]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#10408C",
            text: "AF",
            name: "Albert Flores",
            layout: 1,
            verified: false,
            bottomText: "Joined"
          })
        ],
        ["age_col", "18"],
        ["location_col","Hartford, CT"],
        ["verification_col",verificationCol("Verified")],
        ["last_seen_col", "2 days ago"],
        ["final_col", "..."]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#226E54",
            text: "AM",
            name: "Arlene McCoy",
            layout: 1,
            verified: false,
            bottomText: "Joined"
          })
        ],
        ["age_col", "18"],
        ["location_col","Hartford, CT"],
        ["verification_col",verificationCol("Verified")],
        ["last_seen_col", "2 days ago"],
        ["final_col", "..."]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#2E394C",
            text: "TW",
            name: "Theresa Webb",
            layout: 1,
            verified: true,
            bottomText: "Joined"
          })
        ],
        ["age_col", "18"],
        ["location_col","Hartford, CT"],
        ["verification_col",verificationCol("Not Verified")],
        ["last_seen_col", "2 days ago"],
        ["final_col", "..."]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#D47A64",
            text: "DS",
            name: "Darrell Steward",
            layout: 1,
            verified: false,
            bottomText: "Joined"
          })
        ],
        ["age_col", "18"],
        ["location_col","Hartford, CT"],
        ["verification_col",verificationCol("In Progress")],
        ["last_seen_col", "2 days ago"],
        ["final_col", "..."]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#AA130E",
            text: "SN",
            name: "Savannah Nguyen",
            layout: 1,
            verified: true,
            bottomText: "Joined"
          })
        ],
        ["age_col", "18"],
        ["location_col","Hartford, CT"],
        ["verification_col",verificationCol("In Progress")],
        ["last_seen_col", "2 days ago"],
        ["final_col", "..."]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "DR",
            name: "Darlene Robertson",
            layout: 1,
            verified: false,
            bottomText: "Joined"
          })
        ],
        ["age_col", "18"],
        ["location_col","Hartford, CT"],
        ["verification_col",verificationCol("Verified")],
        ["last_seen_col", "2 days ago"],
        ["final_col", "..."]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#226E54",
            text: "JB",
            name: "Jerome Bell",
            layout: 1,
            verified: true,
            bottomText: "Joined"
          })
        ],
        ["age_col", "18"],
        ["location_col","Hartford, CT"],
        ["verification_col",verificationCol("Verified")],
        ["last_seen_col", "2 days ago"],
        ["final_col", "..."]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#10408C",
            text: "DR",
            name: "Dianne Russell",
            layout: 1,
            verified: false,
            bottomText: "Joined"
          })
        ],
        ["age_col", "18"],
        ["location_col","Hartford, CT"],
        ["verification_col",verificationCol("Verified")],
        ["last_seen_col", "2 days ago"],
        ["final_col", "..."]
      ],
    ]
  }


