import { X } from "react-bootstrap-icons"
import React, { useState, useEffect } from 'react';
import { Form } from "react-bootstrap";


function NewItem(props) {

  const { addItem, closeNewItem, data, locations } = {...props}
  const [itemState, setItemState] = useState({})
  const [showDrop, setShowDrop] = useState(false)
  const [results, setResults] = useState(data);

  const handleNewItemChange = (e) => {
    const change = e.target.value
    setItemState({
      ...itemState,
      [e.target.name]: change
    })
    setShowDrop(true)
    setResults([])
  }

  useEffect(() => {
    if (Object.keys(itemState).length > 0){
      setShowDrop(!(itemState["item_name"] === ""))
    }

    if (itemState['item_name']) {
        const regex = new RegExp(itemState['item_name'].toLowerCase(), "g");
        const dataNotSelected = data.filter((elt) => !locations?.includes(elt.name));
        const matches = dataNotSelected.filter(e => regex.test(e.name.toLowerCase()));
        setResults(matches);
    } else {
        setResults(data);
    }
  }, [itemState]);


  return ( 
    <div>
      <div 
        className="new_item flex-between"
        style={{
          backgroundColor: "rgba(244, 245, 247, 0.64)",
          padding: "0 3px",
          borderRadius: 5,
        }}
      >
        <Form.Control
          type="text"
          name="item_name"
          onChange={handleNewItemChange}
          className="add_new_input"
          placeholder="Enter location"
        />
        <div 
          className="align-items point"
          onClick={closeNewItem}
        >
          <X size={20}/>
        </div>
      </div>
      <div
        className={`cities-drop ${showDrop ? "show" : "hide"}`}
      >
        {results.map((elt) =>
            <div
                onClick={() => addItem(elt.name)}
                className="point"
                key={elt.name}
            >
                <h6>{elt.name}</h6>
            </div>
        )}
      </div>
    </div>
  );
}

export default NewItem;