import React, { useEffect, useState } from "react";
import SessionCard from "../sessionCard";
import { GetSession } from "../../../services/query/experienceSession"


function SessionsTab({ item }) {
  const sessions = item?.sessionsIDs
  const [sessionObjs, setSessionObjs] = useState([]);

  const handleSetSessionObjs = async () => {
    const sessionsData = sessions?.reduce(async (acc, elt) => {
      const prev = await acc;
      const sessionData = await GetSession(elt);
      return [ ...prev, sessionData ];
    }, Promise.resolve([]));

    setSessionObjs(await sessionsData);
  }
  
  useEffect(() => {
    handleSetSessionObjs();
  }, [item])
  
  return ( 
    <div className="sessions_tab">
      {sessionObjs?.map((session, index) => 
        <SessionCard
          id={index+1}
          data={session}
          state={"created"}
          key={`sess-card-${index}`}
        />
      )}
    </div>
  );
}

export default SessionsTab;