import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { CloudUpload } from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";
import ModalWrapper from "../../widgets/Modal/modal";
import {setPopupDataAction} from "../../redux/actions/popupActions";
import {useDispatch} from "react-redux";
import AlertToast from "../Common/Alerts/ToastAlert";
import {axiosClient} from "../../libs/axiosClient";
import { useAuth } from "../../templates/DashBoardTemplate";
import { sendAppNotification } from "../../utils/helpers";

export function DeactivationModal(props) {
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { show, setShow, details, previousModal } = { ...props }
  const [activeToast, setActiveToast] = useState(false);
  const [activeState, setActiveState] = useState('');
  const [toast, setToast] = useState('');
  // const [attachments, setAttachments] = useState([]);
  const deactivationReasonRef = useRef(null);

  const dispatch = useDispatch();
  const { user } = useAuth();

  const deactivationReasons = [
    "Violation of Code of Conduct",
    "User requested to de-activate account",
    "Fraud or Scam"
  ]

  const setPeriod = (period, name) => {
    setState({
      ...state,
      [name]: period
    })
  }

  const setDetailReason = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

  const handleChecks = (index) => {
    const mainDiv = deactivationReasonRef.current.children
    for (let i = 1; i < mainDiv.length; i++) {
      const outerDiv = mainDiv[i];
      const checkWrapper = outerDiv.children[0]
      const checkDiv = checkWrapper.children[0];
      checkDiv.style.backgroundColor = "#fff";
    }
    const outerDiv = deactivationReasonRef.current.children[index];
    const checkWrapper = outerDiv.children[0]
    const checkDiv = checkWrapper.children[0];
    if (checkDiv.style.backgroundColor === "rgb(255, 255, 255)") {
      checkDiv.style.backgroundColor = "#10408C";
      setState({
        ...state,
        reason: deactivationReasons[index-1]
      })
    }
    else {
      checkDiv.style.backgroundColor = "#fff";
    }
  }

  const handleToast = (status, message) => {
    setActiveState(status);
    setToast(message);
    setActiveToast(true);
  };

  const getAccountType = () => {
    if (details?.organizationID) {
      return "resource";
    } else if (details?.dob) {
      return "youth"
    } else {
      return "mentor"
    }
  }

  const deactivate = async () => {
    setLoading(true);
    try {
      await axiosClient.post('/deactivation-request', {
        accountID: details?.id,
        accountType: getAccountType(),
        accountName: details?.name,
        reason: state?.reason,
        description: state?.detailed_reason,
        period: state?.period,
        requesterName: user?.userName,
        requesterID: user?.sub,
        attachments: [],
        priority: state?.priority,
        approved: false,
        approverID: "",
        approverName: "",
        secondApproverApproved: false,
        secondApproverID: "",
        secondApproverName: "",
        dateRequested: new Date(),
        organizationID: details?.schoolID || details?.organizationID
      });
      setLoading(false);
      dispatch(setPopupDataAction({ deactivateRequested: true }));
      dispatch(setPopupDataAction({ deactivateModal: false }));
      await sendAppNotification(
        user?.sub,
        'Deactivation request created successfully',
        'success',
        '/account-deactivation',
        'Click here to view',
        'admin',
        'inapp',
        'checkmarkSuccess'
      )
    } catch (err) {
      console.error(err);
      setLoading(false);
      // handleToast("error", err.response?.data?.message);
      await sendAppNotification(
        user?.sub,
        `${details?.school?.name} couldn't be verified.`,
        'error',
        `/account-deactivation`,
        'Click here to view',
        'admin',
        'inapp',
        'slashCircle'
      )
    }
  }

  const closeModal = () => {
    if (previousModal) {
      dispatch(setPopupDataAction({ [previousModal]: true }));
    }
    dispatch(setPopupDataAction({ deactivateModal: false }));
    setShow(false);
  }


  return (
    <>
      <AlertToast
          variant={activeState ? 'success' : 'error'}
          icon={activeState ? 'delete' : 'check'}
          active={activeToast}
          setActive={setActiveToast}
          info={toast}
      />
      <ModalWrapper
        state={`${show ? "show" : ""}`}
      >
        <div className="wrapper p-20">
          <div className="flex-center mt-20">
            <div
              className="top_icon_wrapper"
              style={{ display: show ? "flex" : "none" }}
            >
              <img src={
                require("../../assets/images/mask.png")
              } alt="" />
            </div>
          </div>
          <div className="top_text text-center">
            <h3>Organization Account De-activation</h3>
            <h6>
              Are you sure you want to request the
              de-activation of {details?.school?.name}’s
              account on Junity?
            </h6>
          </div>
          <div className="deactivation_period mt-40 p-20">
            <h6 className="header">De-activation Period</h6>
            <h6>For how long should this account be deactivated?</h6>
            <div className="bottom_btns mt-20">
              <CustomisedButton
                width="120px"
                height="50px"
                text="7 days"
                fw="600"
                onClick={() => setPeriod("7 days", "period")}
                className={`mr-10 deact-btn ${state?.period === "7 days"
                    ? "active"
                    : ""
                  }`}
              />
              <CustomisedButton
                width="120px"
                height="50px"
                text="30 days"
                fw="600"
                onClick={() => setPeriod("30 days", "period")}
                className={`mr-10 deact-btn ${state?.period === "30 days"
                    ? "active"
                    : ""
                  }`}
              />
              <CustomisedButton
                width="140px"
                height="50px"
                text="Indefinitely"
                fw="600"
                onClick={() => setPeriod("Indefinitely", "period")}
                className={`mr-10 deact-btn ${state?.period === "Indefinitely"
                    ? "active"
                    : ""
                  }`}
              />
            </div>
          </div>
          <div
            className="reason_deactivation mt-20 p-20"
            ref={deactivationReasonRef}
          >
            <h6>Reason for De-activation</h6>
            <div className="check_items">
              <div className="flex-start">
                <div
                  className="check_item mr-10 flex-center"
                  onClick={() => handleChecks(1)}
                >
                  <div className="inner_div"></div>
                </div>
                <span>Violation of Code of Conduct</span>
              </div>
            </div>
            <div className="check_items mt-10">
              <div className="flex-start">
                <div
                  className="check_item mr-10 flex-center"
                  onClick={() => handleChecks(2)}
                >
                  <div className="inner_div"></div>
                </div>
                <span>User requested to de-activate account</span>
              </div>
            </div>
            <div className="check_items mt-10">
              <div className="flex-start">
                <div
                  className="check_item mr-10 flex-center"
                  onClick={() => handleChecks(3)}
                >
                  <div className="inner_div"></div>
                </div>
                <span>Fraud or Scam</span>
              </div>
            </div>
          </div>
          <div className="description_sect mt-20 p-20">
            <div className="flex-between">
              <h6>Describe reason for re-activation</h6>
              <div>
                <CloudUpload
                  className="mr-10"
                  fill="#10408C"
                />
                <span>Attach File(s)</span>
              </div>
            </div>
            <div className="input_field mt-10">
              <Form.Control
                as="textarea"
                className="description_textarea"
                placeholder="Describe reason for de-activation"
                name="reason"
                onChange={setDetailReason}
              />
            </div>
            <div className="deactivation_period mt-10 p-20">
              <h6 className="header">Priority</h6>
              <h6 style={{ fontSize: 14}}>How urgent is this?</h6>
              <div className="bottom_btns mt-20">
                <CustomisedButton
                    width="120px"
                    height="40px"
                    text="Low"
                    fw="600"
                    onClick={() => setPeriod("Low", "priority")}
                    className={`mr-10 deact-btn ${state?.priority === "Low"
                        ? "active"
                        : ""
                    }`}
                />
                <CustomisedButton
                    width="140px"
                    height="40px"
                    text="Medium"
                    fw="600"
                    onClick={() => setPeriod("Medium", "priority")}
                    className={`mr-10 deact-btn ${state?.priority === "Medium"
                        ? "active"
                        : ""
                    }`}
                />
                <CustomisedButton
                    width="120px"
                    height="40px"
                    text="Urgent"
                    fw="600"
                    onClick={() => setPeriod("Urgent", "priority")}
                    className={`mr-10 deact-btn ${state?.priority === "Urgent"
                        ? "active"
                        : ""
                    }`}
                />
              </div>
            </div>
            <h6 className="mt-20 bottom_text">
              Other admins will be informed of your request and
              could de-activate this account after reviewing it.
            </h6>
            <div className="mt-20" style={{ display: "flex" }}>
              <CustomisedButton
                text={loading ? "Loading..." : "Request De-activation" }
                bg="#D41811"
                fg="#fff"
                bd="none"
                pd="10px 10px"
                width="48%"
                className="mr-10"
                onClick={deactivate}
              />
              <CustomisedButton
                text="Cancel"
                bg="#fff"
                fg="#515A6A"
                bd="1px solid #DDDFE2"
                pd="10px 10px"
                width="49%"
                onClick={closeModal}
              />
            </div>
          </div>
        </div>

      </ModalWrapper>

    </>
  );
}

export default DeactivationModal;