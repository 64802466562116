import { gql } from "@apollo/client"

export const UPDATE_RESOURCE = gql`
  mutation UpdateResourceMutation(
    $dates: [AWSDateTime]
    $is_verified: Boolean
    $started: AWSDateTime
    $verification_progress: Int
    $reason: String
    $verifier: String
    $detailed_reason: String
    $period: String
    $date: AWSDateTime
    $id: ID!
    $deactivated: Boolean
    $date_deactivated: AWSDateTime
    $verification_status: String
  ) {
    updateResource(
      input: {
        is_verified: $is_verified
        verification_progress: $verification_progress
        verification_date_started: $started
        verification_progress_dates: $dates
        deactivation_reason: $reason
        deactivation_detailed_reason: $detailed_reason
        deactivation_period: $period
        date_requested: $date
        id: $id
        deactivated: $deactivated
        verifier: $verifier
        date_deactivated: $date_deactivated
        verification_status: $verification_status
      }
    ) {
      id
      verification_status
      verification_progress_dates
      verification_progress
      verification_date_started
      State
      address
      connections
      contact_email
      contact_phone
      createdAt
      date_deactivated
      date_requested
      deactivated
      deactivation_detailed_reason
      deactivation_period
      deactivation_reason
      description
      field
      isAdmin
      is_verified
      job_title
      naics_code
      naics_title
      name
      organizationID
      team_size
      updatedAt
      website
      zipcode
    }
  }
`;