import React, {useEffect, useState} from "react";
import LeftModal from "../Youth/LeftModal/LeftModal";
import {useDispatch, useSelector} from "react-redux";
import {setCurrentTableDataAction} from "../../redux/actions/currentTableDataActions";

function TableWrapper(props) {
  const {
    id,
    data,
    tableContent,
    detailsRefetch,
    layout,
    subLayout,
    btnText,
    details,
    sortFunction,
  } = { ...props };

  const [state, setState] = useState({});
  const popups = useSelector((store) => store.popups.data);
  const [showModal, setShowModal] = useState(false);
  const clickExcludes = ["experiences_purchase", "opps_apps"];
  const [currentItem, setCurrentItem] = useState({});
  const [info, setInfo] = useState({});

  const content = [];

  const dispatch = useDispatch();

  const layoutTypes = {
    "resources": "resourcesTable",
    "councelors": "counselorsTable",
    "youth": "youthTable",
    "account-verification": "accountVerificationTable"
  }

  const handleSelect = (index, item) => {
    if (!clickExcludes.includes(layout)) {
      setState({});
      setState({ [index]: true });
      setCurrentItem(item);
      setShowModal(true);
      setInfo(details[index]);
      dispatch(setCurrentTableDataAction({
        [layoutTypes[layout]]: details[index]
      }));
    }
  };

  const createRowList = (data) => {
    const cells = [];
    data?.forEach((elt, index) => {
      cells.push(
        <div className={elt[0]} key={index}>
          {elt[1]}
        </div>,
      );
    });
    return cells;
  };

  useEffect(() => {
    setShowModal(popups?.leftModal);
  }, [popups]);

  useEffect(() => {
    if (id && details?.length && tableContent?.length) {
      const detailIndex = details?.findIndex((elt) => elt.id === id);
      const detail = tableContent[detailIndex];
      handleSelect(detailIndex, detail);
    }
  }, [details])

  return (
    <>
      <div className="table_wrapper table-responsive">
        <div
          className={`table_row ${layout === "opps_apps" ? "opportunity" : ""}`}
        >
          {data.th.map((th, index) => (
            <div
              className={`${th?.cls}`}
              key={index}
              onClick={
                th.sortable
                  ? () => {
                      sortFunction(th.label);
                    }
                  : () => {}
              }
            >
              {th.component}
            </div>
          ))}
        </div>
        {tableContent?.forEach((item, index) => {
          content.push(
            <div
              className={`table_row ${state[index] ? "selected" : ""}`}
              key={index}
              onClick={() => handleSelect(index, item)}
            >
              {createRowList(item)}
            </div>,
          );
        })}
        <>{content}</>
      </div>
      <LeftModal
        item={currentItem}
        btnText={btnText ? btnText : "Deactivate"}
        show={showModal}
        setShowModal={setShowModal}
        layout={layout}
        subLayout={subLayout}
        details={info}
        detailsRefetch={detailsRefetch}
      />
    </>
  );
}

export default TableWrapper;
