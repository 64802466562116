import {
  SET_EXPERIENCE_DATA,
  SET_EXPERIENCE_REFETCH_DATA,
  RESET_EXPERIENCE_DATA,
} from "../constants/experienceConstants";

const initialLoginState = {
  data: {
    image: null,
  },
  refetch: false,
};

const experienceReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_EXPERIENCE_DATA:
      return { ...state, data: action.payload };
    case SET_EXPERIENCE_REFETCH_DATA:
      return { ...state, refetch: !state.refetch };
    case RESET_EXPERIENCE_DATA:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default experienceReducer;
