import { gql } from "@apollo/client";
import { API } from "aws-amplify";

export const GET_CREDIT_MULTIPLIERS = gql`
  query CreditMultiplierQuery {
    listCreditMultipliers (limit: 1000) {
      items {
        id
        multiplier
        condition
        createdAt
        created_by
        credit_history
        duration
        updatedAt
        updated_by
        value
      }
      nextToken
    }
  }
`;


export const GET_CREDIT_MULTIPLIERS_WITH_TOKEN = gql`
  query CreditMultiplierQuery ($limit: Int, $nextToken: String) {
    listCreditMultipliers (limit: $limit, nextToken: $nextToken) {
      items {
        id
        multiplier
        condition
        createdAt
        credit_history
        created_by
        duration
        updatedAt
        updated_by
        value
      }
      nextToken
    }
  }
`;

export async function AllCreditMultipliers() {
  const resp = await API.graphql({ 
    query: GET_CREDIT_MULTIPLIERS,
  });

  const data = resp?.data?.listCreditMultipliers
  let nextToken = data?.nextToken
  let creditMultipliers = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_CREDIT_MULTIPLIERS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listCreditMultipliers

    const previousData = [...creditMultipliers, ...respData.items]
    creditMultipliers = [...previousData];

    nextToken = respData?.nextToken
  }

  return creditMultipliers || [];
}

export const GET_CREDIT_MULTIPLIERS_HISTORY = gql`
  query CreditMultiplierHistoryQuery {
    listCreditMultiplierHistories (limit: 1000) {
      items {
        createdAt
        createdBy
        data
        id
      }
      nextToken
    }
  }
`;


export const GET_CREDIT_MULTIPLIERS_HISTORY_WITH_TOKEN = gql`
  query CreditMultiplierHistoryQuery ($limit: Int, $nextToken: String) {
    listCreditMultiplierHistories (limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        createdBy
        data
        id
      }
      nextToken
    }
  }
`;

export async function AllCreditMultiplierHistories() {
  const resp = await API.graphql({ 
    query: GET_CREDIT_MULTIPLIERS_HISTORY,
  });

  const data = resp?.data?.listCreditMultiplierHistories
  let nextToken = data?.nextToken
  let creditMultiplierHistories = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_CREDIT_MULTIPLIERS_HISTORY_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.creditMultiplierHistories

    const previousData = [...creditMultiplierHistories, ...respData.items]
    creditMultiplierHistories = [...previousData];

    nextToken = respData?.nextToken
  }

  return creditMultiplierHistories || [];
}

export const sortByCreatedAt = async () => {
  const history = await AllCreditMultiplierHistories();
  history.sort((a, b) => {
    const itemA = new Date(a?.createdAt);
    const itemB = new Date(b?.createdAt);
    if (itemA < itemB) return 1;
    if (itemA > itemB) return -1;
    return 0;
  });

  return history || [];
}

export const sortMultipliersByCreatedAtWithLimit = async (limit) => {
  const data = await sortByCreatedAt();
  return data.length === 0 ? [] : data.slice(0, limit);
}