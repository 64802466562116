import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { InfoCircleFill } from "react-bootstrap-icons";
import { GetCategoryWithInterests } from "../../services/query/categories";
// import { interests } from "../../assets/data/interests";


function Interests() {

  const [interests, setInterests] = useState([]);

  const createLabels = (elt) => {
    return (
      <div 
        style={{
          border: "1px solid #DDDFE2",
          color: "#515A6A",
          borderRadius: "18px",
          padding: "8px 10px"
        }}
      >
        {elt.name}
      </div>
    )
  }

  useEffect(() => {
    GetCategoryWithInterests().then((categories) => {
      setInterests(categories)
    });
  }, []);

  return ( 
    <div>
      {interests.map((item, mainIndex) =>
      <div className="mbd pb-20 mb-20" key={mainIndex}>
        <div className="flex-start">
          <h6 className="mr-10">{item.name}</h6>
          <OverlayTrigger
            key={"right"}
            placement={"right"}
            overlay={
              <Tooltip
                id="tooltip-right"
              >
                <div className="flex-v">
                  <div className="bold text-left">
                    {item.name}
                  </div>
                  <div className="mt-10 text-left">
                    {item.description}
                  </div>
                </div>
              </Tooltip>
            }
          >
            <InfoCircleFill 
              fill="#969CA5"
            />
          </OverlayTrigger>
        </div>
        <div className="flex-start">
          {item.interests.map((innerItem, innerIndex) =>
            <span className="mr-10" key={innerIndex}>
              {createLabels(innerItem)}
            </span>
          )}
        </div>
      </div>
      )}
    </div>
  );
}

export default Interests;