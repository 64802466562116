import React, { useState } from 'react';
import ModalWrapper from "../../../widgets/Modal/modal";
import TopSection from "./TopSection";
import EditMultiplier from "./Layouts/EditMultiplier";
import EditEvents from "./Layouts/EditEvents";
import EditValue from './Layouts/EditValue';


function CreditsModal(props) {

  const { show, setShow, type } = { ...props };
  const [active, setActive] = useState(false);
  const [data, setData] = useState([]);
  const [multiplierRefetch, setMultiplierRefetch] = useState(false);
  const [eventRefetch, setEventRefetch] = useState(false);
  const [valueRefetch, setValueRefetch] = useState(false);

  const layouts = {
    "Edit Multipliers": 
      <EditMultiplier 
        setData={setData} 
        active={active} 
        refetch={multiplierRefetch} 
      />,
    "Edit Events per 1 JC": 
      <EditEvents 
        setData={setData} 
        active={active} 
        refetch={eventRefetch} 
      />,
    "Edit JC Value":
      <EditValue
        setData={setData} 
        active={active} 
        refetch={valueRefetch}
      />
  }

  const returnLayout = (type) => {
    if (Object.keys(layouts).includes(type)) {
      return layouts[type];
    }
  }

  const handleSetRefetch = (data) => {
    if (Object.keys(data).includes("onUpdateCreditMultiplier")) {
      setMultiplierRefetch(prev => !prev);
    }
    else if (Object.keys(data).includes("onUpdateCreditEvent")) {
      setEventRefetch(prev => !prev);
    }
    else if (Object.keys(data).includes("onUpdateCreditValue")) {
      setValueRefetch(prev => !prev);
    }
  }

  return ( 
    <ModalWrapper
      state={`${show ? "show" : "hide"}`}
    >
      <div className="credits_modal">
        <TopSection
          type={type}
          setShow={setShow}
          show={show}
          active={active}
          data={data}
          setActive={setActive}
          setRefetch={handleSetRefetch}
        />
        <>{returnLayout(type)}</>
      </div>
    </ModalWrapper>
  );
}

export default CreditsModal;