import { combineReducers } from "redux";
import adminReducer from "./adminReducers";
import institutionReducer from "./institutionReducers";
import userReducer from "./userReducer";
import experience from "./experiencesReducer";
import refreshActions from "./refreshActionsReducer";
import popupReducer from "./popupReducers";
import currentTableDataReducer from "./currentTableDataReducers";
import currentState from "./currentStateReducer";

const rootReducer = combineReducers({
  adminUser: adminReducer,
  institution: institutionReducer,
  educatorUser: userReducer,
  popups: popupReducer,
  currentTablesData: currentTableDataReducer,
  experience,
  refreshActions,
  currentState
});

export default rootReducer;
