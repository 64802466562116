import React, {useState, useEffect} from 'react';
import {Form} from "react-bootstrap";
import {Arrow90degLeft} from "react-bootstrap-icons";
import CustomisedButton from "../../../widgets/Button/Button";
import {ClipLoader} from "react-spinners";
import {axiosClient} from "../../../libs/axiosClient";
import OtpInput from "react-otp-input";
import {useSelector} from "react-redux";
import AlertToast from "../../Common/Alerts/ToastAlert";
import { sendAppNotification } from '../../../utils/helpers';

function CompleteDeactivation(props) {
    const [state, setState] = useState({})
    const [loading, setLoading] = useState(false);
    const [requestCodeLoading, setRequestCodeLoading] = useState(false);
    const [otpToken, setOtpToken] = useState("");
    const [otp, setOtp] = useState('');
    const [activeToast, setActiveToast] = useState(false);
    const [activeState, setActiveState] = useState('');
    const [toast, setToast] = useState('');

    const user = useSelector((store) => store.adminUser.data);

    const [verified, setVerified] = useState(false)
    const {
        deactivate,
        setDeactivate,
        id, item, refetch
    } = {...props}

    // const [updateUser] = useMutation(layoutKeys[layout])

    const progress = () => {
        return (
            <ClipLoader size={10} color={loading ? "#fff" : ""}/>
        )
    }

    // const finishDeactivation = () => {
    //   const inputData = {
    //     id: item.id,
    //     deactivated: true,
    //     date_deactivated: new Date(),
    //   }
    //   updateUser({
    //     variables: { ...inputData },
    //   }).then(() => {
    //     setLoading(false)
    //     status(true)
    //     setDeactivate(false)
    //     setStatus(id, "In progress")
    //     setToast(true)
    //   }).catch((err) => {
    //     console.error(err)
    //   })
    // }

    const requestCode = async () => {
        setRequestCodeLoading(true);
        try {
            const resp = await axiosClient.post('/change-verify', {
                name: user?.userName,
                email: user?.email,
                action: 'deactivation',
                reason: item?.reason,
                period: item?.period,
                account: item?.info?.name
            });
            setOtpToken(resp.data.data);
            setRequestCodeLoading(false);
            await sendAppNotification(
                user?.sub,
                'Check your email for verification codes',
                'success',
                '/account-deactivation',
                'Click here to view',
                'admin',
                'inapp'
            )
        } catch (e) {
            console.error(e);
            setRequestCodeLoading(false);
            await sendAppNotification(
                user?.sub,
                'Error sending verification codes to email',
                'error',
                '/account-deactivation',
                'Click here to view',
                'admin',
                'inapp',
                'slashCircle'
            )
        }
    }


    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleToast = (status, message) => {
        setActiveState(status);
        setToast(message);
        setActiveToast(true);
    };

    const verifyCode = async () => {
        try {
            await axiosClient.post('/verify-code', {
                otp: otp,
                token: otpToken
            });
            setVerified(true);
            await sendAppNotification(
                user?.sub,
                'Codes verification successful',
                'success',
                '/account-deactivation',
                'Click here to view',
                'admin',
                'inapp',
                'checkmarkSuccess'
            )
        } catch (err) {
            console.error(err);
            await sendAppNotification(
                user?.sub,
                'Error verifying codes',
                'error',
                '/account-deactivation',
                'Click here to view',
                'admin',
                'inapp',
                'slashCircle'
            )
        }
    }

    const deactivateAccount = async () => {
        setLoading(true);
        if (!item?.approved) {
            try {
                const result = await axiosClient.patch('/deactivation-request', {
                    id,
                    approved: true,
                    approverID: user?.sub,
                    approverName: user?.userName,
                });

                setLoading(false);
                refetch();
                setDeactivate(false);

                const {data} = result
                const { message } = data;

                await sendAppNotification(
                    user?.sub,
                    message,
                    'success',
                    '/account-deactivation',
                    'Click here to view',
                    'admin',
                    'inapp',
                    'checkmarkSuccess'
                )
            } catch (err) {
                console.error(err);
                setLoading(false);
                await sendAppNotification(
                    user?.sub,
                    'Error updating deactivation request',
                    'error',
                    '/account-deactivation',
                    'Click here to view',
                    'admin',
                    'inapp',
                    'slashCircle'
                )
            }
        } else {
            try {
                await axiosClient.post('/deactivation-complete', {
                    requestId: id,
                    accountType: item?.accountType,
                    accountID: item?.accountID,
                    organizationID: item?.organizationID,
                    secondApproverApproved: true,
                    secondApproverName: user?.userName,
                    secondApproverID: user?.sub,
                });

                setLoading(false);
                refetch();
                setDeactivate(false);
                await sendAppNotification(
                    user?.sub,
                    'Account deactivated successfully',
                    'success',
                    '/account-deactivation',
                    'Click here to view',
                    'admin',
                    'inapp',
                    'checkmarkSuccess'
                )
            } catch (err) {
                console.error(err);
                setLoading(false);
                await sendAppNotification(
                    user?.sub,
                    'Error deactivating account',
                    'error',
                    '/account-deactivation',
                    'Click here to view',
                    'admin',
                    'inapp',
                    'slashCircle'
                )
            }
        }
    }

    useEffect(() => {
        if (otp?.length === 6) {
            verifyCode();
        }
    }, [otp])

    return (
        <>
            <AlertToast
                variant={activeState ? 'success' : 'error'}
                icon={activeState ? 'delete' : 'check'}
                active={activeToast}
                setActive={setActiveToast}
                info={toast}
            />
            <div className={`complete_deactivation p-20 ${deactivate ? "show" : "hide"}`}>
                <div
                    className="return_btn p-20 cursor-pointer"
                    onClick={() => setDeactivate(false)}
                >
                    <Arrow90degLeft className="mr-10"/>
                    <span>Back to Request Details</span>
                </div>
                <div className="top_text request_deactivation_top_text p-20">
                    <h4 className="bold">De-activate {item?.info?.name}’s Account?</h4>
                    <h6>
                        De-activation will suspend all of {item?.info?.name}’s
                        opportunities and connections for the specified period.
                    </h6>
                </div>
                <div className="p-20">
                    To confirm, please type
                    DEACTIVATE in all caps below:
                </div>
                <div className="type_deactivate mt-10 p-20">
                    <Form.Control
                        placeholder="Type DEACTIVATE"
                        name="deactivate_text"
                        onChange={handleChange}
                    />
                </div>
                <div className="flex-between p-20 mt-10">
                    <div className="align-items">
                        Enter Verification Code
                    </div>
                    <div>
                        <CustomisedButton
                            text={requestCodeLoading ? "Loading..." : "Request Code"}
                            fg={state?.deactivate_text !== "DEACTIVATE" ? "#515A6A" : "#fff"}
                            bg={state?.deactivate_text !== "DEACTIVATE" ? "#fff" : "#D41811"}
                            bd={`1px solid ${state?.deactivate_text !== "DEACTIVATE" ? "#DDDFE2" : "#D41811"}`}
                            pd="10px 10px"
                            db={state?.deactivate_text !== "DEACTIVATE"}
                            onClick={requestCode}
                        />
                    </div>
                </div>
                <div className="form_wrapper ph-10">
                    <div className="pd-20 mt-3 otp-input-wrapper">
                        <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            placeholder="000000"
                            inputStyle={"otp-input"}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                        />
                    </div>
                    <div className="mt-20 foot_text">
                        Since you are the first super admin to approve
                        this request, another super admin still has
                        to approve before this user account can be de-activated.
                    </div>
                </div>
                <div className="mt-20 ph-10">
                    <div className="sect_item btns">
                        <button
                            disabled={!verified}
                            onClick={deactivateAccount}
                            className={`complete_deactivation_btn ${verified ? '' : 'disabled'}`}
                        >{loading ? progress() : "Deactivate Account"}</button>

                        <CustomisedButton
                            width="100%"
                            height="40px"
                            bg="white"
                            bd="1px solid #DDDFE2"
                            text="Cancel"
                            fg="#515A6A"
                            fs="15px"
                            fw="700"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default CompleteDeactivation;