import React from "react";
import { Arrow90degLeft, Check } from "react-bootstrap-icons";

export default function ThirdStep({ setCurrentPage }) {
  return (
    <div>
      <div className="wrapper p-20">
        <div
          className="return_btn p-20"
          onClick={() => setCurrentPage("first")}
        >
          <Arrow90degLeft className="mr-10" />
          <span>Back to Account Overview</span>
        </div>
        <div className="check_icon_wrapper mt-40 flex-center">
          <div className="check_icon p-20">
            <Check size={80} />
          </div>
        </div>
        <div className="text-center mt-20">
          <h4 className="bold">Message sent</h4>
          <h6>Your message has been sent. </h6>
        </div>
      </div>
    </div>
  );
}
