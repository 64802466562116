import React, { useRef, useState, useEffect } from "react";
import ModalWrapper from "../../widgets/Modal/modal";
import { X, PlusCircle, ChevronRight } from "react-bootstrap-icons";
import { Form } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import UploadButton from "../../widgets/Upload/UploadBtn";
import { ReactComponent as Sales } from "../../assets/icons/sales.svg";
import CustomisedButton from "../../widgets/Button/Button";
import Switch from "react-switch";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useQuill } from "react-quilljs";
import { createBtnText } from "../../utils/Experiences/utils";
import SessionsModal from "./SessionsModal";
import makeAnimated from "react-select/animated";
import {
  setExperienceDataAction,
  setRetchExperienceDataAction,
} from "../../redux/actions/experience";
import { Storage } from "aws-amplify";
import { CREATE_EXPERIENCE } from "../../services/mutation/experiences";
import {getCities} from "../../utils/helpers";
import { axiosClient, tasksClient } from "../../libs/axiosClient";
import { useMutation } from "@apollo/client";
import { AllStudentsByState } from "../../services/query/students";
import { AllExperiences } from "../../services/query/experiences";

const animatedComponents = makeAnimated();

const toolbarOptions = [
  ["bold", "italic", "underline", "strike", { list: "bullet" }, "link"],
];
const placeholder =
  "Describe this opportunity and why young people should apply.";
const modules = {
  toolbar: toolbarOptions,
  counter: true,
};

function AddExperiences({ show, setShow }) {
  const [imageSelected, setImageSelected] = useState("");
  const { quill, quillRef, Quill } = useQuill({ modules, placeholder });
  const [verified, setVerified] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [requirements, setRequirements] = useState({});
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [sessionsData, setSessionsData] = useState([]);
  const experience = useSelector((store) => store.experience);
  const [createExperience] = useMutation(CREATE_EXPERIENCE);

  const [selectOptions, setSelectOptions] = useState([]);

  const modifiedStates = getCities({
    keys: ["label", "value"],
    requiresAll: false,
    returnObj: true,
    sort: true
  });

  const [defaultSelectValue] = useState([]);
  const [state, setState] = useState({
    is_featured: true,
  });

  const requirementRef = useRef(null);
  const [requirementInputNumber, setRequirementInputNumber] = useState(0);

  const handleSwitch = (checked) => {
    setState({
      ...state,
      is_featured: checked,
    });
  };

  const handleImageUpload = (file) => {
    Storage.put(file.name, file)
      .then((data) => {
        setState({
          ...state,
          image: `https://joinjunityapp-admin-storage-d8b007d1212713-staging.s3.amazonaws.com/public/${data.key}`,
        });
        setImageSelected(data.key);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setImage = (file) => {
    if (imageSelected !== "") {
      Storage.remove(imageSelected).then(() => {
        handleImageUpload(file);
      });
    } else {
      handleImageUpload(file);
    }
  };

  const handleInputs = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleTypeChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleRequirementChange = (e) => {
    setRequirements({
      ...requirements,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (e) => {
    const partnersSelected = [];
    e.forEach((elt) => {
      partnersSelected.push(elt.value);
    });
    setState({
      ...state,
      partners: partnersSelected,
    });
  };

  const [requirementInputs, setRequirementInputs] = useState([
    <Form.Control
      as="textarea"
      className="mv-10"
      onChange={handleRequirementChange}
      name={`reqInpt_${requirementInputNumber}`}
      style={{ fontSize: 13 }}
      placeholder="List a requirement, e.g. Stable internet connection"
      key={1}
    />,
  ]);

  const requirementInput = (id) => {
    return (
      <Form.Control
        as="textarea"
        name={`reqInpt_${id}`}
        onChange={handleRequirementChange}
        className="mv-10"
        style={{ fontSize: 13 }}
        placeholder="List a requirement, e.g. Stable internet connection"
      />
    );
  };

  const addNewRequirementInput = () => {
    if (requirementInputNumber < 3) {
      setRequirementInputs([
        ...requirementInputs,
        requirementInput(requirementInputNumber + 1),
      ]);

      setRequirementInputNumber(requirementInputNumber + 1);
    }
  };

  const proceedToSessions = () => {
    dispatch(
      setExperienceDataAction({
        ...state,
        requirements: Object.values(requirements),
        description: quill.getText(),
      }),
    );

    setProceed(true);
  };

  const maxDate = () => {
    const date = new Date();
    date.setDate(date.getDate() + 60);
    return date;
  };

  if (Quill && !quill) {
    Quill.register("modules/counter", function (quill) {
      const limit = 200;
      quill.on("text-change", function () {
        if (quill.getLength() > limit) {
          quill.deleteText(limit, quill.getLength());
        }
      });
    });
  }

  const getAllPartners = async () => {
    const partnersResp = await axiosClient.post("/schools", {
      limit: 1000,
      fields: ["name"],
    });
    const dataOptions = partnersResp.data.data.map((elt) => {
      return { label: elt.name, value: elt.name };
    });
    setSelectOptions(dataOptions);
  };

  const addExperience = async (draft = false) => {
    setLoading(true);
    try {
      const inputData = proceed ? experience?.data : state;
      inputData.closing_date = new Date(inputData?.closing_date);
      inputData.sessionsIDs = sessionsData;
      inputData.is_draft = draft;

      await createExperience({
        variables: { ...inputData },
      })
      setLoading(false);
      if (!draft) {
        setToastShow(true);
      }
      setShow(false);
      setProceed(false);
      setState({});
      dispatch(setRetchExperienceDataAction());
      if (!draft){
        const experiences = await AllExperiences(3)
        const studentsPromises = experiences.map(async(experience)=>{
          return await AllStudentsByState(experience?.location)
        })
        let response = await Promise.all(studentsPromises)
        let students = response[0]
        
        const emailPromises = students?.map(async(student)=>{
          let selectedExperiences = []
          experiences.forEach((experience)=>{
            let slots = 0
            experience?.sessions.forEach(async(session)=>{
              slots += session?.slots
            })

            selectedExperiences.push(
              {
                image: experience?.image, 
                title: experience?.title, 
                slots: slots, 
                price: experience?.price_per_person,
                runningOut: slots < 5
              }
            )
          })
          return await tasksClient.post('/email', {
            email: student?.email,
            template: 'upcoming-experiences',
            subject: 'Upcoming Experiences',
            context: {
              name: student?.name,
              fName: student?.name?.split(' ')[0],
              email: student?.email,
              experiences: selectedExperiences
            }
          })
        })

        await Promise.all(emailPromises)
      }
      
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (quill !== undefined) {
      quill.clipboard.dangerouslyPasteHTML("<h4>Write a description</h4>");
    }

    try {
      getAllPartners();
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (
      state?.title?.trim() !== "" &&
      state?.partners !== undefined &&
      state?.partners?.length <= 3
    ) {
      if (state?.image !== undefined && state?.image !== "") {
        setVerified(true);
      }
    } else {
      setVerified(false);
    }
  }, [state, requirements]);

  return (
    <>
      <ModalWrapper state={`${show ? "show" : "hide"}`}>
        <div className="add_experience">
          <div className="mbd">
            <div className="flex-between top_sect">
              <div className="align-items bold">Add New Experience</div>
              <div className="right_side" onClick={() => setShow(false)}>
                <X fill="#515A6A" size={24} />
              </div>
            </div>
          </div>
          <div className="main_content mt-10 overflow-auto pd-20">
            <h3 className="bold">About this Experience</h3>
            <div className="mbd p-20 sect_1">
              <h6 className="mt-10">Featured Image</h6>
              <div className="mt-10">
                <UploadButton setImage={setImage} />
              </div>
            </div>
            <div className="mbd p-20 flex-start">
              <Switch
                onChange={handleSwitch}
                checked={state.is_featured}
                onColor="#AA8545"
                uncheckedIcon={false}
                checkedIcon={false}
                className="mr-10"
              />
              <span>This is a featured experience</span>
            </div>
            <div className="field mt-10 p-20">
              <h6>Title</h6>
              <Form.Control
                name="title"
                onChange={handleInputs}
                placeholder="e.g. Zoom Call with Lebron James"
              />
            </div>
            <div className="field mt-10 p-20">
              <h6>Price per Person (in JC)</h6>
              <div className="input_with_icon price">
                <div className="align-items">
                  <Sales fontSize={100} />
                </div>
                <Form.Control
                  placeholder="0.00"
                  name="price"
                  className="price_input"
                  onChange={handleInputs}
                />
              </div>
            </div>
            <div className="field mt-10 p-20">
              <h6>Location</h6>
              <div className="input_with_icon">
                <Form.Select name="location" onChange={handleInputs}>
                  <option value={0}>Select location</option>
                  {modifiedStates.map((elt, index) => (
                    <option value={elt.value} key={index}>
                      {elt.label}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </div>
            <div className="field mt-10 p-20">
              <h6>Closing date</h6>
              <div className="subtitle">
                Last day to purchase this experience
              </div>
              <div className="input_with_icon mt-10">
                <Form.Control
                  type="date"
                  name="closing_date"
                  min={new Date().toISOString().slice(0, 10)}
                  max={maxDate().toISOString().slice(0, 10)}
                  onChange={handleInputs}
                  placeholder="---- Choose a date ----"
                />
              </div>
            </div>
            <div className="field mt-10 p-20 mbd">
              <h6>Type of Experience</h6>
              <div className="flex-start">
                <CustomisedButton
                  text="In-person Experience"
                  bg="#fff"
                  bd="1px solid #DDDFE2"
                  pd="10px 10px"
                  rd="24px"
                  fs="13px"
                  onClick={() => handleTypeChange("type", "inperson")}
                  className={`mr-10 type_btn ${
                    state?.type === "inperson" ? "active" : ""
                  }`}
                />
                <CustomisedButton
                  text="Online Experience"
                  bg="#fff"
                  bd="1px solid #DDDFE2"
                  pd="10px 10px"
                  rd="24px"
                  fs="13px"
                  onClick={() => handleTypeChange("type", "online")}
                  className={`type_btn ${
                    state?.type === "online" ? "active" : ""
                  }`}
                />
              </div>
            </div>
            <div className="field mt-10 p-20 position-relative">
              <h6>Describe this Experience</h6>
              <div className="">
                <div ref={quillRef} />
              </div>
            </div>
            <div className="field requirements-sect p-20">
              <h6>Requirements</h6>
              <div className="subtitle">
                What will the participants need? (at least one requirement is
                needed)
              </div>
              <div ref={requirementRef}>{requirementInputs}</div>
              <CustomisedButton
                text={createBtnText(
                  "Add New Requirement",
                  <PlusCircle fill="#000" />,
                )}
                bg="rgba(244, 245, 247, 0.64)"
                fg="#000"
                bd="none"
                pd="10px 10px"
                fs="13px"
                onClick={addNewRequirementInput}
              />
            </div>
            <div className="field mt-10 p-20">
              <h6>Partners</h6>
              <div className="subtitle mb-2">
                Add up to a maximum of 3 partners for this experience
              </div>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={selectOptions}
                placeholder="Start typing ..."
                name="partners"
                defaultValue={defaultSelectValue}
                onChange={handleSelectChange}
              />
            </div>
            <div className="field mt-10 p-20 flex-between">
              <CustomisedButton
                text="Save as draft"
                bg="#fff"
                bd="1px solid #DDDFE2"
                pd="10px 10px"
                rd="10px"
                fs="13px"
                width="40%"
                className="mr-10"
                onClick={() => addExperience(true)}
              />
              <CustomisedButton
                text={
                  <div className="flex-between">
                    <span>Proceed to Sections</span>
                    <span className="align-items">
                      <ChevronRight />
                    </span>
                  </div>
                }
                bg="#fff"
                bd="1px solid #DDDFE2"
                db={!verified}
                pd="10px 10px"
                rd="10px"
                fs="13px"
                width="56%"
                className={`add_exp_btn ${verified ? "" : "fade"}`}
                onClick={proceedToSessions}
              />
            </div>
          </div>
        </div>
      </ModalWrapper>
      <SessionsModal
        show={proceed}
        setShow={setProceed}
        toastShow={toastShow}
        setToastShow={setToastShow}
        loading={loading}
        sessionsData={sessionsData}
        setSessionsData={setSessionsData}
        title={state["title"]}
        addExperience={addExperience}
      />
      <ToastContainer />
    </>
  );
}

export default AddExperiences;
