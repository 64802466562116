import React from 'react';
import { ChevronRight } from "react-bootstrap-icons"
import CustomisedButton from "../../widgets/Button/Button"


export const alignHorizontal = (img, text) => {
  return (
    <div className="flex-start">
      <div>
        <img 
          src={img}
          className="small_img mr-10"
          style={{borderRadius: 5}}
        />
      </div>
      <div>
        {text}
      </div>
    </div>
  )
}

export const textLabel = (label) => {

  const confirmed = (
    <CustomisedButton
      bg="#DAF8EE"
      fg="#226E54"
      bd="1px solid #A1EED4"
      rd="18px" 
      pd="8px"
      fs="14px"
      text="Confirmed"
    />
  )

  const canceled = (
    <CustomisedButton
      bg="#DDDFE2"
      fg="#515A6A"
      bd="1px solid #DDDFE2"
      rd="18px" 
      pd="8px"
      fs="14px"
      text="Canceled"
    />
  )

  const labels = {
    "confirmed": confirmed,
    "canceled": canceled
  }

  return labels[label]
}

export const passDate = (date) => {
  return (
    <div className="flex-between">
      <span className="align-items">{date}</span>
      <span className="align-items">
        <ChevronRight/>
      </span>
    </div>

  )
}