import React, { useState } from "react";
import RightContent from "./RightContent";
import LeftContent from "./LeftContent";


function MyAccount() {
//   const [view, setView] = useState(false)
//   const [current, setCurrent] = useState({})
  const [id, ] = useState("")

//   const noView = () => {
//     return (
//       <>
//         <div><InfoCircle fill="#B9BDC3" size={30}/></div>
//         <div className="mt-10">Select a request to review and response</div>
//       </>
//     )
//   }

//   const activateRightSection = (id) => {
//     setView(true)
//     setId(id)
//   }

  return (  
    <div className="justify-content-around mb-3 myaccount_grid row">
      <div 
        className="account_v_main_content col-lg-6 pt-0 px-2 pe-lg-4">
        <LeftContent/>
      </div>
      <div className="view_account col-lg-6 mt-lg-0 px-2 ps-lg-4 rounded-4">
        <RightContent 
          id={id}
        /> 
      </div>
    </div>
  );
}

export default MyAccount;