import React, { useEffect, useRef, useState } from 'react'
import { InfoCircle } from "react-bootstrap-icons";
import RightContent from "./RightContent";
import { axiosClient } from "../../libs/axiosClient";
import { useDispatch, useSelector } from 'react-redux';
import { setRefreshDataAction } from '../../redux/actions/refreshActions';
import { getRandomColor } from '../../utils/helpers';


function RolesAndPermissionsTab() {
  const rolesRefetch = useSelector((store) => store.refreshActions.rolesRefetch)
  const [view, setView] = useState(false)
  const [current, setCurrent] = useState({});
  const [roles, setRoles] = useState([]);
  const [modules, setModules] = useState([]);
  const [id, setId] = useState("");
  const [adminUsers, setAdminUsers] = useState([]);

  const dispatch = useDispatch();
  const randomColors = useRef([]);

  const noView = () => {
    return (
      <>
        <div><InfoCircle fill="#B9BDC3" size={30}/></div>
        <div className="mt-10">Select a request to review and response</div>
      </>
    )
  }

  const activateRightSection = (id) => {
    setId(id);
    setView(true)
  }

  const handleRightSectionPop = (id) => {
    setCurrent({[id]: true});
    activateRightSection(id);
  }

  const getUserCount = (roleId) => {
    return adminUsers.reduce((count, user) => {
      return user?.Attributes["custom:role"] === roleId ? ++count : count;
    }, 0);
  }

  const getAllRoles = async () => {
    try {
      const resp = await axiosClient.get('/permission-role');
      setRoles(resp.data.data.roles);
      setModules(resp.data.data.modules);
      const userResp = await axiosClient.post('/admin-users-sanitize', {
        data: [ 'custom:position', 'custom:role', 'name']
      });
      setAdminUsers(userResp.data.data);
      randomColors.current = userResp.data.data.map(() => getRandomColor());

    } catch (err) {
      console.error(err);
    }
  }
  
  useEffect(() => {
    getAllRoles();
  }, []);

  useEffect(() => {
    if (rolesRefetch) {
      getAllRoles();
    }

    return () => {
      dispatch(setRefreshDataAction({ rolesRefetch: false }));
    }
  }, [rolesRefetch]);

  return (  
    <div className="flex-start h-100">
      <div className="account_v_main_content w-100 overflow-auto">
        {roles.map((elt) => 
          <div 
            style={{
              height: "100px",
              backgroundColor: current[elt.id] ? "#FFF4E1" : "#fff",
              borderRadius: 15,
              marginTop: "1%",
              padding: "20px 20px",
              border: `1px solid ${current[elt.id] ? "#FFE3B3" : "#fff"}`
            }}
            className="point"
            key={elt.id}
            onClick={() => handleRightSectionPop(elt.id)}>
            <h4 className="bold">{elt.name}</h4>
            <h6>{getUserCount(elt.id)} users</h6>
          </div>  
        )}
      </div>
      <div className={`view_account w-100 mt-0 ${view ? "" : "align_middle"}`}>
        {view 
          ? <RightContent 
              id={id}
              modules={modules}
              colors={randomColors}
              users={adminUsers.filter((elt) => elt?.Attributes["custom:role"] === id)}
              items={roles.find((elt) => elt.id === id)}
            /> 
          : noView()
        }
      </div>
    </div>
  );
}

export default RolesAndPermissionsTab;