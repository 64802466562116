import {
    SET_POPUP_DATA,
    RESET_POPUP_DATA,
} from "../constants/popupConstants";

const initialLoginState = {
    data: {
        deactivateModal: false,
        activationModal: false,
        overviewDeactivation: false,
        deactivateRequested: false,
    },
};

const popupReducer = (state = initialLoginState, action) => {
    switch (action.type) {
        case SET_POPUP_DATA:
            return { ...state, data: { ...state.data, ...action.payload } };
        case RESET_POPUP_DATA:
            state = initialLoginState;
            return state;
        default:
            return state;
    }
};

export default popupReducer;
