import React from 'react';
import { Person, Gear, ArrowBarRight,  
  ChevronRight, Trash, HouseDoor, HouseDoorFill, ShieldFill, Shield, ChatLeftDotsFill, ChatLeftDots, BagCheckFill, BagCheck, Sliders2Vertical, GearFill 
} from "react-bootstrap-icons"
import { Form } from "react-bootstrap"
import {ReactComponent as PeopleFill} from "../icons/3_UserFill.svg"
import {ReactComponent as People} from "../icons/3_User.svg"


export const accountMenu = [
  {
    "leftIcon": <Person size={24} className="mr-20"/>,
    "title": "My Account",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Gear size={24} className="mr-20"/>,
    "title": "Admin Settings",
    "rightIcon": "",
    "divide": true,
  },
  {
    "leftIcon": <ArrowBarRight size={24} className="mr-20"/>,
    "title": "Log Out",
    "rightIcon": "",
    "divide": false,
  },
]

export const topSectionMenu = [
  {
    "leftIcon": "",
    "title": "All time",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Past 7 days",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Past 30 days",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Custom data range",
    "rightIcon": <ChevronRight/>,
    "divide": false,
  },
]

export const youthAgeFilter = [
  {
    "leftIcon": "",
    "title": "13 yrs",
    "value": '13',
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "14 yrs",
    "value": '14',
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "15 yrs",
    "value": '15',
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "16 yrs",
    "value": '16',
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "17 yrs",
    "value": '17',
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "18 yrs",
    "value": '18',
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "19 yrs",
    "value": '19',
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "20 yrs",
    "value": '20',
    "rightIcon": "",
    "divide": false,
  },
]

export const youthLocationFilter = [
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "Danbury, CT",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "Stamford, CT",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "Greenwich, CT",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "Hartford, CT",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "New Britain, CT",
    "rightIcon": "",
    "divide": false,
  },
  
  {
    "leftIcon": <Form.Check type="checkbox" className="menu_chkbox"/>,
    "title": "Norwalk, CT",
    "rightIcon": "",
    "divide": false,
  },
]

export const resourcesLocationFilter = [
  {
    "leftIcon": "",
    "title": "1 location",
    "value": "1",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "2-4 locations",
    "value": "2-4",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "5-10 locations",
    "value": "5-10",
    "rightIcon": "",
    "divide": false,
  },
]

export const youthVerificationFilter = [
  {
    "leftIcon": "",
    "title": "Verified",
    "value": "verified",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "In progress",
    "value": "in_progress",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Not verified",
    "value": "unverified",
    "rightIcon": "",
    "divide": false,
  },
]

export const teamDropdown = [
  {
    "leftIcon": "",
    "title": "Assign Admin",
    "value": "assign_admin",
    "rightIcon": "",
    "divide": false,
    "color": "#515A6A"
  },
  {
    "leftIcon": "",
    "title": "Remove member",
    "value": "remove_member",
    "rightIcon": "",
    "divide": false,
    "color": "#D41811"
  },
]

export const resourcesVerificationFilter = [
  {
    "leftIcon": "",
    "title": "Verified",
    "value": "verified",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "In progress",
    "value": "in_progress",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Not verified",
    "value": "unverified",
    "rightIcon": "",
    "divide": false,
  },
]

export const youthFilter = [
  {
    "key": true,
    "leftIcon": <b>Select a filter</b>,
    "title": "",
    "rightIcon": 
      <div className="filter_title">
        Clear All&nbsp;
        <Trash size={15} className="filter_trash_icon"/>
      </div>,
    "divide": true,
  
  },

  {
    "leftIcon": "",
    "title": "Age",
    "rightIcon": <ChevronRight size={15} className="right_icon"/>,
    "divide": true,
    "innerItem": youthAgeFilter,
  },
  {
    "leftIcon": "",
    "title": "Location",
    "rightIcon": <ChevronRight size={15} className="right_icon"/>,
    "divide": true,
    "innerItem": youthLocationFilter,
  },
  {
    "leftIcon": "",
    "title": "Junity Influencer",
    "rightIcon": <ChevronRight size={15} className="right_icon"/>,
    "innerItem": youthLocationFilter,
    "divide": true,
  },
  {
    "leftIcon": "",
    "title": "Verification Status",
    "rightIcon": <ChevronRight size={15} className="right_icon"/>,
    "divide": false,
    "innerItem": youthVerificationFilter,
  },
]


export const RequestedByFilter = [
  {
    "leftIcon": "",
    "title": "Darrell Steward",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Jerome Bell",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Theresa Webb",
    "rightIcon": "",
    "divide": false,
  },
]


export const DeactivationStatusFilter = [
  {
    "leftIcon": "",
    "title": "Pending - 2 approvals",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Pending - 1 approvls",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Confirmed",
    "rightIcon": "",
    "divide": false,
  },
  {
    "leftIcon": "",
    "title": "Rejected",
    "rightIcon": "",
    "divide": false,
  },
]

export const sideBarActives = {
  "dashboard": {
    "active": <HouseDoorFill className="mr-10" size={20}/>,
    "not_active": <HouseDoor className="mr-10" size={20}/>
  },
  "users": {
    "active": <PeopleFill className="mr-10" size={20}/>,
    "not_active": <People className="mr-10" size={20}/>
  },
  "account_center": {
    "active": <ShieldFill className="mr-10" size={20}/>,
    "not_active": <Shield className="mr-10" size={20}/>
  },
  "feedback": {
    "active": <ChatLeftDotsFill className="mr-10" size={20}/>,
    "not_active": <ChatLeftDots className="mr-10" size={20}/>
  },
  "store_main": {
    "active": <BagCheckFill className="mr-10" size={20}/>,
    "not_active": <BagCheck className="mr-10" size={20}/>
  },
  "configuration": {
    "active": <Sliders2Vertical className="mr-10" size={20}/>,
    "not_active": <Sliders2Vertical className="mr-10" size={20}/>
  },
  "admin_settings": {
    "active": <GearFill className="mr-10" size={20}/>,
    "not_active": <Gear className="mr-10" size={20}/>
  },
}


export const subs = {
  "users": ["youth", "resources", "councelors"],
  "account_center": ["account-verification", "account-deactivation"],
  "store": ["store", "experiences", "purchases"],
  "configuration": ["interests", "credits"],
  "admin_settings": ["administrators", "roles-and-permissions"],
}