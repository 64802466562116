import React, { useState, useEffect, createContext, useContext } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import DashBoardPage from "../pages/Admin/DashboardPage";
import NavSideBar from "../components/Admin/NavSidebar";
import TopMostSection from "../components/Admin/TopMostSection";
import TopSection from "../components/Admin/TopSection";
import YouthPage from "../pages/Admin/YouthPage";
import ResourcesPage from "../pages/Admin/ResourcesPage";
import CouncelorsPage from "../pages/Admin/CouncelorsPage";
import AccountVerificationPage from "../pages/Admin/AccountVerificationPage";
import AccountDeactivationPage from "../pages/Admin/AccountDeactivationPage";
import FeedBackCenterPage from "../pages/Admin/FeedBackCenterPage";
import ExperiencesPage from "../pages/Admin/ExperiencesPage";
import PurchasesPage from "../pages/Admin/PurchasesPage";
import InterestsPage from "../pages/Admin/InterestsPage";
import CreditsPage from "../pages/Admin/CreditsPage";
import AdministratorsPage from "../pages/Admin/AdministratorsPage";
import RolesAndPermissionsPage from "../pages/Admin/RolesAndPermissionsPage";
import MyAccountPage from "../pages/Admin/MyAccountPage";
import StorePage from "../pages/Admin/Store";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { onCreateAppNotification, onUpdateUserDevice } from "../graphql/subscriptions";
import { resetAdminDataAction } from "../redux/actions/adminActions";
import { resetExperienceDataAction } from "../redux/actions/experience";
import { resetInstitutionDataAction } from "../redux/actions/institutionAction";
import { resetUserDataAction } from "../redux/actions/userAction";
import { resetRefreshDataAction } from "../redux/actions/refreshActions";
import { axiosClient } from "../libs/axiosClient";
import { generateDeviceID } from "../utils/helpers";
import { ReactComponent as CheckmarkWarning } from '../assets/images/checkmark-warning.svg';
import { ReactComponent as CheckmarkSuccess } from '../assets/images/checkmark-success.svg';
import { AppNotifications } from "../components/Notification";
import "../scss/Template/dashboardTemplate.scss";

export default function DashBoardTemplate(props) {
  const { path, header, subHeader } = props;

  return (
    <>
      <Routes>
        <Route
          exact
          path={path}
          element={
            <PageWrapper path={path} header={header} subHeader={subHeader} />
          }
        />
      </Routes>
    </>
  );
}

const routeHandler = (path, data) => {
  const routes = {
    "/dashboard": <DashBoardPage />,
    "/youth": <YouthPage />,
    "/resources": <ResourcesPage />,
    "/councelors": <CouncelorsPage />,
    "/account-verification": <AccountVerificationPage />,
    "/account-deactivation": <AccountDeactivationPage />,
    "/feedback": <FeedBackCenterPage />,
    "/store": <StorePage />,
    "/experiences": <ExperiencesPage experiences={data} />,
    "/purchases": <PurchasesPage />,
    "/interests": <InterestsPage />,
    "/credits": <CreditsPage />,
    "/administrators/:id": <AdministratorsPage />,
    "/administrators": <AdministratorsPage />,
    "/roles-and-permissions": <RolesAndPermissionsPage />,
    "/my-account": <MyAccountPage />,
  };

  if (Object.keys(routes).includes(path)) {
    return routes[path];
  }
};

export const PageContext = createContext()

const PageContentProvider = ({children}) => {
  const [filterByDate, setFilterByDate] = useState([]);
  const user = useSelector((store) => store.adminUser.data);
  const [notificationsList, setNotificationsList] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const pathname = useLocation().pathname
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = async() => {
    dispatch(resetAdminDataAction());
    dispatch(resetExperienceDataAction());
    dispatch(resetInstitutionDataAction());
    dispatch(resetUserDataAction());
    dispatch(resetRefreshDataAction());
    await setDeviceStatus("Logout");
    window.localStorage.clear()
    window.sessionStorage.clear()
    navigate('/');
  };

  const setDeviceStatus = async (status) => {
    await axiosClient.post("/update-device-status", {
      userID: user?.sub,
      deviceID: generateDeviceID(),
      status,
    });
  };

  const createPushMessage = (notification) => {
    return {
      body: notification?.text,
      timestamp: new Date(notification?.time),
      silent: false,
      icon: notification?.icon
    }
  }

  const createPushNotification = (messageObject) => {
    if (document.visibilityState === 'visible') {
      return;
    }
    let notification = null
    if (Notification.permission === 'granted') {
      notification = new Notification('Junity Admin', {
        ...messageObject
      });
    }

    if (notification && messageObject?.linkUrl) {
      notification.onclick = ()=>{
        window.location.href = window.location.origin + messageObject?.linkUrl
      }
    }
  };

  const addNotification = (notification) => {
    setNotificationsList(prev => {
        let newArray = prev
        if (!prev.includes(notification)){
            newArray = [...prev, notification]
        }
        return newArray
    });
    setAllNotifications(prev => {
        let newArray = prev
        if (!prev.includes(notification)){
            newArray = [...prev, notification]
        }
        return newArray
    });
    if (notification?.type === 'push' || notification?.type === 'both'){
      createPushNotification(createPushMessage(notification))
    }
  };

  const removeNotification = (index) => {
    let newNotifications = [...notificationsList];
    newNotifications.splice(index, 1);
    setNotificationsList(newNotifications);
  };

  const clearNotifications = () => {
    setNotificationsList([]);
    setAllNotifications([])
  };

  useEffect(() => {
    let notifier1;
    let notifier2;

    notifier1 = API.graphql({
      query: onCreateAppNotification,
      variables: {userId: user?.sub, module: 'admin'}
    }).subscribe({
      next: async(data) => {
        const noti = data?.value?.data?.onCreateAppNotification
        addNotification({
          status: noti?.title,
          text: noti?.text,
          linkUrl: noti?.link,
          linkTitle: noti?.action,
          icon: <CheckmarkSuccess/>,
          type: noti?.type,
          time: new Date().toISOString(),
          shown: false
        })
      },
    });

    notifier2 = API.graphql({
      query: onCreateAppNotification,
      variables: {userId: '', module: 'admin'}
    }).subscribe({
      next: async(data) => {
        const noti = data?.value?.data?.onCreateAppNotification
        addNotification({
          status: noti?.title,
          text: noti?.text,
          linkUrl: noti?.link,
          linkTitle: noti?.action,
          icon: <CheckmarkSuccess/>,
          type: noti?.type,
          time: new Date().toISOString(),
          shown: false
        })
      },
    });

    return () => {
      notifier1.unsubscribe()
      notifier2.unsubscribe()
    };
    
  }, [pathname])

  return (
    <PageContext.Provider value={{
      filterByDate, 
      notificationsList, 
      allNotifications,
      user,
      setFilterByDate,
      addNotification, 
      removeNotification, 
      clearNotifications,
      logout 
      }}>
        <AppNotifications 
          notifications={notificationsList} 
          removeNotification={removeNotification}
        />
      {children}
    </PageContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(PageContext);
};

const PageWrapper = (props) => {
  const { path, header, subHeader } = props;
  const navigate = useNavigate();
  const [experiences, setExperiences] = useState([]);
  const refetchExperiences = useSelector((store) => store.experience.refetch);
  const userData = useSelector((store) => store.adminUser.data);
  const pathname = path.split("/")[1];
  const [sideBarDraw, setSideBarDraw] = useState(true);

  const excludes = ["experiences", "my-account", "interests", "feedback"];
  const dispatch = useDispatch();

  const logout = async () => {
    dispatch(resetAdminDataAction());
    dispatch(resetExperienceDataAction());
    dispatch(resetInstitutionDataAction());
    dispatch(resetUserDataAction());
    dispatch(resetRefreshDataAction());
    await setDeviceStatus("Logout");
    navigate("/login");
  };

  const setDeviceStatus = async (status) => {
    await axiosClient.post("/update-device-status", {
      userID: userData?.sub,
      deviceID: generateDeviceID(),
      status,
    });
  };

  const signOutDevice = async (data) => {
    const findDeviceIndex = data?.devices?.findIndex(
      (elt) => JSON.parse(elt || "{}")?.deviceID === generateDeviceID(),
    );
    if (findDeviceIndex === -1) {
      logout();
    } else {
      const oldStatus = JSON.parse(
        data?.devices[findDeviceIndex] || "{}",
      )?.status;
      const newStatus = "Online";
      if (oldStatus !== newStatus) {
        await setDeviceStatus("Online");
      }
    }
  };

  const deviceInactive = async () => {
    if (document.visibilityState === "hidden") {
      try {
        await setDeviceStatus("Logout");
      } catch (err) {
        console.error(err);
      }
    }
  };

  const getAllExperiences = async () => {
    try {
      const resp = await axiosClient.post("/experiences", {
        fields: [
          "id",
          "image",
          "title",
          "likes",
          "liked_by",
          "is_featured",
          "closing_date",
          "sessionsIDs",
          "is_draft",
          "price_per_person",
          "partners",
        ],
      });
      setExperiences(resp.data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllExperiences();
  }, [refetchExperiences]);

  useEffect(() => {
    const userDeviceSubscription = API.graphql({
      query: onUpdateUserDevice,
      variables: {
        userID: userData?.sub,
      },
    }).subscribe({
      next: async (data) =>
        signOutDevice(data?.value?.data?.onUpdateUserDevice),
      error: (error) => console.log(error),
    });

    window.addEventListener("visibilitychange", deviceInactive);

    return () => {
      userDeviceSubscription.unsubscribe();
      window.removeEventListener("visibilitychange", deviceInactive);
    };
  }, []);

  useEffect(() => {
    if (!userData?.tokens?.accessToken) {
      navigate("/login");
    }
  }, [userData]);

  return (
    <PageContentProvider>
      <div className="dashboard_container">
        <div className="dashboard_container_row">
          <div
            className="left_col overflow-auto"
            style={{
              marginLeft: sideBarDraw ? "0" : "-350px",
            }}
          >
            <NavSideBar active={pathname} />
          </div>
          <div
            className={`right_col half_width ${
              sideBarDraw ? "half_width" : "full_width"
            }`}
          >
            <div className="dashboard_header">
              <TopMostSection
                handleSideBarResize={setSideBarDraw}
                sideBarResize={sideBarDraw}
              />
              <TopSection
                showOption={!excludes.includes(pathname)}
                header={header}
                subHeader={subHeader}
                path={pathname}
                experiences={experiences}
              />
            </div>
            <>{routeHandler(path, experiences)}</>
          </div>
        </div>
      </div>
    </PageContentProvider>
  );
};
