import { gql } from "@apollo/client";
import { API } from "aws-amplify";

export const GET_CREDIT_EVENTS = gql`
  query CreditEventQuery {
    listCreditEvents (limit: 1000) {
      items {
        condition
        created_at
        created_by
        id
        duration
        credit_history
        updated_at
        updated_by
        value
      }
      nextToken
    }
  }
`;


export const GET_CREDIT_EVENTS_WITH_TOKEN = gql`
  query CreditEventQuery ($limit: Int, $nextToken: String) {
    listCreditEvents (limit: $limit, nextToken: $nextToken) {
      items {
        condition
        created_at
        created_by
        id
        duration
        updated_at
        updated_by
        credit_history
        value
      }
      nextToken
    }
  }
`;

export async function AllCreditEvents() {
  const resp = await API.graphql({ 
    query: GET_CREDIT_EVENTS,
  });

  const data = resp?.data?.listCreditEvents
  let nextToken = data?.nextToken
  let creditEvents = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_CREDIT_EVENTS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listCreditEvents

    const previousData = [...creditEvents, ...respData.items]
    creditEvents = [...previousData];

    nextToken = respData?.nextToken
  }

  return creditEvents || [];
}

export const GET_CREDIT_EVENTS_HISTORY = gql`
  query CreditEventHistoryQuery {
    listCreditEventHistories (limit: 1000) {
      items {
        createdAt
        createdBy
        data
        id
      }
      nextToken
    }
  }
`;


export const GET_CREDIT_EVENTS_HISTORY_WITH_TOKEN = gql`
  query CreditEventHistoryQuery ($limit: Int, $nextToken: String) {
    listCreditEventHistories (limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        createdBy
        data
        id
      }
      nextToken
    }
  }
`;

export async function AllCreditEventHistories() {
  const resp = await API.graphql({ 
    query: GET_CREDIT_EVENTS_HISTORY,
  });

  const data = resp?.data?.listCreditEventHistories
  let nextToken = data?.nextToken
  let creditEventHistory = data?.items;

  while (nextToken) {
    const resp = await API.graphql({ 
      query: GET_CREDIT_EVENTS_HISTORY_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.creditEventHistory

    const previousData = [...creditEventHistory, ...respData.items]
    creditEventHistory = [...previousData];

    nextToken = respData?.nextToken
  }

  return creditEventHistory || [];
}

export const sortByCreatedAt = async () => {
  const history = await AllCreditEventHistories();
  history.sort((a, b) => {
    const itemA = new Date(a?.createdAt);
    const itemB = new Date(b?.createdAt);
    if (itemA < itemB) return 1;
    if (itemA > itemB) return -1;
    return 0;
  });

  return history || [];
}

export const sortEventsByCreatedAtWithLimit = async (limit) => {
  const data = await sortByCreatedAt();
  return data.length === 0 ? [] : data.slice(0, limit);
}