import React from "react";
import { nameCol } from "../TableWrapper/tableWrapper";
import CustomisedButton from "../../widgets/Button/Button";
import { formatDatePurchaseStyle, getInitial, titleWords } from "../helpers";

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const createBtnText = (text, icon) => {
  return (
    <div className="flex-start">
      <div>{text}</div>
      <div className="align-items ml-10">{icon}</div>
    </div>
  );
};

export const formatDate = (date) => {
  let dateObj = new Date(date);
  if (dateObj) {
    return `${days[dateObj.getDay()]}, 
      ${months[dateObj.getMonth()]} 
      ${dateObj.getDate()}, 
      ${dateObj.getFullYear()}`;
  }

  return "";
};

export const calculateClosingDateTime = (date) => {
  if (date) {
    const previous = new Date();
    const now = new Date(date);
    if (now.getFullYear() === previous.getFullYear()) {
      if (now.getMonth() === previous.getMonth()) {
        const res = now.getDate() - previous.getDate();
        if (res === 0) {
          return "Today";
        }
        return `in ${res} ${res === 1 ? "day" : "days"}`;
      } else {
        const res = now.getMonth() - previous.getMonth();
        return `in ${res === 1 ? "next month" : `${res} months`}`;
      }
    } else {
      const res = now.getFullYear() - previous.getFullYear();
      return `in ${res === 1 ? "next year" : `${res} years`}`;
    }
  } else {
    return "";
  }
};

export const experienceStatusButton = (type) => {
  if (type !== undefined) {
    const btnColors = {
      Confirmed: ["#DAF8EE", "#A1EED4"],
      Canceled: ["#FFFFFF", "#DDDFE2"],
    };

    return (
      <>
        <CustomisedButton
          bg={Object.keys(btnColors).includes(type) ? btnColors[type][0] : ""}
          bd={`1px solid ${
            Object.keys(btnColors).includes(type) ? btnColors[type][1] : ""
          }`}
          text={type}
          rd="15px"
          pd="5px 8px"
          fs="13px"
        />
      </>
    );
  }

  return "";
};

export const createSlotColumnContent = (slots) => {
  if (slots) {
    if (parseInt(slots) === 1) return `${slots} slot`;
    else return `${slots} slots`;
  }

  return 0;
};

export const createExperienceColumnContent = (experience) => {
  const isMorethanOne = (experience) => {
    return experience?.length > 1;
  };

  return (
    <div className="flex-start" style={{ alignItems: "center" }}>
      <div>
        {/* eslint-disable-next-line no-undef */}
        <img
          src={experience?.image}
          alt="lebron"
          style={{ width: "35px", height: "35px", borderRadius: "50%" }}
        />
      </div>
      <span className="ml-10">
        <span className="user-purchases-title">
          {experience !== undefined ? experience?.title : ""}{" "}
        </span>
        {isMorethanOne(experience) ? `+ ${experience?.length - 1} others` : ""}
      </span>
    </div>
  );
};

export const createNameCol = (name) => {
  return nameCol({
    Ibg: "#D4A657",
    text: getInitial(name),
    name: titleWords(name),
    layout: 1,
    verified: false,
  });
};

export const createPurchasesTableContent = (data) => {
  const res = [];
  data?.forEach((elt, index) => {
    const obj = {
      name: ["name_col", createNameCol(elt?.userData?.name)],
      session: ["name_col", `Session #${index + 1}`],
      location: ["location_col", formatDatePurchaseStyle(elt?.createdAt)],
    };

    res.push(Object.values(obj));
  });
  return res;
};

// const getTotalAmount = (experiences) => {
//   let sum = 0;
//   experiences?.forEach((experience) => {
//     sum += getSubTotal(experience)
//   })
//
//   return sum;
// }

export const createPurchasesFullTableContent = (data) => {
  return data?.map((elt, index) => {
    return [
      ["col-12", createNameCol(elt?.userData?.name)],
      ["col-12", createExperienceColumnContent(elt?.experience)],
      [
        "col-12",
        <span style={{ color: "#226E54" }} key={index}>
          {elt?.amount} JC
        </span>,
      ],
      ["col-12", createSlotColumnContent(elt?.slots)],
      ["col-12", experienceStatusButton(elt?.status)],
      ["col-12", formatDatePurchaseStyle(elt?.createdAt)],
    ];
  });
};
