import React, { useContext, useEffect, useState } from "react";
import { youthTableList } from "../../assets/data/youthTable";
import TableTemplate from "../../components/Admin/TableTemplate";
import PaginationWrapper from "../../components/Youth/PaginationWrapper";
import TopSection from "../../components/Youth/TopSection";
import { createYouthTableContent, sortByAge } from "../../utils/Youth/utils";
import {
  FilterFieldUnique,
  filterDataByAges,
  searchData,
  sortDataByField,
  filterDataByLocations,
  filterDataByVerificationStatus,
  getDataByDate, filterYouthByState
} from "../../utils/helpers";
import "../../scss/Admin/youthPage.scss";
import { PageContext } from "../../templates/DashBoardTemplate";
import DeactivationModal from "../../components/AccountVerification/DeactivationModal";
import ActivationModal from "../../components/AccountVerification/ActivationModal";
import DeactivationRequested from "../../components/AccountVerification/DeactivationSucessful";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {setPopupDataAction} from "../../redux/actions/popupActions";
import {axiosClient} from "../../libs/axiosClient";



function YouthPage() {
  const [students, setStudents] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [contentList, setContentList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [nextToken, setNextToken] = useState("");
  const [previousToken, setPreviousToken] = useState({});
  const { filterByDate } = useContext(PageContext);
  const currentState = useSelector((store) => store.currentState.state);


  const popups = useSelector((store) => store.popups.data);
  const currentTable = useSelector((store) => store.currentTablesData.data);

  const dispatch = useDispatch();

  const search = new URLSearchParams(useLocation().search);
  const id = search.get('id');


  const sortTable = (sortBy)=>{
    if (sortBy === 'age'){
      setContentList(createYouthTableContent(sortByAge(filtered)))
    }
    else {
      setContentList(createYouthTableContent(sortDataByField(filtered, sortBy)))
    }
  }

  const filterCallback = (id, data) => {
    let newContent = filtered;

    if (data.length){
      if (id === 'age')
        newContent = filterDataByAges(newContent, data)

      else if (id === 'location')
        newContent = filterDataByLocations(newContent, data)

      else if (id === 'verification_status')
        newContent = filterDataByVerificationStatus(newContent, data)

      setFiltered(newContent)
    }
    else {
      setFiltered(students)
    }
  }

  const closeModal = (id) => {
    dispatch(setPopupDataAction({[id]: false}));
  }

  const getStudents = async (page, nextToken) => {
    const payload = {
      limit: pageLimit,
    }
    if (nextToken) {
      payload["nextToken"] = nextToken
    }
    try {
      const resp = await axiosClient.post('/youth', payload);
      const respData = resp?.data?.data;
      setPreviousToken({...previousToken, [page + 1]: respData?.nextToken});
      setNextToken(respData?.nextToken);
      const data = respData?.students;
      setStudents(data);
      setFiltered(data);
      setLocations(FilterFieldUnique(data, 'state'));
    } catch (err) {
      console.error(err);
    }
  }

  const moveToPreviousPage = async () => {
    const currentPage = page - 1;
    setPage(currentPage);
    await getStudents(currentPage, previousToken[currentPage]);
  }

  const moveToNextPage = async () => {
    const currentPage = page + 1;
    setPage(currentPage);
    await getStudents(currentPage, nextToken);
  }

  useEffect(() => {
    getStudents(page);
  }, [pageLimit]);

  // useEffect(() => {
  //   AllStudents().then((data) => {
  //     setStudents(data)
  //     setFiltered(data)
  //     setLocations(FilterFieldUnique(data, 'state'))
  //   });
  // }, []);

  useEffect(() => {
    if (filterByDate?.length){
      setFiltered(getDataByDate(students, ...filterByDate))
    }
    else {
      setFiltered(students)
    }
  }, [filterByDate])
  
  useEffect(() => {
    setContentList(createYouthTableContent(filterYouthByState(filtered, currentState)))
  }, [filtered]);
  
  useEffect(() => {
    setContentList(createYouthTableContent(filterYouthByState(students, currentState)))
  }, [students, currentState]);
  
  return ( 
    <div className="youth_main_content">
      <div className="youth_main_inner">
        <TopSection
            menu="youth"
            inputPlaceholder="Search for a youth..."
            locations={locations}
            content={students}
            filtered={filtered}
            setFiltered={setFiltered}
            callback={searchData}
            filterCallback={filterCallback}
        />
        <TableTemplate
            data={youthTableList}
            details={students}
            tableContent={contentList}
            sortFunction={sortTable}
            layout="youth"
            id={id}
        />
        <PaginationWrapper
            setPageLimit={setPageLimit}
            moveToPreviousPage={moveToPreviousPage}
            moveToNextPage={moveToNextPage}
            page={page}
            nextToken={nextToken}
        />
        <DeactivationModal
            previousModal={"leftModal"}
            show={popups?.deactivateModal}
            setShow={() => closeModal("deactivateModal")}
            details={currentTable?.youthTable}
            refetch={() => setRefresh(prev => !prev)}
        />
        <ActivationModal
            previousModal={"leftModal"}
            show={popups?.activationModal}
            subLayout={"youth"}
            setShow={() => closeModal("activationModal")}
            details={currentTable?.youthTable}
            detailsRefetch={() => setRefresh(prev => !prev)}
        />
        <DeactivationRequested
            show={popups?.deactivateRequested}
        />
      </div>
    </div>
  );
}

export default YouthPage;