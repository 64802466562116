import React from 'react';
import { 
  Box, 
  Briefcase, 
  // ChevronExpand
} from "react-bootstrap-icons";
import { ReactComponent as Sales } from "../icons/sales.svg";
import { nameCol } from "../../utils/TableWrapper/tableWrapper";


// const expandIcon = <span><ChevronExpand fontSize={18}/></span>

export const cardList = [
  {
    "icon": <Box size={24}/>,
    "category_title": "Experiences",
    "category_number": "12",
    "bottom_text": "",
    "id": "experiences"
  },
  {
    "icon": <Briefcase size={24}/>,
    "category_title": "Purchases",
    "category_number": "143",
    "bottom_text": "16 new this week",
    "id": "purchases"
  },
  {
    "icon": <Sales size={24}/>,
    "category_title": "Total Sales",
    "category_number": "1,365.11 JC",
    "bottom_text": "$800.32 JC this week",
    "id": "sales"
  }
]

export const experiencesCardList = [
  {
    "img": require("../../assets/images/experiences_info.png"),
    "name": "Zoom Call with LeBron James",
    "orders": "56",
    "credits": "20"
  },
  {
    "img": require("../../assets/images/experiences_info.png"),
    "name": "Zoom Call with LeBron James",
    "orders": "56",
    "credits": "20"
  },
  {
    "img": require("../../assets/images/experiences_info.png"),
    "name": "Zoom Call with LeBron James",
    "orders": "56",
    "credits": "20"
  },
  {
    "img": require("../../assets/images/experiences_info.png"),
    "name": "Zoom Call with LeBron James",
    "orders": "56",
    "credits": "20"
  },
]

export const experiences = [
  {
    "img": require("../images/exp_1.png"),
    "name": "White Hour Tour",
    "purchased": "0 of 20 slots purchased",
    "price": "20.11 JC",
    "status": "Closing in 24 days"
  },
  {
    "img": require("../images/exp_3.png"),
    "name": "White Hour Tour",
    "purchased": "0 of 20 slots purchased",
    "price": "20.11 JC",
    "status": "Closing in 24 days"
  },
  {
    "img": require("../images/exp_3.png"),
    "name": "White Hour Tour",
    "purchased": "0 of 20 slots purchased",
    "price": "20.11 JC",
    "status": "Closing in 24 days"
  },
  {
    "img": require("../images/exp_4.png"),
    "name": "White Hour Tour",
    "purchased": "0 of 20 slots purchased",
    "price": "20.11 JC",
    "status": "Closing in 24 days"
  },
  {
    "img": require("../images/exp_5.png"),
    "name": "White Hour Tour",
    "purchased": "0 of 20 slots purchased",
    "price": "20.11 JC",
    "status": "Closing in 24 days"
  },
  {
    "img": require("../images/exp_4.png"),
    "name": "White Hour Tour",
    "purchased": "0 of 20 slots purchased",
    "price": "20.11 JC",
    "status": "Closing in 24 days"
  },
  {
    "img": require("../images/exp_5.png"),
    "name": "White Hour Tour",
    "purchased": "0 of 20 slots purchased",
    "price": "20.11 JC",
    "status": "Closing in 24 days"

  },

]


export const archives = [
  {
    "img": require("../images/exp_1.png"),
    "name": "White Hour Tour",
    "purchased": "0 of 20 slots purchased",
    "price": "20.11 JC",
    "status": "Closed"
  },
  {
    "img": require("../images/exp_3.png"),
    "name": "White Hour Tour",
    "purchased": "0 of 20 slots purchased",
    "price": "20.11 JC",
    "status": "Closed"
  },

]

export const archivePurchasesTabList = 
  {
    "th": [
      {
        "component": <>NAME</>,
        "cls": "name_col"
      },
      {
        "component": <>SESSION</>,
        "cls": "name_col"
      },
      {
        "component": <>DATE</>,
        "cls": "location_col"
      },
    ],

    "tc": [
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "BD",
            name: "Bryce David",
            layout: 1,
            verified: false,
          })
        ],
        ["name_col", "Session #1"],
        ["location_col", "Mar 27, 2022"]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "BD",
            name: "Bryce David",
            layout: 1,
            verified: false,
          })
        ],
        ["name_col","Session #1"],
        ["location_col", "Mar 27, 2022"]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "BD",
            name: "Bryce David",
            layout: 1,
            verified: false,
          })
        ],
        ["name_col","Session #1"],
        ["location_col", "Mar 27, 2022"]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "BD",
            name: "Bryce David",
            layout: 1,
            verified: false,
          })
        ],
        ["name_col","Session #1"],
        ["location_col", "Mar 27, 2022"]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "BD",
            name: "Bryce David",
            layout: 1,
            verified: false,
          })
        ],
        ["name_col","Session #1"],
        ["location_col", "Mar 27, 2022"]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "BD",
            name: "Bryce David",
            layout: 1,
            verified: false,
          })
        ],
        ["name_col","Session #1"],
        ["location_col", "Mar 27, 2022"]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "BD",
            name: "Bryce David",
            layout: 1,
            verified: false,
          })
        ],
        ["name_col","Session #1"],
        ["location_col", "Jerome Bell"]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "BD",
            name: "Bryce David",
            layout: 1,
            verified: false,
          })
        ],
        ["name_col","Session #1"],
        ["location_col", "Jerome Bell"]
      ],
      [
        ["name_col", 
          nameCol({
            Ibg: "#D4A657",
            text: "BD",
            name: "Bryce David",
            layout: 1,
            verified: false,
          })
        ],
        ["name_col","Session #1"],
        ["location_col", "Mar 27, 2022"]
      ],

    ]
}