import React from 'react';
import MyAccount from "../../components/MyAccount/MyAccount";
import "../../scss/Admin/myAccountPage.scss"


function MyAccountPage() {
  return ( 
    <div className="dashboard_main_content">
      <MyAccount/>
    </div>
  );
}

export default MyAccountPage;