import { gql } from "@apollo/client";
import { API } from "aws-amplify";

export const GET_EXPERIENCE_SESSIONS = gql`
  query AllExperienceSessions {
    listExperienceSessions (limit: 1000) {
      items {
        date
        end_time
        id
        slots
        start_time
      }
    }
  }
`

export const GET_EXPERIENCE_SESSIONS_WITH_TOKEN = gql`
  query AllExperienceSessions ($limit: Int, $nextToken: String) {
    listExperienceSessions (limit: $limit, nextToken: $nextToken) {
      items {
        date
        end_time
        id
        slots
        start_time
      }
    }
  }
`


export async function AllExperienceSessions () {
  const resp = await API.graphql({
    query: GET_EXPERIENCE_SESSIONS,
  });  
  
  const data = resp?.data?.listExperienceSessions;
  let nextToken = data?.nextToken;
  let sessions = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_EXPERIENCE_SESSIONS_WITH_TOKEN,
      variables: { limit: 1000, nextToken },
    });
    const respData = resp?.data?.listExperienceSessions;

    const previousData = [...sessions, ...respData.items];
    sessions = [...previousData];

    nextToken = respData?.nextToken;
  }

  return sessions || [];
}


export const GET_EXPERIENCE_SESSION = gql`
  query getExperienceSession ($id: ID!) {
    getExperienceSession (id: $id) {
      date
      end_time
      id
      slots
      start_time
    }
  }
`

export async function GetSession(id) {
  try { 
    const resp = await API.graphql({
      query: GET_EXPERIENCE_SESSION,
      variables: { id }
    });

    return resp?.data?.getExperienceSession  || {}
  } catch (err) {
    console.error(err);
  }
}
