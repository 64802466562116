import React, { useState } from "react";
import { resourcesRightModalIcons } from "../../assets/data/resourcesTabList";
import { 
  ThreeDots, 
  X, 
} from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";
import { OpportunitesTabDrop } from "../../widgets/Dropdown/resourcesOpportunities";

function OpportunitiesModalTopSection(props) {
  
  const [displayDrop, setDisplayDrop] = useState(false)
  const { setShow, item } = {...props}

  return ( 
    <>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <div className="title">
            {item?.name}
          </div>
          <div className="flex-start">
            <div>
              <div
                className="modal_top_icons mr-10 d-flex align-items-center justify-content-center" 
                onClick={() => setDisplayDrop(!displayDrop)}
              >
                <ThreeDots  
                  fill="#515A6A" 
                  size={16}
                  className="cursor-pointer"
                />
              </div>
              <OpportunitesTabDrop 
                display={displayDrop}
                setDisplay={setDisplayDrop}
                opportunity={item}
              />
            </div>
            <div 
              className="modal_top_icons d-flex align-items-center justify-content-center" 
              onClick={() => setShow(false)}
            >
              <X 
                fill="#515A6A" 
                size={16}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        <div className="mt-20 flex-start">
          {item?.interests.map((elt, index) =>
          <CustomisedButton 
            text={elt}
            bg="#DAF8EE"
            fg="#226E54"
            bd="1px solid #44DDA9"
            rd="25px"
            pd="5px 10px"
            className="mr-10"
            key={`btn-${index}`}
          />
        )}
        </div>
        <div className="content_header">
          <div className="flex-between">
            {resourcesRightModalIcons(item)?.map((item, index) =>
            <div 
              className="flex-v"
              key={index}
            >
              <div className="flex-start gap-1">
                <span className="opportunity-info-number" style={{fontSize: 20}}>
                    {item.number}
                </span>
                <div className="flex-center opportunity-info-icon">
                  {item.icon}
                </div>
              </div>
              <div className="opportunity-info-title">{item.title}</div>
            </div>
            )}
          </div>
        </div>
      </div>
    </>
   );
}

export default OpportunitiesModalTopSection;