import {
  SET_REFRESH_ACTIONS_DATA,
  RESET_REFRESH_ACTIONS_DATA,
} from "../constants/refreshActionsConstants";

const initialLoginState = {
  rolesRefetch: false,
  adminsRefetch: false,
};

const refreshActionsReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_REFRESH_ACTIONS_DATA:
      return { ...state, ...action.payload };
    case RESET_REFRESH_ACTIONS_DATA:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default refreshActionsReducer;
