import React, { useState, useEffect } from "react";
import { ChevronDown } from "react-bootstrap-icons";
import { DashBoardTopDrop } from "../../widgets/Dropdown/alltimeDrop";
import { GetUserPayments } from "../../services/query/payments";
import CustomisedButton from "../../widgets/Button/Button";


function PaymentTab({details, cls}) {

  const [state, setState] = useState(false)
  const [purchases, setPurchases] = useState([]);
  const [lastPurchaseDate, setLastPurchaseDate] = useState('');
  const [items, setItems] = useState({})

  const toggleDrop = () => {
    setState(!state)
  }

  const setSelected = (id, item) => {
    setItems({...items, [id]:item})
  }

  useEffect(() => {
    if (purchases && purchases?.length)
      setLastPurchaseDate(new Date(purchases[0].createdAt).toDateString().slice(4));
  }, [purchases]);

  useEffect(() => {
    GetUserPayments(details?.id).then((payments) => {
      setPurchases(payments);
    })
  }, [details]);
  
  return (
    <>
    <div className="resources_payment_tab">
      <div className='activity_top_sect flex-between mt-20'>
        <div className="align-items">Showing recent activities</div>
        <div 
          className="flex-between pd-20 all_time_drop"
          onClick={toggleDrop}
        >
          All time
          <ChevronDown size={13} className="icon_down"/>
        </div>
      </div>
      <div className="tab_banner mt-20 flex-center">
        <div className="flex-v text-center">
          <h6>Credit balance</h6>
          <h1>{details?.credits?.toFixed(2) || 0}</h1>
          <h6>Last purchase: {lastPurchaseDate || ''}</h6>
        </div>
      </div> 
      <div className="main_content mt-20">
        {/* <div className="title">Today</div> */}
        <div className="content_items">
          {purchases.map((item, index) => 
          <div 
            className="content_item mt-10 mbd pv-10"
            key={index}
          >
            <div className="flex-between">
              <div className="flex-v">
                <span className="bold">{item.credits} Credits</span>
                <span>
                  <CustomisedButton 
                    text={item.status}
                    bg="#DAF8EE"
                    fg="#226E54"
                    bd="1px solid #44DDA9"
                    rd="25px"
                    pd="3px 8px"
                    fs="13px"
                  />
                </span>
              </div>
              <div className="flex-v text-center">
                <span>${item.amount}</span>
                {/* <span className="mt-10">{item.description}</span> */}
              </div>
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
    <DashBoardTopDrop
      display={state}
      setData={setSelected}
      toggleDrop={toggleDrop}
      cls={cls}
    />
    </>
  );
}

export default PaymentTab;