import React from 'react';
import CustomisedButton from "../../widgets/Button/Button";
import { ChevronDoubleRight } from "react-bootstrap-icons";
import { CreateInfoCard } from "../../utils/AccountVerfication/utils";
import { notifyCard } from "../../assets/data/accountVerificationTableList";
import { useSelector } from "react-redux";

function AccountVerificationTopSection(props) {
  const user = useSelector((store) => store.adminUser.data);
  const userName = user.userName;

  const {
    setDateStarted,
    layout,
    hideStartDisplay, 
    updateCloud,
    setHideStartDisplay
  } = {...props}

  const hideCard = () => {
    const inputData = {
      started: new Date(),
    }
    updateCloud(inputData)
    setDateStarted(new Date());
    setHideStartDisplay(true);
  }
  
  return ( 
    <>
      <CreateInfoCard 
        data={notifyCard(layout, userName?.split(" ")[0])}
      />
      {hideStartDisplay
      ? "" 
      : (
        <div className="inner_content_start">
          <p>Start verification to activate the timeline and verification process.</p>
          <CustomisedButton 
            text={
              <>
                <span className="mr-10">Start Verification</span>
                <ChevronDoubleRight/>
              </>
            }
            bg="#FFDA9A"
            fg="#000"
            bd="1px solid #FFDA9A"
            rd="10px"
            pd="8px 10px"
            width="100%"
            onClick={hideCard}
          />
        </div>
        )
      }
    </>
  );
}

export default AccountVerificationTopSection;