import Switch from "react-switch";
import React, { useState } from "react";
import { ChevronUp, ChevronDown } from "react-bootstrap-icons";
import { getInitial } from "../../utils/helpers";

function RightContent(props) {
  const { items, modules, users, colors } = { ...props };
  const [state, setState] = useState({});

  const icons = {
    true: <ChevronUp />,
    false: <ChevronDown />,
  };

  const [expands, setExpands] = useState({
    perms: {
      state: true,
      icon: <ChevronUp />,
    },
    users: {
      state: true,
      icon: <ChevronUp />,
    },
  });

  const handleSwitch = (checked, event, id) => {
    setState({
      ...state,
      [id]: checked,
    });
  };

  const toggle = (id) => {
    const obj = expands[id];
    obj.state = !obj.state;
    obj.icon = icons[`${obj.state}`];

    setExpands({
      ...expands,
      [id]: obj,
    });
  };


  return (
    <div className="roles-right-modal">
      <h4 className="bold pm-20">{items.name}</h4>
      <div className="pm-20">
        <div>
          <div className="flex-between">
            <h6 className="bold">PERMISSIONS</h6>
            <div className="align-items" onClick={() => toggle("perms")}>
              {expands.perms.icon}
            </div>
          </div>
          <div
            className={`perms_wrapper ${
              expands.perms.state ? "show" : "hide"
            } mt-20`}
          >
            <div className="flex-between mbd pv-10">
              <div style={{ width: "70%" }}>Section</div>
              <div className="flex-between" style={{ width: "30%" }}>
                <div>Read only</div>
                <div>Edit</div>
              </div>
            </div>
            {modules.map((item, index) => (
              <div className="flex-between mbd pv-10" key={index}>
                <div style={{ width: "70%" }}>{item.name}</div>
                <div className="flex-between" style={{ width: "30%" }}>
                  <div>
                    <Switch
                      onChange={handleSwitch}
                      checked={items.read.includes(item.id)}
                      onColor="#2D9371"
                      offColor="#B9BDC3"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className="mr-10"
                      id={item.id}
                    />
                  </div>
                  <div>
                    <Switch
                      onChange={handleSwitch}
                      checked={items.edit.includes(item.id)}
                      onColor="#2D9371"
                      offColor="#B9BDC3"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className="mr-10"
                      id={item.id}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-20">
          <div className="flex-between">
            <h6 className="bold">USERS</h6>
            <div className="align-items" onClick={() => toggle("users")}>
              {expands.users.icon}
            </div>
          </div>
          <div
            className={`main_content ${expands.users.state ? "show" : "hide"}`}
          >
            {users.map((item, index) => (
              <div className="account_item" key={index}>
                <div
                  className="account_initial_wrapper"
                  style={{ backgroundColor: colors.current[index] }}
                >
                  <span>{getInitial(item?.Attributes.name)}</span>
                </div>
                <h5>{item?.Attributes.name}</h5>
                {/* <h6>{item.location}</h6> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightContent;
