import React, { useEffect, useState } from 'react';
import NotificationMsg from './NotificationMsg';
import EmptyNoti from '../../../assets/images/empty-noti.png';
import { getUserNotifications } from '../../../services/query/notifications';
import { useAuth } from '../../../templates/DashBoardTemplate';
import './style.css';
// import { deleteAppNotification } from '../../../graphql/mutations';
// import { API } from 'aws-amplify';

const Notifications = (props) => {
  const [userNotifications, setUserNotifications] = useState([]);
  const { user } = useAuth();

  const clearNotifications = async()=>{
    try {
      // const result = await API.graphql({
      //   query: deleteAppNotification,
      //   variables: {
      //     input: {
      //       module: 'admin',
      //       userId: user?.sub
      //     }
      //   }
      // })
      // console.log(result)
      setUserNotifications([])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const retrieveNotifications = async()=>{
      let results = await getUserNotifications(user?.sub)
      let results1 = await getUserNotifications('')
      results = results?.concat(results1)
      console.log(results)
      setUserNotifications(results?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)))
    }

    retrieveNotifications()
  }, [])

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="fs-22 font-weight-700 darkColor">Notifications</h6>
        <div className="d-flex align-items-center">
          <div
            onClick={props.hidePopup}
            className="w-10 h-10 d-flex align-items-center justify-content-center rounded-md bg--lighterGray cursor-pointer">
            <i className="fa fa-times" aria-hidden="true"></i>
          </div>
          <hr />
        </div>
      </div>
      <hr className="my-4" />
      <div className="d-flex justify-content-between mt-4 mb-3">
        <p className="fs-14 font-weight-500 text-dark noti-count">Showing {userNotifications?.length} Notification{userNotifications?.length === 1 ? '' : 's'}</p>
        <p className="fs-14 font-weight-500 text-dark cursor-pointer noti-clear-btn" onClick={()=>{clearNotifications()}}>
          Clear all
        </p>
      </div>
      <div className="notifications">
        {userNotifications?.length ?
          <>
            {userNotifications?.reverse().map((notification, index) => (
              <NotificationMsg
                key={index}
                content={notification?.text}
                link={notification?.link}
                action={notification?.action}
                receivedTime={notification?.createdAt}
              />
            ))}
          </>
          : 
          <div className="h-100 d-flex flex-column justify-content-center align-items-center">
            <div className="mb-5 text-center">
              <img src={EmptyNoti} alt="Empty" />
              <p className="fs-16 font-weight-500 gray text-center mb-5 pb-5 col-9 mx-auto">
                <span className="d-inline-block mb-5">
                  You do not have any notification at the moment
                </span>
              </p>
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default Notifications;
