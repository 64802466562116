import { gql, useQuery } from "@apollo/client";
import { API } from "aws-amplify";
import { GetSession } from "./experienceSession";

export const GET_EXPERIENCES = gql`
  query ExperiencesQuery($limit: Int) {
    listExperiences(limit: $limit) {
      items {
        closing_date
        description
        id
        is_archived
        is_featured
        liked_by
        likes
        location
        messageIDs
        partners
        price_per_person
        requirements
        sessionsIDs
        title
        type
        image
      }
      nextToken
    }
  }
`;

export const GET_EXPERIENCES_WITH_TOKEN = gql`
  query InterestsQuery($limit: Int, $nextToken: String) {
    listExperiences(limit: $limit, nextToken: $nextToken) {
      items {
        closing_date
        description
        id
        is_archived
        is_featured
        liked_by
        likes
        location
        messageIDs
        partners
        price_per_person
        requirements
        sessionsIDs
        title
        type
        image
      }
      nextToken
    }
  }
`;

export async function AllExperiences(limit=1000) {
  const resp = await API.graphql({
    query: GET_EXPERIENCES,
    variables: { limit }
  });

  const data = resp?.data?.listExperiences;
  let nextToken = data?.nextToken;
  let experiences = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_EXPERIENCES_WITH_TOKEN,
      variables: { limit: 1000, nextToken },
    });
    const respData = resp?.data?.listExperiences;

    const previousData = [...experiences, ...respData.items];
    experiences = [...previousData];

    nextToken = respData?.nextToken;
  }

  return await experiences.reduce(async (acc, item) => {
    const prev = await acc;
    const sessions = await item?.sessionsIDs?.reduce(async (innerAcc, sessionID) => {
      const innerPrev = await innerAcc;
      const session = await GetSession(sessionID);
      return [ ...innerPrev, session];
    }, []);

    return [ ...prev, { ...item, sessions } ];
  }, Promise.resolve([]));
}

export async function GetExperienceLikedByStudent(studentID) {
  const experiences = await AllExperiences();
  const likedByStudent = experiences?.filter((experience) =>
    experience?.liked_by?.includes(studentID)
  );
  return likedByStudent || [];
}

export async function GetExperienceByTypes() {
  const experiences = await AllExperiences();
  const featuredExperiences = experiences?.filter(
    (elt) => elt?.is_featured === true
  );
  const notFeaturedExperiences = experiences?.filter(
    (elt) => elt?.is_featured === false
  );
  return {
    featured: featuredExperiences,
    notFeatured: notFeaturedExperiences,
  };
}

export const GET_EXPERIENCE = gql`
  query SingleExperience($id: ID!) {
    getExperience(id: $id) {
      closing_date
      description
      id
      is_archived
      is_featured
      liked_by
      likes
      location
      messageIDs
      partners
      price_per_person
      requirements
      sessionsIDs
      title
      type
      image
    }
  }
`;

export function GetExperience(id) {
  const resp = useQuery(GET_EXPERIENCE, {
    variables: {
      id,
    },
  });

  if (resp?.data) {
    return {
      data: resp?.data?.getExperience,
      refetch: resp?.refetch,
    };
  }

  return {};
}

export async function GetSingleExperience(id) {
  try {
    const respExperience = await API.graphql({
      query: GET_EXPERIENCE,
      variables: { id },
    });
    return respExperience?.data?.getExperience;
  } catch (err) {
    console.error(err);
    return {};
  }
}
