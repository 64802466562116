import React from 'react';
import { 
  ChevronExpand, Lightbulb, BoxArrowUpRight, 
  Clock, ChevronRight, ChevronDown 
} from "react-bootstrap-icons"
import { CreateInfoCard, CreateTeamDetailCard } from "../../utils/AccountVerfication/utils"
import {formatDatePurchaseStyle, getInitial, getSchoolState} from "../../utils/helpers";
import {store} from "../../redux/store";
import {setCurrentTableDataAction} from "../../redux/actions/currentTableDataActions";


const expandIcon = <span><ChevronExpand fontSize={18}/></span>

export const accountVerificationTableList = 
  {
    "th": [
      {
        "component": <>RESOURCE ORGANIZATION{expandIcon}</>,
        "cls": "col-4 justify-center"
      },
      {
        "component": <>LOCATION{expandIcon}</>,
        "cls": "col-2 justify-center"
      },
      {
        "component": <>DATE REQUESTED{expandIcon}</>,
        "cls": "col-2 justify-center"
      },
      {
        "component": <>VERIFICATION{expandIcon}</>,
        "cls": "col-2 justify-center"
      },
      {
        "component": <>VERIFIER{expandIcon}</>,
        "cls": "col-2 justify-center"
      },
    ],

}


export const councelorsAccountVerificationTableList = 
  {
    "th": [
      {
        "component": <>COUNSELING INSTTITUTION{expandIcon}</>,
        "cls": "col-4"
      },
      {
        "component": <>LOCATION{expandIcon}</>,
        "cls": "col-2 justify-center"
      },
      {
        "component": <>DATE REQUESTED{expandIcon}</>,
        "cls": "col-2 justify-center"
      },
      {
        "component": <>VERIFICATION{expandIcon}</>,
        "cls": "col-2 justify-center"
      },
      {
        "component": <>VERIFIER{expandIcon}</>,
        "cls": "col-2 justify-center"
      },
    ],
      
}

const capitalizeFirstLetter = (title) => {
  return title[0]?.toUpperCase() + title.slice(1)
}

const singularVersion = (word) => {
  return word?.slice(0, word?.length - 1)
}

export const notifyCard = (layout, name) => {
  return {
    "title": `${singularVersion(capitalizeFirstLetter(layout))} Verification Guide`,
    "text": `Hey ${name}, remember to check the ${singularVersion(layout)} \
            verification guide for detailed instructions on the verification process`,
    "icon": <Lightbulb fill="#D4A657" size={50}/>,
    "body" : 
      <>
      <span className="mr-10">Open verification guide</span>
      <BoxArrowUpRight fill="#D4A657"/>
      </>
  }
}

const moveToVerificationTab = () => {
  store.dispatch(setCurrentTableDataAction({
    verificationTab: "verification"
  }));
}

export const accountOverviewNotifyCard = {
  "title": "Resource Verification Guide",
  "title_color": "#554223",
  "text": "You started verifying this resource 1 day ago.",
  "icon": 
    <Clock 
      fill="#D4A657" 
      size={50}
      style={{backgroundColor: "#FFECCD"}}
    />,
  "bg": "rgba(255, 244, 225, 0.64)",
  "body" : 
    <div style={{fontWeight: 600}} className="cursor-pointer" onClick={moveToVerificationTab}>
      <span
        className="mr-10"
        style={{color: "#806334"}}
      >
        View verification request
      </span>
      <ChevronRight fill="#806334"/>
    </div>
}

export const accountOverviewTabList = (details, team) => {
  return [
    {
      "title": "ACCOUNT & PROFILE",
      "notify":
          <CreateInfoCard
              data={accountOverviewNotifyCard}
          />
      ,
      "top_section":
          <div className="flex-start">
            <div
                className="flex-v"
                style={{width: "50%"}}
            >
              <div className="sect_details_title">Date Created</div>
              <div className="sect_details_sub_title">{formatDatePurchaseStyle(details?.school?.createdAt)}</div>
            </div>
            <div
                className="flex-v"
                style={{width: "50%"}}
            >
              <div className="sect_details_title">Created By</div>
              <div className="sect_details_sub_title">
                <div className="d-flex justify-content-start align-items-center">
                  {details?.image
                      ? (
                          <img
                            src={details?.image}
                            className="small_img mr-10"
                            alt=""
                        />
                      ) : (
                          <div
                              className="mr-10"
                              style={{
                                backgroundColor: "#10408C",
                                color: "#fff",
                                padding: "5px",
                                borderRadius: "50%",
                                fontSize: 11
                              }}
                          >{getInitial(details?.name)}</div>
                      )
                  }
                  <div>{details?.name}</div>
                </div>
              </div>
            </div>
          </div>
      ,
      "innerItem": [
        {
          "key": "Organization Name",
          "val": details?.school?.name,
        },
        {
          "key": "Location",
          "val": getSchoolState(details?.school?.full_address)
        },
        {
          "key": "Office Address",
          "val": details?.address
        },
        {
          "key": "Description",
          "val":
              <div className="flex-v">
            <span>
              {details?.about ?? "--Not Specified--"}
            </span>
                <div
                    className="mt-10"
                    style={{color: "#515A6A"}}
                >
                  <span className="mr-10">See more</span>
                  <ChevronDown/>
                </div>
              </div>
        },
        {
          "key": "Website",
          "val":
              <a href={details?.website}>
            <span
                className="mr-10"
                style={{color: "#10408C", fontWeight: 700 }}
            >
              {details?.website}
            </span>
                <BoxArrowUpRight
                    fill="#10408C"
                />
              </a>
        },
      ]
    },
    {
      "title": "ORGANIZATION DETAILS",
      "innerItem": [
        {
          "key": "Industry:",
          "val": details?.school?.industry?.toString() ?? "--Not Specified--",
        },
        {
          "key": "Organization Size:",
          "val": details?.school?.organization_size,
        },
        {
          "key": "Team Size:",
          "val": details?.school?.team_size,
        },
      ]
    },
    {
      "title": "TEAM",
      "innerItem": [
        {
          "key": "",
          "val":
              <div className="team_data_list">
                {team.map((item, index) =>
                    <CreateTeamDetailCard
                        item={item}
                        index={index}
                        key={`card-team-${index}`}
                    />
                )}
              </div>
        },
      ]
    },

  ]
}

