import React, { useState } from "react";
import CustomisedButton, {
  PublicProfileBtn,
} from "../../../widgets/Button/Button";
import {
  CheckCircleFill,
  Dot,
  X,
  Envelope,
  Telephone,
  ThreeDots,
  Lock,
} from "react-bootstrap-icons";
import ToastContainerComponent from "../../AccountVerification/ToastContainer";
import { calculateClosingDateTime } from "../../../utils/Experiences/utils";
import { getInitial, getRandomColor, sendAppNotification } from "../../../utils/helpers";
import { axiosClient } from "../../../libs/axiosClient";
import { nameCol } from "../../../utils/TableWrapper/tableWrapper";
import {useDispatch} from "react-redux";
import {setPopupDataAction} from "../../../redux/actions/popupActions";
import { useAuth } from "../../../templates/DashBoardTemplate";

function LeftModalTopSection(props) {
  const { item, layout, func, btnText, details, btnCallback, extra } = {
    ...props,
  };
  const [showToast, setShowToast] = useState(false);
  const { user } = useAuth();
  const PUBLIC_URL =
    process.env.REACT_APP_PUBLIC_URL || "https://setup.joinjunity.com";

  const dispatch = useDispatch();

  const openDeactivateModal = () => {
    if (layout === "youth") {
      dispatch(setPopupDataAction({ deactivateModal: true }));
      dispatch(setPopupDataAction({ leftModal: false }));
    } else {
      const popupKeys = {
        "verified": "deactivateModal",
        "unverified": "activationModal",
        "inprogress": "activationModal"
      }
      dispatch(setPopupDataAction({ [popupKeys[details?.verification_status]]: true }));
      dispatch(setPopupDataAction({ leftModal: false }));

    }
  }

  const youthLayout = () => {
    return (
      <div className="left_side">
        {item[0] ? (item[0].length > 1 ? item[0][1] : "") : ""}
      </div>
    );
  };

  const accountDeactivationLayout = (role) => {
    return (
      <div className="left_side">
        {!item?.image ? (
          <div className="profile_wrapper">
            <div
              className="account_initial_wrapper"
              style={{ backgroundColor: item.initials_color }}
            >
              <span>{getInitial(item?.info?.name)}</span>
              <div
                className="verify_icon_bg"
                style={{ display: item?.is_verified ? "flex" : "none" }}
              >
                <CheckCircleFill size={20} fill="#0D3575" />
              </div>
            </div>
          </div>
        ) : (
          <div className="account_initial_wrapper">
            <img src={item?.info?.image} alt="" />
          </div>
        )}
        <div className="name_details">
          <div className="youth_name_sect">
            <div className="youth_name">{item?.info?.name}</div>
          </div>
          <div className="youth_status">
            {role ? item?.role : item?.state}
          </div>
          <div className="youth_status">Last seen recently</div>
        </div>
      </div>
    );
  };

  const administratorLayout = () => {
    return (
      <div className="left_side">
        <div
          className="account_initial_wrapper"
          style={{ backgroundColor: item.color }}
        >
          <span>{getInitial(item?.Attributes?.name)}</span>
          <div
            className="verify_icon_bg"
            style={{ display: item.verified ? "flex" : "none" }}
          >
            <CheckCircleFill size={20} fill="#0D3575" />
          </div>
        </div>
        <div className="name_details">
          <div className="youth_name_sect">
            <div className="youth_name">{item?.Attributes?.name}</div>
          </div>
          <div className="youth_status">
            {Object.keys(item?.Attributes || {}).includes("custom:position") &&
              item?.Attributes["custom:position"]}
          </div>
          <div className="youth_status">Last seen {item?.date}</div>
        </div>
      </div>
    );
  };

  const resourcesLayout = () => {
    return (
      <div className="left_side">
        {item[0] ? (item[0].length > 1 ? item[0][1] : "") : ""}
      </div>
    );
  };

  const purchasesLayout = () => {
    return nameCol({
      layout: 1,
      Ibg: getRandomColor(),
      text: getInitial(details?.userData?.name),
      name: details?.userData?.name,
      bottomText: details?.userData?.state,
    });
  };

  const experiencesArchiveLayout = () => {
    return (
      <div className="left_side">
        <div className="mr-10">
          <img
            src={item?.image}
            style={{
              width: 50,
              height: 50,
              borderRadius: "10px",
            }}
            alt=""
          />
        </div>
        <div className="name_details">
          <div className="youth_name_sect">
            <div className="youth_name">{item?.title}</div>
          </div>
          <div className="youth_status">
            Closing {calculateClosingDateTime(item?.closing_date)}
          </div>
        </div>
      </div>
    );
  };

  const layouts = {
    youth: youthLayout(),
    "account-deactivation": accountDeactivationLayout(),
    administrators: administratorLayout(),
    purchases: purchasesLayout(),
    "experiences-archive": experiencesArchiveLayout(),
    experiences: experiencesArchiveLayout(),
  };

  const returnLayout = (layout) => {
    if (extra === "youth-deact") {
      return accountDeactivationLayout();
    } else {
      if (Object.keys(layouts).includes(layout)) {
        return layouts[layout];
      } else {
        return resourcesLayout();
      }
    }
  };

  const youthBtnLayout = () => {
    return (
      <CustomisedButton
        width="100%"
        height="40px"
        bg="white"
        bd="1px solid #DDDFE2"
        mg="20px 0"
        text={btnText}
        fg="#515A6A"
        fw="700"
        onClick={openDeactivateModal}
      />
    );
  };

  const purchasesBtnLayout = () => {
    return (
      <CustomisedButton
        width="100%"
        height="40px"
        bg="white"
        bd="1px solid #DDDFE2"
        mg="20px 0"
        text={btnText}
        fg="#515A6A"
        fw="700"
        onClick={btnCallback}
      />
    );
  };

  const resourcesBtnLayout = (type) => {
    const urlData = {
      "mentors": `mentor-profile?id=${details?.schoolID}`,
      "resources": `resource-profile?id=${details?.organizationID}`,
    }
    const bgColors = {
      "verified": {
        bg: "rgba(251, 213, 213, 0.3)",
        bd: "1px solid rgba(251, 213, 213, 0.3)",
        text: "De-activate Account",
        fg: "#D41811"
      },
      "unverified": {
        bg: "#82E8C6",
        bd: "1px solid #82E8C6",
        text: "Activate Account",
        fg: "#226E54"
      },
      "inprogress": {
        bg: "#82E8C6",
        bd: "1px solid #82E8C6",
        text: "Activate Account",
        fg: "#226E54"
      },
    }
    return (
      <div className="flex-between">
        <PublicProfileBtn
          href={`${PUBLIC_URL}/${urlData[type]}`}
        />
        <CustomisedButton
          width="48%"
          height="40px"
          bg={bgColors[details?.verification_status]?.bg}
          bd={bgColors[details?.verification_status]?.bd}
          mg="20px 0"
          text={bgColors[details?.verification_status]?.text}
          fg={bgColors[details?.verification_status]?.fg}
          fw="700"
          onClick={openDeactivateModal}
        />
      </div>
    );
  };

  const administratorsBtnLayout = () => {
    const data = {
      title: "Reset Password Sent!",
      message: "Admin has been sent instruction to reset their password.",
      icon: <Lock fill="#226E54" size={40} />,
    };

    const handlePasswordReset = async () => {
      try {
        await axiosClient.patch("/reset-password", {
          username: item.Username,
          email: item?.Attributes?.email,
          fname: item?.Attributes?.name?.split(" ")[0]
        });
        await sendAppNotification(
          user?.sub,
          'Admin has been sent instruction to reset their password.',
          'success',
          '/administrators',
          'Click here to view',
          'admin',
          'inapp',
          'checkmarkSuccess'
        )
      } catch (err) {
        console.error(err);
        await sendAppNotification(
          user?.sub,
          'Error resetting password',
          'error',
          `/administrators`,
          'Click here to view',
          'admin',
          'inapp',
          'slashCircle'
        )
      }
    };

    return (
      <div className="flex-between">
        <CustomisedButton
          width="48%"
          height="40px"
          bg="white"
          bd="1px solid #DDDFE2"
          mg="20px 0"
          text="Edit profile"
          fg="#515A6A"
          fw="700"
          onClick={btnCallback}
        />
        <CustomisedButton
          width="48%"
          height="40px"
          bg="white"
          bd="1px solid #DDDFE2"
          mg="20px 0"
          text="Reset Password"
          fg="#515A6A"
          fw="700"
          onClick={handlePasswordReset}
        />
        <ToastContainerComponent
          setToastShow={setShowToast}
          toastShow={showToast}
          data={data}
        />
      </div>
    );
  };

  const btnLayouts = {
    youth: youthBtnLayout(),
    resources: resourcesBtnLayout("resources"),
    councelors: resourcesBtnLayout("mentors"),
    purchases: purchasesBtnLayout(),
    "account-deactivation": purchasesBtnLayout(),
    administrators: administratorsBtnLayout(),
  };

  const returnBtnLayout = (layout) => {
    if (Object.keys(btnLayouts).includes(layout)) {
      return btnLayouts[layout];
    } else {
      return "";
    }
  };

  const closeModal = () => {
    dispatch(setPopupDataAction({
      leftModal: false
    }));
    func(false);
  }

  const commonHeadLayout = (layout) => {
    return (
      <div className="head_section">
        <>{returnLayout(layout)}</>
        <div className="right_side cursor-pointer" onClick={closeModal}>
          <X fill="#515A6A" size={24} />
        </div>
      </div>
    );
  };

  const purchasesHeadLayout = (layout) => {
    return (
      <div>
        <div className="head_section mbd pv-10">
          <div className="flex-v">
            <span className="bold">Purchases #{details?.number}</span>
            <span>
              {details?.experience?.length} Experience
              {details?.experience?.length > 1 ? "s" : ""}
              <Dot />
              {details?.slots} slot{details?.slots > 1 ? "s" : ""}
            </span>
          </div>
          <div
            className="right_side cursor-pointer"
            onClick={closeModal}
          >
            <X fill="#515A6A" size={24} />
          </div>
        </div>
        <div className="head_section mt-20">
          <>{returnLayout(layout)}</>
          <div className="">
            <div className="flex-start align-items">
              <div className="icon_wrapper">
                <Telephone size={18} />
              </div>
              <div className="icon_wrapper">
                <Envelope size={18} />
              </div>
              <div className="icon_wrapper">
                <ThreeDots size={18} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const headLayouts = {
    purchases: purchasesHeadLayout(layout),
  };

  const returnHeadLayout = (layout) => {
    if (Object.keys(headLayouts).includes(layout)) {
      return headLayouts[layout];
    } else {
      return commonHeadLayout(layout);
    }
  };

  return (
    <div className={`left_modal_top ${layout}`}>
      <>{returnHeadLayout(layout)}</>
      <>{returnBtnLayout(layout)}</>
    </div>
  );
}

export default LeftModalTopSection;
