import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { ReactComponent as ResizeBtn } from "../../assets/icons/backbtn.svg";
import { ReactComponent as Bell } from "../../assets/images/bell.svg";
import { TopMostSectionDrop } from "../../widgets/Dropdown/dropdown";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetAdminDataAction } from "../../redux/actions/adminActions";
import { resetExperienceDataAction } from "../../redux/actions/experience";
import { resetInstitutionDataAction } from "../../redux/actions/institutionAction";
import { resetUserDataAction } from "../../redux/actions/userAction";
import {generateDeviceID, getCityNames, getInitial} from "../../utils/helpers";
import { resetRefreshDataAction } from "../../redux/actions/refreshActions";
import { axiosClient } from "../../libs/axiosClient";
// import Notifications from "../Notifications";
import NotificationPopup from "../NotificationPopup";
import {setCurrentStateDataAction} from "../../redux/actions/currentStateAction";

function TopMostSection({ sideBarResize, handleSideBarResize }) {
  const user = useSelector((store) => store.adminUser.data);
  const userName = user.userName;
  const cityNames = getCityNames();
  const currentState = useSelector((store) => store.currentState.state);
  const popup = useRef(null);
  const openPopup = (mode) => {
    popup.current.showPopup(mode);
  };

  const [state, setState] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [option, setOption] = useState('notification');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const toggleDrop = () => {
    setState((prev) => !prev);
  };

  const logout = async () => {
    dispatch(resetAdminDataAction());
    dispatch(resetExperienceDataAction());
    dispatch(resetInstitutionDataAction());
    dispatch(resetUserDataAction());
    dispatch(resetRefreshDataAction());
    await axiosClient.post("/update-device-status", {
      userID: user?.sub,
      deviceID: generateDeviceID(),
      status: "Logout",
    });
    navigate("/login");
  };

  const handleStateChange = (e) => {
    dispatch(setCurrentStateDataAction(e.target.value));
  }

  return (
    <>
      <div className="top_most_wrapper">
        <div className="left_side">
          <div
            className="left_icon_wrapper"
            onClick={() => handleSideBarResize(!sideBarResize)}
          >
            <ResizeBtn
              style={sideBarResize ? {} : { transform: "rotate(180deg)" }}
            />
          </div>
          <Form.Select onChange={handleStateChange} value={currentState}>
            {cityNames?.map((elt) =>
                <option value={elt?.isoCode} key={elt.isoCode}>{elt?.name}</option>
            )}
          </Form.Select>
        </div>
        <div className="right_side">
          <div className="right_icon_wrapper cursor-pointer" onClick={() => openPopup('notification')}>
            <Bell/>
          </div>
          {user?.image ? (
            <img src={user?.image} alt="" className="user-image" />
          ) : (
            <div className="initials">{getInitial(userName)}</div>
          )}
          <div className="right_icon_wrapper point">
            {state ? (
              <ChevronUp size={15} onClick={toggleDrop} />
            ) : (
              <ChevronDown size={15} onClick={toggleDrop} />
            )}
          </div>
        </div>
      </div>
      <TopMostSectionDrop display={state} logout={logout} />
      <NotificationPopup ref={popup}/>
    </>
  );
}

export default TopMostSection;
