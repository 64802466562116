import React, { useEffect, useRef, useState } from "react";
import TopSection from "../Youth/TopSection";
import { CheckCircleFill, InfoCircle } from "react-bootstrap-icons";
import RightContent from "./RightContent";
import { axiosClient } from "../../libs/axiosClient";
import { getInitial, getRandomColor } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { setRefreshDataAction } from "../../redux/actions/refreshActions";
import { useNavigate, useParams } from "react-router-dom";

function AdministratorsTab() {
  const randomColors = useRef([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const accountItemRef = useRef(null);
  const [view, setView] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedColor, setSelectedColor] = useState("");
  const [admins, setAdmins] = useState([]);
  const [filteredAdmin, setFilteredAdmins] = useState([]);
  const [permRoles, setPermRoles] = useState([]);

  const adminsFetch = useSelector(
    (store) => store.refreshActions.adminsRefetch,
  );

  const [status, setStatus] = useState({});
  const [userIndex, setId] = useState();

  const handleClick = (id) => {
    // clearSelections();
    setId(id);
    setSelected(admins[id]);
    setSelectedColor(randomColors.current[id]);
    navigate(`/administrators/${admins[id]?.Attributes?.sub}`);
  };

  const handleStatusUpdate = (id, status) => {
    setStatus({ ...status, [id]: status });
  };

  const closePreview = () => {
    navigate("/administrators");
  };

  const noView = () => {
    return (
      <>
        <div>
          <InfoCircle fill="#B9BDC3" size={30} />
        </div>
        <div className="mt-10 text-center">
          Select a request to review and response
        </div>
      </>
    );
  };

  const getAllAdmins = async (refresh = false) => {
    try {
      const resp = await axiosClient.get("/admin-users");
      const data = resp.data.data;
      randomColors.current = data.map(() => getRandomColor());
      setAdmins(data);
      setFilteredAdmins(data);
      if (refresh) {
        setSelected((prev) => data?.find((elt) => elt?.id === prev?.id));
      }
      if (id) {
        const admin = data.find((elt) => elt?.Attributes?.sub === id);
        const adminIndex = data.findIndex((elt) => elt?.Attributes?.sub === id);
        setId(adminIndex);
        setSelected(admin);
        setSelectedColor(randomColors.current[adminIndex]);
        setView(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getPermissionRoles = async () => {
    try {
      const resp = await axiosClient.get("/permission-role");
      setPermRoles(resp.data?.data?.roles);
    } catch (err) {
      console.log(err);
    }
  };

  const searchAdmins = (term) => {
    if (term) {
      const re = new RegExp(term?.toLowerCase(), "g");
      const matches = admins.filter(
        (elt) => elt?.Attributes?.name?.toLowerCase().match(re) !== null,
      );
      setFilteredAdmins(matches);
    } else {
      setFilteredAdmins(admins);
    }
  };

  const filterAdmins = (type, filters) => {
    if (type === "location") {
      const filteredAdmins = admins.filter((elt) => {
        const userLocations = JSON.stringify(
          elt?.Attributes["custom:locations"] || "[]",
        );
        return Object.keys(filters)
          .filter((filteredLocations) =>
            userLocations.includes(filteredLocations),
          )
          .some(Boolean);
      });

      setFilteredAdmins(filteredAdmins);
    } else if (type === "role") {
      const filteredAdmins = admins.filter((elt) => {
        const userRole = elt?.Attributes["custom:role"];
        return Object.keys(filters).includes(userRole);
      });
      setFilteredAdmins(filteredAdmins);
    }
  };

  useEffect(() => {
    getAllAdmins();
    getPermissionRoles();
  }, [id]);

  useEffect(() => {
    if (adminsFetch) {
      getAllAdmins(true);
    }

    return () => {
      dispatch(setRefreshDataAction({ adminsRefetch: false }));
    };
  }, [adminsFetch]);

  return (
    <div className="d-flex h-100">
      <div className="account_v_main_content">
        <div className="account_v_main_inner admin h-100">
          <TopSection
            filterText={false}
            menu="administration"
            inputPlaceholder="Search for a user..."
            callback={searchAdmins}
            setFiltered={setFilteredAdmins}
            content={admins}
            filterCallback={filterAdmins}
            defaultData={{ roles: permRoles }}
          />
          <div className="main_content" ref={accountItemRef}>
            {filteredAdmin.map((item, index) => (
              <div
                className={`account_item ${
                  item?.Attributes?.sub === id && "selected"
                }`}
                key={index}
                onClick={() => handleClick(index)}
              >
                <div className="profile_wrapper">
                  <div
                    className="account_initial_wrapper"
                    style={{ backgroundColor: randomColors.current[index] }}
                  >
                    <span>{getInitial(item.Attributes.name)}</span>
                  </div>
                  <div
                    className="verify_icon_bg"
                    style={{
                      display:
                        item.UserStatus === "CONFIRMED" ? "flex" : "none",
                    }}
                  >
                    <CheckCircleFill size={20} fill="#0D3575" />
                  </div>
                </div>

                <h5>{item.Attributes.name}</h5>
                <h6>{item.Attributes["custom:position"]}</h6>
                <h6>
                  {
                    JSON.parse(item.Attributes["custom:locations"] || "[]")
                      ?.length
                  }{" "}
                  location
                  {JSON.parse(item.Attributes["custom:locations"] || "[]")
                    ?.length > 1
                    ? "s"
                    : ""}
                </h6>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={`view_account admin ${view ? "" : "align_middle"}`}>
        {view ? (
          <RightContent
            item={selected}
            id={userIndex}
            status={status}
            update={handleStatusUpdate}
            func={closePreview}
            setView={setView}
            color={selectedColor}
          />
        ) : (
          noView()
        )}
      </div>
    </div>
  );
}

export default AdministratorsTab;
