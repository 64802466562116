import React, { useEffect, useState, useRef } from "react";
// import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { AllCreditMultipliers } from "../../../../services/query/creditMultiplier";
// import { cleanDataForDownload } from "../../../../utils/helpers";

function EditMultiplier({ active, setData, refetch }) {
  const firstRun = useRef(true);
  const [creditMultipliers, setCreditMultipliers] = useState([]);
  const [state, setState] = useState([]);

  const handleChange = (e, condition, index) => {
    const currentItem = state[index];
    currentItem[condition] = e.target.value;

    const modState = state.filter((_, id) => id !== index);
    modState.splice(index, 0, currentItem);
    setState(modState);
  };

  useEffect(() => {
    AllCreditMultipliers().then((credits) => {
      const modCredits = [ ...credits ];
      modCredits.sort((a, b) => {
        const itemA = new Date(a?.createdAt);
        const itemB = new Date(b?.createdAt);
        if (itemA < itemB) return -1;
        if (itemA > itemB) return 1;
        return 0;
      });
      setCreditMultipliers(modCredits);
      setState(modCredits);
    });
  }, [refetch]);
  
  useEffect(() => {
    if (!firstRun.current && !active) {
      setState(creditMultipliers);
    } else {
      firstRun.current = false;
    }
  }, [active]);

  useEffect(() => {
    setData(state);
  }, [state]);
  
  return ( 
    <div className="edit_multiplier_grid overflow-auto">
      <div className="item header">
        <div>Condition</div>
        <div>Multiplier</div>
        <div>Duration</div>
      </div>
      {state.map((elt, index) => 
      <div className="item" key={index}>
        <div className="flex-start">
          {elt.value &&
          <Form.Control
            type="number"
            className="form_input mr-10"
            value={elt.value}
            style={{width: "50%"}}
            disabled={!active}
            onChange={(e) => handleChange(e, "value", index)}
          />}
          <Form.Control
            className="form_input"
            value={elt.condition}
            disabled={!active}
            onChange={(e) => handleChange(e, "condition", index)}
          />
        </div>
        <div>
          <Form.Control
            type="number"
            className="form_input"
            value={elt.multiplier}
            disabled={!active}
            step={.01}
            onChange={(e) => handleChange(e, "multiplier", index)}
          />
        </div>
        {elt.duration &&
        <div className="flex-start">
          <Form.Control
            className="form_input mr-10"
            value="days"
            style={{width: "90%"}}
          />
          <Form.Control
            type="number"
            className="form_input"
            value={elt.duration}
            disabled={!active}
            onChange={(e) => handleChange(e, "duration", index)}
          />
        </div>
        }
      </div>
      )}
    </div>
  );
}

export default EditMultiplier;