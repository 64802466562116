import { gql } from "@apollo/client";
import { API } from "aws-amplify";

export const GET_STUDENT_GUARDIANS = gql`
  query AllGuardians {
    listStudentGuardians(limit: 1000) {
      items {
        createdAt
        id
        email
        name
        phone
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_STUDENT_GUARDIANS_WITH_TOKEN = gql`
  query AllGuardians($limit: Int, $nextToken: String) {
    listStudentGuardians(limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        email
        name
        phone
        updatedAt
      }
      nextToken
    }
  }
`;

export async function AllGuardians() {
  const resp = await API.graphql({
    query: GET_STUDENT_GUARDIANS,
  });

  const data = resp?.data?.listStudentGuardians;
  let nextToken = data?.nextToken;
  let guardians = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_STUDENT_GUARDIANS_WITH_TOKEN,
      variables: { limit: 1000, nextToken },
    });
    const respData = resp?.data?.listStudentGuardians;

    const previousData = [...guardians, ...respData.items];
    guardians = [...previousData];

    nextToken = respData?.nextToken;
  }

  return guardians || [];
}

export const GET_STUDENT_GUARDIAN = gql`
  query SingleGuardian($id: ID!) {
    getStudentGuardian(id: $id) {
      createdAt
      id
      email
      name
      phone
      updatedAt
    }
  }
`;



export async function GetSingleGuardian(id) {
  try {
    const respGuardian = await API.graphql({
      query: GET_STUDENT_GUARDIAN,
      variables: { id },
    });
    const guardian = respGuardian?.data?.getStudentGuardian;
    return guardian;
  } catch (err) {
    return {};
  }
}
