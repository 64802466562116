import React, { useState } from 'react';
import "./style.scss"
import OpportunitiesModalTabs from '../../components/Resources/OpportunitiesModalTabs';


export function OpportunitesTabDrop(props) {
  
  const {
    display, 
    setDisplay,
    opportunity
  } = {...props}

  const [state, setState] = useState(false)
  const [layout, setLayout] = useState("")
  
  // const apps = () => {
  //
  // }

  // const layouts = {
  //   "apps": apps()
  // }
  
  const checkCategory = (layout) => {
    setState(true)
    setDisplay(false)
    setLayout(layout)
  }

  return ( 
    <div style={{height: 0, width:0}} className="position-relative">
      <div 
        className="drop_wrapper opps_drop"
        style={{display: display ? "block" : "none"}}
      >
        <div className='drop_main_content sublist'>
          <div 
            className='point'
            onClick={() => checkCategory("apps")}
          >
            View Applications
          </div>
          <div 
            className='mt-10 point'
            onClick={() => checkCategory("youth")}
          >
            View Youth
          </div>
          <div 
            className='mt-10 point'
            onClick={() => checkCategory("promote")}
          >
            Promote
          </div>
          <div 
            className='mt-10 point'
            onClick={() => checkCategory("support")}
          >
            Support 
          </div>
          <div 
            className='mt-10 point text-danger fw-bold'
            onClick={() => checkCategory("delete")}
          >
            Delete
          </div>
        </div>
      </div>
      <OpportunitiesModalTabs 
        show={state}
        setShow={setState}
        layout={layout}
        opportunity={opportunity}
      />
    </div>
  );
}

