import { gql } from "@apollo/client";

export const UPDATE_EDUCATOR = gql`
  mutation UpdateEducatorMutation(
    $dates: [AWSDateTime]
    $is_verified: Boolean
    $verification_progress: Int
    $started: AWSDateTime
    $reason: String
    $detailed_reason: String
    $period: String
    $date: AWSDateTime
    $id: ID!
    $deactivated: Boolean
    $verifier: String
    $date_deactivated: AWSDateTime
    $verification_status: String
  ) {
    updateEducator(
      input: {
        is_verified: $is_verified
        verification_progress: $verification_progress
        verification_progress_dates: $dates
        verification_date_started: $started
        deactivation_reason: $reason
        deactivation_detailed_reason: $detailed_reason
        deactivation_period: $period
        date_requested: $date
        id: $id
        verifier: $verifier
        deactivated: $deactivated
        date_deactivated: $date_deactivated
        verification_status: $verification_status
      }
    ) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      personality
      role
      isAdmin
      phone
      schoolID
      schoolid
      state
      updatedAt
      zipcode
      verifier
      is_verified
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
    }
  }
`;
