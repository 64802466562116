import React, { useState } from 'react';
import { ChevronDown } from 'react-bootstrap-icons';
import { DashBoardTopDrop } from '../../widgets/Dropdown/alltimeDrop';


function ActivityTab(props) {
  const {
    // layout,
    cls
  } = {...props}
  const [state, setState] = useState(false)
  const [data, setData] = useState({})

  const toggleDrop = () => {
    setState(!state)
  }

  const setSelected = (id, item) => {
    setData({...data, [id]:item})
  }

  // const resourcesLayout = (item) => {
  //   return (
  //     <>
  //       <div className='flex-v'>
  //         <div className='bold'>{item.header}</div>
  //         <div>
  //           {
  //             item.img ? <img src={item.img} alt="" className='mr-10'/> : ""
  //           }
  //           <span className='activity_youth_title'>Microsoft - Connecticut</span>
  //         </div>
  //       </div>
  //       <div className='activity_content_right'>
  //         {item.time}
  //       </div>
  //     </>
  //   )
  // }

  // const councelorsLayout = (item) => {
  //   return (
  //     <>
  //       <div className='flex-v'>
  //         <div className='flex-start'>
  //           <img
  //             src={item.img}
  //             alt=""
  //             className="box-img-small mr-10"
  //           />
  //           <div className='bold align-items'>{item.header}</div>
  //         </div>
  //         <div>
  //           <span className='activity_youth_title'>{item.title}</span>
  //         </div>
  //       </div>
  //       <div className='activity_content_right'>
  //         {item.time}
  //       </div>
  //     </>
  //   )
  // }

  // const returnLayout = (layout, item) => {
  //   if (layout === "2"){return councelorsLayout(item)}
  //   else {return resourcesLayout(item)}
  // }

  return ( 
    <>
      <div className='resources_activity_tab '>
        <div className='flex-between'>
          <div className='align-items'>Showing recent activities</div>
          <div 
            className="flex-between pd-20 all_time_drop"
            onClick={toggleDrop}
          >
            All time
            <ChevronDown size={13} className="icon_down"/>
          </div>
        </div>

        <div className='activity_main mt-20'>
          <div className="activity_main_header">Today</div>
          {/* {list.map((item, index) => 
          <div 
            className='activity_content flex-between mt-20'
            key={index}
          >
            <>{returnLayout(layout, item)}</>
          </div>
          )} */}
        </div>
      </div>
      <DashBoardTopDrop
        display={state}
        setData={setSelected}
        toggleDrop={toggleDrop}
        cls={cls}
      />
    </>
  );
}

export default ActivityTab;