import { gql } from '@apollo/client';
import { API } from 'aws-amplify';

export const GET_PAYMENTS = gql`
  query PaymentsQuery {
    listPayments(limit: 20) {
      items {
        id
        roleID
        roleType
        type
        amount
        description
        credits
        status
        metaData
        createdAt
        updatedAt
      }

      nextToken
    }
  }
`;

export const GET_PAYMENTS_BY_ROLE_ID = gql`
  query PaymentsQuery($roleID: ID) {
    listPayments(limit: 1000, filter: { roleID: { eq: $roleID } }) {
      items {
        id
        roleID
        roleType
        type
        amount
        description
        credits
        status
        metaData
        createdAt
        updatedAt
      }

      nextToken
    }
  }
`;

export const GET_PAYMENT = gql`
  query SinglePayment($id: ID!) {
    getPayment(id: $id) {
      id
      roleID
      roleType
      type
      amount
      description
      credits
      status
      metaData
      createdAt
      updatedAt
    }
  }
`;

export async function GetPayment(id) {
  try {
    const resp = await API.graphql({
      query: GET_PAYMENT,
      variables: { id }
    });

    return resp?.data?.getPayment;
  } catch (err) {
    console.error(err);
    return {};
  }
}

export const GET_PAYMENTS_BY_ROLE_ID_WITH_TOKEN = gql`
  query PaymentsQuery($limit: Int, $nextToken: String, $roleID: ID) {
    listPayments(limit: $limit, nextToken: $nextToken, filter: { roleID: { eq: $roleID } }) {
      items {
        id
        roleID
        roleType
        type
        amount
        description
        credits
        status
        metaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const GET_PAYMENTS_WITH_TOKEN = gql`
  query PaymentsQuery($limit: Int, $nextToken: String) {
    listPayments(limit: $limit, nextToken: $nextToken) {
      items {
        id
        roleID
        roleType
        type
        amount
        description
        credits
        status
        metaData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export async function AllPayments() {
  const resp = await API.graphql({
    query: GET_PAYMENTS
  });

  const data = resp?.data?.listPayments;
  let nextToken = data?.nextToken;
  let payments = data?.items;

  while (nextToken) {
    const resp = await API.graphql({
      query: GET_PAYMENTS_WITH_TOKEN,
      variables: { limit: 1000, nextToken }
    });
    const respData = resp?.data?.listPayments;

    const previousData = [...payments, ...respData.items];
    payments = [...previousData];

    nextToken = respData?.nextToken;
  }

  return payments;
}

export async function GetUserPayments(roleID) {
  try {
    const resp = await API.graphql({
      query: GET_PAYMENTS_BY_ROLE_ID,
      variables: { roleID },
      cache: true
    });

    const data = resp?.data?.listPayments;
    let nextToken = data?.nextToken;
    let payments = data?.items;

    while (nextToken) {
      const resp = await API.graphql({
        query: GET_PAYMENTS_BY_ROLE_ID_WITH_TOKEN,
        variables: { limit: 1000, nextToken, roleID }
      });
      const respData = resp?.data?.listPayments;

      const previousData = [...payments, ...respData.items];
      payments = [...previousData];

      nextToken = respData?.nextToken;
    }


    return payments;
  } catch (err) {
    console.error(err);
    return [];
  }
}
