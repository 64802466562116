import React, {useEffect, useState} from 'react';
import { Tabs, Tab } from "react-bootstrap";
import AccountOverviewTab from "./AccountOverviewTab";
import AccountVerificationTab from "./AccountVerificationTab";
import {useSelector} from "react-redux";

function AccountVerificationTabs(props) {
  const { 
    display, 
    subLayout,
    details,
    detailsRefetch,
    modal,
    setModal
  } = {...props}

  const currentTableData = useSelector((store) => store.currentTablesData.data);
  const [key, setKey] = useState(currentTableData.verificationTab);

  useEffect(() => {
    setKey(currentTableData.verificationTab);
  }, [currentTableData]);

  return (
    <div 
      className="account_verification__resource_tab tabs_wrapper"
      style={{display: display ? "block" : "none"}}
    >
      <Tabs
        id="account_verification__resource_tab"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="verification" title="Verification">
          <AccountVerificationTab 
            layout={subLayout}
            details={details}
            detailsRefetch={detailsRefetch}
            modal={modal}
            setModal={setModal}
          />
        </Tab>
        <Tab eventKey="account-overview" title="Account Overview">
          <AccountOverviewTab
            details={details}
            layout={subLayout}
          />
        </Tab>
      </Tabs>
    </div> 
  );
}

export default AccountVerificationTabs;