import { ChevronDown, Gear, PlusCircle } from "react-bootstrap-icons";
import {
  CreditsTopDrop,
  DashBoardTopDrop,
} from "../../widgets/Dropdown/alltimeDrop";
import React, { useContext, useState } from "react";
import CustomisedButton from "../../widgets/Button/Button";
import { createBtnText } from "../../utils/Experiences/utils";
import AddExperiences from "../Experiences/AddExperiences";
import AddAdministrators from "../Administrators/Modal/AddAdministrators";
import AddRoleModal from "../RolesAndPermissions/Modal/AddRoleModal";

import { AllStudents } from "../../services/query/students";
import { AllResources } from "../../services/query/resources";
import { AllEducators } from "../../services/query/educators";
import { useEffect } from "react";
import { PageContext } from "../../templates/DashBoardTemplate";
import { getDataByDate, substractDaysFromDate } from "../../utils/helpers";

function TopSection(props) {
  const [students, setStudents] = useState([]);
  const [resources, setResources] = useState([]);
  const [counselors, setCounselors] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [filteredResources, setFilteredResources] = useState([]);
  const [filteredCounselors, setFilteredCounselors] = useState([]);

  const [state, setState] = useState(false);
  const [selectedTFrame, setSelectedTFrame] = useState('All Time');
  const { filterByDate, setFilterByDate } = useContext(PageContext)
  const [creditState, setCreditState] = useState(false);
  const { showOption, header, subHeader, path } = { ...props };

  const [data, setData] = useState({});
  const [showExperienceModal, setShowExperienceModal] = useState(false);
  const [showAdministratorsModal, setShowAdministratorsModal] = useState(false);
  const [showRolesAndPermissionModal, setShowRolesAndPermissionModal] =
    useState(false);

  const toggleDrop = () => {
    setState(!state);
  };

  const toggleCreditDrop = () => {
    setCreditState(!creditState);
  };

  const setSelected = (id, item, label) => {
    setData({ ...data, [id]: item });
    if (id !== 'cdata_range'){
      setSelectedTFrame(label)

      if (id !== 'all_time'){
        const days = Number.parseInt(label.split(" ")[1])
        setFilterByDate([substractDaysFromDate(days)])
      }
      else setFilterByDate([])
    }
    else {
      
      const dateS = item[0]
      let startDate = new Date(dateS.startDate).toDateString().split(" ")
      startDate = startDate.slice(1,3).join(" ")
      let endDate = new Date(dateS.endDate).toDateString().split(" ")
      endDate = endDate.slice(1,3).join(" ")

      setSelectedTFrame(`${startDate} - ${endDate}`)
      setFilterByDate([new Date(dateS.startDate), new Date(dateS.endDate)])
    }
  };

  const experiencesLayout = () => {
    return (
      <CustomisedButton
        text={createBtnText("Add New Experience", <PlusCircle fill="#fff" />)}
        bg="#10408C"
        fg="#fff"
        bd="none"
        pd="10px 10px"
        onClick={() => setShowExperienceModal(true)}
      />
    );
  };

  const administratorsLayout = () => {
    return (
      <CustomisedButton
        text={createBtnText("Add Administrator", <PlusCircle fill="#fff" />)}
        bg="#10408C"
        fg="#fff"
        bd="none"
        pd="10px 10px"
        onClick={() => setShowAdministratorsModal(true)}
      />
    );
  };

  const rolesAndPermissionsLayout = () => {
    return (
      <CustomisedButton
        text={createBtnText("Add Role", <PlusCircle fill="#fff" />)}
        bg="#10408C"
        fg="#fff"
        bd="none"
        pd="10px 10px"
        onClick={() => setShowRolesAndPermissionModal(true)}
      />
    );
  };

  const otherLayout = () => {
    return (
      <>
        {showOption ? (
          <div
            className="flex-between pd-20 all_time_drop"
            onClick={toggleDrop}
          >
            {selectedTFrame}
            <ChevronDown size={13} className="icon_down" />
          </div>
        ) : (
          ""
        )}
      </>
    );
  };

  const creditsLayout = () => {
    return (
      <div
        style={{
          padding: "8px 10px",
          borderRadius: "8px",
          backgroundColor: "#DDDFE2",
        }}
        className="flex-center"
        onClick={toggleCreditDrop}
      >
        <Gear fill="#2E394C" size={25} />
      </div>
    );
  };

  const layouts = {
    experiences: experiencesLayout(),
    credits: creditsLayout(),
    "roles-and-permissions": rolesAndPermissionsLayout(),
    administrators: administratorsLayout(),
  };

  const returnLayout = (path) => {
    if (Object.keys(layouts).includes(path)) {
      return layouts[path];
    } else {
      return otherLayout();
    }
  };

  const subHeaders = {
    youth: filteredStudents,
    resources: filteredResources,
    councelors: filteredCounselors,
  };

  const titles = (path) => {
    if (subHeaders[path]?.length <= 1) {
      return path.slice(0, path?.length);
    }

    return path;
  };

  useEffect(() => {
    AllStudents().then((students) => {
      setStudents(students)
      setFilteredStudents(students)
    });
    AllResources().then((resources) => {
      setResources(resources.filter((elt) => elt?.isAdmin === true))
      setFilteredResources(resources.filter((elt) => elt?.isAdmin === true))
    });
    AllEducators().then((educators) => {
      setCounselors(educators.filter((elt) => elt?.isAdmin === true))
      setFilteredCounselors(educators.filter((elt) => elt?.isAdmin === true))
    });
  }, []);

  useEffect(() => {
    if (filterByDate?.length){
      setFilteredStudents(getDataByDate(students, ...filterByDate))
      setFilteredCounselors(getDataByDate(counselors, ...filterByDate))
      setFilteredResources(getDataByDate(resources, ...filterByDate))
    }
    else {
      setFilteredStudents(students)
      setFilteredCounselors(counselors)
      setFilteredResources(resources)
    }
  }, [filterByDate])

  return (
    <div>
      <div className="top_wrapper">
        <div className="top_details">
          <h2>{header}</h2>
          {Object.keys(subHeaders || "").includes(path) ? (
            <p>{`Showing ${subHeaders[path]?.length} ${titles(path)}`}</p>
          ) : subHeader ? (
            <p>{subHeader}</p>
          ) : (
            ""
          )}
        </div>
        <>{returnLayout(path)}</>
      </div>
      <DashBoardTopDrop
        display={state}
        setData={setSelected}
        toggleDrop={toggleDrop}
      />
      <CreditsTopDrop
        display={creditState}
        setData={setSelected}
        toggleDrop={toggleCreditDrop}
      />
      <AddExperiences
        show={showExperienceModal}
        setShow={setShowExperienceModal}
      />
      <AddAdministrators
        show={showAdministratorsModal}
        setShow={setShowAdministratorsModal}
      />
      <AddRoleModal
        show={showRolesAndPermissionModal}
        setShow={setShowRolesAndPermissionModal}
      />
    </div>
  );
}

export default TopSection;
