/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInterest = /* GraphQL */ `
  mutation CreateInterest($input: CreateInterestInput!) {
    createInterest(input: $input) {
      id
      categoryID
      name
      description
      icon_name
      students {
        nextToken
        __typename
      }
      category {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInterest = /* GraphQL */ `
  mutation UpdateInterest($input: UpdateInterestInput!) {
    updateInterest(input: $input) {
      id
      categoryID
      name
      description
      icon_name
      students {
        nextToken
        __typename
      }
      category {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInterest = /* GraphQL */ `
  mutation DeleteInterest($input: DeleteInterestInput!) {
    deleteInterest(input: $input) {
      id
      categoryID
      name
      description
      icon_name
      students {
        nextToken
        __typename
      }
      category {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFacilitator = /* GraphQL */ `
  mutation CreateFacilitator($input: CreateFacilitatorInput!) {
    createFacilitator(input: $input) {
      id
      name
      bio
      createdAt
      dob
      email
      gender
      jam_status
      personality
      resourceID
      phone
      resourse_status
      state
      updatedAt
      zipcode
      __typename
    }
  }
`;
export const updateFacilitator = /* GraphQL */ `
  mutation UpdateFacilitator($input: UpdateFacilitatorInput!) {
    updateFacilitator(input: $input) {
      id
      name
      bio
      createdAt
      dob
      email
      gender
      jam_status
      personality
      resourceID
      phone
      resourse_status
      state
      updatedAt
      zipcode
      __typename
    }
  }
`;
export const deleteFacilitator = /* GraphQL */ `
  mutation DeleteFacilitator($input: DeleteFacilitatorInput!) {
    deleteFacilitator(input: $input) {
      id
      name
      bio
      createdAt
      dob
      email
      gender
      jam_status
      personality
      resourceID
      phone
      resourse_status
      state
      updatedAt
      zipcode
      __typename
    }
  }
`;
export const createCampaign = /* GraphQL */ `
  mutation CreateCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      id
      resourceID
      budget
      createdAt
      duration
      end_date
      start_date
      updatedAt
      __typename
    }
  }
`;
export const updateCampaign = /* GraphQL */ `
  mutation UpdateCampaign($input: UpdateCampaignInput!) {
    updateCampaign(input: $input) {
      id
      resourceID
      budget
      createdAt
      duration
      end_date
      start_date
      updatedAt
      __typename
    }
  }
`;
export const deleteCampaign = /* GraphQL */ `
  mutation DeleteCampaign($input: DeleteCampaignInput!) {
    deleteCampaign(input: $input) {
      id
      resourceID
      budget
      createdAt
      duration
      end_date
      start_date
      updatedAt
      __typename
    }
  }
`;
export const createSchool = /* GraphQL */ `
  mutation CreateSchool($input: CreateSchoolInput!) {
    createSchool(input: $input) {
      id
      contact_email
      contact_phone
      createdAt
      name
      state
      updatedAt
      zipcode
      address
      about
      image
      banner
      type
      accepting_connection
      website
      is_verified
      dont_show_again
      counselors_dont_show_again
      connections
      connection_dates
      team_size
      industry
      organization_size
      full_address
      invitations
      invitations_dates
      invitations_names
      Educators {
        nextToken
        __typename
      }
      Students {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateSchool = /* GraphQL */ `
  mutation UpdateSchool($input: UpdateSchoolInput!) {
    updateSchool(input: $input) {
      id
      contact_email
      contact_phone
      createdAt
      name
      state
      updatedAt
      zipcode
      address
      about
      image
      banner
      type
      accepting_connection
      website
      is_verified
      dont_show_again
      counselors_dont_show_again
      connections
      connection_dates
      team_size
      industry
      organization_size
      full_address
      invitations
      invitations_dates
      invitations_names
      Educators {
        nextToken
        __typename
      }
      Students {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteSchool = /* GraphQL */ `
  mutation DeleteSchool($input: DeleteSchoolInput!) {
    deleteSchool(input: $input) {
      id
      contact_email
      contact_phone
      createdAt
      name
      state
      updatedAt
      zipcode
      address
      about
      image
      banner
      type
      accepting_connection
      website
      is_verified
      dont_show_again
      counselors_dont_show_again
      connections
      connection_dates
      team_size
      industry
      organization_size
      full_address
      invitations
      invitations_dates
      invitations_names
      Educators {
        nextToken
        __typename
      }
      Students {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createOpportunity = /* GraphQL */ `
  mutation CreateOpportunity(
    $input: CreateOpportunityInput!
    $condition: ModelOpportunityConditionInput
  ) {
    createOpportunity(input: $input, condition: $condition) {
      automatic_message
      questions
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      id
      name
      remote
      requirements
      likes
      liked_by
      resourceID
      schoolID
      state
      status
      tag
      views
      interests
      interestIDs
      updatedAt
      saved_by
      Applications {
        nextToken
        __typename
      }
      Reviews {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateOpportunity = /* GraphQL */ `
  mutation UpdateOpportunity(
    $input: UpdateOpportunityInput!
    $condition: ModelOpportunityConditionInput
  ) {
    updateOpportunity(input: $input, condition: $condition) {
      automatic_message
      questions
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      id
      name
      remote
      requirements
      likes
      liked_by
      resourceID
      schoolID
      state
      status
      tag
      views
      interests
      interestIDs
      updatedAt
      saved_by
      Applications {
        nextToken
        __typename
      }
      Reviews {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteOpportunity = /* GraphQL */ `
  mutation DeleteOpportunity(
    $input: DeleteOpportunityInput!
    $condition: ModelOpportunityConditionInput
  ) {
    deleteOpportunity(input: $input, condition: $condition) {
      automatic_message
      questions
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      id
      name
      remote
      requirements
      likes
      liked_by
      resourceID
      schoolID
      state
      status
      tag
      views
      interests
      interestIDs
      updatedAt
      saved_by
      Applications {
        nextToken
        __typename
      }
      Reviews {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createResource = /* GraphQL */ `
  mutation CreateResource($input: CreateResourceInput!) {
    createResource(input: $input) {
      id
      State
      contact_email
      contact_phone
      createdAt
      description
      field
      naics_code
      naics_title
      job_title
      name
      deleted
      image
      chat_accessKey
      isAdmin
      address
      full_address
      organizationID
      updatedAt
      connections
      website
      team_size
      twoFA
      zipcode
      is_verified
      stripeID
      hubspotID
      credits
      verification_status
      deactivated
      verification_progress
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      date_deactivated
      __typename
    }
  }
`;
export const updateResource = /* GraphQL */ `
  mutation UpdateResource($input: UpdateResourceInput!) {
    updateResource(input: $input) {
      id
      State
      contact_email
      contact_phone
      createdAt
      description
      field
      naics_code
      naics_title
      job_title
      name
      deleted
      image
      chat_accessKey
      isAdmin
      address
      full_address
      organizationID
      updatedAt
      connections
      website
      team_size
      twoFA
      zipcode
      is_verified
      stripeID
      hubspotID
      credits
      verification_status
      deactivated
      verification_progress
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      date_deactivated
      __typename
    }
  }
`;
export const deleteResource = /* GraphQL */ `
  mutation DeleteResource($input: DeleteResourceInput!) {
    deleteResource(input: $input) {
      id
      State
      contact_email
      contact_phone
      createdAt
      description
      field
      naics_code
      naics_title
      job_title
      name
      deleted
      image
      chat_accessKey
      isAdmin
      address
      full_address
      organizationID
      updatedAt
      connections
      website
      team_size
      twoFA
      zipcode
      is_verified
      stripeID
      hubspotID
      credits
      verification_status
      deactivated
      verification_progress
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      date_deactivated
      __typename
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication($input: CreateApplicationInput!) {
    createApplication(input: $input) {
      id
      opportunityID
      studentID
      status
      shareprofile
      createdAt
      category
      campaignID
      application
      updatedAt
      __typename
    }
  }
`;
export const updateApplication = /* GraphQL */ `
  mutation UpdateApplication($input: UpdateApplicationInput!) {
    updateApplication(input: $input) {
      id
      opportunityID
      studentID
      status
      shareprofile
      createdAt
      category
      campaignID
      application
      updatedAt
      __typename
    }
  }
`;
export const deleteApplication = /* GraphQL */ `
  mutation DeleteApplication($input: DeleteApplicationInput!) {
    deleteApplication(input: $input) {
      id
      opportunityID
      studentID
      status
      shareprofile
      createdAt
      category
      campaignID
      application
      updatedAt
      __typename
    }
  }
`;
export const createEducator = /* GraphQL */ `
  mutation CreateEducator(
    $input: CreateEducatorInput!
    $condition: ModelEducatorConditionInput
  ) {
    createEducator(input: $input, condition: $condition) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      personality
      role
      isAdmin
      phone
      schoolID
      schoolid
      state
      image
      updatedAt
      zipcode
      chat_accessKey
      is_verified
      stripeID
      hubspotID
      twoFA
      credits
      deleted
      subscription
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
      __typename
    }
  }
`;
export const updateEducator = /* GraphQL */ `
  mutation UpdateEducator(
    $input: UpdateEducatorInput!
    $condition: ModelEducatorConditionInput
  ) {
    updateEducator(input: $input, condition: $condition) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      personality
      role
      isAdmin
      phone
      schoolID
      schoolid
      state
      image
      updatedAt
      zipcode
      chat_accessKey
      is_verified
      stripeID
      hubspotID
      twoFA
      credits
      deleted
      subscription
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
      __typename
    }
  }
`;
export const deleteEducator = /* GraphQL */ `
  mutation DeleteEducator(
    $input: DeleteEducatorInput!
    $condition: ModelEducatorConditionInput
  ) {
    deleteEducator(input: $input, condition: $condition) {
      id
      bio
      createdAt
      dob
      address
      email
      gender
      name
      personality
      role
      isAdmin
      phone
      schoolID
      schoolid
      state
      image
      updatedAt
      zipcode
      chat_accessKey
      is_verified
      stripeID
      hubspotID
      twoFA
      credits
      deleted
      subscription
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      verifier
      deactivation_reason
      deactivation_detailed_reason
      deactivation_period
      date_requested
      deactivated
      date_deactivated
      __typename
    }
  }
`;
export const createMyGroup = /* GraphQL */ `
  mutation CreateMyGroup($input: CreateMyGroupInput!) {
    createMyGroup(input: $input) {
      id
      createdAt
      updatedAt
      name
      roleID
      studentIDs
      description
      __typename
    }
  }
`;
export const updateMyGroup = /* GraphQL */ `
  mutation UpdateMyGroup($input: UpdateMyGroupInput!) {
    updateMyGroup(input: $input) {
      id
      createdAt
      updatedAt
      name
      roleID
      studentIDs
      description
      __typename
    }
  }
`;
export const deleteMyGroup = /* GraphQL */ `
  mutation DeleteMyGroup($input: DeleteMyGroupInput!) {
    deleteMyGroup(input: $input) {
      id
      createdAt
      updatedAt
      name
      roleID
      studentIDs
      description
      __typename
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      color
      description
      title
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote($input: UpdateNoteInput!) {
    updateNote(input: $input) {
      id
      color
      description
      title
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote($input: DeleteNoteInput!) {
    deleteNote(input: $input) {
      id
      color
      description
      title
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createExperienceMessage = /* GraphQL */ `
  mutation CreateExperienceMessage($input: CreateExperienceMessageInput!) {
    createExperienceMessage(input: $input) {
      id
      experienceID
      message
      session
      sessionID
      method
      subject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateExperienceMessage = /* GraphQL */ `
  mutation UpdateExperienceMessage($input: UpdateExperienceMessageInput!) {
    updateExperienceMessage(input: $input) {
      id
      experienceID
      message
      session
      sessionID
      method
      subject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteExperienceMessage = /* GraphQL */ `
  mutation DeleteExperienceMessage($input: DeleteExperienceMessageInput!) {
    deleteExperienceMessage(input: $input) {
      id
      experienceID
      message
      session
      sessionID
      method
      subject
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createExperienceSession = /* GraphQL */ `
  mutation CreateExperienceSession($input: CreateExperienceSessionInput!) {
    createExperienceSession(input: $input) {
      id
      date
      end_time
      slots
      start_time
      __typename
    }
  }
`;
export const updateExperienceSession = /* GraphQL */ `
  mutation UpdateExperienceSession($input: UpdateExperienceSessionInput!) {
    updateExperienceSession(input: $input) {
      id
      date
      end_time
      slots
      start_time
      __typename
    }
  }
`;
export const deleteExperienceSession = /* GraphQL */ `
  mutation DeleteExperienceSession($input: DeleteExperienceSessionInput!) {
    deleteExperienceSession(input: $input) {
      id
      date
      end_time
      slots
      start_time
      __typename
    }
  }
`;
export const createExperience = /* GraphQL */ `
  mutation CreateExperience($input: CreateExperienceInput!) {
    createExperience(input: $input) {
      id
      closing_date
      viewing
      description
      is_archived
      is_featured
      location
      partners
      price_per_person
      sessionsIDs
      requirements
      title
      type
      messageIDs
      likes
      liked_by
      image
      total_slots
      is_draft
      __typename
    }
  }
`;
export const updateExperience = /* GraphQL */ `
  mutation UpdateExperience($input: UpdateExperienceInput!) {
    updateExperience(input: $input) {
      id
      closing_date
      viewing
      description
      is_archived
      is_featured
      location
      partners
      price_per_person
      sessionsIDs
      requirements
      title
      type
      messageIDs
      likes
      liked_by
      image
      total_slots
      is_draft
      __typename
    }
  }
`;
export const deleteExperience = /* GraphQL */ `
  mutation DeleteExperience($input: DeleteExperienceInput!) {
    deleteExperience(input: $input) {
      id
      closing_date
      viewing
      description
      is_archived
      is_featured
      location
      partners
      price_per_person
      sessionsIDs
      requirements
      title
      type
      messageIDs
      likes
      liked_by
      image
      total_slots
      is_draft
      __typename
    }
  }
`;
export const createPurchases = /* GraphQL */ `
  mutation CreatePurchases($input: CreatePurchasesInput!) {
    createPurchases(input: $input) {
      id
      amount
      createdAt
      experienceID
      purchased_by
      slots
      updatedAt
      purchaser_role
      sessionID
      status
      __typename
    }
  }
`;
export const updatePurchases = /* GraphQL */ `
  mutation UpdatePurchases($input: UpdatePurchasesInput!) {
    updatePurchases(input: $input) {
      id
      amount
      createdAt
      experienceID
      purchased_by
      slots
      updatedAt
      purchaser_role
      sessionID
      status
      __typename
    }
  }
`;
export const deletePurchases = /* GraphQL */ `
  mutation DeletePurchases($input: DeletePurchasesInput!) {
    deletePurchases(input: $input) {
      id
      amount
      createdAt
      experienceID
      purchased_by
      slots
      updatedAt
      purchaser_role
      sessionID
      status
      __typename
    }
  }
`;
export const createRecommendation = /* GraphQL */ `
  mutation CreateRecommendation($input: CreateRecommendationInput!) {
    createRecommendation(input: $input) {
      id
      createdAt
      educatorID
      opportunityID
      groupID
      categoryID
      studentID
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateRecommendation = /* GraphQL */ `
  mutation UpdateRecommendation($input: UpdateRecommendationInput!) {
    updateRecommendation(input: $input) {
      id
      createdAt
      educatorID
      opportunityID
      groupID
      categoryID
      studentID
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteRecommendation = /* GraphQL */ `
  mutation DeleteRecommendation($input: DeleteRecommendationInput!) {
    deleteRecommendation(input: $input) {
      id
      createdAt
      educatorID
      opportunityID
      groupID
      categoryID
      studentID
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createLikeOpportunity = /* GraphQL */ `
  mutation CreateLikeOpportunity($input: CreateLikeOpportunityInput!) {
    createLikeOpportunity(input: $input) {
      id
      opportunityID
      studentID
      roleID
      createdAt
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateLikeOpportunity = /* GraphQL */ `
  mutation UpdateLikeOpportunity($input: UpdateLikeOpportunityInput!) {
    updateLikeOpportunity(input: $input) {
      id
      opportunityID
      studentID
      roleID
      createdAt
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteLikeOpportunity = /* GraphQL */ `
  mutation DeleteLikeOpportunity($input: DeleteLikeOpportunityInput!) {
    deleteLikeOpportunity(input: $input) {
      id
      opportunityID
      studentID
      roleID
      createdAt
      updatedAt
      opportunity {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createStudent = /* GraphQL */ `
  mutation CreateStudent($input: CreateStudentInput!) {
    createStudent(input: $input) {
      id
      email
      username
      email_verified
      name
      state
      educatorID
      profile_sharing
      dob
      dont_show_app_pop
      gender
      phone
      points
      schoolID
      school
      hubspotID
      address
      updatedAt
      zipcode
      createdAt
      balance
      is_verified
      multiplier
      multiplier_level
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivated
      date_deactivated
      apartment
      schooling_mode
      chat_accessKey
      graduation_year
      graduation_term
      guardianID
      interestIDs
      interests
      current_employer
      employed
      current_occupation
      last_seen
      invited_by
      shared_opportunities
      submissions_scan
      submissions_scan_1
      submissions_scan_2
      event_submissions_scan
      event_submissions_recommendation_scan
      unique_views_scan
      resource_reviews_scan
      opportunity_share_scan
      consecutive_counselor_chat_scan
      consecutive_resource_chat_scan
      invites_scan
      deleted
      chat_username
      chat_secret
      referral_code
      opportunity_acceptance_scane
      Applications {
        nextToken
        __typename
      }
      Recommendations {
        nextToken
        __typename
      }
      likedOpportunity {
        nextToken
        __typename
      }
      Educators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateStudent = /* GraphQL */ `
  mutation UpdateStudent($input: UpdateStudentInput!) {
    updateStudent(input: $input) {
      id
      email
      username
      email_verified
      name
      state
      educatorID
      profile_sharing
      dob
      dont_show_app_pop
      gender
      phone
      points
      schoolID
      school
      hubspotID
      address
      updatedAt
      zipcode
      createdAt
      balance
      is_verified
      multiplier
      multiplier_level
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivated
      date_deactivated
      apartment
      schooling_mode
      chat_accessKey
      graduation_year
      graduation_term
      guardianID
      interestIDs
      interests
      current_employer
      employed
      current_occupation
      last_seen
      invited_by
      shared_opportunities
      submissions_scan
      submissions_scan_1
      submissions_scan_2
      event_submissions_scan
      event_submissions_recommendation_scan
      unique_views_scan
      resource_reviews_scan
      opportunity_share_scan
      consecutive_counselor_chat_scan
      consecutive_resource_chat_scan
      invites_scan
      deleted
      chat_username
      chat_secret
      referral_code
      opportunity_acceptance_scane
      Applications {
        nextToken
        __typename
      }
      Recommendations {
        nextToken
        __typename
      }
      likedOpportunity {
        nextToken
        __typename
      }
      Educators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteStudent = /* GraphQL */ `
  mutation DeleteStudent($input: DeleteStudentInput!) {
    deleteStudent(input: $input) {
      id
      email
      username
      email_verified
      name
      state
      educatorID
      profile_sharing
      dob
      dont_show_app_pop
      gender
      phone
      points
      schoolID
      school
      hubspotID
      address
      updatedAt
      zipcode
      createdAt
      balance
      is_verified
      multiplier
      multiplier_level
      verification_progress
      verification_status
      verification_date_started
      verification_progress_dates
      deactivated
      date_deactivated
      apartment
      schooling_mode
      chat_accessKey
      graduation_year
      graduation_term
      guardianID
      interestIDs
      interests
      current_employer
      employed
      current_occupation
      last_seen
      invited_by
      shared_opportunities
      submissions_scan
      submissions_scan_1
      submissions_scan_2
      event_submissions_scan
      event_submissions_recommendation_scan
      unique_views_scan
      resource_reviews_scan
      opportunity_share_scan
      consecutive_counselor_chat_scan
      consecutive_resource_chat_scan
      invites_scan
      deleted
      chat_username
      chat_secret
      referral_code
      opportunity_acceptance_scane
      Applications {
        nextToken
        __typename
      }
      Recommendations {
        nextToken
        __typename
      }
      likedOpportunity {
        nextToken
        __typename
      }
      Educators {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createStudentInterest = /* GraphQL */ `
  mutation CreateStudentInterest($input: CreateStudentInterestInput!) {
    createStudentInterest(input: $input) {
      id
      studentID
      interestIDs
      createdAt
      updatedAt
      interest {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateStudentInterest = /* GraphQL */ `
  mutation UpdateStudentInterest($input: UpdateStudentInterestInput!) {
    updateStudentInterest(input: $input) {
      id
      studentID
      interestIDs
      createdAt
      updatedAt
      interest {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteStudentInterest = /* GraphQL */ `
  mutation DeleteStudentInterest($input: DeleteStudentInterestInput!) {
    deleteStudentInterest(input: $input) {
      id
      studentID
      interestIDs
      createdAt
      updatedAt
      interest {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      id
      icon_name
      name
      createdAt
      description
      updatedAt
      Interests {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      id
      icon_name
      name
      createdAt
      description
      updatedAt
      Interests {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      id
      icon_name
      name
      createdAt
      description
      updatedAt
      Interests {
        nextToken
        __typename
      }
      __typename
    }
  }
`;
export const createNotificationSetting = /* GraphQL */ `
  mutation CreateNotificationSetting($input: CreateNotificationSettingInput!) {
    createNotificationSetting(input: $input) {
      id
      new_chat
      new_reply
      new_student_joining
      roleID
      saved_closing_date
      updatedAt
      new_opportunity_application
      new_reply_review
      new_review
      opportunities_closing_date
      opportunities_closure
      group_chat_replies
      createdAt
      __typename
    }
  }
`;
export const updateNotificationSetting = /* GraphQL */ `
  mutation UpdateNotificationSetting($input: UpdateNotificationSettingInput!) {
    updateNotificationSetting(input: $input) {
      id
      new_chat
      new_reply
      new_student_joining
      roleID
      saved_closing_date
      updatedAt
      new_opportunity_application
      new_reply_review
      new_review
      opportunities_closing_date
      opportunities_closure
      group_chat_replies
      createdAt
      __typename
    }
  }
`;
export const deleteNotificationSetting = /* GraphQL */ `
  mutation DeleteNotificationSetting($input: DeleteNotificationSettingInput!) {
    deleteNotificationSetting(input: $input) {
      id
      new_chat
      new_reply
      new_student_joining
      roleID
      saved_closing_date
      updatedAt
      new_opportunity_application
      new_reply_review
      new_review
      opportunities_closing_date
      opportunities_closure
      group_chat_replies
      createdAt
      __typename
    }
  }
`;
export const createReview = /* GraphQL */ `
  mutation CreateReview($input: CreateReviewInput!) {
    createReview(input: $input) {
      id
      review
      description
      opportunityID
      resourceID
      studentID
      stars
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReview = /* GraphQL */ `
  mutation UpdateReview($input: UpdateReviewInput!) {
    updateReview(input: $input) {
      id
      review
      description
      opportunityID
      resourceID
      studentID
      stars
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReview = /* GraphQL */ `
  mutation DeleteReview($input: DeleteReviewInput!) {
    deleteReview(input: $input) {
      id
      review
      description
      opportunityID
      resourceID
      studentID
      stars
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudentOpportunityApplication = /* GraphQL */ `
  mutation CreateStudentOpportunityApplication(
    $input: CreateStudentOpportunityApplicationInput!
    $condition: ModelStudentOpportunityApplicationConditionInput
  ) {
    createStudentOpportunityApplication(input: $input, condition: $condition) {
      id
      dont_show_again
      employment_status
      employer
      occupation
      household_income
      opportunityID
      studentID
      schoolID
      state
      question_reply
      resourceID
      status
      opportunity {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudentOpportunityApplication = /* GraphQL */ `
  mutation UpdateStudentOpportunityApplication(
    $input: UpdateStudentOpportunityApplicationInput!
    $condition: ModelStudentOpportunityApplicationConditionInput
  ) {
    updateStudentOpportunityApplication(input: $input, condition: $condition) {
      id
      dont_show_again
      employment_status
      employer
      occupation
      household_income
      opportunityID
      studentID
      schoolID
      state
      question_reply
      resourceID
      status
      opportunity {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudentOpportunityApplication = /* GraphQL */ `
  mutation DeleteStudentOpportunityApplication(
    $input: DeleteStudentOpportunityApplicationInput!
    $condition: ModelStudentOpportunityApplicationConditionInput
  ) {
    deleteStudentOpportunityApplication(input: $input, condition: $condition) {
      id
      dont_show_again
      employment_status
      employer
      occupation
      household_income
      opportunityID
      studentID
      schoolID
      state
      question_reply
      resourceID
      status
      opportunity {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createStudentGuardian = /* GraphQL */ `
  mutation CreateStudentGuardian($input: CreateStudentGuardianInput!) {
    createStudentGuardian(input: $input) {
      id
      name
      phone
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudentGuardian = /* GraphQL */ `
  mutation UpdateStudentGuardian($input: UpdateStudentGuardianInput!) {
    updateStudentGuardian(input: $input) {
      id
      name
      phone
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudentGuardian = /* GraphQL */ `
  mutation DeleteStudentGuardian($input: DeleteStudentGuardianInput!) {
    deleteStudentGuardian(input: $input) {
      id
      name
      phone
      email
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createJunityInfluencer = /* GraphQL */ `
  mutation CreateJunityInfluencer($input: CreateJunityInfluencerInput!) {
    createJunityInfluencer(input: $input) {
      id
      role
      role_ID
      createdAt
      is_active
      __typename
    }
  }
`;
export const updateJunityInfluencer = /* GraphQL */ `
  mutation UpdateJunityInfluencer($input: UpdateJunityInfluencerInput!) {
    updateJunityInfluencer(input: $input) {
      id
      role
      role_ID
      createdAt
      is_active
      __typename
    }
  }
`;
export const deleteJunityInfluencer = /* GraphQL */ `
  mutation DeleteJunityInfluencer($input: DeleteJunityInfluencerInput!) {
    deleteJunityInfluencer(input: $input) {
      id
      role
      role_ID
      createdAt
      is_active
      __typename
    }
  }
`;
export const createStudentVolunteerWork = /* GraphQL */ `
  mutation CreateStudentVolunteerWork(
    $input: CreateStudentVolunteerWorkInput!
  ) {
    createStudentVolunteerWork(input: $input) {
      id
      organization
      hours_spent
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateStudentVolunteerWork = /* GraphQL */ `
  mutation UpdateStudentVolunteerWork(
    $input: UpdateStudentVolunteerWorkInput!
  ) {
    updateStudentVolunteerWork(input: $input) {
      id
      organization
      hours_spent
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteStudentVolunteerWork = /* GraphQL */ `
  mutation DeleteStudentVolunteerWork(
    $input: DeleteStudentVolunteerWorkInput!
  ) {
    deleteStudentVolunteerWork(input: $input) {
      id
      organization
      hours_spent
      studentID
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createWithdrawnOpportunityApplications = /* GraphQL */ `
  mutation CreateWithdrawnOpportunityApplications(
    $input: CreateWithdrawnOpportunityApplicationsInput!
  ) {
    createWithdrawnOpportunityApplications(input: $input) {
      id
      studentID
      opportunityName
      reason
      createdAt
      __typename
    }
  }
`;
export const updateWithdrawnOpportunityApplications = /* GraphQL */ `
  mutation UpdateWithdrawnOpportunityApplications(
    $input: UpdateWithdrawnOpportunityApplicationsInput!
  ) {
    updateWithdrawnOpportunityApplications(input: $input) {
      id
      studentID
      opportunityName
      reason
      createdAt
      __typename
    }
  }
`;
export const deleteWithdrawnOpportunityApplications = /* GraphQL */ `
  mutation DeleteWithdrawnOpportunityApplications(
    $input: DeleteWithdrawnOpportunityApplicationsInput!
  ) {
    deleteWithdrawnOpportunityApplications(input: $input) {
      id
      studentID
      opportunityName
      reason
      createdAt
      __typename
    }
  }
`;
export const createRevokeProfileSharing = /* GraphQL */ `
  mutation CreateRevokeProfileSharing(
    $input: CreateRevokeProfileSharingInput!
  ) {
    createRevokeProfileSharing(input: $input) {
      id
      studentID
      school
      createdAt
      reason
      __typename
    }
  }
`;
export const updateRevokeProfileSharing = /* GraphQL */ `
  mutation UpdateRevokeProfileSharing(
    $input: UpdateRevokeProfileSharingInput!
  ) {
    updateRevokeProfileSharing(input: $input) {
      id
      studentID
      school
      createdAt
      reason
      __typename
    }
  }
`;
export const deleteRevokeProfileSharing = /* GraphQL */ `
  mutation DeleteRevokeProfileSharing(
    $input: DeleteRevokeProfileSharingInput!
  ) {
    deleteRevokeProfileSharing(input: $input) {
      id
      studentID
      school
      createdAt
      reason
      __typename
    }
  }
`;
export const createDraftOpportunity = /* GraphQL */ `
  mutation CreateDraftOpportunity($input: CreateDraftOpportunityInput!) {
    createDraftOpportunity(input: $input) {
      id
      automatic_message
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      name
      remote
      requirements
      questions
      likes
      liked_by
      resourceID
      state
      status
      tag
      interests
      interestIDs
      updatedAt
      __typename
    }
  }
`;
export const updateDraftOpportunity = /* GraphQL */ `
  mutation UpdateDraftOpportunity($input: UpdateDraftOpportunityInput!) {
    updateDraftOpportunity(input: $input) {
      id
      automatic_message
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      name
      remote
      requirements
      questions
      likes
      liked_by
      resourceID
      state
      status
      tag
      interests
      interestIDs
      updatedAt
      __typename
    }
  }
`;
export const deleteDraftOpportunity = /* GraphQL */ `
  mutation DeleteDraftOpportunity($input: DeleteDraftOpportunityInput!) {
    deleteDraftOpportunity(input: $input) {
      id
      automatic_message
      campaignID
      category
      createdAt
      description
      duration
      expiry_date
      facilitators
      facilitatorIDs
      name
      remote
      requirements
      questions
      likes
      liked_by
      resourceID
      state
      status
      tag
      interests
      interestIDs
      updatedAt
      __typename
    }
  }
`;
export const createFeedBack = /* GraphQL */ `
  mutation CreateFeedBack($input: CreateFeedBackInput!) {
    createFeedBack(input: $input) {
      id
      type
      creator
      creatorType
      rating
      message
      __typename
    }
  }
`;
export const updateFeedBack = /* GraphQL */ `
  mutation UpdateFeedBack($input: UpdateFeedBackInput!) {
    updateFeedBack(input: $input) {
      id
      type
      creator
      creatorType
      rating
      message
      __typename
    }
  }
`;
export const deleteFeedBack = /* GraphQL */ `
  mutation DeleteFeedBack($input: DeleteFeedBackInput!) {
    deleteFeedBack(input: $input) {
      id
      type
      creator
      creatorType
      rating
      message
      __typename
    }
  }
`;
export const createCreditMultiplier = /* GraphQL */ `
  mutation CreateCreditMultiplier($input: CreateCreditMultiplierInput!) {
    createCreditMultiplier(input: $input) {
      id
      condition
      duration
      value
      multiplier
      created_by
      updated_by
      updatedValues
      credit_history
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCreditMultiplier = /* GraphQL */ `
  mutation UpdateCreditMultiplier($input: UpdateCreditMultiplierInput!) {
    updateCreditMultiplier(input: $input) {
      id
      condition
      duration
      value
      multiplier
      created_by
      updated_by
      updatedValues
      credit_history
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCreditMultiplier = /* GraphQL */ `
  mutation DeleteCreditMultiplier($input: DeleteCreditMultiplierInput!) {
    deleteCreditMultiplier(input: $input) {
      id
      condition
      duration
      value
      multiplier
      created_by
      updated_by
      updatedValues
      credit_history
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCreditEvent = /* GraphQL */ `
  mutation CreateCreditEvent($input: CreateCreditEventInput!) {
    createCreditEvent(input: $input) {
      id
      condition
      value
      updated_by
      created_by
      duration
      credit_history
      updated_at
      created_at
      __typename
    }
  }
`;
export const updateCreditEvent = /* GraphQL */ `
  mutation UpdateCreditEvent($input: UpdateCreditEventInput!) {
    updateCreditEvent(input: $input) {
      id
      condition
      value
      updated_by
      created_by
      duration
      credit_history
      updated_at
      created_at
      __typename
    }
  }
`;
export const deleteCreditEvent = /* GraphQL */ `
  mutation DeleteCreditEvent($input: DeleteCreditEventInput!) {
    deleteCreditEvent(input: $input) {
      id
      condition
      value
      updated_by
      created_by
      duration
      credit_history
      updated_at
      created_at
      __typename
    }
  }
`;
export const createCreditValue = /* GraphQL */ `
  mutation CreateCreditValue($input: CreateCreditValueInput!) {
    createCreditValue(input: $input) {
      id
      updatedBy
      updatedAt
      updatedValues
      value
      __typename
    }
  }
`;
export const updateCreditValue = /* GraphQL */ `
  mutation UpdateCreditValue($input: UpdateCreditValueInput!) {
    updateCreditValue(input: $input) {
      id
      updatedBy
      updatedAt
      updatedValues
      value
      __typename
    }
  }
`;
export const deleteCreditValue = /* GraphQL */ `
  mutation DeleteCreditValue($input: DeleteCreditValueInput!) {
    deleteCreditValue(input: $input) {
      id
      updatedBy
      updatedAt
      updatedValues
      value
      __typename
    }
  }
`;
export const createCreditMultiplierHistory = /* GraphQL */ `
  mutation CreateCreditMultiplierHistory(
    $input: CreateCreditMultiplierHistoryInput!
  ) {
    createCreditMultiplierHistory(input: $input) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const updateCreditMultiplierHistory = /* GraphQL */ `
  mutation UpdateCreditMultiplierHistory(
    $input: UpdateCreditMultiplierHistoryInput!
  ) {
    updateCreditMultiplierHistory(input: $input) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const deleteCreditMultiplierHistory = /* GraphQL */ `
  mutation DeleteCreditMultiplierHistory(
    $input: DeleteCreditMultiplierHistoryInput!
  ) {
    deleteCreditMultiplierHistory(input: $input) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const createCreditEventHistory = /* GraphQL */ `
  mutation CreateCreditEventHistory($input: CreateCreditEventHistoryInput!) {
    createCreditEventHistory(input: $input) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const updateCreditEventHistory = /* GraphQL */ `
  mutation UpdateCreditEventHistory($input: UpdateCreditEventHistoryInput!) {
    updateCreditEventHistory(input: $input) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const deleteCreditEventHistory = /* GraphQL */ `
  mutation DeleteCreditEventHistory($input: DeleteCreditEventHistoryInput!) {
    deleteCreditEventHistory(input: $input) {
      id
      data
      createdAt
      createdBy
      __typename
    }
  }
`;
export const createSubmissionScan = /* GraphQL */ `
  mutation CreateSubmissionScan($input: CreateSubmissionScanInput!) {
    createSubmissionScan(input: $input) {
      id
      name
      scanned
      __typename
    }
  }
`;
export const updateSubmissionScan = /* GraphQL */ `
  mutation UpdateSubmissionScan($input: UpdateSubmissionScanInput!) {
    updateSubmissionScan(input: $input) {
      id
      name
      scanned
      __typename
    }
  }
`;
export const deleteSubmissionScan = /* GraphQL */ `
  mutation DeleteSubmissionScan($input: DeleteSubmissionScanInput!) {
    deleteSubmissionScan(input: $input) {
      id
      name
      scanned
      __typename
    }
  }
`;
export const createSubscriptionUpgradeRequest = /* GraphQL */ `
  mutation CreateSubscriptionUpgradeRequest(
    $input: CreateSubscriptionUpgradeRequestInput!
  ) {
    createSubscriptionUpgradeRequest(input: $input) {
      id
      organizationID
      name
      plan
      cost
      roleID
      roleType
      status
      approval
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubscriptionUpgradeRequest = /* GraphQL */ `
  mutation UpdateSubscriptionUpgradeRequest(
    $input: UpdateSubscriptionUpgradeRequestInput!
  ) {
    updateSubscriptionUpgradeRequest(input: $input) {
      id
      organizationID
      name
      plan
      cost
      roleID
      roleType
      status
      approval
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubscriptionUpgradeRequest = /* GraphQL */ `
  mutation DeleteSubscriptionUpgradeRequest(
    $input: DeleteSubscriptionUpgradeRequestInput!
  ) {
    deleteSubscriptionUpgradeRequest(input: $input) {
      id
      organizationID
      name
      plan
      cost
      roleID
      roleType
      status
      approval
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment($input: CreatePaymentInput!) {
    createPayment(input: $input) {
      id
      roleID
      roleType
      type
      amount
      description
      credits
      status
      metaData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      id
      roleID
      roleType
      type
      amount
      description
      credits
      status
      metaData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment($input: DeletePaymentInput!) {
    deletePayment(input: $input) {
      id
      roleID
      roleType
      type
      amount
      description
      credits
      status
      metaData
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChatHandler = /* GraphQL */ `
  mutation CreateChatHandler($input: CreateChatHandlerInput!) {
    createChatHandler(input: $input) {
      chat_id
      access_key
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const updateChatHandler = /* GraphQL */ `
  mutation UpdateChatHandler($input: UpdateChatHandlerInput!) {
    updateChatHandler(input: $input) {
      chat_id
      access_key
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const deleteChatHandler = /* GraphQL */ `
  mutation DeleteChatHandler($input: DeleteChatHandlerInput!) {
    deleteChatHandler(input: $input) {
      chat_id
      access_key
      updatedAt
      createdAt
      __typename
    }
  }
`;
export const createAdminPermissionModule = /* GraphQL */ `
  mutation CreateAdminPermissionModule(
    $input: CreateAdminPermissionModuleInput!
  ) {
    createAdminPermissionModule(input: $input) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdminPermissionModule = /* GraphQL */ `
  mutation UpdateAdminPermissionModule(
    $input: UpdateAdminPermissionModuleInput!
  ) {
    updateAdminPermissionModule(input: $input) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdminPermissionModule = /* GraphQL */ `
  mutation DeleteAdminPermissionModule(
    $input: DeleteAdminPermissionModuleInput!
  ) {
    deleteAdminPermissionModule(input: $input) {
      id
      name
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPermissionRoles = /* GraphQL */ `
  mutation CreatePermissionRoles($input: CreatePermissionRolesInput!) {
    createPermissionRoles(input: $input) {
      id
      name
      read
      edit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePermissionRoles = /* GraphQL */ `
  mutation UpdatePermissionRoles($input: UpdatePermissionRolesInput!) {
    updatePermissionRoles(input: $input) {
      id
      name
      read
      edit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePermissionRoles = /* GraphQL */ `
  mutation DeletePermissionRoles($input: DeletePermissionRolesInput!) {
    deletePermissionRoles(input: $input) {
      id
      name
      read
      edit
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAdminNotificationSettings = /* GraphQL */ `
  mutation CreateAdminNotificationSettings(
    $input: CreateAdminNotificationSettingsInput!
  ) {
    createAdminNotificationSettings(input: $input) {
      id
      admin_id
      verification_request
      opportunities_feedback
      issue_reports
      interest_suggestions
      __typename
    }
  }
`;
export const updateAdminNotificationSettings = /* GraphQL */ `
  mutation UpdateAdminNotificationSettings(
    $input: UpdateAdminNotificationSettingsInput!
  ) {
    updateAdminNotificationSettings(input: $input) {
      id
      admin_id
      verification_request
      opportunities_feedback
      issue_reports
      interest_suggestions
      __typename
    }
  }
`;
export const deleteAdminNotificationSettings = /* GraphQL */ `
  mutation DeleteAdminNotificationSettings(
    $input: DeleteAdminNotificationSettingsInput!
  ) {
    deleteAdminNotificationSettings(input: $input) {
      id
      admin_id
      verification_request
      opportunities_feedback
      issue_reports
      interest_suggestions
      __typename
    }
  }
`;
export const createUserDevice = /* GraphQL */ `
  mutation CreateUserDevice($input: CreateUserDeviceInput!) {
    createUserDevice(input: $input) {
      userID
      devices
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUserDevice = /* GraphQL */ `
  mutation UpdateUserDevice($input: UpdateUserDeviceInput!) {
    updateUserDevice(input: $input) {
      userID
      devices
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUserDevice = /* GraphQL */ `
  mutation DeleteUserDevice($input: DeleteUserDeviceInput!) {
    deleteUserDevice(input: $input) {
      userID
      devices
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDeactivationRequest = /* GraphQL */ `
  mutation CreateDeactivationRequest($input: CreateDeactivationRequestInput!) {
    createDeactivationRequest(input: $input) {
      id
      accountID
      organizationID
      accountType
      dateRequested
      period
      reason
      description
      requesterID
      requesterName
      attachments
      priority
      approved
      approverID
      approverName
      secondApproverApproved
      secondApproverID
      secondApproverName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDeactivationRequest = /* GraphQL */ `
  mutation UpdateDeactivationRequest($input: UpdateDeactivationRequestInput!) {
    updateDeactivationRequest(input: $input) {
      id
      accountID
      organizationID
      accountType
      dateRequested
      period
      reason
      description
      requesterID
      requesterName
      attachments
      priority
      approved
      approverID
      approverName
      secondApproverApproved
      secondApproverID
      secondApproverName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDeactivationRequest = /* GraphQL */ `
  mutation DeleteDeactivationRequest($input: DeleteDeactivationRequestInput!) {
    deleteDeactivationRequest(input: $input) {
      id
      accountID
      organizationID
      accountType
      dateRequested
      period
      reason
      description
      requesterID
      requesterName
      attachments
      priority
      approved
      approverID
      approverName
      secondApproverApproved
      secondApproverID
      secondApproverName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createYouthNotificationSetting = /* GraphQL */ `
  mutation CreateYouthNotificationSetting(
    $input: CreateYouthNotificationSettingInput!
  ) {
    createYouthNotificationSetting(input: $input) {
      id
      youthId
      push_expiring_opportunities
      push_update_application
      push_new_chat
      email_expiring_opportunities
      email_update_application
      email_new_chat
      sms_expiring_opportunities
      sms_update_application
      sms_new_chat
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateYouthNotificationSetting = /* GraphQL */ `
  mutation UpdateYouthNotificationSetting(
    $input: UpdateYouthNotificationSettingInput!
  ) {
    updateYouthNotificationSetting(input: $input) {
      id
      youthId
      push_expiring_opportunities
      push_update_application
      push_new_chat
      email_expiring_opportunities
      email_update_application
      email_new_chat
      sms_expiring_opportunities
      sms_update_application
      sms_new_chat
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteYouthNotificationSetting = /* GraphQL */ `
  mutation DeleteYouthNotificationSetting(
    $input: DeleteYouthNotificationSettingInput!
  ) {
    deleteYouthNotificationSetting(input: $input) {
      id
      youthId
      push_expiring_opportunities
      push_update_application
      push_new_chat
      email_expiring_opportunities
      email_update_application
      email_new_chat
      sms_expiring_opportunities
      sms_update_application
      sms_new_chat
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAppNotification = /* GraphQL */ `
  mutation CreateAppNotification($input: CreateAppNotificationInput!) {
    createAppNotification(input: $input) {
      id
      userId
      title
      text
      link
      action
      module
      type
      icon
      read
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAppNotification = /* GraphQL */ `
  mutation UpdateAppNotification($input: UpdateAppNotificationInput!) {
    updateAppNotification(input: $input) {
      id
      userId
      title
      text
      link
      action
      module
      type
      icon
      read
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const deleteAppNotification = /* GraphQL */ `
  mutation DeleteAppNotification($input: DeleteAppNotificationInput!) {
    deleteAppNotification(input: $input) {
      id
      userId
      title
      text
      link
      action
      module
      type
      icon
      read
      createdAt
      updatedAt
      __typename
    }
  }
`;
