import React, { useState } from "react";
import "../../scss/Admin/experiences.scss"
import { Tabs, Tab } from "react-bootstrap";
import ActiveTab from "../../components/Experiences/activeTab";
import ArchiveTab from "../../components/Experiences/archiveTab";

function ExperiencesPage({ experiences }) {
  const [key, setKey] = useState('active');

  return ( 
    <div className="experiences tabs_wrapper">
      <Tabs
        id="experiences"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="active" title="Active">
          <div className="experiences_active_main_content">
            <ActiveTab experiences={experiences} />
          </div>
        </Tab>
        <Tab eventKey="archived" title="Archived">
          <div className="experiences_active_main_content">
            <ArchiveTab experiences={experiences} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default ExperiencesPage;