import React, { useState, useEffect } from "react";
import ModalWrapper from "../../widgets/Modal/modal";
import { Arrow90degLeft, X, PlusCircle, Handbag } from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";
import SessionEditCard from "./sessionEditCard";
import SessionCard from "./sessionCard";
import ToastContainerComponent from "../AccountVerification/ToastContainer";
import { ClipLoader } from "react-spinners";

function SessionsModal({
  show,
  setShow,
  loading,
  addExperience,
  title,
  setSessionsData,
  sessionsData,
  toastShow,
  setToastShow,
}) {
  const [sessions, setSessions] = useState([]);
  const [proceed, setProceed] = useState(false);

  const progress = () => {
    return <ClipLoader size={10} color={loading ? "#fff" : ""} />;
  };

  const cancelSession = (id) => {
    setSessions(sessions.filter((_, index) => index !== 1 - id));
  };

  const handleSessionItemSave = (id, data) => {
    const itemId = parseInt(id);
    setEditSessions((current) =>
      current.filter((item, index) => {
        return index + 1 !== itemId;
      }),
    );

    const cardId = sessions.length + 1;
    setSessions([
      ...sessions,
      <SessionCard
        id={cardId}
        data={data}
        cancelSession={cancelSession}
        key={`sess-card-${cardId}`}
      />,
    ]);
  };

  const removeEditSession = (id) => {
    setEditSessions((prev) => prev.filter((_, index) => index !== id - 1));
  };

  const [editSessions, setEditSessions] = useState([]);

  const addEditSession = () => {
    const id = editSessions.length + 1;
    setEditSessions([
      ...editSessions,
      <SessionEditCard
        func={handleSessionItemSave}
        setData={setSessionsData}
        sessionIds={sessionsData}
        cancelSession={removeEditSession}
        id={id}
        key={`sess-edit-card-${id}`}
      />,
    ]);
  };

  useEffect(() => {
    setProceed(sessions.length > 0);
  }, [sessions]);

  const toastData = {
    title: "New Experience Created!",
    message: title,
    icon: <Handbag fill="#226E54" size={40} />,
  };

  return (
    <>
      <ModalWrapper state={`${show ? "show" : "hide"}`}>
        <div className="add_experience add_exp_sessions">
          <div className="mbd">
            <div className="flex-between top_sect">
              <div className="align-items bold">Add New Experience</div>
              <div className="right_side" onClick={() => setShow(false)}>
                <X fill="#515A6A" size={24} />
              </div>
            </div>
          </div>
          <div className="mt-10 overflow-auto" style={{ height: "87%" }}>
            <div
              className="mt-10 pd-20 back-exp"
              style={{ cursor: "pointer" }}
              onClick={() => setShow(false)}
            >
              <Arrow90degLeft size={16} className="mr-10" />
              <span>Back to Experience Details</span>
            </div>
            <div className="session_body pd-20">
              <div className="mt-40">
                <h3 className="bold">Create Sessions</h3>
                <h6>
                  Youth can purchase one or more sessions of this experience.
                </h6>
              </div>
              <div className="sessions">{sessions}</div>
              <div>{editSessions}</div>
              <div className="add_session mt-20" onClick={addEditSession}>
                <span>
                  <PlusCircle />
                </span>
                <span className="ml-10">Add Session</span>
              </div>
              <hr></hr>
              <div className="field mt-10 p-20 flex-between">
                <CustomisedButton
                  text="Save as draft"
                  bg="#fff"
                  bd="1px solid #DDDFE2"
                  pd="10px 10px"
                  rd="10px"
                  width="48%"
                  className="mr-10"
                  onClick={() => addExperience(true)}
                />
                <CustomisedButton
                  text={loading ? progress() : "Create and Publish"}
                  fg={proceed ? "#fff" : "#B9BDC3"}
                  bg={proceed ? "#2D9371" : "#F4F5F7"}
                  bd="1px solid #F4F5F7"
                  pd="10px 10px"
                  rd="10px"
                  op={proceed ? 1 : 0.6}
                  db={!proceed}
                  width="48%"
                  onClick={() => addExperience()}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalWrapper>
      <ToastContainerComponent
        setToastShow={setToastShow}
        toastShow={toastShow}
        data={toastData}
        bottom={false}
      />
    </>
  );
}

export default SessionsModal;
