import React from "react";
import ChartData from "../data";
import ReactApexChart from "react-apexcharts";
import { cleanChartData, sameYearData } from "../../../utils/helpers";

const BasicAreaChart = ({ earnings }) => {
  const earningsValues = earnings.reduce(
    (acc, item) => [...acc, ...item.value],
    []
  );
  const sameYearChartDataArr = earningsValues.filter((elt) =>
    sameYearData(JSON.parse(elt))
  );
    
  const earners = {}
  
  const sameMonthChartDataArr = sameYearChartDataArr.reduce((acc, elt) => {
    const resp = { ...acc };
    const eltObj = JSON.parse(elt);
    const dateCreatedAt = new Date(eltObj.createdAt);
    const dateCreatedMonth = String(dateCreatedAt.getMonth());
    if (Object.keys(resp).includes(dateCreatedMonth)) {
      if (!(Object.keys(earners).includes(dateCreatedMonth) && earners[dateCreatedMonth].includes(eltObj.earner))) {
        resp[dateCreatedMonth] += 1
        earners[dateCreatedMonth] = [...(earners[dateCreatedMonth] || []), eltObj.earner];
      } 
    } else {
      resp[dateCreatedAt.getMonth()] = 1
      earners[dateCreatedMonth] = [eltObj.earner];
    }
    return resp;
  }, {});

  const cleanedData = cleanChartData(sameMonthChartDataArr);

  const series = [
    { name: "Earnings (JC)", data: Object.values(cleanedData) },
  ];
  const basicOptions = {
    chart: {
      id: "Chart",
      type: "area",
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    labels: ChartData.monthDataSeries1.dates,
    xaxis: {
      type: "category",
      categories: ChartData.monthDataSeries1.dates,
      title: {
        text: "Month",
        style: {
          fontSize: "15px",
          color: "#667085",
        },
      },
    },
    colors: ["#39B88D", ""],
    yaxis: {
      opposite: false,
      title: {
        text: "JCoin Earners",
        style: {
          fontSize: "15px",
          color: "#667085",
        },
      },
    },
    fill: {
      opacity: [0, 0, 0, 0],
      type: "gradient",
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  return (
    <div>
      <ReactApexChart
        options={basicOptions}
        series={series}
        type="area"
        height={300}
      />
    </div>
  );
};

export default BasicAreaChart;
