import {
    SET_CURRENT_STATE_DATA,
    RESET_CURRENT_STATE_DATA,
} from "../constants/currentStateConstants";

const initialLoginState = {
    state: "CT",
};

const stateReducer = (state = initialLoginState, action) => {
    switch (action.type) {
        case SET_CURRENT_STATE_DATA:
            return { ...state, state: action.payload };
        case RESET_CURRENT_STATE_DATA:
            state = initialLoginState;
            return state;
        default:
            return state;
    }
};

export default stateReducer;
