import React from "react";
// import { Row } from "react-bootstrap";
import { ChevronDown, Download } from "react-bootstrap-icons";
import CustomisedButton from "../../widgets/Button/Button";
import BasicAreaChart from "./Charts/EventEarningsChart";
import LargeAreaChart from "./Charts/Chart";
import EarnersAreaChart from "./Charts/EarnersChart";
import { ReactComponent as NoImageDistr } from "../../assets/images/no-age-distr.svg";

const createLabel = (text, icon) => {
  return (
    <div className="flex-between">
      <span className="align-items mr-10">{text}</span>
      <span className="align-items">{icon}</span>
    </div>
  );
};

function Infos({ events, multipliers, purchases }) {
  const totalEarnings = [ ...events, ... multipliers ];
  const totalItems = [ ...totalEarnings, ... purchases ];

  return (
    <div className="demand_infos">
      <div className="flex-v">
        <div className="info_card main-chart">
          <div className="flex-between">
            <div className="info_card_title">Earnings vs Purchases</div>
            <div className="mt-10 flex-start chart-action-btns">
              <CustomisedButton
                text={createLabel("Past 1 year", <ChevronDown />)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
                className="mr-10"
              />
              <CustomisedButton
                text={createLabel("Download", <Download />)}
                bd="1px solid #DDDFE2"
                pd="8px"
                fs="12px"
                bg="#fff"
              />
            </div>
          </div>
          <LargeAreaChart earnings={[ ...events, ...multipliers]} purchases={purchases}/>
          {totalItems.length === 0 && 
          (<div className="flex-center no-img">
            <NoImageDistr />
          </div>)}
        </div>
        <div className="info_card">
          <div className="flex-between">
            <div className="info_card_title">Earnings by Event</div>
            <div className="mt-10 flex-start chart-action-btns">
              <CustomisedButton
                text={createLabel("Past 1 year", <ChevronDown />)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
                className="mr-10"
              />
              <CustomisedButton
                text={createLabel("Download", <Download />)}
                bd="1px solid #DDDFE2"
                pd="8px"
                fs="12px"
                bg="#fff"
              />
            </div>
          </div>
          {events.length === 0 && <div className="flex-center no-img">
            <NoImageDistr />
          </div> }
          <div className="earning_cards">
            {events?.map((item, index) => (
              <div className="card flex-between" key={index}>
                <div style={{ width: "50%" }}>
                  <h4 className="bold">{item.earnings} JC</h4>
                  <h6>{item.event_value} {item.title}</h6>
                </div>
                <div className="chart_area">
                  <BasicAreaChart chartData={item.value} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="info_card">
          <div className="flex-between">
            <div className="info_card_title">Earners</div>
            <div className="mt-10 flex-start chart-action-btns">
              <CustomisedButton
                text={createLabel("Past 1 year", <ChevronDown />)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
                className="mr-10"
              />
              <CustomisedButton
                text={createLabel("Download", <Download />)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
              />
            </div>
          </div>
          {totalEarnings.length === 0 &&
          <div className="flex-center no-img">
            <NoImageDistr />
          </div> }
          <EarnersAreaChart earnings={totalEarnings} />
        </div>
        <div className="info_card">
          <div className="flex-between">
            <div className="info_card_title">Earnings by Multiplier</div>
            <div className="mt-10 flex-start chart-action-btns">
              <CustomisedButton
                text={createLabel("Past 1 year", <ChevronDown />)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
                className="mr-10"
              />
              <CustomisedButton
                text={createLabel("Download", <Download />)}
                bd="1px solid #DDDFE2"
                pd="8px"
                bg="#fff"
                fs="12px"
              />
            </div>
          </div>
          {multipliers.length === 0 && <div className="flex-center no-img">
            <NoImageDistr />
          </div> }
          <div className="earning_cards">
            {multipliers?.map((item, index) =>
            <div 
              className="card flex-between"
              key={index}
            >
              <div style={{width: "50%"}}>
                <h4 className="bold">
                  {item.earnings}
                </h4>
                <h6>{item?.multiplierValue} {item.title} {item?.duration ? `in ${item?.duration} days`: ''}</h6>
              </div>
              <div className="chart_area">
                <BasicAreaChart chartData={item.value} type="multiplier" />
              </div>
            </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Infos;
