import React from "react";
import ModalWrapper from "../../../widgets/Modal/modal";
import LeftModalContent from "./LeftModalContent";
import LeftModalTopSection from "./TopSection";

function LeftModal(props) {
  const {
    item,
    detailsRefetch,
    show,
    setShowModal,
    layout,
    subLayout,
    btnText,
    details,
    extra,
  } = { ...props };

  return (
    <ModalWrapper state={`${show ? "show" : ""}`}>
      <LeftModalTopSection
        item={item}
        func={setShowModal}
        layout={layout}
        btnText={btnText}
        details={details}
        extra={extra}
      />
      <LeftModalContent
        layout={layout}
        detailsRefetch={detailsRefetch}
        subLayout={subLayout}
        selected={item?.length > 0 ? (item[0] ? item[0][2] : "") : ""}
        modal={show}
        setModal={setShowModal}
        items={item}
        details={details}
      />
    </ModalWrapper>
  );
}

export default LeftModal;
