import React, { useState, useEffect, useRef } from 'react';
import { Form } from "react-bootstrap";
import { AllCreditEvents } from "../../../../services/query/creditEvents";


function EditEvents({ active, setData, refetch }) {

  const [state, setState] = useState([]);
  const [events, setEvents] = useState([]);

  const firstRun = useRef(true);

  const handleChange = (e, condition, index) => {
    const currentItem = state[index];
    currentItem[condition] = e.target.value;

    const modState = state.filter((elt, id) => id !== index);
    modState.splice(index, 0, currentItem);
    setState(modState);
  }

  const layout2 = (item, index) => {
    return (
      <div 
        className="flex-between mt-20 pd-20 pb-20"
        style={{ borderBottom: "3px dashed #DDDFE2"}}
      >
        <div className="flex-start mr-10">
          <div 
            className="mr-10"
            style={{width: "30%"}}
          >
            <Form.Control
              type="number"
              name={`field_${index}_0`}
              className="form_input mr-10"
              value={item.value}
              disabled={!active}
              onChange={(e) => handleChange(e, "value", index)}
            />
          </div>
          <div style={{width: "70%"}}>
            <Form.Control
              className="form_input"
              value={item.condition}
              onChange={(e) => handleChange(e, "condition", index)}
              disabled={!active}
            />
          </div>
        </div>
        <div className="flex-start">
          <div 
            className="mr-10"
            style={{width: "30%"}}
          >
            <Form.Control
              type="number"
              name={`field_${index}_1`}
              className="form_input"
              value={item.duration}
              disabled={!active}
              onChange={(e) => handleChange(e, "duration", index)}
            />
          </div>
          <div style={{width: "70%"}}>
            <Form.Control
              className="form_input"
              value={item.condition === "Invite friend" ? "per month" : ""}
              disabled
            />
          </div>
        </div>
      </div>
    )
  }

  const layout1 = (item, index) => {
    return (
      <div className="item">
        <div>
          <Form.Control
            type="number"
            name={`field_${index+1}`}
            className="form_input"
            value={item.value}
            disabled={!active}
            onChange={(e) => handleChange(e, "value", index)}
          />
        </div>
        <div>
          <Form.Control
            className="form_input"
            value={item.condition}
            disabled={!active}
            onChange={(e) => handleChange(e, "condition", index)}
          />
        </div>
      </div>
    )
  }

  const returnLayout = (item, index) => {
    if (item.duration) {
      return layout2(item, index);
    }
    else {
      return layout1(item, index);
    }
  }

  useEffect(() => {
    AllCreditEvents().then((events) => {
      const modeEvents = [ ...events ];
      modeEvents.sort((a, b) => {
        const itemA = new Date(a?.created_at);
        const itemB = new Date(b?.created_at);
        if (itemA < itemB) return -1;
        if (itemA > itemB) return 1;
        return 0;
      });
      setEvents(modeEvents);
      setState(modeEvents);
    });
  }, [refetch]);

  useEffect(() => {
    if (!firstRun.current && !active) {
      setState(events);
    } else {
      firstRun.current = false;
    }
  }, [active]);

  useEffect(() => {
    setData(state);
  }, [state]);
  
  return ( 
    <div className="edit_events_grid overflow-auto">
     <div className="item header">
        <div>Condition</div>
        <div>Maximum</div>
      </div>
      {state.map((item, index) =>
        <>{returnLayout(item, index)}</>
      )}
    </div>
  );
}

export default EditEvents;