import React, {useEffect, useRef, useState} from "react";
import TopSection from "../Youth/TopSection";
import { CheckCircleFill, InfoCircle } from "react-bootstrap-icons";
import RightContent from "./RightContent/RightContent";
import { getInitial, calculateTime } from "../../utils/helpers";

function TabContent({ layout, data, colors, refetch }) {
  const accountItemRef = useRef(null);
  const [view, setView] = useState(false);
  const [selected, setSelected] = useState({});
  const [status, setStatus] = useState({});
  const [id, setId] = useState("");

  const handleClick = (id) => {
    setView(true);
    setId(id);
    setSelected(data.find((elt) => elt.id === id));
  };

  const handleStatusUpdate = (id, status) => {
    setStatus({ ...status, [id]: status });
  };

  const closePreview = () => {
    // if (resp) {
    setSelected({});
    setView(false);
    // }
  };

  const noView = () => {
    return (
      <>
        <div>
          <InfoCircle fill="#B9BDC3" size={30} />
        </div>
        <div className="mt-10">Select a request to review and response</div>
      </>
    );
  };

  useEffect(() => {
    if (data) {
      const findItem = data?.find((elt) => elt?.id === selected?.id);
      if (findItem) {
        setSelected(findItem);
      } else {
        closePreview();
      }
    }
  }, [data]);

  return (
    <div className="d-flex w-100 h-100 deactivate-sect">
      <div className="account_v_main_content">
        <div className="account_v_main_inner overflow-auto h-100">
          <TopSection
            filterText={false}
            menu="account-deactivation"
            inputPlaceholder="Search for a request"
          />
          <div className="main_content" ref={accountItemRef}>
            {data?.map((item, index) => (
              <div
                className="account_item pb-3"
                key={index}
                style={{ backgroundColor: selected?.id === item?.id ? "#FFE3B3" : "#F4F5F7" }}
                onClick={() => handleClick(item?.id)}
              >
                {layout === "youth" ? (
                  <div className="profile_wrapper">
                    <div
                      className="account_initial_wrapper"
                      style={{ backgroundColor: colors[index] }}
                    >
                      <span>{getInitial(item?.info?.name)}</span>
                      {layout === "youth" && item?.is_verified && (
                        <div className="verify_icon_bg">
                          <CheckCircleFill size={20} fill="#0D3575" />
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <img
                    src={item?.info?.image}
                    alt={item?.info?.name}
                    className="item_img"
                  />
                )}
                <h5 style={{ fontSize: 14 }}>{item?.info?.name}</h5>
                <h6 style={{ fontSize: 11 }}>{calculateTime(item?.dateRequested)}</h6>
              </div>
            ))}
          </div>
          <div className="set_pb"></div>
        </div>
      </div>
      <div className={`view_account ${view ? "" : "align_middle"}`}>
        {view ? (
          <RightContent
            item={selected}
            id={id}
            status={status}
            layout={layout}
            update={handleStatusUpdate}
            func={closePreview}
            bgColor={colors[data?.findIndex((elt) => elt.id === id)]}
            refetch={refetch}
          />
        ) : (
          noView()
        )}
      </div>
    </div>
  );
}

export default TabContent;
