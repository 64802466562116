import React from 'react';
import { ChevronExpand } from "react-bootstrap-icons"
import { nameCol, verificationCol } from "../../utils/TableWrapper/tableWrapper"


const expandIcon = <span><ChevronExpand fontSize={18}/></span>

export const councelorsTableList = 
  {
    "th": [
      {
        "component": "INSTITUTION NAME",
        "cls": "org_name_col"
      },
      {
        "component": "AGE",
        "cls": "no_of_loc"
      },
      {
        "component": <>VERIFICATION{expandIcon}</>,
        "cls": "verification_col"
      },
      {
        "component": <>STATUS{expandIcon}</>,
        "cls": "verification_col"
      },
      {
        "component": " ",
        "cls": "final_col"
      },
    ],

    "tc": [
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/achieve_hartford.png"),
            name: "Bulkeley High School",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/amazon_1.png"),
            name: "Kings College",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/dell_1.png"),
            name: "Graceland High School",
            layout: 2,
            verified: false
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/salesforce_1.png"),
            name: "Holy Family College",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("In Progress")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/johnson_johnson.png"),
            name: "Federal Government College",
            layout: 2,
            verified: false
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Not Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/netflix.png"),
            name: "Federal Government Girl College",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("In Progress")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/mastercard.png"),
            name: "Command High School",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/starbucks.png"),
            name: "Tower of Ivory High School",
            layout: 2,
            verified: false
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/nitendo.png"),
            name: "Laporte D’ Jugador",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],

    ]
  }


  export const councelorsSectTableList = 
  {
    "th": [
      {
        "component": "INSTITUTION NAME",
        "cls": "org_name_col",
        "sortable": false,
        "label": "name"
      },
      {
        "component": "LOCATION",
        "cls": "no_of_loc",
        "sortable": false,
        "label": "locations"
      },
      {
        "component": <>VERIFICATION{expandIcon}</>,
        "cls": "verification_col",
        "sortable": true,
        "label": "verification_status"
      },
      {
        "component": " ",
        "cls": "final_col",
        "sortable": false,
        "label": "actions"
      },
    ],

    "tc": [
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/achieve_hartford.png"),
            name: "Bulkeley High School",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/amazon_1.png"),
            name: "Kings College",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/dell_1.png"),
            name: "Graceland High School",
            layout: 2,
            verified: false
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/salesforce_1.png"),
            name: "Holy Family College",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("In Progress")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/johnson_johnson.png"),
            name: "Federal Government College",
            layout: 2,
            verified: false
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Not Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/netflix.png"),
            name: "Federal Government Girl College",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("In Progress")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/mastercard.png"),
            name: "Command High School",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/starbucks.png"),
            name: "Tower of Ivory High School",
            layout: 2,
            verified: false
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/nitendo.png"),
            name: "Laporte D’ Jugador",
            layout: 2,
            verified: true
          })
        ],
        ["no_of_loc","18"],
        ["verification_col",verificationCol("Verified")],
        ["verification_col","Live"],
        ["final_col", "..."]
      ],

    ]
  }