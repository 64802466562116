import {
  SET_INSTITUTION_DATA,
  RESET_INSTITUTION_DATA,
} from "../constants/institutionConstants";

export const setInstitutionDataAction = (data) => async (dispatch) => {
  dispatch({
    type: SET_INSTITUTION_DATA,
    payload: data,
  });
};

export const resetInstitutionDataAction = () => async (dispatch) => {
  dispatch({
    type: RESET_INSTITUTION_DATA,
  });
};
