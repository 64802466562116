import {
    SET_CURRENT_TABLE_DATA,
    RESET_CURRENT_TABLE_DATA,
} from "../constants/currentTableDataConstants";

export const setCurrentTableDataAction = (data) => async (dispatch) => {
    dispatch({
        type: SET_CURRENT_TABLE_DATA,
        payload: data,
    });
};

export const resetCurrentTableDataAction = () => async (dispatch) => {
    dispatch({
        type: RESET_CURRENT_TABLE_DATA,
    });
};
