import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import Validation from "../../utils/Validation/Validation";
import { useDispatch } from "react-redux";
import { setAdminDataAction } from "../../redux/actions/adminActions";
import { ClipLoader } from "react-spinners";
import browser from "browser-detect";

import "../../scss/AccountAuth/loginPage.scss";
import { axiosClient } from "../../libs/axiosClient";
import { generateDeviceID } from "../../utils/helpers";

function LoginPage() {
  const [state, setState] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const userBrowser = browser();

  const progress = () => {
    return <ClipLoader size={10} color={loading ? "#fff" : ""} />;
  };

  useEffect(() => {
    Validation.validatePassword(state.password) &&
    Validation.validateUsername(state.username)
      ? setBtnDisabled(false)
      : setBtnDisabled(true);
  }, [state.password, state.username]);

  const handleInput = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  // const rememberDevice = async()=>{
  //    const user = await Auth.currentAuthenticatedUser({
  //       bypassCache: true
  //    });
  //    user.getCachedDeviceKeyAndPassword();
  //    user.setDeviceStatusRemembered({
  //       onSuccess: (result) => {
  //         console.log(result)
  //       },
  //       onFailure: (err) => {
  //          console.error(err);
  //       }
  //    });
  // }

  async function signIn() {
    setLoading(true);
    try {
      const resp = await axiosClient.post("/auth/login", {
        username: state?.username,
        password: state?.password,
        deviceID: generateDeviceID(),
        userBrowser,
      });

      const respData = resp.data.data;

      if (resp.status === 201) {
        dispatch(setAdminDataAction(respData));
        navigate("/signup");
      } else {
        dispatch(setAdminDataAction(respData));
        setLoading(false);
        navigate("/dashboard");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error.response.data.message);
    }
  }

  useEffect(() => {
    if ('Notification' in window && Notification.permission !== 'granted') {
      Notification.requestPermission().then((permission) => {
        console.log('')
      });
    }
  }, [])

  return (
    <>
      <div className="login_card_content">
        <div className="form_header">
          <h1>Admin Login</h1>
        </div>
        <div className="form_wrapper">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Admin username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Admin username"
                name="username"
                id="username_input"
                onChange={handleInput}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                id="password_input"
                onChange={handleInput}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check
                type="checkbox"
                label="Keep me logged in on this device"
              />
            </Form.Group>
            <div className="d-grid gap-2" onClick={signIn}>
              <Button variant="primary" size="lg" disabled={btnDisabled}>
                {loading ? progress() : "Log In"}
              </Button>
            </div>
            <div className="foot_note">
              Can’t remember your password?&nbsp;
              <span onClick={() => navigate("/verify")}>Reset Password</span>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
