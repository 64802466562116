import React from "react";
import { Trash, ChevronDown } from "react-bootstrap-icons";
import { formatDate } from "../../utils/Experiences/utils";

function SessionCard(props) {
  const { id, data, state, cancelSession } = { ...props };

  return (
    <div className="session_card mt-20 p-20">
      <div className="session_card_top mbd flex-between pv-10">
        <div className="head_left">Session #{id}</div>
        {!state && (
          <div className="head_right">
            {/*<Pencil className="mr-10" />*/}
            <Trash className="mr-10" onClick={() => cancelSession(id)} />
            <ChevronDown className="cursor-pointer" />
          </div>
        )}
      </div>
      <div className="session_card_body mt-20">
        <h6 className="bold">{data?.session_date || formatDate(data?.date)}</h6>
        <h6>
          {data?.start_time} - {data?.end_time}
        </h6>
        <h6 className="mt-20" style={{ color: !state ? "#2D9371" : "#969CA5" }}>
          {data?.slots} slots available
        </h6>
      </div>
    </div>
  );
}

export default SessionCard;
