import React, { useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import AccountAuthTemplate from "./templates/AccountAuthTemplate";
import DashBoardTemplate from "./templates/DashBoardTemplate";
import { DAYS } from "./utils/data";
import {filterYouthByState, formatDateWithBar} from "./utils/helpers";
import { AllCategories } from "./services/query/categories";
import { AllStudents } from "./services/query/students";
import { AllResources } from "./services/query/resources";
import { AllEducators } from "./services/query/educators";

function App() {
  const user = useSelector((store) => store.adminUser.data);
  const [interests, setInterests] = useState(0);
  const [youths, setYouths] = useState(0);
  const [resources, setResources] = useState(0);
  const [educators, setEducators] = useState(0);
  const userName = user.userName || "";
  const currentState = useSelector((store) => store.currentState.state);

  const today = new Date();
  const day = today.getDay();

  useEffect(() => {
    AllCategories().then((categories) => setInterests(categories.length));
    AllStudents().then((youths) => setYouths(filterYouthByState(youths, currentState)?.length));
    AllResources().then((resources) =>
      setResources(resources.filter((elt) => elt?.isAdmin === true).length),
    );
    AllEducators().then((educators) =>
      setEducators(educators.filter((elt) => elt?.isAdmin === true).length),
    );
  }, [currentState]);

  return (
    <>
      <AccountAuthTemplate path="/" />
      <AccountAuthTemplate path="/login" />
      <AccountAuthTemplate path="/signup" />
      <AccountAuthTemplate path="/verify" />

      <DashBoardTemplate
        path="/dashboard"
        header={`Hey ${userName?.split(" ")[0] || ""},`}
        subHeader={`${DAYS[day]}, ${formatDateWithBar(today)}`}
      />

      <DashBoardTemplate
        path="/youth"
        header="Youth"
        subHeader={`Showing ${youths} youths`}
      />

      <DashBoardTemplate
        path="/resources"
        header="Resources"
        subHeader={`Showing ${resources} resources`}
      />

      <DashBoardTemplate
        path="/councelors"
        header="Mentors"
        subHeader={`Showing ${educators} mentors`}
      />

      <DashBoardTemplate
        path="/account-verification"
        header="Account Verification requests"
        subHeader=""
      />

      <DashBoardTemplate
        path="/account-deactivation"
        header="Account De-activation requests"
        subHeader=""
      />

      <DashBoardTemplate
        path="/feedback"
        header="Feedback Center"
        subHeader=""
      />

      <DashBoardTemplate
        path="/store"
        header="Store"
        subHeader="Junity store statistics and earnings"
      />

      <DashBoardTemplate
        path="/experiences"
        header="Experiences"
        subHeader=""
      />

      <DashBoardTemplate path="/purchases" header="Purchases" subHeader="" />

      <DashBoardTemplate
        path="/interests"
        header="Interests"
        subHeader={`Showing ${interests} interests`}
      />

      <DashBoardTemplate path="/credits" header="Credits" subHeader="" />

      <DashBoardTemplate
        path="/administrators/:id"
        header="Admin Settings"
        subHeader=""
      />

      <DashBoardTemplate
        path="/administrators"
        header="Admin Settings"
        subHeader=""
      />

      <DashBoardTemplate
        path="/roles-and-permissions"
        header="Admin Settings"
        subHeader=""
      />

      <DashBoardTemplate path="/my-account" header="My Account" subHeader="" />
    </>
  );
}

export default App;
