import React from "react";
import ChartData from "../data";
import ReactApexChart from "react-apexcharts";
import { cleanChartData, sameYearData } from "../../../utils/helpers";

const sameYearChartDataArray = (data) => {
  return data.filter((elt) => sameYearData(elt) === true);
}

const sameMonthChartDataArray = (data) => {
  return cleanChartData(data.reduce((acc, elt) => {
    const resp = { ...acc };
    const dateCreatedAt = new Date(elt.createdAt);
    if (Object.keys(resp).includes(String(dateCreatedAt.getMonth()))) {
      if (Object.keys(elt).includes("credits"))
        resp[dateCreatedAt.getMonth()] += elt.credits;
      else resp[dateCreatedAt.getMonth()] += elt.credits;
    } else {
      if (Object.keys(elt).includes("credits"))
        resp[dateCreatedAt.getMonth()] = elt.credits;
      else resp[dateCreatedAt.getMonth()] = elt.credits;
    }

    return resp;
  }, {}));
}

const BasicAreaChart = ({ purchase, usage, colors }) => {
  const sameYearPurchaseData = sameYearChartDataArray(purchase);
  const sameYearUsageData = sameYearChartDataArray(usage);

  const sameMonthChartPurchaseDataArr = sameMonthChartDataArray(sameYearPurchaseData);
  const sameMonthChartUsageDataArr = sameMonthChartDataArray(sameYearUsageData);

  const series = [
    { name: "Purchases (JC)", data: Object.values(sameMonthChartPurchaseDataArr) },
    { name: "Usages (JC)", data: Object.values(sameMonthChartUsageDataArr) },
  ];
  const basicOptions = {
    chart: {
      id: "Chart",
      type: "area",
      height: 350,
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    labels: ChartData.monthDataSeries1.dates,
    xaxis: {
      type: "category",
      categories: ChartData.monthDataSeries1.dates,
      title: {
        text: "Month",
        style: {
          fontSize: "15px",
          color: "#667085",
        },
      },
    },
    colors,
    yaxis: {
      opposite: false,
      title: {
        text: "Purchases vs Usage",
        style: {
          fontSize: "15px",
          color: "#667085",
        },
      },
    },
    fill: {
      opacity: [0, 0, 0, 0],
      type: "gradient",
    },
    legend: {
      horizontalAlign: "left",
    },
  };

  return (
    <div>
      <ReactApexChart
        options={basicOptions}
        series={series}
        type="area"
        height={300}
      />
    </div>
  );
};

export default BasicAreaChart;
