import { Image } from "react-bootstrap-icons";
import "./style.scss";
import React, { useRef } from 'react';
import { Pencil } from "react-bootstrap-icons";


function UploadButton({setImage}) {

  const imgWrapperRef = useRef()
  const iconRef = useRef()
  const imgRef = useRef()
  const pencilRef = useRef()

  const handleImageUpload = (e) => {
    const image = e.target.files[0]
    
    if (image) {
      const imgUrl = window.URL.createObjectURL(image);
      const refStyle = imgWrapperRef.current.style;
      
      refStyle.backgroundImage = `url(${imgUrl})`;
      refStyle.backgroundSize = "cover";
      refStyle.backgroundColor = "#fff";
      refStyle.backgroundRepeat = "no-repeat";
      
      iconRef.current.style.display = "none";
      pencilRef.current.style.display = "flex";
      
      setImage(image);
    }

  }

  const clickImage = () => {
    imgRef.current.click()
  }

  return ( 
    <div>
      <div 
        className="upload_btn"
        ref={imgWrapperRef}
      >
        <input 
          src=""
          type="file" 
          className="choose_file"
          accept="image/*"
          ref={imgRef}
          onChange={handleImageUpload}
        />
        <div
          className="upload_pencil"
          ref={pencilRef}
          onClick={clickImage}
        >
          <Pencil fill="#fff" size={25}/>
        </div>
        <div 
          className="img_icon_wrapper"
          onClick={clickImage}
          ref={iconRef}
        >
          <Image 
            fill="#969CA5" 
            size={20}
          />
        </div>  
      </div>
    </div>
  );
}

export default UploadButton;