import React, { useState, useEffect } from "react";
import TableTemplate from "../../components/Admin/TableTemplate";
import PaginationWrapper from "../../components/Youth/PaginationWrapper";
import TopSection from "../../components/Youth/TopSection";
import { Tabs, Tab, Badge } from "react-bootstrap";
import { 
  accountVerificationTableList, 
  councelorsAccountVerificationTableList 
} from "../../assets/data/accountVerificationTableList";
import { AllEducators } from "../../services/query/educators"
import { AllResources } from "../../services/query/resources"
import { createCounselorsTableContent } from "../../utils/Counselors/utils"

import "../../scss/Admin/accountVerificationPage.scss"
import RequestDeactivationModal from "../../components/AccountVerification/RequestDeactivationModal";
import {useDispatch, useSelector} from "react-redux";
import DeactivationRequested from "../../components/AccountVerification/DeactivationSucessful";
import {setCurrentTableDataAction} from "../../redux/actions/currentTableDataActions";


function AccountVerificationPage() {
  const [counselorsContentList, setCounselorsContentList] = useState([]);
  const [resourcesContentList, setResourcesContentList] = useState([]);
  const [refetch, setRefetch] = useState(true);
  const [resources, setResources] = useState([]);
  const [counsellors, setCounsellors] = useState([]);
  const [key, setKey] = useState('resources');

  const dispatch = useDispatch();

  const popups = useSelector((store) => store.popups.data);
  const currentTableData = useSelector((store) => store.currentTablesData.data);

  const handleRefetch = () => {
    setRefetch(true);
  }

  const setTitle = (title, number) => {
    return (
      <div className="set_title_wrapper">
        {title}
        <span className="ml-10"><Badge pill bg="danger">
          {number}
          </Badge>
        </span>
      </div>
    )
  }

  useEffect(() => {
    if (refetch) {
      AllEducators().then((educators) => { 
        const filteredEducators = educators.filter(
          (elt) => (elt.verification_date_started) !== null && (elt?.isAdmin === true)
        );
        const detail = filteredEducators?.find((item) => item?.id === currentTableData?.accountVerificationTable?.id)
        dispatch(setCurrentTableDataAction(detail || {}));
        setCounsellors(filteredEducators); 
        setCounselorsContentList(createCounselorsTableContent(filteredEducators)) 
      });
  
      AllResources().then((resources) => {
        const filteredResources = resources.filter(
          (elt) => (elt.verification_date_started !== null) && (elt?.isAdmin === true) 
        );
        const detail = filteredResources?.find((item) => item?.id === currentTableData?.accountVerificationTable?.id)
        dispatch(setCurrentTableDataAction(detail || {}));
        setResources(filteredResources); 
        setResourcesContentList(createCounselorsTableContent(filteredResources)) 
      });
      setRefetch(false);
    }
  }, [refetch]);

  return ( 
    <div className="tabs_wrapper account_verification_tabs">
      <Tabs
        id="youth_tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="resources" title={setTitle("Resources", resources?.length)}>
          <div className="account_v_main_content">
            <div className="account_v_main_inner">
              <TopSection 
                inputPlaceholder="Search for a resource"
              />
              <TableTemplate 
                layout="account-verification"
                subLayout="resources"
                details={resources}
                detailsRefetch={handleRefetch}
                tableContent={resourcesContentList}
                data={accountVerificationTableList}
                btnText=""
              />
              <PaginationWrapper
                  moveToPreviousPage={() => {}}
                  moveToNextPage={() => {}}
                  page={1}
                  setPageLimit={() => {}}
                  nextToken={""}
              />
            </div>
          </div>
        </Tab>
        <Tab eventKey="councelors" title={setTitle("Councelors", counsellors?.length)}>
          <div className="account_v_main_content">
            <div className="account_v_main_inner councelors">
              <TopSection inputPlaceholder="Search for an institution"/>
              <TableTemplate 
                data={councelorsAccountVerificationTableList}
                subLayout="councelors"
                details={counsellors}
                detailsRefetch={handleRefetch}
                tableContent={counselorsContentList}
                layout="account-verification"
              />
              <PaginationWrapper
                  moveToPreviousPage={() => {}}
                  moveToNextPage={() => {}}
                  page={1}
                  setPageLimit={() => {}}
                  nextToken={""}
              />
            </div>
          </div>
        </Tab>
      </Tabs>
      <RequestDeactivationModal
          show={popups?.overviewDeactivation}
          details={currentTableData?.accountVerificationTable}
          previousModal={"leftModal"}
          refetch={() => setRefetch(prev => !prev)}
      />
      <DeactivationRequested
          show={popups?.deactivateRequested}
      />
    </div>
  );
}

export default AccountVerificationPage;