import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { sameYearData } from '../../../utils/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: '',
    },
  },
  legend: {
    display: false,
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
  }
};

export const data = (chartData, type) => {
  const sameYearChartDataArr = chartData.filter((elt) => sameYearData(JSON.parse(elt)));
  const sameMonthChartDataArr = sameYearChartDataArr.reduce((acc, elt) => {
    const resp = { ...acc };
    const dateCreatedAt = new Date(JSON.parse(elt).createdAt);
    if (Object.keys(resp).includes(dateCreatedAt.getMonth())) {
      resp[dateCreatedAt.getMonth()] += JSON.parse(elt)[type === "multiplier" ? "earned_credit" : "credit"];
    } else {
      resp[dateCreatedAt.getMonth()] = JSON.parse(elt)[type === "multiplier" ? "earned_credit" : "credit"];
    }

    return resp;
  }, {});
  
  return {
    labels: Object.keys(sameMonthChartDataArr),
    datasets: [
      {
        fill: true,
        tension: 0.5,
        data: Object.values(sameMonthChartDataArr),
        borderColor: '#39B88D',
        pointBackgroundColor: "#39B88D",
        backgroundColor: "#515A6A0F",
        pointBorderColor: "#39B88D14"
      },
    ],
  }
  
};

function App({ chartData, type }) {
  return <Line options={options} data={data(chartData, type)} />;
}

export default App;
