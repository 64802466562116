import React from 'react'
import ModalWrapper from "../../../widgets/Modal/modal";
import { Arrow90degLeft, Check } from "react-bootstrap-icons";


function SaveStatus(props) {

  const { show, setShow, name } = {...props}

  return ( 
    <ModalWrapper
      state={`${show ? "show" : ""}`}
    >
      <div className="wrapper p-20">
        <div 
          className="return_btn p-20"
          onClick={() => setShow(false)}
        >
          <Arrow90degLeft className="mr-10"/>
          <span>Back to Admin Settings</span>
        </div>
        <div 
          className="check_icon_wrapper mt-40 flex-center"
          style={{display: show ? "flex": "none"}}
        >
          <div className="check_icon p-20"><Check size={80}/></div>
        </div>
        <div className="text-center mt-20">
          <h4 className="bold">Role Created</h4>
          <h6>{`You have created a new role "${name}"`}</h6>
        </div>

      </div>
    </ModalWrapper>
  );
}

export default SaveStatus;