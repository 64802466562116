import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import FeedbackCounselors from "../../components/FeedbackPage/FeedbackCounselors";
import FeedbackResources from "../../components/FeedbackPage/FeedbackResources";
import FeedbackYouth from "../../components/FeedbackPage/FeedbackYouth";
import "../../scss/Admin/feedBackCenterPage.scss"



function FeedBackCenterPage() {

  const [key, setKey] = useState("youth");

  return ( 
    <div className="tabs_wrapper account_verification_tabs">
      <Tabs
        id="youth_tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="youth" title="Youth">
          <FeedbackYouth />
        </Tab>
        <Tab eventKey="resources" title="Resources">
          <FeedbackResources />
        </Tab>
        <Tab eventKey="councelors" title="Councelors">
          <FeedbackCounselors />
        </Tab>
      </Tabs>
    </div>
  );
}

export default FeedBackCenterPage;