import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as CheckmarkSuccess} from '../../assets/images/checkmark-success.svg';
import { ReactComponent as SlashCircle} from '../../assets/images/slash-circle.svg';
import { ReactComponent as MapSuccess } from '../../assets/images/map-success.svg';
import { ReactComponent as MapDanger } from '../../assets/images/map-danger.svg';
import './style.css';

const icons = {
  checkmarkSuccess: <CheckmarkSuccess/>,
  slashCircle: <SlashCircle/>,
  mapSuccess: <MapSuccess/>,
  mapDanger: <MapDanger/>

}

export const PushNotificationItem = ({notification, onClose})=>{
  const [closed, setClosed] = useState(false);
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setClosed(true);
    setTimeout(() => {
      setShow(false);
      onClose();
    }, 1000);
  }

  useEffect(()=>{
    const timer = setTimeout(() => {
      handleClose(); 
    }, 5000); // hide after 10 seconds

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
    {show && 
      <div className={`notification push ${closed ? 'slide-out' : ''} mb-2`}>
        <div className={`notification-icon ${notification?.status}`}>
          {notification?.icon 
          ? <>{icons[notification?.icon]}</>
          : <i className="fa fa-info" aria-hidden="true"></i>
        }
        </div>
        <div className="notification-content">
          <p>{notification?.text}</p>
          {notification?.linkUrl &&
            <Link 
              to={notification?.linkUrl} 
              className='notification-link'
              onClick={handleClose}
              >{notification?.linkTitle}</Link>
          }
        </div>
        <i className="fa fa-times cursor-pointer" onClick={handleClose} aria-hidden="true"></i>
      </div>
    }
    </>
  )
}

export const InAppNotificationItem = ({notification, onClose})=>{
  const [closed, setClosed] = useState(false);
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setClosed(true);
    setTimeout(() => {
      setShow(false);
      onClose()
    }, 1000);
  }

  useEffect(()=>{
    const timer = setTimeout(() => {
      handleClose(); 
    }, 3000); // hide after 10 seconds

    return () => clearTimeout(timer)
  }, [])

  return (
    <>
    {show && 
      <div className={`notification inapp ${closed ? 'slide-out' : ''} mb-2`}>
        <div className="notification-content">
          <p>{notification?.text}</p>
          {notification?.linkUrl &&
            <Link 
              to={notification?.linkUrl} 
              className='notification-link'
              onClick={handleClose}
              >{notification?.linkTitle}</Link>
          }
        </div>
      </div>
      }
    </>
  )
}

export const AppNotifications = ({notifications, removeNotification}) => {
  return (
    <div className="notifications-container">
        {notifications.map((notification, index) => (
          <>
            {notification?.type === 'push' ?
              <PushNotificationItem
                key={index}
                notification={notification}
                onClose={()=>removeNotification(index)}
              />
            : null
            }
          </>
        ))}
        {notifications.map((notification, index) => (
          <>
            {notification?.type === 'inapp'?
              <InAppNotificationItem
                key={index}
                notification={notification}
                onClose={()=>removeNotification(index)}
              />
            : null
            }
          </>
        ))}
      </div>
  );
};

