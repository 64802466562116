import React from 'react';
import "./style.scss"

function ModalWrapper(props) {

  const {
    children, 
    state,
    className
  } = {...props}

  return ( 
    <div className={`modal_wrapper ${state}`}>
      <div className={`left_modal ${state} ${className}`}>
        {children}
      </div>
    </div>
  );
}

export default ModalWrapper;