import React from "react";
import { ChevronExpand } from "react-bootstrap-icons";

const expandIcon = (
  <span>
    <ChevronExpand fontSize={18} />
  </span>
);

export const purchasesTableList = {
  th: [
    {
      component: <>NAME{expandIcon}</>,
      cls: "col-2",
    },
    {
      component: <>EXPERIENCES{expandIcon}</>,
      cls: "col-2",
    },
    {
      component: <>AMOUNT{expandIcon}</>,
      cls: "col-2",
    },
    {
      component: <>SLOTS{expandIcon}</>,
      cls: "col-2",
    },
    {
      component: <>STATUS{expandIcon}</>,
      cls: "col-2",
    },
    {
      component: <>DATE PURCHASED{expandIcon}</>,
      cls: "col-2",
    },
  ],
};
