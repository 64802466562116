import {
  SET_ADMIN_DATA,
  SET_ADMIN_TOKEN_DATA,
  RESET_ADMIN_DATA,
} from "../constants/adminConstants";

const initialLoginState = {
  data: {},
};

const adminReducer = (state = initialLoginState, action) => {
  switch (action.type) {
    case SET_ADMIN_DATA:
      return { ...state, data: action.payload };
    case SET_ADMIN_TOKEN_DATA:
      return { ...state, data: { ...state.data, ...action.payload } };
    case RESET_ADMIN_DATA:
      state = initialLoginState;
      return state;
    default:
      return state;
  }
};

export default adminReducer;
