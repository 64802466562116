
export const myAccountNotificationSettingList = [
  {
    "title": "New verification request",
    "id": "verification_request"
  }
]

export const myAccountFeedBackSettingsList = [
  {
    "title": "Feedback on Opportunities",
    "id": "opportunities_feedback"
  },
  {
    "title": "Issue reports",
    "id": "issue_reports"
  },
  {
    "title": "Interest Suggestions",
    "id": "interest_suggestions"
  },

]