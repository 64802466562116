import React from 'react';

export function CreateInfoCard (props) {
  
  const { data } = {...props}

  return (
    <div 
      className="top_card flex-start p-20 m-1"
      style={{
        backgroundColor: data?.bg,
        borderRadius: 10
      }}
    >
      <div 
        className="top_card_img mr-10"
      >
        {data.icon}
      </div>
      <div className="top_card_info flex-v">
        <div 
          className="card_info_title"
          style={{color: data?.title_color}}
        >
          {data.title}
        </div>
        <div 
          className="card_info_main_text"
          style={{color: data?.title_color}}
        >
          {data.text}
        </div>

        <div className="card_info_bottom_link mt-20">
          {data.body}
        </div>
      </div>
    </div>
  )
}