import React, { useState, useEffect } from "react";
import Switch from "react-switch";
import {
  myAccountFeedBackSettingsList,
  myAccountNotificationSettingList,
} from "../../assets/data/myAccount";
import {
  getNotificationSettings,
  updateNotificationSettings,
  createNotificationSettings,
} from "../../utils/Account";
import { useSelector } from "react-redux";

function RightContent() {
  const [user] = useState(useSelector((store) => store.adminUser.data));
  const [settings, setSettings] = useState({
    id: "",
    interest_suggestions: false,
    issue_reports: false,
    opportunities_feedback: false,
    verification_request: false,
  });
  const [, setHasSettings] = useState(false);

  const handleSwitch = (checked, event, id) => {
    setSettings({
      ...settings,
      [id]: checked,
    });

    updateSettings({ [id]: checked });
  };

  const createSettings = async () => {
    try {
      setHasSettings(true);
      delete settings["id"];
      settings["admin_id"] = user?.sub;
      const data = await createNotificationSettings(settings);
      if (Object.keys(data).length) setSettings({ ...settings, ...data });
    } catch (error) {
      console.log(error);
    }
  };

  const getSettings = async () => {
    try {
      let data = await getNotificationSettings(user?.sub);
      if (Object.keys(data).length) {
        setSettings({ ...settings, ...data });
      } else {
        await createSettings();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateSettings = async (changes) => {
    try {
      const data = await updateNotificationSettings({
        ...settings,
        ...changes,
      });
      if (Object.keys(data).length) setSettings({ ...settings, ...data });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <div className="right_content">
      <div className="pm-20">
        <h4 className="bold">NOTIFICATION SETTINGS</h4>
        <h6>Customize your email notification settings</h6>
      </div>
      <div className="pm-20">
        <div className="mt-20">
          <h6 className="">ACCOUNT CENTER</h6>
          <div className="perms_wrapper  mt-20">
            {myAccountNotificationSettingList.map((item, index) => (
              <div
                className="d-flex justify-content-between align-items-center mbd pv-10"
                key={index}
              >
                <div>{item.title}</div>
                <div>
                  <Switch
                    onChange={handleSwitch}
                    checked={settings[item.id]}
                    onColor="#2D9371"
                    offColor="#B9BDC3"
                    uncheckedIcon={true}
                    checkedIcon={true}
                    className="mr-10"
                    id={item.id}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-20">
          <h6 className="">FEEDBACK CENTER</h6>
          <div className="perms_wrapper mt-20">
            {myAccountFeedBackSettingsList.map((item, index) => (
              <div className="flex-between mbd pv-10" key={index}>
                <div style={{ width: "70%" }}>{item.title}</div>
                <div className="flex-end" style={{ width: "30%" }}>
                  <div>
                    <Switch
                      onChange={handleSwitch}
                      checked={settings[item.id]}
                      onColor="#2D9371"
                      offColor="#B9BDC3"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className="mr-10"
                      id={item.id}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightContent;
