import React from 'react';
import ReactApexChart from "react-apexcharts";
import { cleanChartData, sameYearData } from "../../../utils/helpers";
import ChartData from "../data";

const sameYearChartDataArray = (data) => {
  return data.filter((elt) => sameYearData(elt) === true);
}

const sameMonthChartDataArray = (data) => {
  return cleanChartData(data.reduce((acc, elt) => {
    const resp = { ...acc };
    const dateCreatedAt = new Date(elt.createdAt);
    if (Object.keys(resp).includes(String(dateCreatedAt.getMonth()))) {
      if (Object.keys(elt).includes("credits"))
        resp[dateCreatedAt.getMonth()] += elt.credits;
      else resp[dateCreatedAt.getMonth()] += elt.credits;
    } else {
      if (Object.keys(elt).includes("credits"))
        resp[dateCreatedAt.getMonth()] = elt.credits;
      else resp[dateCreatedAt.getMonth()] = elt.credits;
    }

    return resp;
  }, {}));
}

const BarChart = ({ purchasesByResource, purchasesByCounselors }) => {
  const sameYearPurchaseByResourceData = sameYearChartDataArray(purchasesByResource);
  const sameYearPurchaseByCounselorsData = sameYearChartDataArray(purchasesByCounselors);

  const sameMonthPurchasesByResourceData = sameMonthChartDataArray(sameYearPurchaseByResourceData);
  const sameMonthPurchasesByCounselorsData = sameMonthChartDataArray(sameYearPurchaseByCounselorsData);

  const series = [{
    name: 'Resources',
    data: Object.values(sameMonthPurchasesByResourceData)
  }, {
    name: 'Counselors',
    data: Object.values(sameMonthPurchasesByCounselorsData)
  }];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    colors: ["#39B88D", "#38609F"],
    xaxis: {
      type: "category",
      categories: ChartData.monthDataSeries1.dates,
      title: {
        text: "Month",
        style: {
          fontSize: "15px",
          color: "#667085",
          fontWeight: 500
        },
      },
    },
    yaxis: {
      opposite: false,
      title: {
        text: "Earnings & Purchases",
        style: {
          fontSize: "15px",
          color: "#667085",
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "JC"
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: 40
    }
  };
    

  return (
    <div id="chart">
      <ReactApexChart 
        options={options} 
        series={series} 
        type="bar" 
        height={350} 
      />
    </div>
  )
};

export default BarChart;

