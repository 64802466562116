import React from 'react';
import { 
  Check2Circle, CheckAll, ExclamationTriangle,
  Telephone, Envelope, ThreeDots
} from "react-bootstrap-icons";
import {formatDatePurchaseStyle, getInitial} from "../helpers";

export const setDate = (dateTime, id) => {
  const divId = parseInt(id)
  const getDiv = document.getElementsByClassName("sect_text")[divId-1];
  if (dateTime !== ""){
    getDiv.children[2].innerHTML = "You on " + dateTime
  }

  else {
    getDiv.children[2].innerHTML = dateTime
  }

}

export const setColor = (percentage, setProgressCircleColor) => {
  if (percentage <= 20) {
    setProgressCircleColor("#FFDA9A")
  }
  else if (percentage > 20 && percentage <= 40) {
    setProgressCircleColor("#FFC768")
  }

  else if (percentage > 40 && percentage <= 60) {
    setProgressCircleColor("#63E3B7")
  }

  else if (percentage > 60 && percentage <= 80) {
    setProgressCircleColor("#39B88D")
  }

  else {
    setProgressCircleColor("#2D9371")
  }
}

export const formatDate = (date) => {
  const dateNow = date.toString().split(" ")
  const monthDay = dateNow[1] + " " + dateNow[2]
  let time = dateNow[4].split(":")
  let hour = time[0]

  const ampm = parseInt(hour) >= 12 ? 'pm' : 'am';
  hour %= 12
  hour = hour || 12

  return `${monthDay}, ${dateNow[3]}, ${hour}:${time[1]} ${ampm}`
}

export const TYPES  = {
  "initial": {
    "text": "Complete Verification",
    "icon": <Check2Circle/>,
    "bg": "#F4F5F7",
    "fg": "#969CA5",
  },
  "done": {
    "text": "Complete Verification",
    "icon": <CheckAll/>,
    "bg": "#2D9371",
    "fg": "#ffffff",
  },
  "complete": {
    "text": "Revoke Verification",
    "icon": <ExclamationTriangle/>,
    "bg": "#FBD5D5",
    "fg": "#D41811",
  }
}


export function CreateInfoCard (props) {
  
  const { data } = {...props}

  return (
    <div 
      className="top_card flex-start p-20"
      style={{backgroundColor: data?.bg}}
    >
      <div 
        className="top_card_img mr-10"
      >
        {data.icon}
      </div>
      <div className="top_card_info flex-v">
        <div 
          className="card_info_title"
          style={{color: data?.title_color}}
        >
          {data.title}
        </div>
        <div 
          className="card_info_main_text"
          style={{color: data?.title_color}}
        >
          {data.text}
        </div>

        <div className="card_info_bottom_link mt-20">
          {data.body}
        </div>
      </div>
    </div>
  )
}


export function CreateTeamDetailCard (props) {
  
  const { item, index, callBack } = {...props}

  const handleTelephoneClick = () => {
    const phoneNumber = item?.phone || item?.contact_phone;
    window.location.href = `tel:${phoneNumber}`;
  };

  const handleEnvelopeClick = () => {
    const emailAddress = item?.contact_email || item?.email;
    window.location.href = `mailto:${emailAddress}`;
  };


  return (
    <div 
      className="team_data flex-between mbd pv-10"
      key={index}
    >
      <div className="flex-start align-items-center">
        <div className="mr-10">
          {item?.image
              ?
              <img
                src={item.image}
                alt=""
                className="team_member_img"
              />
              : <div className="team_member_profile">
                {getInitial(item?.name)}
              </div>
          }
        </div>
        <div className="name_role flex-v">
          <div className="flex-start">
            <span className="mr-10">{item.name}</span>
            {item?.isAdmin === true && <span className="admin_badge">Admin</span>}
          </div>
          <span>Joined {formatDatePurchaseStyle(item?.createdAt)}</span>
        </div>
      </div>
      <div className="flex-start align-items">
        <div className="icon_wrapper" title={`Call ${item?.phone || item?.contact_phone}`}>
          <Telephone 
            size={18}
            className="point"
            onClick={handleTelephoneClick}
          />
        </div>
        <div className="icon_wrapper" title={`Email ${item?.email || item?.contact_email}`}>
          <Envelope 
            size={18}
            className="point"
            onClick={handleEnvelopeClick}
          />
        </div>
        <div className="icon_wrapper">
          <ThreeDots
              className="point"
              size={18}
              onClick={callBack}
          />
        </div>
      </div>   
    </div>
  )
}

export const isPreviousStepVerified = (taskNumber, id) => {
  return taskNumber?.includes(`${parseInt(id) - 1}`)
}

export const toggleCheck = (props) => {
  const {
    id,
    percentageProgress,
    taskNumber,
    setTaskNumber,
    setPercentageProgress,
  } = {...props}

  
  if (taskNumber?.includes(id)) {
    const taskNumberArray = taskNumber?.filter(
      elt => elt !== id
    )
    setPercentageProgress(percentageProgress - 20)
    setTaskNumber(taskNumberArray)
    setDate("", id);
  } else {
    setPercentageProgress(percentageProgress + 20)
    setTaskNumber([...taskNumber, id])
    setDate(formatDate(new Date()), id);
  }
}
