import React from 'react';
import { ChevronExpand } from "react-bootstrap-icons"
import { nameCol, verificationCol } from "../../utils/TableWrapper/tableWrapper"


const expandIcon = <span><ChevronExpand fontSize={18}/></span>

export const resourcesTableList = 
  {
    "th": [
      {
        "component": "ORGANIZATION NAME",
        "cls": "org_name_col",
        "sortable": false,
        "label": "name"
      },
      {
        "component": <>NO. OF LOCATIONS{expandIcon}</>,
        "cls": "no_of_loc",
        "sortable": true,
        "label": "locations"
      },
      {
        "component": <>VERIFICATION{expandIcon}</>,
        "cls": "  org_verification_col",
        "sortable": true,
        "label": "verification_status"
      },
      {
        "component": " ",
        "cls": "final_col",
        "sortable": false,
        "label": "actions"
      },
    ],

    "tc": [
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/achieve_hartford.png"),
            name: "Achieve Hartford",
            layout: 2,
            bottomText: "Super resource",
            verified: true
          }),
          "Achieve Hartford",
          require("../../assets/images/achieve_hartford.png"),
        ],
        ["no_of_loc","18"],
        ["org_verification_col",verificationCol("Verified")],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/amazon_1.png"),
            name: "Amazon",
            layout: 2,
            bottomText: "Super resource",
            verified: true
          }),
          "Amazon",
          require("../../assets/images/amazon_1.png"),
        ],
        ["no_of_loc","18"],
        ["org_verification_col",verificationCol("Verified")],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/dell_1.png"),
            name: "Dell",
            layout: 2,
            bottomText: "Super resource",
            verified: false
          }),
          "Dell",
          require("../../assets/images/dell_1.png"),
        ],
        ["no_of_loc","18"],
        ["org_verification_col",verificationCol("Verified")],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/salesforce_1.png"),
            name: "Salesforce",
            layout: 2,
            bottomText: "Super resource",
            verified: true
          }),
          "Salesforce",
          require("../../assets/images/salesforce_1.png"),
        ],
        ["no_of_loc","18"],
        ["org_verification_col",verificationCol("In Progress")],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../../assets/images/johnson_johnson.png"),
            name: "Johnson & Johnson",
            layout: 2,
            bottomText: "Super resource",
            verified: false
          }),
          "Johnson & Johnson",
          require("../../assets/images/johnson_johnson.png"),
        ],
        ["no_of_loc","18"],
        ["org_verification_col",verificationCol("Not Verified")],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/netflix.png"),
            name: "Netflix",
            layout: 2,
            bottomText: "Super resource",
            verified: true
          }),
          "Netflix",
          require("../images/netflix.png"),
        ],
        ["no_of_loc","18"],
        ["org_verification_col",verificationCol("In Progress")],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/mastercard.png"),
            name: "Mastercard",
            layout: 2,
            bottomText: "Super resource",
            verified: true
          }),
          "Mastercard",
          require("../images/mastercard.png"),
        ],
        ["no_of_loc","18"],
        ["org_verification_col",verificationCol("Verified")],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/starbucks.png"),
            name: "Starbucks",
            layout: 2,
            bottomText: "Super resource",
            verified: false
          }),
          "Starbucks",
          require("../images/starbucks.png"),
        ],
        ["no_of_loc","18"],
        ["org_verification_col",verificationCol("Verified")],
        ["final_col", "..."]
      ],
      [
        ["org_name_col", 
          nameCol({
            img: require("../images/nitendo.png"),
            name: "Nitendo",
            layout: 2,
            bottomText: "Super resource",
            verified: true
          }),
          "Nitendo",
          require("../images/starbucks.png"),
        ],
        ["no_of_loc","18"],
        ["org_verification_col",verificationCol("Verified")],
        ["final_col", "..."]
      ],

    ]
  }


export const resourcesLeftModalCards = {
  "Achieve Hartford" : {
    "title": "Archive Hartford",
    "locations": [
      "Hartford, CT", "Albany, NY", "Las Vegas, NV", "Tucson, AZ",
      "New Ark, NJ", "Cincinnati, OH", "San Francisco, CA",
      "Houston, TX", "Rochester, NY", "Yonkers, NY", "Detroit, MI"
    ]
  },
}


